export const $$initialState = {
    dataError : false,
    dataErrorMessage : ''

};

export default function registerReducer($$state = $$initialState, action = null) {
    switch (action.type) {
        case 'TARIFA_INPUT_FIELD_CHANGED':
            return Object.assign({}, $$state, {
                [action.payload.field]: action.payload.value,
                dataErrorMessage: { [action.payload.field] : '' }
            });

        case 'CREATE_TARIFA':
            return Object.assign({}, $$state, {
                tarifa : action.payload.tarifa,
                dataError: false,
                dataErrorMessage : '',
            });

        case 'CREATE_TARIFA_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage : action.payload.message,
            });

        case 'UPDATE_TARIFA':
            return Object.assign({}, $$state, {
                tarifa : action.payload.tarifa,
                dataError: false,
                dataErrorMessage : '',
            });

        case 'UPDATE_TARIFA_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage : action.payload.message,
            });

        case 'TARIFA_LIST':
            return Object.assign({}, $$state, {
                tarifas : action.payload,
                dataError: false,
                dataErrorMessage : '',
            });

        case 'TARIFA_LIST_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage : action.payload.message,
            });

        case 'GET_TARIFA':
            return Object.assign({}, $$state, {
                tarifa : action.payload,
                dataError: false,
                dataErrorMessage : '',
            });

        case 'GET_TARIFA_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage : action.payload.message,
            });

        case 'DELETE_TARIFA':
            return Object.assign({}, $$state, {
                dataError: false,
                dataErrorMessage : '',
            });

        case 'DELETE_TARIFA_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage : action.payload.message,
            });

        default: {
            return $$state;
        }
    }
}
