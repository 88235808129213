import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import Menu from '../../components/menu';

import { Modal } from 'antd';
import { ExclamationCircleOutlined,
            LoadingOutlined,
            ItalicOutlined,
            BoldOutlined } from '@ant-design/icons';

import Loader from '../../components/loader';
import InputText from '../../components/input';
import TextAreaInput from '../../components/textarea';
import ContentEditable from 'react-contenteditable'
import sanitizeHtml from "sanitize-html"

import { inputFieldChanged,
            saveTarifa } from '../../actions/tarifa.js';
import { isLogged } from '../../actions/user.js';

import { withTranslation } from 'react-i18next';

const { confirm } = Modal;

class AdminTarifaCreate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded : true,
            formError : {},
            sanitizeConf : {
                transformTags: {
                    "div": "p",
                },
            },
        };
    }

    componentDidMount() {
        const { i18n } = this.props;

        let lang = typeof this.props.match.params.lang !== 'undefined' ? this.props.match.params.lang : null;

        if(lang === 'es' || lang === 'en'){ 
            i18n.changeLanguage(lang) 
        }

        this._getApiData()
    }

    _getApiData(){
        this.setState({ isLoaded : false})

        Promise.resolve(this.props.isLogged())
        .then((response)=> {
            if(this.props.isLoggedUser){
                if(this.props.user.role.code != 0){
                    this.props.history.push('/404')
                }

                this.setState({ isLoaded : true})
            }else{
                this.props.history.push('/login')
            }
        });
    }

    _saveData() {
        const { t } = this.props;
        let error = false;
        const formError = {};
        let requestedTests = {};
        this.setState({ formError : formError })
        this.setState({ isLoaded : false })

        if (typeof this.props.service === 'undefined' || this.props.service === ''){
            formError.service = t('El nombre del servicio es obligatorio')
            this.setState({ formError : formError })
            error = true
        }
        
        if (typeof this.props.description === 'undefined' || this.props.description === ''){
            formError.description = t('La descripción del servicio es obligatoria')
            this.setState({ formError : formError })
            error = true
        }
        
        if (typeof this.props.time === 'undefined' || this.props.time === ''){
            formError.time = t('El tiempo de entrega es obligatorio')
            this.setState({ formError : formError })
            error = true
        }

        if (typeof this.props.price === 'undefined' || this.props.price === ''){
            formError.price = t('El precio es obligatorio')
            this.setState({ formError : formError })
            error = true
        }

        if (typeof this.props.price !== 'undefined' && this.props.price !== '' && this.props.price.indexOf('€') === -1){
            formError.price = t('El precio debe incluir el simbolo €')
            this.setState({ formError : formError })
            error = true
        }

        if(error === false){
            Promise.resolve(this.props.saveTarifa(
                this.props.service,
                this.props.description,
                this.props.time,
                this.props.price
            ))
            .then((response)=> {
                if(this.props.dataError === false){
                    // Limpiamos la Store
                    this.props.inputFieldChanged('service', '')
                    this.props.inputFieldChanged('description', '')
                    this.props.inputFieldChanged('time', '')
                    this.props.inputFieldChanged('price', '')

                    this.props.history.push('/admin/tarifas')
                }
            });
        }

        this.setState({ isLoaded : true })
    }

    _cancelData() {
        // Limpiamos la Store
        this.props.inputFieldChanged('service', '')
        this.props.inputFieldChanged('description', '')
        this.props.inputFieldChanged('time', '')
        this.props.inputFieldChanged('price', '')

        this.props.history.push('/admin/tarifas')
    }


    _renderForm() {
        const { t } = this.props;

        return(
            <React.Fragment>
                <div className={'box-with-shadow pt-4 pl-5 pr-5 pb-2 border'}>
                    <div className={'row mt-3'}>
                        
                        <div className={'col-3 border-bottom-gray-min pl-0'}>
                            <h2 className={'fs-14 font-weight-normal'}>
                                {t('Titulo')}
                            </h2>
                        </div>
                        <div className={'col-9 border-bottom-gray-min pr-0'}>
                            <div className={'row'}>
                                <div className={'col-12 mb-2'}>
                                    <InputText
                                        id={'service'}
                                        name={'service'}
                                        placeholder={t('Escribe el servicio')}
                                        classes={'w-100 mb-3'}
                                        label={t('Servicio')}
                                        onChange={this.props.inputFieldChanged.bind(this)}
                                        value={this.props.service}
                                        error={this.state.formError.service}
                                        />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className={'row mt-4'}>
                        
                        <div className={'col-3 border-bottom-gray-min pl-0'}>
                            <h2 className={'fs-14 font-weight-normal'}>
                                {t('Descripción')}
                            </h2>
                        </div>
                        <div className={'col-9 border-bottom-gray-min pr-0'}>
                            <div className={'row'}>
                                <div className={'col-12 mb-2'}>
                                    <ContentEditable
                                        placeholder={t('Escribe la descripcion')}
                                        className={'editor-box'}
                                        onChange={(e) => { this.props.inputFieldChanged('description', e.target.value) }}
                                        onBlur={(e) => { this.props.inputFieldChanged('description', sanitizeHtml(this.props.description, this.state.sanitizeConf)) }}
                                        html={this.props.description}
                                        />
                                </div>

                                <div className={'col-12'}>
                                    <button 
                                        title={t('Negrita')}
                                        className={'btn-tool'}
                                        onMouseDown={evt => {
                                            evt.preventDefault();
                                            document.execCommand('bold', false);
                                        }}>
                                        <b><BoldOutlined /></b>
                                    </button>
                                    <button 
                                        title={t('Cursiva')}
                                        className={'btn-tool ml-1 mb-4'}
                                        onMouseDown={evt => {
                                            evt.preventDefault();
                                            document.execCommand('italic', false);
                                        }}>
                                        <b><ItalicOutlined /></b>
                                    </button>
                                </div>

                                {(typeof this.state.formError.description !== 'undefined' && this.state.formError.description !== '') ?
                                    <React.Fragment>
                                        <div className={'col-12'}>
                                            <p className={'red fs-09 mt-0'}>{this.state.formError.description}</p>
                                        </div>
                                    </React.Fragment>
                                : null }
                            </div>
                        </div>

                    </div>

                    <div className={'row mt-4'}>
                        
                        <div className={'col-3 border-bottom-gray-min pl-0'}>
                            <h2 className={'fs-14 font-weight-normal'}>
                                {t('Tiempo de entrega')}
                            </h2>
                        </div>
                        <div className={'col-9 border-bottom-gray-min pr-0'}>
                            <div className={'row'}>
                                <div className={'col-12 mb-2'}>
                                    <InputText
                                        id={'time'}
                                        name={'time'}
                                        placeholder={t('Escribe el tiempo de entrega')}
                                        classes={'w-100 mb-3'}
                                        label={t('Tiempo')}
                                        onChange={this.props.inputFieldChanged.bind(this)}
                                        value={this.props.time}
                                        error={this.state.formError.time}
                                        />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className={'row mt-4'}>
                        
                        <div className={'col-3 border-bottom-gray-min pl-0'}>
                            <h2 className={'fs-14 font-weight-normal'}>
                                {t('Importe')}
                            </h2>
                        </div>
                        <div className={'col-9 border-bottom-gray-min pr-0'}>
                            <div className={'row'}>
                                <div className={'col-12 mb-2'}>
                                    <InputText
                                        id={'price'}
                                        name={'price'}
                                        placeholder={t('Escribe el precio') + ': ' + t('10€')}
                                        classes={'w-100 mb-3'}
                                        label={t('Precio')}
                                        onChange={this.props.inputFieldChanged.bind(this)}
                                        value={this.props.price}
                                        error={this.state.formError.price}
                                        />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className={'row mt-5 mb-4'}>
                        
                        <div className={'col-6 text-left'}>
                            <button className={'btn-white'} onClick={() => {this._cancelData()}}>
                                {t('Cancelar')}
                            </button>
                        </div>

                        <div className={'col-6 text-right'}>
                            <button className={'btn-purple'} onClick={() => {this._saveData()}}>
                                {t('Guardar')}
                            </button>
                        </div>

                    </div>
                </div>
            </React.Fragment>
        )
    }

    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <div className={"split-container"}>
                    <Menu history={this.props.history} />
                    <div className={"container coachee"}>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <header className={"managementPanel__content-header mb-3"}>
                                    <div className={"col-5"}>
                                        <h1 className={"fs-14 poppins"}>{t('Nueva tarifa')}</h1>
                                    </div>

                                    <div className={"col-7 text-right"}>
                                    </div>
                                </header>
                                
                                <section className={"coachee__content-body"}>
                                    { this.state.isLoaded ?
                                        this._renderForm() 
                                    :
                                        <React.Fragment>
                                            <Loader t={t} />
                                        </React.Fragment>
                                    }
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

function mapStateToProps(state) {
    const storeUsers = state.users;
    const storeTarifas = state.tarifas;

    return {
        isLoggedUser : storeUsers.isLogged,
        user: storeUsers.user,
        service : storeTarifas.service,
        description : storeTarifas.description,
        time : storeTarifas.time,
        price : storeTarifas.price,
        dataError : storeTarifas.dataError,
        dataErrorMessage : storeTarifas.dataErrorMessage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        isLogged: redirectTo => dispatch(isLogged()),
        inputFieldChanged: (field, value) => dispatch(inputFieldChanged(field, value)),
        saveTarifa: (service, description, time, price) => dispatch(saveTarifa(service, description, time, price))
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(AdminTarifaCreate));



