export const $$initialState = {
    isLoaded : false,
    loggedUser : '',
    dataError : false,
    dataErrorMessage : '',
};


export default function appReducer($$state = $$initialState, action = null) {
    switch (action.type) {
        case 'LIVE_INPUT_FIELD_CHANGED':
            return Object.assign({}, $$state, {
                [action.payload.field]: action.payload.value,
                dataErrorMessage: { [action.payload.field] : '' }
            });

        case 'SUBSCRIBE_LIVE':
            return Object.assign({}, $$state, {
                dataError: false,
                dataErrorMessage : '',
            });

        case 'SUBSCRIBE_LIVE_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage : action.payload.message,
            });

        default: {
            return $$state;
        }
    }
}
