import React from "react"

const EyeClosed = (props) => {
  return (
    <svg width="18px" height="12px" viewBox="0 0 18 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
	    <title>Group 2</title>
	    <desc>Created with Sketch.</desc>
	    <defs>
	        <path d="M15.6907105,5.83792035 C15.2873677,5.29823295 14.7202496,4.69898924 14.0737799,4.15321222 C12.4749673,2.80342726 10.6889825,2 9,2 C7.31101751,2 5.52503271,2.80342726 3.92622012,4.15321222 C3.27975035,4.69898924 2.71263233,5.29823295 2.30928953,5.83792035 C2.26736821,5.89401262 2.2282045,5.94820283 2.19200768,6 C2.2282045,6.05179717 2.26736821,6.10598738 2.30928953,6.16207965 C2.71263233,6.70176705 3.27975035,7.30101076 3.92622012,7.84678778 C5.52503271,9.19657274 7.31101751,10 9,10 C10.6889825,10 12.4749673,9.19657274 14.0737799,7.84678778 C14.7202496,7.30101076 15.2873677,6.70176705 15.6907105,6.16207965 C15.7326318,6.10598738 15.7717955,6.05179717 15.8079923,6 C15.7717955,5.94820283 15.7326318,5.89401262 15.6907105,5.83792035 Z M9,12 C4.02943725,12 2.090772e-12,7 4.4408921e-14,6 C-2.00195416e-12,5 4.02943725,-5.32907052e-15 9,-5.32907052e-15 C13.9705627,-5.32907052e-15 18,5 18,6 C18,7 13.9705627,12 9,12 Z M9,9 C7.34314575,9 6,7.65685425 6,6 C6,4.34314575 7.34314575,3 9,3 C10.6568542,3 12,4.34314575 12,6 C12,7.65685425 10.6568542,9 9,9 Z M9,7 C9.55228475,7 10,6.55228475 10,6 C10,5.44771525 9.55228475,5 9,5 C8.44771525,5 8,5.44771525 8,6 C8,6.55228475 8.44771525,7 9,7 Z" id="path-eye-closed"></path>
	    </defs>
	    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
	        <g id="Group-2">
	            <g id="Ver-Copy">
	                <g id="Mask" fill="#000000" fillRule="nonzero">
	                    <path d="M15.6907105,5.83792035 C15.2873677,5.29823295 14.7202496,4.69898924 14.0737799,4.15321222 C12.4749673,2.80342726 10.6889825,2 9,2 C7.31101751,2 5.52503271,2.80342726 3.92622012,4.15321222 C3.27975035,4.69898924 2.71263233,5.29823295 2.30928953,5.83792035 C2.26736821,5.89401262 2.2282045,5.94820283 2.19200768,6 C2.2282045,6.05179717 2.26736821,6.10598738 2.30928953,6.16207965 C2.71263233,6.70176705 3.27975035,7.30101076 3.92622012,7.84678778 C5.52503271,9.19657274 7.31101751,10 9,10 C10.6889825,10 12.4749673,9.19657274 14.0737799,7.84678778 C14.7202496,7.30101076 15.2873677,6.70176705 15.6907105,6.16207965 C15.7326318,6.10598738 15.7717955,6.05179717 15.8079923,6 C15.7717955,5.94820283 15.7326318,5.89401262 15.6907105,5.83792035 Z M9,12 C4.02943725,12 2.090772e-12,7 4.4408921e-14,6 C-2.00195416e-12,5 4.02943725,-5.32907052e-15 9,-5.32907052e-15 C13.9705627,-5.32907052e-15 18,5 18,6 C18,7 13.9705627,12 9,12 Z M9,9 C7.34314575,9 6,7.65685425 6,6 C6,4.34314575 7.34314575,3 9,3 C10.6568542,3 12,4.34314575 12,6 C12,7.65685425 10.6568542,9 9,9 Z M9,7 C9.55228475,7 10,6.55228475 10,6 C10,5.44771525 9.55228475,5 9,5 C8.44771525,5 8,5.44771525 8,6 C8,6.55228475 8.44771525,7 9,7 Z" id="path-eye-closed"></path>
	                </g>
	                <g id="Rectangle-Clipped">
	                    <mask id="mask-eye-closed" fill="white">
	                        <use xlinkHref="#path-eye-closed"></use>
	                    </mask>
	                    <g id="path-eye-closed"></g>
	                    <g id="Rectangle" mask="url(#mask-eye-closed)">
	                        <g transform="translate(-3.000000, -6.000000)" id="Group">
	                            <g>
	                                <rect id="Rectangle" fill="#9B9B9B" x="0" y="0" width="24" height="24"></rect>
	                            </g>
	                        </g>
	                    </g>
	                </g>
	            </g>
	            <path d="M2.5,2.5 L15.5,9.5" id="Line" stroke="#979797" strokeWidth="3" stroke-linecap="square" fillRule="nonzero"></path>
	        </g>
	    </g>
	</svg>
  )
}

export default EyeClosed;