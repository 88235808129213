import React from 'react';
import { types } from '../constants/constants';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import InputText from '../components/input';
import { Modal, Checkbox, Select } from 'antd';

import User from '../components/icons/user'
import Close from '../components/icons/close'
import PDF from '../components/icons/pdf'
import Download from '../components/icons/download'

import { cleanStore, 
            inputFieldChanged, 
            doRegister } from '../actions/register.js';

import logo from '../assets/img/logo-t-cito-black-typo.svg'
import sepaDocument from '../assets/documents/ModeloSEPA_T-CITO.pdf'

import { isLogged } from '../actions/user.js';

import { withTranslation } from 'react-i18next';

const { Option } = Select;

const { confirm } = Modal;

class Registro extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            passwordType : 'password',
            formError : {},
            provinces : ['Álava','Albacete','Alicante','Almería','Asturias','Avila','Badajoz','Barcelona','Burgos','Cáceres',
                            'Cádiz','Cantabria','Castellón','Ciudad Real','Córdoba','La Coruña','Cuenca','Gerona','Granada','Guadalajara',
                            'Guipúzcoa','Huelva','Huesca','Islas Baleares','Jaén','León','Lérida','Lugo','Madrid','Málaga','Murcia','Navarra',
                            'Orense','Palencia','Las Palmas','Pontevedra','La Rioja','Salamanca','Segovia','Sevilla','Soria','Tarragona',
                            'Santa Cruz de Tenerife','Teruel','Toledo','Valencia','Valladolid','Vizcaya','Zamora','Zaragoza'],
            isLoaded : true
        };
    }

    componentDidMount() {
        const { i18n } = this.props;

        let lang = typeof this.props.match.params.lang != 'undefined' ? this.props.match.params.lang : null;

        if(lang === 'es' || lang === 'en'){ 
            i18n.changeLanguage(lang) 
        }

        Promise.resolve(this.props.isLogged())
        .then((response)=> {
            if(this.props.isLoggedUser){
                this.props.history.push('/login')
            }
        });

    }

    _addContact(event, type){
        const { t } = this.props;
        let error = false;
        const formError = {};
        this.setState({ formError : formError })

        if (event.key === 'Enter') {
            if(type === 'facturacion'){
                const invoice_contacts = typeof this.props.invoice_contacts === 'undefined' ? [] : this.props.invoice_contacts
                
                if (typeof this.props.invoice_contact === 'undefined' || this.props.invoice_contact === ''){
                    formError.invoice_contact = t('El email es obligatorio')
                    this.setState({ formError : formError })
                    error = true
                }else{
                    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                    if (this.props.invoice_contact.match(pattern) === null) {
                        formError.invoice_contact = t('El email introducido no es válido')
                        this.setState({ formError : formError })
                        error = true
                    }
                }

                if(invoice_contacts.indexOf(this.props.invoice_contact) !== -1){
                    formError.invoice_contact = t('Este email ya existe como contacto')
                    this.setState({ formError : formError })
                    error = true
                }

                if (error === false) {
                    invoice_contacts.push(this.props.invoice_contact)
                    this.props.inputFieldChanged('invoice_contact', '')
                    this.props.inputFieldChanged('invoice_contacts', invoice_contacts)
                }
            }

            if(type === 'reportes'){
                const report_contacts = typeof this.props.report_contacts === 'undefined' ? [] : this.props.report_contacts
                
                if (typeof this.props.report_contact === 'undefined' || this.props.report_contact === ''){
                    formError.report_contact = t('El email es obligatorio')
                    this.setState({ formError : formError })
                    error = true
                }else{
                    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                    if (this.props.report_contact.match(pattern) === null) {
                        formError.report_contact = t('El email introducido no es válido')
                        this.setState({ formError : formError })
                        error = true
                    }
                }

                if(report_contacts.indexOf(this.props.report_contact) !== -1){
                    formError.report_contact = t('Este email ya existe como contacto')
                    this.setState({ formError : formError })
                    error = true
                }

                if (error === false) {
                    report_contacts.push(this.props.report_contact)
                    this.props.inputFieldChanged('report_contact', '')
                    this.props.inputFieldChanged('report_contacts', report_contacts)
                }
            }

            if(type === 'comunicaciones'){
                const comunication_contacts = typeof this.props.comunication_contacts === 'undefined' ? [] : this.props.comunication_contacts
                
                if (typeof this.props.comunication_contact === 'undefined' || this.props.comunication_contact === ''){
                    formError.comunication_contact = t('El email es obligatorio')
                    this.setState({ formError : formError })
                    error = true
                }else{
                    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                    if (this.props.comunication_contact.match(pattern) === null) {
                        formError.comunication_contact = t('El email introducido no es válido')
                        this.setState({ formError : formError })
                        error = true
                    }
                }

                if(comunication_contacts.indexOf(this.props.comunication_contact) !== -1){
                    formError.comunication_contact = t('Este email ya existe como contacto')
                    this.setState({ formError : formError })
                    error = true
                }

                if (error === false) {
                    comunication_contacts.push(this.props.comunication_contact)
                    this.props.inputFieldChanged('comunication_contact', '')
                    this.props.inputFieldChanged('comunication_contacts', comunication_contacts)
                }
            }
        }
    }

    _removeContact(type, key){
        if(type === 'facturacion'){
            const invoice_contacts = typeof this.props.invoice_contacts === 'undefined' ? [] : this.props.invoice_contacts
            invoice_contacts.splice(key, 1)
            this.props.inputFieldChanged('invoice_contacts', invoice_contacts)
        }

        if(type === 'reportes'){
            const report_contacts = typeof this.props.report_contacts === 'undefined' ? [] : this.props.report_contacts
            report_contacts.splice(key, 1)
            this.props.inputFieldChanged('report_contacts', report_contacts)
        }

        if(type === 'comunicaciones'){
            const comunication_contacts = typeof this.props.comunication_contacts === 'undefined' ? [] : this.props.comunication_contacts
            comunication_contacts.splice(key, 1)
            this.props.inputFieldChanged('comunication_contacts', comunication_contacts)
        }
    }

    _renderStep1(){
        const { t } = this.props;

        return(
            <div className={"col-12 col-md-8 offset-md-2 box-with-shadow position-relative"}>
                <h1 className={'poppins bold mb-5'}>{t('Crear cuenta')}</h1>

                <InputText
                    id={'company'}
                    name={'company'}
                    placeholder={t('Nombre del centro')}
                    classes={'w-100 mb-3'}
                    label={t('Nombre del centro')}
                    value={this.props.company}
                    onChange={this.props.inputFieldChanged.bind(this)}
                    error={this.state.formError.company }  
                    />

                <InputText
                    id={'email'}
                    name={'email'}
                    placeholder={t('Email')}
                    classes={'w-100 mb-3'}
                    label={t('Email')}
                    value={this.props.email}
                    onChange={this.props.inputFieldChanged.bind(this)}
                    error={this.state.formError.email}  
                    />

                <InputText
                    id={'password'}
                    name={'password'}
                    type={'password'}
                    placeholder={t('Contraseña')}
                    classes={'w-100 mb-3'}
                    label={t('Contraseña')}
                    value={this.props.password}
                    onChange={this.props.inputFieldChanged.bind(this)}
                    error={this.state.formError.password}  
                    />

                <Checkbox 
                    id={'lopd'}
                    name={'lopd'}
                    className={'mt-2'}
                    checked={ typeof this.props.lopd !== 'undefined' ? this.props.lopd.target.checked : false }
                    onChange={this.props.inputFieldChanged.bind(this, 'lopd')}
                    >{t('Acepto todos los')}{' '} 
                    <a href={ '#' } target='_blank' className={'purple'}>{t('Términos de uso')}</a>
                    {' '}{t('y')}{' '} <a href={ '#' } target='_blank' className={'purple'}>{t('Política de Privacidad')}</a>
                </Checkbox>

                { typeof this.state.formError != 'undefined' && this.state.formError.lopd !== '' ?

                    <p className={'red fs-09 mt-1'}>{ this.state.formError.lopd }</p>

                : null }

                <div className={"row p-0 mt-4"}>

                    <div className={"col-12 text-right"}>

                    <button className={'btn-purple text-center'} onClick={this._saveStep1.bind(this)}>
                        {t('Empezar')}
                    </button>

                    </div>

                </div>
            </div>
        )
    }

    _renderStep2(){
        const { t } = this.props;

        return(
            <div className={'col-12 col-md-8 offset-md-2 box-with-shadow'}>
                <h1 className={'poppins bold'}>{t('Bienvenidos')}</h1>
                <p className={'fs-11 mb-5'}>{t('Comencemos completando los datos del perfil')}</p>

                <h2 className={'fs-12 poppins purple font-weight-normal mb-4'}>{t('DATOS DEL CENTRO')}</h2>

                <InputText
                    id={'address'}
                    name={'address'}
                    placeholder={t('Dirección')}
                    classes={'w-100 mb-3'}
                    label={t('Dirección')}
                    value={this.props.address}
                    onChange={this.props.inputFieldChanged.bind(this)}
                    error={this.state.formError.address }
                    />

                <div className={'row'}>
                    <div className={'col-12 col-md-3'}>
                        <InputText
                            id={'address_number'}
                            name={'address_number'}
                            placeholder={t('Nº')}
                            classes={'w-100 mb-3'}
                            label={t('Número')}
                            value={this.props.address_number}
                            onChange={this.props.inputFieldChanged.bind(this)}
                            error={this.state.formError.address_number }
                            />
                    </div>

                    <div className={'col-12 col-md-3'}>
                        <InputText
                            id={'address_floor'}
                            name={'address_floor'}
                            placeholder={t('Planta')}
                            classes={'w-100 mb-3'}
                            label={t('Planta/Piso')}
                            value={this.props.address_floor}
                            onChange={this.props.inputFieldChanged.bind(this)}
                            error={this.state.formError.address_floor }
                            />
                    </div>

                    <div className={'col-12 col-md-6'}>
                        <InputText
                            id={'address_other'}
                            name={'address_other'}
                            placeholder={t('Ej. Centro comercial')}
                            classes={'w-100 mb-3'}
                            label={t('Datos adicionales')}
                            value={this.props.address_other}
                            onChange={this.props.inputFieldChanged.bind(this)}
                            error={this.state.formError.address_other }
                            />
                    </div>

                    <div className={'col-12'}>
                        <InputText
                            id={'city'}
                            name={'city'}
                            placeholder={t('Ciudad')}
                            classes={'w-100 mb-3'}
                            label={t('Ciudad')}
                            value={this.props.city}
                            onChange={this.props.inputFieldChanged.bind(this)}
                            error={this.state.formError.city }
                            />
                    </div>
                </div>

                <div className={'row'}>
                    <div className={'col-12 col-md-6'}>
                        <InputText
                            id={'zipcode'}
                            name={'zipcode'}
                            placeholder={t('Código postal')}
                            classes={'w-100 mb-3'}
                            label={t('Código postal')}
                            value={this.props.zipcode}
                            onChange={this.props.inputFieldChanged.bind(this)}
                            error={this.state.formError.zipcode }
                            />
                    </div>

                    <div className={'col-12 col-md-6'}>
                        <Select 
                        id={'province'}
                        placeholder={t('Provincia')} 
                        className={'w-100 mb-3'}
                        value={this.props.province}
                        onChange={this.props.inputFieldChanged.bind(this, 'province')}>

                            {typeof this.state.provinces !== 'undefined' ? 
                                Object.entries(this.state.provinces).map(([key, province]) => {
                                    return(
                                        <Option key={key} value={province}>{province}</Option>
                                    );
                                })
                            : null}

                        </Select>

                        {(typeof this.state.formError.province !== 'undefined' && this.state.formError.province !== '') ?
                            <p className={'red fs-09 mt-0'}>{this.state.formError.province}</p>
                        : null }
                    </div>
                </div>

                <div className={'row'}>
                    <div className={'col-12 col-md-6'}>
                        <InputText
                            id={'cif'}
                            name={'cif'}
                            placeholder={t('CIF/NIE')}
                            classes={'w-100 mb-3'}
                            label={t('CIF/NIE')}
                            value={this.props.cif}
                            onChange={this.props.inputFieldChanged.bind(this)}
                            error={this.state.formError.cif }
                            />
                    </div>

                    <div className={'col-12 col-md-6'}>
                        <InputText
                            id={'phone'}
                            name={'phone'}
                            placeholder={t('Teléfono')}
                            classes={'w-100 mb-3'}
                            label={t('Teléfono')}
                            value={this.props.phone}
                            onChange={this.props.inputFieldChanged.bind(this)}
                            error={this.state.formError.phone }
                            />
                    </div>
                </div>

                <InputText
                    id={'business'}
                    name={'business'}
                    placeholder={t('Razón Social')}
                    classes={'w-100 mb-3'}
                    label={t('Razón Social')}
                    value={this.props.business}
                    onChange={this.props.inputFieldChanged.bind(this)}
                    error={this.state.formError.business }
                    />

                <Checkbox 
                    id={'same_address'}
                    name={'same_address'}
                    className={'mt-2 mb-5'}
                    checked={ typeof this.props.same_address !== 'undefined' ? this.props.same_address.target.checked : false }
                    onChange={this.props.inputFieldChanged.bind(this, 'same_address')}
                    >{t('Usar la dirección del centro como direccción de facturación')}
                </Checkbox>

                
                
                { typeof this.props.same_address == 'undefined' || this.props.same_address.target.checked === false ?

                    <React.Fragment>
                        <h2 className={'fs-12 poppins purple font-weight-normal mb-4'}>{t('DATOS DE FACTURACIÓN')}</h2>

                        <InputText
                            id={'business_address'}
                            name={'business_address'}
                            placeholder={t('Dirección')}
                            classes={'w-100 mb-3'}
                            label={t('Dirección')}
                            value={this.props.business_address}
                            onChange={this.props.inputFieldChanged.bind(this)}
                            error={this.state.formError.business_address }
                            />

                        <div className={'row'}>
                            <div className={'col-12 col-md-3'}>
                                <InputText
                                    id={'business_address_number'}
                                    name={'business_address_number'}
                                    placeholder={t('Nº')}
                                    classes={'w-100 mb-3'}
                                    label={t('Número')}
                                    value={this.props.business_address_number}
                                    onChange={this.props.inputFieldChanged.bind(this)}
                                    error={this.state.formError.business_address_number }
                                    />
                            </div>

                            <div className={'col-12 col-md-3'}>
                                <InputText
                                    id={'business_address_floor'}
                                    name={'business_address_floor'}
                                    placeholder={t('Planta')}
                                    classes={'w-100 mb-3'}
                                    label={t('Planta/Piso')}
                                    value={this.props.business_address_floor}
                                    onChange={this.props.inputFieldChanged.bind(this)}
                                    error={this.state.formError.business_address_floor }
                                    />
                            </div>

                            <div className={'col-12 col-md-6'}>
                                <InputText
                                    id={'business_address_other'}
                                    name={'business_address_other'}
                                    placeholder={t('Ej. Centro comercial')}
                                    classes={'w-100 mb-3'}
                                    label={t('Datos adicionales')}
                                    value={this.props.business_address_other}
                                    onChange={this.props.inputFieldChanged.bind(this)}
                                    error={this.state.formError.business_address_other }
                                    />
                            </div>

                            <div className={'col-12'}>
                                <InputText
                                    id={'business_city'}
                                    name={'business_city'}
                                    placeholder={t('Ciudad')}
                                    classes={'w-100 mb-3'}
                                    label={t('Ciudad')}
                                    value={this.props.business_city}
                                    onChange={this.props.inputFieldChanged.bind(this)}
                                    error={this.state.formError.business_city }
                                    />
                            </div>
                        </div>

                        <div className={'row'}>
                            <div className={'col-12 col-md-6'}>
                                <InputText
                                    id={'business_zipcode'}
                                    name={'business_zipcode'}
                                    placeholder={t('Código postal')}
                                    classes={'w-100 mb-3'}
                                    label={t('Código postal')}
                                    value={this.props.business_zipcode}
                                    onChange={this.props.inputFieldChanged.bind(this)}
                                    error={this.state.formError.business_zipcode }
                                    />
                            </div>

                            <div className={'col-12 col-md-6'}>
                                <Select 
                                id={'business_province'}
                                placeholder={t('Provincia')} 
                                className={'w-100 mb-3'}
                                value={this.props.business_province}
                                onChange={this.props.inputFieldChanged.bind(this, 'business_province')}>

                                    {typeof this.state.provinces !== 'undefined' ? 
                                        Object.entries(this.state.provinces).map(([key, province]) => {
                                            return(
                                                <Option key={key} value={province}>{province}</Option>
                                            );
                                        })
                                    : null}

                                </Select>

                                {(typeof this.state.formError.business_province !== 'undefined' && this.state.formError.business_province !== '') ?
                                    <p className={'red fs-09 mt-0 pl-3'}>{this.state.formError.business_province}</p>
                                : null }
                            </div>
                        </div>
                    </React.Fragment>
                
                : null}


                <div className={"row p-0 mt-5"}>

                    <div className={"col-6 text-left"}>
                        <button className={'btn-white text-center'} onClick={() => { this.props.inputFieldChanged('step', '1') }}>
                            {t('Atrás')}
                        </button>
                    </div>

                    <div className={"col-6 text-right"}>
                        <button className={'btn-purple text-center'} onClick={this._saveStep2.bind(this)}>
                            {t('Siguiente')}
                        </button>
                    </div>

                </div>
            </div>
        )
    }

    _renderStep3(){
        const { t } = this.props;

        return(
            <div className={'col-12 col-md-8 offset-md-2 box-with-shadow'}>
                <h1 className={'poppins bold'}>{t('Ya casi estamos...')}</h1>
                <p className={'fs-11 mb-5'}>{t('Añade contactos para recibir información específica')}</p>

                <h2 className={'fs-12 poppins purple font-weight-normal mb-4'}>{t('CONTACTOS DE FACTURACIÓN')}</h2>

                <InputText
                    id={'invoice_contact'}
                    name={'invoice_contact'}
                    value={this.props.invoice_contact}
                    placeholder={t('Agrega el correo aquí y pulsa enter')}
                    classes={'w-100 mb-1'}
                    label={t('Email')}
                    onChange={this.props.inputFieldChanged.bind(this)}
                    onKeyPress={event => { this._addContact(event, 'facturacion') } }
                    error={this.state.formError.invoice_contact }
                    />

                <p className={'poppins purple font-weight-normal mb-3'}>{t('Pulsa enter para incluir el email')}</p>

                { typeof this.props.invoice_contacts != 'undefined' ? 

                    this.props.invoice_contacts.map((email, key) =>{
                        return(
                            <React.Fragment key={key}>
                                <div className={'row align-items-center border-bottom mx-0 py-3'}>
                                    <div className={'col-2 text-center'}>
                                        <User />
                                    </div>

                                    <div className={'col-9'}>
                                        { email }
                                    </div>

                                    <div className={'col-1'}>
                                        <a onClick={() => { this._removeContact('facturacion', key) } }>
                                            <Close />
                                        </a>
                                    </div>
                                </div>
                            </React.Fragment>
                        )
                    })

                : null}

                <h2 className={'fs-12 poppins purple font-weight-normal mt-5 mb-4'}>{t('CONTACTOS DE INFORMES')}</h2>

                <InputText
                    id={'report_contact'}
                    name={'report_contact'}
                    value={this.props.report_contact}
                    placeholder={t('Agrega el correo aquí y pulsa enter')}
                    classes={'w-100 mb-1'}
                    label={t('Email')}
                    onChange={this.props.inputFieldChanged.bind(this)}
                    onKeyPress={event => { this._addContact(event, 'reportes') } }
                    error={this.state.formError.report_contact }
                    />

                <p className={'poppins purple font-weight-normal mb-3'}>{t('Pulsa enter para incluir el email')}</p>

                { typeof this.props.report_contacts != 'undefined' ? 

                    this.props.report_contacts.map((email, key) =>{
                        return(
                            <React.Fragment key={key}>
                                <div className={'row align-items-center border-bottom mx-0 py-3'}>
                                    <div className={'col-2 text-center'}>
                                        <User />
                                    </div>

                                    <div className={'col-9'}>
                                        { email }
                                    </div>

                                    <div className={'col-1'}>
                                        <a onClick={() => { this._removeContact('reportes', key) } }>
                                            <Close />
                                        </a>
                                    </div>
                                </div>
                            </React.Fragment>
                        )
                    })

                : null}

                <h2 className={'fs-12 poppins purple font-weight-normal mt-5 mb-3'}>{t('CONTACTOS DE COMUNICACIÓN')}</h2>

                <InputText
                    id={'comunication_contact'}
                    name={'comunication_contact'}
                    value={this.props.comunication_contact}
                    placeholder={t('Agrega el correo aquí y pulsa enter')}
                    classes={'w-100 mb-1'}
                    label={t('Email')}
                    onChange={this.props.inputFieldChanged.bind(this)}
                    onKeyPress={event => { this._addContact(event, 'comunicaciones') } }
                    error={this.state.formError.comunication_contact }
                    />

                <p className={'poppins purple font-weight-normal mb-4'}>{t('Pulsa enter para incluir el email')}</p>

                { typeof this.props.comunication_contacts != 'undefined' ? 

                    this.props.comunication_contacts.map((email, key) =>{
                        return(
                            <React.Fragment key={key}>
                                <div className={'row align-items-center border-bottom mx-0 py-3'}>
                                    <div className={'col-2 text-center'}>
                                        <User />
                                    </div>

                                    <div className={'col-9'}>
                                        { email }
                                    </div>

                                    <div className={'col-1'}>
                                        <a onClick={() => { this._removeContact('comunicaciones', key) } }>
                                            <Close />
                                        </a>
                                    </div>
                                </div>
                            </React.Fragment>
                        )
                    })

                : null}

                <div className={"row p-0 mt-5"}>
                    <div className={"col-6 text-left"}>
                        <button className={'btn-white text-center'} onClick={() => { this.props.inputFieldChanged('step', '2') }}>
                            {t('Atrás')}
                        </button>
                    </div>

                    <div className={"col-6 text-right"}>
                        <button className={'btn-purple text-center'} onClick={this._saveStep3.bind(this)}>
                            {t('Finalizar')}
                        </button>
                    </div>

                </div>
            </div>
        )
    }

    _renderStep4(){
        const { t } = this.props;

        return(
            <div className={'col-12 col-md-8 offset-md-2 box-with-shadow'}>
                <h1 className={'poppins bold'}>{t('Por último')}</h1>
                <p className={'fs-11 mb-5'}>{t('Para poder recibir informes es necesario que adjuntes este documento SEPA firmado en tu perfil de cliente')}</p>

                <div className={'row border-bottom align-items-center py-4 m-0'}>

                    <div className={'col-2 col-md-1 text-left px-0'}>
                        <PDF />
                    </div>

                    <div className={'col-8 col-md-9'}>
                        <p className={'bold m-0'}>{t('Documento SEPA.pdf')}</p>
                    </div>

                    <div className={'col-2 text-right pr-0'}>
                        <a href={sepaDocument} target={'_blank'}>
                            <Download />
                        </a>
                    </div>

                </div>

                <div className={"row p-0 mt-5"}>

                    <div className={"col-6 text-left"}>
                        <button className={'btn-white text-center'} onClick={() => { this.props.inputFieldChanged('step', '3') }}>
                            {t('Atrás')}
                        </button>
                    </div>

                    <div className={"col-6 text-right"}>

                        { this.state.isLoaded ?
                            <button className={'btn-purple text-center'} onClick={this._saveStep4.bind(this)}>
                                {t('Finalizar registro')}
                            </button>
                        :
                            <button className={'btn-purple disabled text-center'} onClick={()=>{ return false }}>
                                {t('Finalizar registro')}
                            </button>
                        }

                    </div>

                </div>

                { typeof this.props.dataError != 'undefined' && this.props.dataError === true ?

                    <p className={'red fs-09 mt-3'}>{ this.props.dataErrorMessage }</p>

                : null }
            </div>
        )
    }

    _saveStep1(){
        const { t } = this.props;
        let error = false;
        const formError = {};
        this.setState({ formError : formError })

        if (typeof this.props.company === 'undefined' || this.props.company === ''){
            formError.company = t('El nombre de empresa es obligatorio')
            this.setState({ formError : formError })
            error = true
        }

        if (typeof this.props.email === 'undefined' || this.props.email === ''){
            formError.email = t('El email es obligatorio')
            this.setState({ formError : formError })
            error = true
        }else{
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (this.props.email.match(pattern) === null) {
                formError.email = t('El email introducido no es válido')
                this.setState({ formError : formError })
                error = true
            }
        }

        if (typeof this.props.password === 'undefined' || this.props.password === ''){
            formError.password = t('La contraseña es obligatoria')
            this.setState({ formError : formError })
            error = true
        }

        if (typeof this.props.lopd === 'undefined' || typeof this.props.lopd.target === 'undefined' || this.props.lopd.target.checked === false){
            formError.lopd = t('Debes aceptar los Términos de uso y la Política ')
            this.setState({ formError : formError })
            error = true
        }

        if(error === false){
            // Validamos que el email no está ya registrado
            var formBody = [];
            
            var details={ 
                'email' : this.props.email
            };

            for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(details[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
            }

            fetch(types.API_URL + 'is-registered', {
                method: 'post',
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: formBody.join("&"),
            })
            .then(function(response) {
                if (response.status === 404 || response.status === 200) {
                    return response.json()
                } else {
                    Modal.error({
                        title: t('Ha ocurrido un error'),
                        content: t('No hemos podido validar los datos de registro')
                    });
                }
            })
            .then((responseJson) => {
                if (responseJson === false){
                    this.props.inputFieldChanged('step', '2');
                }else{
                    Modal.error({
                        title: t('Email ya registrado'),
                        content: t('Lo sentimos pero este email ya está en uso')
                    });
                }
            })
            .catch((error) => {
                alert(error)
            });
        }
    }

    _saveStep2(){
        const { t } = this.props;
        let error = false;
        const formError = {};
        this.setState({ formError : formError })

        if (typeof this.props.address === 'undefined' || this.props.address === ''){
            formError.address = t('El dirección es obligatoria')
            this.setState({ formError : formError })
            error = true
        }

        if (typeof this.props.address_number === 'undefined' || this.props.address_number === ''){
            formError.address_number = t('Obligatorio')
            this.setState({ formError : formError })
            error = true
        }

        if (typeof this.props.city === 'undefined' || this.props.city === ''){
            formError.city = t('La ciudad es obligatoria')
            this.setState({ formError : formError })
            error = true
        }
        
        if (typeof this.props.zipcode === 'undefined' || this.props.zipcode === ''){
            formError.zipcode = t('La cod. postal es obligatorio')
            this.setState({ formError : formError })
            error = true
        }

        if (typeof this.props.province === 'undefined' || this.props.province === ''){
            formError.province = t('La provincia es obligatoria')
            this.setState({ formError : formError })
            error = true
        }

        if (typeof this.props.cif === 'undefined' || this.props.cif === ''){
            formError.cif = t('El CIF/NIE es obligatorio')
            this.setState({ formError : formError })
            error = true
        }

        if (typeof this.props.phone === 'undefined' || this.props.phone === ''){
            formError.phone = t('El teléfono es obligatorio')
            this.setState({ formError : formError })
            error = true
        }

        if (typeof this.props.business === 'undefined' || this.props.business === ''){
            formError.business = t('La Razón Social es obligatoria')
            this.setState({ formError : formError })
            error = true
        }

        if (typeof this.props.same_address === 'undefined' || typeof this.props.same_address.target === 'undefined' || this.props.same_address.target.checked === false){
            
            if (typeof this.props.business_address === 'undefined' || this.props.business_address === ''){
                formError.business_address = t('El dirección facturación es obligatoria')
                this.setState({ formError : formError })
                error = true
            }

            if (typeof this.props.business_address_number === 'undefined' || this.props.business_address_number === ''){
                formError.business_address_number = t('Obligatorio')
                this.setState({ formError : formError })
                error = true
            }

            if (typeof this.props.business_city === 'undefined' || this.props.business_city === ''){
                formError.business_city = t('La ciudad de facturación es obligatoria')
                this.setState({ formError : formError })
                error = true
            }
            
            if (typeof this.props.business_zipcode === 'undefined' || this.props.business_zipcode === ''){
                formError.business_zipcode = t('La cod. postal es obligatorio')
                this.setState({ formError : formError })
                error = true
            }

            if (typeof this.props.business_province === 'undefined' || this.props.business_province === ''){
                formError.business_province = t('La provincia de facturación es obligatoria')
                this.setState({ formError : formError })
                error = true
            }

        }

        if(error === false){
            this.props.inputFieldChanged('step', '3');
        }
    }

    _saveStep3(){
        this.props.inputFieldChanged('step', '4');
    }

    _saveStep4(){
        const { t } = this.props;
        let error = false;
        const formError = {};
        this.setState({ formError : formError })
        this.setState({ isLoaded : false })

        if(error === false){
            Promise.resolve(this.props.doRegister(
                this.props.company,
                this.props.email, 
                this.props.password,
                this.props.address,
                this.props.address_number,
                this.props.address_floor,
                this.props.address_other,
                this.props.city,
                this.props.zipcode,
                this.props.province,
                this.props.cif,
                this.props.phone,
                this.props.business,
                this.props.business_address,
                this.props.business_address_number,
                this.props.business_address_floor,
                this.props.business_address_other,
                this.props.business_city,
                this.props.business_zipcode,
                this.props.business_province,
                this.props.invoice_contacts,
                this.props.report_contacts,
                this.props.comunication_contacts,
                this.props.iban
            ))
            .then((response)=> {
                if(this.props.dataError === false){
                    // Redirigimos a la página principal
                    this.props.cleanStore()
                    this.props.history.push('/muestras')
                }

                this.setState({ isLoaded : true })
            });
        }

        this.setState({ isLoaded : false })
    }

    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <div className={"row align-items-center box-with-shadow py-3 mb-5"}>
                    <div className={"col-12 col-md-6 text-left"}>
                        <a href="/">
                          <img
                            src={logo}
                            className={"logo"}
                            alt={"Logo " + types.COMPANY}
                          />
                        </a>
                    </div>

                    <div className={"col-12 col-md-6 text-right"}>
                        <span className={'button-text'}>{t('¿Ya tienes cuenta?')}</span>
                        <a className={'purple'} href={'/login'}>
                            {' '}{t('Accede')}
                        </a>
                    </div>
                </div>
                <div className={'container'}>
                    <div className={"row"}>
                        { 
                            typeof this.props.step === 'undefined' || this.props.step === '1'  ?
                                this._renderStep1()
                            : null
                        }

                        { 
                            this.props.step === '2'  ?
                                this._renderStep2()
                            : null
                        }

                        { 
                            this.props.step === '3'  ?
                                this._renderStep3()
                            : null
                        }

                        { 
                            this.props.step === '4'  ?
                                this._renderStep4()
                            : null
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

function mapStateToProps(state) {
    const storeUsers = state.users;
    const storeRegister = state.register;

    return {
        isLoggedUser : storeUsers.isLogged,
        user: storeUsers.user,
        new_user: storeRegister.user,
        step: storeRegister.step,
        company: storeRegister.company,
        email: storeRegister.email,
        password: storeRegister.password,
        lopd: storeRegister.lopd,
        address: storeRegister.address,
        address_number: storeRegister.address_number,
        address_floor: storeRegister.address_floor,
        address_other: storeRegister.address_other,
        city: storeRegister.city,
        zipcode: storeRegister.zipcode,
        province: storeRegister.province,
        cif: storeRegister.cif,
        phone: storeRegister.phone,
        business: storeRegister.business,
        business_address: storeRegister.business_address,
        business_address_number: storeRegister.business_address_number,
        business_address_floor: storeRegister.business_address_floor,
        business_address_other: storeRegister.business_address_other,
        business_city: storeRegister.business_city,
        business_zipcode: storeRegister.business_zipcode,
        business_province: storeRegister.business_province,
        same_address: storeRegister.same_address,
        invoice_contact: storeRegister.invoice_contact, /* form value */
        report_contact: storeRegister.report_contact, /* form value */
        comunication_contact: storeRegister.comunication_contact, /* form value */
        invoice_contacts: storeRegister.invoice_contacts, /* array of contacts */
        report_contacts: storeRegister.report_contacts, /* array of contacts */
        comunication_contacts: storeRegister.comunication_contacts, /* array of contacts */
        iban: storeRegister.iban,
        dataError : storeRegister.dataError,
        dataErrorMessage: storeRegister.dataErrorMessage,
        successMessage : storeRegister.successMessage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        cleanStore: () => dispatch(cleanStore()),
        inputFieldChanged: (field, text) => dispatch(inputFieldChanged(field, text)),
        doRegister: (company,email,password,address,address_number,address_floor,address_other,city,zipcode,province,cif,phone,business,business_address,business_address_number,business_address_floor,business_address_other,business_city,business_zipcode,business_province,invoice_contacts,report_contacts,communication_contacts,iban) => dispatch(doRegister(company,email,password,address,address_number,address_floor,address_other,city,zipcode,province,cif,phone,business,business_address,business_address_number,business_address_floor,business_address_other,business_city,business_zipcode,business_province,invoice_contacts,report_contacts,communication_contacts,iban)),
        isLogged: redirectTo => dispatch(isLogged())
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(Registro));



