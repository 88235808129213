import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import Menu from '../../components/menu';

import { ExclamationCircleOutlined,
            LoadingOutlined,
            ItalicOutlined,
            BoldOutlined } from '@ant-design/icons';

import Loader from '../../components/loader';
import InputText from '../../components/input';
import TextAreaInput from '../../components/textarea';
import ContentEditable from 'react-contenteditable'
import sanitizeHtml from "sanitize-html"
import ImageMapper from 'react-image-mapper'

import { Modal, Checkbox, Select, Radio, Tooltip } from 'antd';

import InfoIcon from "../../components/icons/info";
import animalMap from '../../assets/img/animal_map.jpg';
import ResultOk from "../../components/icons/resultok";

import { saveTarifa,
            getTarifas } from '../../actions/tarifa.js';

import { inputFieldChanged,
            saveMuestra,
            cleanStore } from '../../actions/muestra.js';
import { isLogged,
            getUser,
            getCustomer } from '../../actions/user.js';

import { withTranslation } from 'react-i18next';

const { confirm } = Modal;
const { Option } = Select;

class AdminMuestraCreate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded : true,
            formError : {},
            genders : ['Macho', 'Macho castrado', 'Hembra', 'Hembra castrada', 'Desconocido'],
            locations : ['1', '2', '>2'],
            map : {
                name : 'AnimalMap',
                areas : []
            },
            showModal : false
        };
    }

    componentDidMount() {
        const { i18n } = this.props;

        let lang = typeof this.props.match.params.lang !== 'undefined' ? this.props.match.params.lang : null;

        if(lang === 'es' || lang === 'en'){ 
            i18n.changeLanguage(lang) 
        }

        this._getApiData()
    }

    _getApiData(){
        this.setState({ isLoaded : false})

        let id = typeof this.props.match.params.id !== 'undefined' ? this.props.match.params.id : null;

        Promise.resolve(this.props.isLogged())
        .then((response)=> {
            if(this.props.isLoggedUser){
                if(this.props.user.role.code != 0){
                    this.props.history.push('/404')
                }

                Promise.resolve(this.props.getTarifas())
                .then((response)=> {
                })

                Promise.resolve(this.props.getCustomer(id))
                .then((response) => {
                    if(this.props.dataErrorClinica){
                        alert(this.props.dataErrorClinicaMessage)
                    }

                    console.debug(this.props.customer)
                })

                this.setState({ isLoaded : true})
            }else{
                this.props.history.push('/login')
            }
        });
    }

    _clickedImage(evt) {
        const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };

        let map = this.state.map;
        map.areas.push(
            {
                shape: "circle",
                coords: [coords.x, coords.y, 15],
                preFillColor: "#ff0000",
                lineWidth: 2
            }
        )

        this.setState({ map : map });
        this.props.inputFieldChanged('injury_location', map);
    }

    _clickedArea(area, key) {
        let map = this.state.map
        map.areas.splice(key, 1);

        this.setState({ map : map })
        this.props.inputFieldChanged('injury_location', map)
    }

    _saveData() {
        const { t } = this.props;
        let error = false;
        const formError = {};
        let requestedTests = {};
        this.setState({ formError : formError })
        this.setState({ isLoaded : false })
        
        if (typeof this.props.animal_name === 'undefined' || this.props.animal_name === ''){
            formError.animal_name = t('El nombre del animal es obligatorio')
            this.setState({ formError : formError })
            error = true
        }
        
        if (typeof this.props.animal_species === 'undefined' || this.props.animal_species === ''){
            formError.animal_species = t('La especie del animal es obligatoria')
            this.setState({ formError : formError })
            error = true
        }

        if (typeof this.props.animal_age === 'undefined' || this.props.animal_age === ''){
            formError.animal_age = t('La edad del animal es obligatoria')
            this.setState({ formError : formError })
            error = true
        }

        if (
            (typeof this.props.cytology === 'undefined' || typeof this.props.cytology.target === 'undefined' || this.props.cytology.target.checked === false) && 
            (typeof this.props.fluid === 'undefined' || typeof this.props.fluid.target === 'undefined' || this.props.fluid.target.checked === false) && 
            (typeof this.props.other === 'undefined' || typeof this.props.other.target === 'undefined' || this.props.other.target.checked === false)
           ){
            formError.muestra_type = t('Debes elegir al menos una opción')
            this.setState({ formError : formError })
            error = true
        }

        if (typeof this.props.requested_test === 'undefined' || this.props.requested_test.length === 0){
            formError.requested_test = t('Debes elegir al menos una opción')
            this.setState({ formError : formError })
            error = true
        }

        if (typeof this.props.clinical_history === 'undefined' || this.props.clinical_history === ''){
            formError.clinical_history = t('La historia clínica es obligatoria')
            this.setState({ formError : formError })
            error = true
        }

        if(error === false){
            Promise.resolve(this.props.saveMuestra(
                this.props.contact_person,
                this.props.animal_name,
                this.props.animal_history,
                this.props.animal_species,
                this.props.animal_race,
                this.props.animal_gender,
                this.props.animal_age,
                this.props.animal_owner,
                (typeof this.props.priority != 'undefined' && typeof this.props.priority.target != 'undefined' ? this.props.priority.target.value : false),
                (typeof this.props.cytology != 'undefined' && typeof this.props.cytology.target != 'undefined' ? this.props.cytology.target.checked : false),
                this.props.cytology_number,
                (typeof this.props.fluid != 'undefined' && typeof this.props.fluid.target != 'undefined' ? this.props.fluid.target.checked : false),
                this.props.fluid_origin,
                (typeof this.props.fluid_type != 'undefined' && typeof this.props.fluid_type.target != 'undefined' ? this.props.fluid_type.target.value : false),
                (typeof this.props.other != 'undefined' && typeof this.props.other.target != 'undefined' ? this.props.other.target.checked : false),
                this.props.other_details,
                JSON.stringify(this.props.requested_test),
                this.props.requested_test_extra,
                this.props.aditional_location,
                this.props.previous_report,
                this.props.clinical_history,
                this.props.aditional_tests,
                this.props.differential_diagnoses,
                this.props.injury_location,
                this.props.customer._id
            ))
            .then((response)=> {
                if(this.props.dataError === false){
                    // Limpiamos la Store
                    this.props.cleanStore()
                    // Mostramos la modal de OK
                    this.setState({ showModal : true })
                }
            });
        }else{
            console.log(formError)
        }

        this.setState({ isLoaded : true })
    }

    _selectTest(e) {
        console.log(e)
        this.props.inputFieldChanged('requested_test', e)
    }

    _renderForm() {
        const { t } = this.props;
        const textInfoCito = t('Las citologías de líquido sinovial se consideran como una citología completa cuando se remiten muestras de 2 o más articulaciones. Las muestras que no sean de líquido sinovial remitidas a partir de 2 articulaciones se consideran localizaciones adicionales. Ej. Citologías de 4 articulaciones y bazo') + ':' + t('1 citología normal + localización adicional. Citología de 1 articulación + hígado: 1 citología normal.')
        const textInfoAnalisis = t('Si el fluido requiere citocentrifugar. Incluye también recuento de células, proteínas en refractómetro y en los casos que lo requieran test de Rivalta, determinación de triglicéridos, colesterol, urea, creatinina o bilirrubina total.')

        return(
            <React.Fragment>
                <div className={'box-with-shadow pt-4 pl-5 pr-5 pb-2 border'}>
                    <div className={'row mt-3'}>
                        
                        <div className={'col-3 border-bottom-gray-min pl-0 pb-3'}>
                            <h2 className={'fs-14 font-weight-normal'}>
                                {this.props.clinica.company}
                            </h2>
                            <p className={'edit-gray'}>
                                {this.props.customer.address} {' '} {this.props.customer.address_number} {', '} {this.props.customer.zipcode} {' '} {this.props.customer.city}
                            </p>
                        </div>
                        <div className={'col-9 border-bottom-gray-min pr-0 pb-3'}>

                        </div>

                    </div>

                    <div className={'row mt-4'}>
                        
                        <div className={'col-3 border-bottom-gray-min pl-0 pb-3'}>
                            <h2 className={'fs-14 font-weight-normal'}>
                                {t('Datos de contacto')}
                            </h2>
                        </div>
                        <div className={'col-9 border-bottom-gray-min pr-0 pb-3'}>
                            <div className={'row'}>
                                <div className={'col-12'}>
                                    <InputText
                                        id={'contact_person'}
                                        name={'contact_person'}
                                        placeholder={t('Veterinario de contacto')}
                                        classes={'w-100 mb-3'}
                                        label={t('Veterinario de contacto')}
                                        value={this.props.contact_person}
                                        onChange={this.props.inputFieldChanged.bind(this)}
                                        error={this.state.formError.contact_person }  
                                        />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className={'row mt-4'}>
                        
                        <div className={'col-3 border-bottom-gray-min pl-0 pb-3'}>
                            <h2 className={'fs-14 font-weight-normal'}>
                                {t('Datos del animal')}
                            </h2>
                        </div>
                        <div className={'col-9 border-bottom-gray-min pr-0 pb-3'}>
                            <div className={'row'}>
                                <div className={'col-md-8'}>
                                    <InputText
                                        id={'animal_name'}
                                        name={'animal_name'}
                                        placeholder={t('Nombre *')}
                                        classes={'w-100 mb-3'}
                                        label={t('Nombre *')}
                                        value={this.props.animal_name}
                                        onChange={this.props.inputFieldChanged.bind(this)}
                                        error={this.state.formError.animal_name }  
                                        />
                                </div>

                                <div className={'col-md-4'}>
                                    <InputText
                                        id={'animal_history'}
                                        name={'animal_history'}
                                        placeholder={t('Número de historia')}
                                        classes={'w-100 mb-3'}
                                        label={t('Número de historia')}
                                        value={this.props.animal_history}
                                        onChange={this.props.inputFieldChanged.bind(this)}
                                        error={this.state.formError.animal_history }  
                                        />
                                </div>

                                <div className={'col-md-6'}>
                                    <InputText
                                        id={'animal_species'}
                                        name={'animal_species'}
                                        placeholder={t('Especie *')}
                                        classes={'w-100 mb-3'}
                                        label={t('Especie *')}
                                        value={this.props.animal_species}
                                        onChange={this.props.inputFieldChanged.bind(this)}
                                        error={this.state.formError.animal_species }  
                                        />
                                </div>

                                <div className={'col-md-6'}>
                                    <InputText
                                        id={'animal_race'}
                                        name={'animal_race'}
                                        placeholder={t('Raza')}
                                        classes={'w-100 mb-3'}
                                        label={t('Raza')}
                                        value={this.props.animal_race}
                                        onChange={this.props.inputFieldChanged.bind(this)}
                                        error={this.state.formError.animal_race }  
                                        />
                                </div>

                                <div className={'col-md-3'}>
                                    <Select 
                                    id={'gender'}
                                    placeholder={t('Sexo')} 
                                    className={'w-100 mb-3'}
                                    value={this.props.gender}
                                    onChange={this.props.inputFieldChanged.bind(this, 'animal_gender')}>

                                        {typeof this.state.genders !== 'undefined' ? 
                                            Object.entries(this.state.genders).map(([key, gender]) => {
                                                return(
                                                    <Option key={key} value={gender}>{gender}</Option>
                                                );
                                            })
                                        : null}

                                    </Select>

                                    {(typeof this.state.formError.animal_gender !== 'undefined' && this.state.formError.animal_gender !== '') ?
                                        <p className={'red fs-09 mt-0 pl-3'}>{this.state.formError.animal_gender}</p>
                                    : null }
                                </div>

                                <div className={'col-md-3'}>
                                    <InputText
                                        id={'animal_age'}
                                        name={'animal_age'}
                                        placeholder={t('Edad *')}
                                        classes={'w-100 mb-3'}
                                        label={t('Edad *')}
                                        value={this.props.animal_age}
                                        onChange={this.props.inputFieldChanged.bind(this)}
                                        error={this.state.formError.animal_age }  
                                        />
                                </div>

                                <div className={'col-md-6'}>
                                    <InputText
                                        id={'animal_owner'}
                                        name={'animal_owner'}
                                        placeholder={t('Propietario')}
                                        classes={'w-100 mb-3'}
                                        label={t('Propietario')}
                                        value={this.props.animal_owner}
                                        onChange={this.props.inputFieldChanged.bind(this)}
                                        error={this.state.formError.animal_owner }  
                                        />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className={'row mt-4'}>
                        
                        <div className={'col-3 border-bottom-gray-min pl-0 pb-3'}>
                            <h2 className={'fs-14 font-weight-normal'}>
                                {t('Prioridad')}
                            </h2>
                        </div>
                        <div className={'col-9 border-bottom-gray-min pr-0 pb-3'}>
                            <div className={'row'}>
                                <Radio.Group 
                                className={'ml-3'}
                                onChange={this.props.inputFieldChanged.bind(this, 'priority')} 
                                value={typeof this.props.priority != 'undefined' ? this.props.priority.target.value : 'normal'}>
                                    <Radio 
                                        value={'normal'}>
                                        {t('Normal')}</Radio>
                                    <Radio 
                                        value={'urgente'}>
                                        {t('Urgente (Se aplican cargos)')}</Radio>
                                </Radio.Group>
                            </div>
                        </div>

                    </div>

                    <div className={'row mt-4'}>
                        
                        <div className={'col-3 border-bottom-gray-min pl-0'}>
                            <h2 className={'fs-14 font-weight-normal'}>
                                {t('Muestra remitida')}
                            </h2>
                        </div>
                        <div className={'col-9 border-bottom-gray-min pr-0 pb-3'}>
                            <div className={'row'}>
                                <div className={'col-12 mb-2'}>
                                    <Checkbox 
                                        id={'cytology'}
                                        name={'cytology'}
                                        className={'mt-2'}
                                        checked={ typeof this.props.cytology !== 'undefined' && typeof this.props.cytology.target != 'undefined' ? this.props.cytology.target.checked : false }
                                        onChange={this.props.inputFieldChanged.bind(this, 'cytology')}
                                        >{t('Citologías')}
                                    </Checkbox>
                                </div>

                                <div className={'col-12'}>
                                    <InputText
                                        id={'cytology_number'}
                                        name={'cytology_number'}
                                        placeholder={t('Nº de preparaciones')}
                                        classes={'w-100 mb-3'}
                                        label={t('Nº de preparaciones')}
                                        value={this.props.cytology_number}
                                        onChange={this.props.inputFieldChanged.bind(this)}
                                        error={this.state.formError.cytology_number }  
                                        />
                                </div>

                                <div className={'col-12 mb-2'}>
                                    <Checkbox 
                                        id={'fluid'}
                                        name={'fluid'}
                                        className={'mt-2'}
                                        checked={ typeof this.props.fluid !== 'undefined' && typeof this.props.fluid.target != 'undefined' ? this.props.fluid.target.checked : false }
                                        onChange={this.props.inputFieldChanged.bind(this, 'fluid')}
                                        >{t('Fluido. ')} <span className={"edit-gray pr-2"}>{t('Info sobre las citologías de líquido sinovial')}</span> 
                                        <Tooltip placement="topLeft" title={textInfoCito} arrowPointAtCenter>
                                            <span><InfoIcon /></span>
                                        </Tooltip>
                                    </Checkbox>
                                </div>

                                <div className={'col-12'}>
                                    <InputText
                                        id={'fluid_origin'}
                                        name={'fluid_origin'}
                                        placeholder={t('Indica el origen')}
                                        classes={'w-100 mb-3'}
                                        label={t('Indica el origen')}
                                        value={this.props.fluid_origin}
                                        onChange={this.props.inputFieldChanged.bind(this)}
                                        error={this.state.formError.fluid_origin }  
                                        />
                                </div>

                                <div className={'col-12 mb-4'}>
                                    <Radio.Group 
                                    onChange={this.props.inputFieldChanged.bind(this, 'fluid_type')} 
                                    value={typeof this.props.fluid_type !== 'undefined' ? this.props.fluid_type.target.value : ''}>
                                        <Radio 
                                            value={'sangre'}>
                                            {t('Sangre')} <span className={"pl-3 pr-2"}>{t('o')}</span></Radio>
                                        <Radio 
                                            value={'edta'}>
                                            {t('MO en EDTA')}</Radio>
                                    </Radio.Group>
                                </div>

                                <div className={'col-12 mb-2'}>
                                    <Checkbox 
                                        id={'other'}
                                        name={'other'}
                                        className={'mt-2'}
                                        checked={ typeof this.props.other !== 'undefined' && typeof this.props.other.target != 'undefined' ? this.props.other.target.checked : false }
                                        onChange={this.props.inputFieldChanged.bind(this, 'other')}
                                        >{t('Otros')}
                                    </Checkbox>
                                </div>

                                <div className={'col-12'}>
                                    <InputText
                                        id={'other_details'}
                                        name={'other_details'}
                                        placeholder={t('Indica el origen')}
                                        classes={'w-100 mb-3'}
                                        label={t('Es necesario especificar')}
                                        value={this.props.other_details}
                                        onChange={this.props.inputFieldChanged.bind(this)}
                                        error={this.state.formError.other_details }  
                                        />
                                </div>

                                {(typeof this.state.formError.muestra_type !== 'undefined' && this.state.formError.muestra_type !== '') ?
                                    <p className={'red fs-09 mt-0 pl-3'}>{this.state.formError.muestra_type}</p>
                                : null }
                            </div>
                        </div>

                    </div>

                    <div className={'row mt-4'}>
                        
                        <div className={'col-3 border-bottom-gray-min pl-0 pb-3'}>
                            <h2 className={'fs-14 font-weight-normal'}>
                                {t('Examen solicitado')}
                            </h2>
                        </div>
                        <div className={'col-9 border-bottom-gray-min pr-0 pb-3'}>
                            <div className={'row'}>
                                <Checkbox.Group
                                name={'requested_test'} 
                                value={typeof this.props.requested_test !== 'undefined' ? this.props.requested_test : {}}
                                onChange={this._selectTest.bind(this)}>
                                    {typeof this.props.tarifas !== 'undefined' ? 
                                        Object.entries(this.props.tarifas).map(([key, tarifa]) => {
                                            return(
                                                <div className={'col-12 mb-3'} key={key}>
                                                    <Checkbox 
                                                        value={tarifa.id}
                                                        >{tarifa.service}
                                                        { tarifa.service.indexOf('de frotis') !== -1 || tarifa.service.indexOf('médula ósea') !== -1 ?
                                                            <span> *</span>
                                                        : null }
                                                    </Checkbox>

                                                    {/* Select de localizacion adicional */}
                                                    { tarifa.service.indexOf('Localización adicional') !== -1 && this.props.requested_test.indexOf(tarifa.id) !== -1 ?
                                                        <Select 
                                                            id={'aditional_location'}
                                                            placeholder={t('Localización Adicional')} 
                                                            className={'w-100 little mt-2'}
                                                            onChange={this.props.inputFieldChanged.bind(this, 'aditional_location')}
                                                            value={this.props.aditional_location}>

                                                                {typeof this.state.locations !== 'undefined' ? 
                                                                    Object.entries(this.state.locations).map(([key, location]) => {
                                                                        return(
                                                                            <Option key={key} value={location}>{location}</Option>
                                                                        );
                                                                    })
                                                                : null}

                                                        </Select>
                                                    : null }
                                                    
                                                    {/* Input de número de informe previo */}
                                                    { tarifa.service.indexOf('Citología control') !== -1 && this.props.requested_test.indexOf(tarifa.id) !== -1 ?
                                                        <InputText
                                                            id={'previous_report'}
                                                            name={'previous_report'}
                                                            placeholder={t('Nº de informe previo')}
                                                            classes={'w-100 mt-1'}
                                                            label={t('Nº de informe previo')}
                                                            onChange={this.props.inputFieldChanged.bind(this)}
                                                            value={this.props.previous_report}
                                                            />
                                                    : null }
                                                    
                                                    {/* Tooltip analisis de fluidos */}
                                                    { tarifa.service.indexOf('Análisis de fluidos') !== -1 ?
                                                        <Tooltip placement="topLeft" title={textInfoAnalisis} arrowPointAtCenter>
                                                            <span><InfoIcon /></span>
                                                        </Tooltip>
                                                    : null }
                                                </div>
                                            );
                                        })
                                    : null}
                                </Checkbox.Group>

                                <div className={'col-12 mb-2'}>
                                    <p>{t('* No incluye hemograma, es necesario adjuntarlo con las muestras')}</p>
                                </div>

                                {(typeof this.state.formError.requested_test !== 'undefined' && this.state.formError.requested_test !== '') ?
                                    <React.Fragment>
                                        <div className={'col-12 order-11 order-md-11'}>
                                            <p className={'red fs-09 mt-0'}>{this.state.formError.requested_test}</p>
                                        </div>
                                    </React.Fragment>
                                : null }
                            </div>
                        </div>

                    </div>

                    <div className={'row mt-4'}>
                        
                        <div className={'col-3 border-bottom-gray-min pl-0 pb-3'}>
                            <h2 className={'fs-14 font-weight-normal'}>
                                {t('Historia clínica')}
                            </h2>
                        </div>
                        <div className={'col-9 border-bottom-gray-min pr-0 pb-3'}>
                            <div className={'row'}>
                                <div className={'col-12'}>
                                    <TextAreaInput
                                        id={'clinical_history'}
                                        name={'clinical_history'}
                                        placeholder={t('Escribe aquí la información')}
                                        classes={'w-100 mb-3'}
                                        label={t('Historia clínica')}
                                        value={this.props.clinical_history}
                                        onChange={this.props.inputFieldChanged.bind(this)}
                                        rows={5}
                                        error={this.state.formError.clinical_history }  
                                        />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className={'row mt-4'}>
                        
                        <div className={'col-3 border-bottom-gray-min pl-0 pb-3'}>
                            <h2 className={'fs-14 font-weight-normal'}>
                                {t('Pruebas adicionales')}
                            </h2>
                        </div>
                        <div className={'col-9 border-bottom-gray-min pr-0 pb-3'}>
                            <div className={'row'}>
                                <div className={'col-12'}>
                                    <TextAreaInput
                                        id={'aditional_tests'}
                                        name={'aditional_tests'}
                                        placeholder={t('Escribe aquí la información')}
                                        classes={'w-100 mb-3'}
                                        label={t('Pruebas adicionales')}
                                        value={this.props.aditional_tests}
                                        onChange={this.props.inputFieldChanged.bind(this)}
                                        rows={5}
                                        error={this.state.formError.aditional_tests }  
                                        />
                                </div>
                            </div>
                        </div>

                    </div>
                    
                    <div className={'row mt-4'}>
                        
                        <div className={'col-3 border-bottom-gray-min pl-0 pb-3'}>
                            <h2 className={'fs-14 font-weight-normal'}>
                                {t('Diagnósticos diferenciales')}
                            </h2>
                        </div>
                        <div className={'col-9 border-bottom-gray-min pr-0 pb-3'}>
                            <div className={'row'}>
                                <div className={'col-12'}>
                                    <TextAreaInput
                                        id={'differential_diagnoses'}
                                        name={'differential_diagnoses'}
                                        placeholder={t('Escribe aquí la información')}
                                        classes={'w-100 mb-3'}
                                        label={t('Diagnósticos diferenciales')}
                                        value={this.props.differential_diagnoses}
                                        onChange={this.props.inputFieldChanged.bind(this)}
                                        rows={5}
                                        error={this.state.formError.differential_diagnoses }  
                                        />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className={'row mt-4'}>
                        
                        <div className={'col-3 border-bottom-gray-min pl-0 pb-3'}>
                            <h2 className={'fs-14 font-weight-normal'}>
                                {t('Localización de la lesión')}
                            </h2>
                        </div>
                        <div className={'col-9 border-bottom-gray-min pr-0 pb-3'}>
                            <div className={'row'}>
                                <div className={'col-12'}>
                                    <p>{t('Haz click sobre la figura en la zona de la lesión.')}</p>
                                    <ImageMapper 
                                        width={500}
                                        src={ animalMap } 
                                        map={ this.state.map }
                                        onImageClick={evt => this._clickedImage(evt)}
                                        onClick={(area, key) => this._clickedArea(area, key)} />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className={'row mt-5 mb-4'}>
                        
                        <div className={'col-6 text-left'}>
                            <a href={'/admin/cliente/' + this.props.clinica._id} className={'btn-white'}>
                                {t('Cancelar')}
                            </a>
                        </div>

                        <div className={'col-6 text-right'}>
                            <button className={'btn-purple'} onClick={() => {this._saveData()}}>
                                {t('Guardar')}
                            </button>
                        </div>

                    </div>

                    <Modal 
                        visible={this.state.showModal}
                        footer={null}
                        closable={false}
                    >
                        <div className={'row mt-3'}>
                            <div className={'col-12 text-center'}>
                                <ResultOk />
                                <p className={'poppins text-uppercase font-weight-bold fs-13 my-2 px-4'}>{t('MUESTRA GUARDADA CON ÉXITO')}</p>
                            </div>

                            <div className={'col-12 pt-5 pb-3 text-center'}>
                                <a href={'/admin/muestras'} className={'btn-purple-text'}>{t('Volver a muestras')}</a>
                            </div>
                        </div>
                    </Modal>
                </div>
            </React.Fragment>
        )
    }

    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <div className={"split-container"}>
                    <Menu history={this.props.history} />
                    <div className={"container coachee"}>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <header className={"managementPanel__content-header mb-3"}>
                                    <div className={"col-5"}>
                                        <h1 className={"fs-14 poppins"}>{t('Nueva muestra')}</h1>
                                    </div>

                                    <div className={"col-7 text-right"}>
                                    </div>
                                </header>
                                
                                <section className={"coachee__content-body"}>
                                    { this.state.isLoaded ?
                                        this._renderForm() 
                                    :
                                        <React.Fragment>
                                            <Loader t={t} />
                                        </React.Fragment>
                                    }
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

function mapStateToProps(state) {
    const storeUsers = state.users;
    const storeTarifas = state.tarifas;
    const storeMuestras = state.muestras;

    return {
        isLoggedUser : storeUsers.isLogged,
        tarifas : storeTarifas.tarifas,
        customer: storeUsers.customer,
        clinica: storeUsers.customer,
        address: storeUsers.address,
        address_number: storeUsers.address_number,
        zipcode: storeUsers.zipcode,
        city: storeUsers.city,
        user: storeUsers.user,
        contact_person : storeMuestras.contact_person,
        animal_name : storeMuestras.animal_name,
        animal_history: storeMuestras.animal_history,
        animal_species : storeMuestras.animal_species,
        animal_race : storeMuestras.animal_race,
        animal_gender : storeMuestras.animal_gender,
        animal_age : storeMuestras.animal_age,
        animal_owner : storeMuestras.animal_owner,
        priority : storeMuestras.priority,
        cytology : storeMuestras.cytology,
        cytology_number : storeMuestras.cytology_number,
        fluid : storeMuestras.fluid,
        fluid_origin : storeMuestras.fluid_origin,
        fluid_type : storeMuestras.fluid_type,
        other : storeMuestras.other,
        other_details : storeMuestras.other_details,
        requested_test : storeMuestras.requested_test,
        requested_test_extra : storeMuestras.requested_test_extra,
        previous_report : storeMuestras.previous_report,
        normal_cytology : storeMuestras.normal_cytology,
        fluid_analysis : storeMuestras.fluid_analysis,
        cytology_aditional : storeMuestras.cytology_aditional,
        aditional_location : storeMuestras.aditional_location,
        marrow_study : storeMuestras.marrow_study,
        cytology_basic : storeMuestras.cytology_basic,
        frontis_study : storeMuestras.frontis_study,
        cytology_control : storeMuestras.cytology_control,
        leukogram : storeMuestras.leukogram,
        previous_report : storeMuestras.previous_report,
        clinical_history : storeMuestras.clinical_history,
        aditional_tests : storeMuestras.aditional_tests,
        differential_diagnoses : storeMuestras.differential_diagnoses,
        injury_location : storeMuestras.injury_location,
        dataError : storeTarifas.dataError,
        dataErrorMessage : storeTarifas.dataErrorMessage,
        dataErrorClinica : storeUsers.dataError,
        dataErrorClinicaMessage : storeUsers.dataErrorMessage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        isLogged: redirectTo => dispatch(isLogged()),
        inputFieldChanged: (field, value) => dispatch(inputFieldChanged(field, value)),
        getUser: (id) => dispatch(getUser(id)),
        getCustomer: (id) => dispatch(getCustomer(id)),
        saveTarifa: (service, description, time, price) => dispatch(saveTarifa(service, description, time, price)),
        getTarifas: () => dispatch(getTarifas()),
        saveMuestra: (contact_person, animal_name, animal_history, animal_species, animal_race, animal_gender, animal_age, animal_owner, priority, cytology, cytology_number, fluid, fluid_origin, fluid_type, other, other_details, requested_test, requested_test_extra, aditional_location, previous_report, clinical_history, aditional_tests, differential_diagnoses, injury_location, user_id) => dispatch(saveMuestra(contact_person, animal_name, animal_history, animal_species, animal_race, animal_gender, animal_age, animal_owner, priority, cytology, cytology_number, fluid, fluid_origin, fluid_type, other, other_details, requested_test, requested_test_extra, aditional_location, previous_report, clinical_history, aditional_tests, differential_diagnoses, injury_location, user_id)),
        cleanStore: () => dispatch(cleanStore()),
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(AdminMuestraCreate));



