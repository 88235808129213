import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { types } from '../../constants/constants';

import Menu from '../../components/menu';

import { Modal } from 'antd';
import { ExclamationCircleOutlined,
            LoadingOutlined,
            ItalicOutlined,
            BoldOutlined } from '@ant-design/icons';
import ResultOk from "../../components/icons/resultok";
import PDF from "../../components/icons/pdf";

import Loader from '../../components/loader';
import InputText from '../../components/input';
import TextAreaInput from '../../components/textarea';
import ContentEditable from 'react-contenteditable'

import { inputFieldChanged,
            saveFactura } from '../../actions/factura.js';
import { isLogged,
            getCustomer } from '../../actions/user.js';
import { getTarifas } from '../../actions/tarifa.js';

import { withTranslation } from 'react-i18next';

const { confirm } = Modal;

class AdminCreateFactura extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded : true,
            formError : {},
            showModal : false,
        };
    }

    componentDidMount() {
        const { i18n } = this.props;

        let lang = typeof this.props.match.params.lang !== 'undefined' ? this.props.match.params.lang : null;

        if(lang === 'es' || lang === 'en'){ 
            i18n.changeLanguage(lang) 
        }

        this._getApiData()
    }

    _getApiData(){
        this.setState({ isLoaded : false})

        let id = typeof this.props.match.params.id !== 'undefined' ? this.props.match.params.id : null;

        Promise.resolve(this.props.isLogged())
        .then((response)=> {
            if(this.props.isLoggedUser){
                if(this.props.user.role.code != 0){
                    this.props.history.push('/404')
                }

                Promise.resolve(this.props.getCustomer(id))
                .then((response) => {
                    if(this.props.dataErrorClinica){
                        alert(this.props.dataErrorClinicaMessage)
                    }
                })

                this.setState({ isLoaded : true})
            }else{
                this.props.history.push('/login')
            }
        });
    }

    _saveData() {
        const { t } = this.props;
        let error = false;
        const formError = {};
        let requestedTests = {};
        this.setState({ formError : formError })
        this.setState({ isLoaded : false })

        if (typeof this.props.number === 'undefined' || this.props.number === ''){
            formError.number = t('El numero de factura es obligatorio')
            this.setState({ formError : formError })
            error = true
        }
        
        if (typeof this.props.tarifa === 'undefined' || this.props.tarifa === ''){
            formError.tarifa = t('La tarifa/servicio es obligatoria')
            this.setState({ formError : formError })
            error = true
        }
        
        if (typeof this.props.amount === 'undefined' || this.props.amount === ''){
            formError.amount = t('El importe es obligatorio')
            this.setState({ formError : formError })
            error = true
        }

        if (typeof this.props.file === 'undefined' || this.props.file === ''){
            formError.file = t('El fichero es obligatorio')
            this.setState({ formError : formError })
            error = true
        }

        if(error === false){
            Promise.resolve(this.props.saveFactura(
                this.props.number,
                this.props.tarifa,
                this.props.amount,
                this.props.file,
                this.props.customer._id
            ))
            .then((response)=> {
                if(this.props.dataError === false){
                    // Limpiamos la Store
                    this.props.inputFieldChanged('number', '')
                    this.props.inputFieldChanged('tarifa', '')
                    this.props.inputFieldChanged('amount', '')
                    this.props.inputFieldChanged('file', '')

                    this.props.history.push('/admin/cliente/' + this.props.customer._id)
                }
            });
        }

        this.setState({ isLoaded : true })
    }

    _cancelData() {
        // Limpiamos la Store
        this.props.inputFieldChanged('number', '')
        this.props.inputFieldChanged('tarifa', '')
        this.props.inputFieldChanged('amount', '')
        this.props.inputFieldChanged('file', '')

        this.props.history.push('/admin/cliente/' + this.props.customer._id)
    }

    _uploadFactura = event=>{
        const { t } = this.props;

        var data = new FormData()
        data.append('folder', 'pdf')
        data.append('file', event.target.files[0])        

        fetch(types.API_URL + 'upload', {
              method: 'POST',
              body: data
        })
        .then(function(response) {
            if (response.status === 404 || response.status === 200) {
                return response.json()
            } else {
                if(response.status === 401){  // If unauthorized
                    window.location.href = '/login'; 
                }else{
                    alert('Error subiendo el fichero')
                }
            }
        })
        .then((responseJson) => {
            this.props.inputFieldChanged('file', responseJson)
            this.setState({ 
                showModal : true
            })
        })
        .catch((error) => {
            alert(error)
        });
    }


    _renderForm() {
        const { t } = this.props;

        return(
            <React.Fragment>
                <div className={'box-with-shadow pt-4 pl-5 pr-5 pb-2 border'}>
                    <div className={'row mt-3'}>
                        
                        <div className={'col-3 border-bottom-gray-min pl-0'}>
                            <h2 className={'fs-14 font-weight-normal'}>
                                {t('Nº de factura')}
                            </h2>
                        </div>
                        <div className={'col-9 border-bottom-gray-min pr-0'}>
                            <div className={'row'}>
                                <div className={'col-12 mb-2'}>
                                    <InputText
                                        id={'number'}
                                        name={'number'}
                                        placeholder={t('Escribe el número de factura')}
                                        classes={'w-100 mb-3'}
                                        label={t('Nº de factura')}
                                        onChange={this.props.inputFieldChanged.bind(this)}
                                        value={this.props.number}
                                        error={this.state.formError.number}
                                        />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className={'row mt-4'}>
                        
                        <div className={'col-3 border-bottom-gray-min pl-0'}>
                            <h2 className={'fs-14 font-weight-normal'}>
                                {t('Tarifa / Servicio')}
                            </h2>
                        </div>
                        <div className={'col-9 border-bottom-gray-min pr-0'}>
                            <div className={'row'}>
                                <div className={'col-12 mb-2'}>
                                    <InputText
                                        id={'tarifa'}
                                        name={'tarifa'}
                                        placeholder={t('Escribe la tarifa o el servicio facturado')}
                                        classes={'w-100 mb-3'}
                                        label={t('Tarifa / Servicio')}
                                        onChange={this.props.inputFieldChanged.bind(this)}
                                        value={this.props.tarifa}
                                        error={this.state.formError.tarifa}
                                        />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className={'row mt-4'}>
                        
                        <div className={'col-3 border-bottom-gray-min pl-0'}>
                            <h2 className={'fs-14 font-weight-normal'}>
                                {t('Importe')}
                            </h2>
                        </div>
                        <div className={'col-9 border-bottom-gray-min pr-0'}>
                            <div className={'row'}>
                                <div className={'col-12 mb-2'}>
                                    <InputText
                                        id={'amount'}
                                        name={'amount'}
                                        placeholder={t('Escribe el importe de la factura')}
                                        classes={'w-100 mb-3'}
                                        label={t('Importe')}
                                        onChange={this.props.inputFieldChanged.bind(this)}
                                        value={this.props.amount}
                                        error={this.state.formError.amount}
                                        />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className={'row mt-4'}>
                        
                        <div className={'col-3 border-bottom-gray-min pl-0'}>
                            <h2 className={'fs-14 font-weight-normal'}>
                                {t('Subir factura')}
                            </h2>
                        </div>
                        <div className={'col-9 border-bottom-gray-min pr-0'}>
                            <div className={'row'}>
                                <div className={'col-12 mb-2'}>
                                    <input type="file" ref={input => this.factura = input} className={'d-none'} onChange={this._uploadFactura.bind(this)} accept="application/pdf" />
                                    
                                    { typeof this.props.file == 'undefined' || this.props.file == '' ?
                                        <button className={'btn-white'} onClick={()=>{ this.factura.click(); this.factura.value=null }}>
                                            {t('Subir factura en PDF')}
                                        </button>
                                    :
                                        <p>
                                            {this.props.file} {' '}
                                            <button className={'btn-white'} onClick={()=>{ this.factura.click(); this.factura.value=null }}>
                                                {t('Cambiar PDF')}
                                            </button>
                                        </p>
                                    }
                                        

                                    {(typeof this.state.formError.file !== 'undefined' && this.state.formError.file !== '') ?
                                        <p className={'red fs-09 mt-2'}>{this.state.formError.file}</p>
                                    : null }
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className={'row mt-5 mb-4'}>
                        
                        <div className={'col-6 text-left'}>
                            <button className={'btn-white'} onClick={() => {this._cancelData()}}>
                                {t('Cancelar')}
                            </button>
                        </div>

                        <div className={'col-6 text-right'}>
                            <button className={'btn-purple'} onClick={() => {this._saveData()}}>
                                {t('Guardar')}
                            </button>
                        </div>

                    </div>
                </div>
            </React.Fragment>
        )
    }

    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <div className={"split-container"}>
                    <Menu history={this.props.history} />
                    <div className={"container coachee"}>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <header className={"managementPanel__content-header mb-3"}>
                                    <div className={"col-5"}>
                                        <h1 className={"fs-14 poppins"}>{t('Nueva factura')}</h1>
                                    </div>

                                    <div className={"col-7 text-right"}>
                                    </div>
                                </header>
                                
                                <section className={"coachee__content-body"}>
                                    { this.state.isLoaded ?
                                        this._renderForm() 
                                    :
                                        <React.Fragment>
                                            <Loader t={t} />
                                        </React.Fragment>
                                    }
                                </section>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal visible={ this.state.showModal } footer={ null } onCancel={ ()=>{ this.setState({ showModal : false }) } } >
                    <div className={'row mt-3'}>
                        <div className={'col-12 text-center'}>
                            <ResultOk />
                            <p className={'poppins text-uppercase font-weight-bold fs-13 my-2 px-4'}>{t('FICHERO SUBIDO')}</p>
                        </div>
                        
                        <div className={'col-12 text-center'}>
                            <p className={'gray07 fs-12 lh-12 my-3 px-4'}>{t('El fichero de la factura se ha adjuntado correctamente.')}</p>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }

}

function mapStateToProps(state) {
    const storeUsers = state.users;
    const storeFacturas = state.facturas;
    const storeTarifas = state.tarifas;

    return {
        isLoggedUser : storeUsers.isLogged,
        user: storeUsers.user,
        customer: storeUsers.customer,
        tarifas: storeTarifas.tarifas,
        number : storeFacturas.number,
        tarifa : storeFacturas.tarifa,
        amount : storeFacturas.amount,
        file : storeFacturas.file,
        dataError : storeFacturas.dataError,
        dataErrorMessage : storeFacturas.dataErrorMessage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        isLogged: redirectTo => dispatch(isLogged()),
        inputFieldChanged: (field, value) => dispatch(inputFieldChanged(field, value)),
        getCustomer: (id) => dispatch(getCustomer(id)),
        getTarifas: () => dispatch(getTarifas()),
        saveFactura: (number, tarifa, amount, file, user_id) => dispatch(saveFactura(number, tarifa, amount, file, user_id))
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(AdminCreateFactura));



