import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import Menu from '../../components/menu';
import CustomTable from '../../components/table';
import deleteIcon from '../../assets/img/delete-icon.svg';

import { Modal } from 'antd';
import { ExclamationCircleOutlined,
            LoadingOutlined,
            EditOutlined } from '@ant-design/icons';

import { getTarifas,
            deleteTarifa } from '../../actions/tarifa.js';
import { isLogged } from '../../actions/user.js';

import { withTranslation } from 'react-i18next';

const { confirm } = Modal;

class AdminTarifas extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded : true,
            dataSource : []
        };
    }

    componentDidMount() {
        const { i18n } = this.props;

        let lang = typeof this.props.match.params.lang !== 'undefined' ? this.props.match.params.lang : null;

        if(lang === 'es' || lang === 'en'){ 
            i18n.changeLanguage(lang) 
        }

        this._getApiData()
    }

    _getApiData(){
        this.setState({ isLoaded : false})

        Promise.resolve(this.props.isLogged())
        .then((response)=> {
            if(this.props.isLoggedUser){
                if(this.props.user.role.code != 0){
                    this.props.history.push('/404')
                }
                
                Promise.resolve(this.props.getTarifas())
                .then((response)=> {
                    if(this.props.tarifas){
                        let dataSource = [];

                        this.props.tarifas.map((tarifa, key) =>{
                            let item = {
                                id : tarifa.id,
                                service : tarifa.service,
                                description : tarifa.description,
                                time : tarifa.time,
                                price : tarifa.price
                            }

                            dataSource.push(item);
                        })

                        this.setState({ dataSource : dataSource })
                        this.setState({ isLoaded : true })
                    }
                });

            }else{
                this.props.history.push('/login')
            }
        });
    }

    _deleteTarifa(id) {
        const { t } = this.props;

        confirm({
            title: t('¿Seguro que quieres eliminar esta tarifa?'),
            icon: <ExclamationCircleOutlined style={{ color: '#FF866B' }} />,
            content: t('Si la eliminas se borra definitivamente de tu listado'),
            okText: t('Si, borrar'),
            cancelText: t('No'),
            onOk : () => {

                Promise.resolve(this.props.deleteTarifa(id))
                .then((response)=> {
                    if(this.props.dataError === true){
                        alert( this.props.dataErrorMessage )
                    }

                    this._getApiData()
                });
                
            },
            onCancel() {
                
            },
        });
    }

    _renderTable() {
        const { t } = this.props;
        const { dataSource } = this.state;

        const columns = [
            {
                title: t('Servicio'),
                dataIndex: "service",
                key: "service",
                sorter: (a, b) => a.service.localeCompare(b.service),
            },
            {
                title: t('Tiempo de entrega'),
                dataIndex: "time",
                key: "time",
                sorter: (a, b) => a.time.localeCompare(b.time),
            },
            {
                title: "Importe tarifa",
                dataIndex: "price",
                key: "price",
            },
            {
                title: "",
                key: "herramientas",
                align: "center",
                render: (text, record) => (
                    <React.Fragment>
                        <a href={ '/admin/tarifa/' + record.id } title={t('Editar')} className={'mr-2'}>
                            <EditOutlined style={{'fontSize': '1.6em', 'color' : '#979797'}} />
                        </a>
                        <img src={deleteIcon} role={"button"} onClick={this._deleteTarifa.bind(this, record.id)} alt={t('Borrar este registro')} title={t('Borrar')} />
                    </React.Fragment>
                ),
            }
        ]

        const pagination = {  }

        return (
            <React.Fragment>

                <CustomTable
                    columns={columns}
                    dataSource={dataSource}
                    pagination={pagination}
                    loaded={this.state.isLoaded}
                    t={t}
                />
                                
            </React.Fragment>
        )
    }

    _renderLoader(){
        return ('')
    }

    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <div className={"split-container"}>
                    <Menu history={this.props.history} />
                    <div className={"container coachee"}>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <header className={"managementPanel__content-header mb-3"}>
                                    <div className={"col-5"}>
                                        <h1 className={"fs-14 poppins"}>{t('Listado de tarifas')}</h1>
                                    </div>

                                    <div className={"col-7 text-right"}>
                                        <a href={'/admin/tarifa/create'} className={'btn-purple'}>
                                            {t('Añadir tarifa')}
                                        </a>
                                    </div>
                                </header>
                                
                                <section className={"coachee__content-body"}>
                                    { this._renderTable() }
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

function mapStateToProps(state) {
    const storeUsers = state.users;
    const storeTarifas = state.tarifas;

    return {
        isLoggedUser : storeUsers.isLogged,
        user: storeUsers.user,
        tarifas : storeTarifas.tarifas,
        dataError : storeTarifas.dataError,
        dataErrorMessage : storeTarifas.dataErrorMessage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        isLogged: redirectTo => dispatch(isLogged()),
        getTarifas: () => dispatch(getTarifas()),
        deleteTarifa: (id) => dispatch(deleteTarifa(id))
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(AdminTarifas));



