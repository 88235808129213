import React from 'react';

import { CloseOutlined } from '@ant-design/icons';

class TarifaCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }
  
  render(){
    const { t, tiempo, nombre, descripcion, precio } = this.props;

    console.log(typeof tiempo);

    return (
      <div className={'tarifa-card mb-4'}>
        <p className={'purple-light mb-3'}>{ tiempo }</p>
        <h2 className={'purple fs-18 mb-4'}>{ nombre }</h2>
        <div className={'description mb-3'} dangerouslySetInnerHTML={{__html: descripcion}}></div>
        <p className={'price purple fs-25 font-weight-bold mb-0'}>{ precio }</p>
      </div>
    )
  }
}

export default TarifaCard;