export const $$initialState = {
    contact_person : '',
    animal_name : '',
    animal_history: '',
    animal_species : '',
    animal_race : '',
    animal_gender : '',
    animal_age : '',
    animal_owner : '',
    priority : {
        target : {
            value : 'normal'
        }
    },
    cytology : '',
    cytology_number : '',
    fluid : '',
    fluid_origin : '',
    fluid_type : {
        target : {
            value : ''
        }
    },
    other : '',
    other_details : '',
    requested_test : '',
    clinical_history : '',
    aditional_tests : '',
    differential_diagnoses : '',
    injury_location : '',
    shipment_method : {
        target : {
            value : 'mrw'
        }
    },
    zipcodes : {},
    dataError : false,
    dataErrorMessage : ''

};

export default function registerReducer($$state = $$initialState, action = null) {
    switch (action.type) {
        case 'CLEAN_STORE':
            return Object.assign({}, $$state, {
                contact_person : '',
                animal_name : '',
                animal_history: '',
                animal_species : '',
                animal_race : '',
                animal_gender : '',
                animal_age : '',
                animal_owner : '',
                priority : {
                    target : {
                        value : 'normal'
                    }
                },
                cytology : 'undefined',
                cytology_number : '',
                fluid : 'undefined',
                fluid_origin : '',
                fluid_type : {
                    target : {
                        value : ''
                    }
                },
                other : 'undefined',
                other_details : '',
                requested_test : '',
                previous_report : '',
                aditional_location : '',
                clinical_history : '',
                aditional_tests : '',
                differential_diagnoses : '',
                injury_location : '',
                shipment_method : {
                    target : {
                        value : 'mrw'
                    }
                },
                zipcodes : {},
                dataError : false,
                dataErrorMessage : ''
            })
        case 'MUESTRA_INPUT_FIELD_CHANGED':
            return Object.assign({}, $$state, {
                [action.payload.field]: action.payload.value,
                dataErrorMessage: { [action.payload.field] : '' }
            });

        case 'CREATE_MUESTRA':
            return Object.assign({}, $$state, {
                muestra : action.payload.muestra,
                dataError: false,
                dataErrorMessage : '',
            });

        case 'CREATE_MUESTRA_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage : action.payload.message,
            });

        case 'MUESTRA_LIST':
            return Object.assign({}, $$state, {
                muestras : action.payload,
                dataError: false,
                dataErrorMessage : '',
            });

        case 'MUESTRA_LIST_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage : action.payload.message,
            });

        case 'GET_MUESTRA':
            return Object.assign({}, $$state, {
                muestra : action.payload,
                dataError: false,
                dataErrorMessage : '',
            });

        case 'GET_MUESTRA_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage : action.payload.message,
            });
        
        case 'USER_MUESTRA_LIST':
            return Object.assign({}, $$state, {
                muestras : action.payload,
                dataError: false,
                dataErrorMessage : '',
            });

        case 'USER_MUESTRA_LIST_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage : action.payload.message,
            });

        case 'EXPRESS_ZIPCODES':
            return Object.assign({}, $$state, {
                zipcodes : action.payload,
                dataError: false,
                dataErrorMessage : '',
            });

        case 'EXPRESS_ZIPCODES_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage : action.payload.message,
            });

        case 'DELETE_MUESTRA':
            return Object.assign({}, $$state, {
                dataError: false,
                dataErrorMessage : '',
            });

        case 'DELETE_MUESTRA_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage : action.payload.message,
            });

        default: {
            return $$state;
        }
    }
}
