import React from "react"

const Informes = (props) => {
  return (
  	props.active ? (
		<svg width="28px" height="28px" viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
		    <title>578AE50D-76AC-460C-B8B4-153ABC312279</title>
		    <desc>Created with sketchtool.</desc>
		    <defs>
		        <path d="M2.33333333,2.83333333 L2.33333333,14.6666667 C2.33333333,14.942809 2.55719096,15.1666667 2.83333333,15.1666667 L18.1666667,15.1666667 C18.442809,15.1666667 18.6666667,14.942809 18.6666667,14.6666667 L18.6666667,5.79487179 C18.6666667,5.51872942 18.442809,5.29487179 18.1666667,5.29487179 L10.4577542,5.29487179 C9.80006963,5.29487179 9.18442097,4.97152664 8.81112762,4.43004618 L7.96187203,3.19815895 C7.58857868,2.65667849 6.97293003,2.33333333 6.31524544,2.33333333 L2.83333333,2.33333333 C2.55719096,2.33333333 2.33333333,2.55719096 2.33333333,2.83333333 Z M2,0 L7.34140537,-8.8817842e-16 C8.00361484,-1.00982433e-15 8.62289135,0.327781397 8.99525837,0.875379957 L10.3511854,2.8693903 C10.4442772,3.00628994 10.5990963,3.08823529 10.7646487,3.08823529 L19,3.08823529 C20.1045695,3.08823529 21,3.98366579 21,5.08823529 L21,15.5 C21,16.6045695 20.1045695,17.5 19,17.5 L2,17.5 C0.8954305,17.5 1.3527075e-16,16.6045695 0,15.5 L0,2 C-1.3527075e-16,0.8954305 0.8954305,2.02906125e-16 2,0 Z" id="path-4"></path>
		    </defs>
		    <g id="Asset-Artboard-Page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
		        <g id="icono-/-settings-copy-2-icono-/-Informe">
		            <g id="Color-/-Purple" transform="translate(3.500000, 4.666667)">
		                <mask id="mask-4" fill="white">
		                    <use xlinkHref="#path-4"></use>
		                </mask>
		                <use id="Mask" fill="#000000" fillRule="nonzero" xlinkHref="#path-4"></use>
		                <g id="Color-/-Purple-Color-/White" mask="url(#mask-4)" fill="#FFFFFF">
		                    <g transform="translate(-3.500000, -4.666667)" id="Rectangle">
		                        <rect x="0" y="0" width="28" height="28"></rect>
		                    </g>
		                </g>
		            </g>
		        </g>
		    </g>
		</svg>
	):(
		<svg width="28px" height="28px" viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
		    <title>578AE50D-76AC-460C-B8B4-153ABC312279</title>
		    <desc>Created with sketchtool.</desc>
		    <defs>
		        <path d="M2.33333333,2.83333333 L2.33333333,14.6666667 C2.33333333,14.942809 2.55719096,15.1666667 2.83333333,15.1666667 L18.1666667,15.1666667 C18.442809,15.1666667 18.6666667,14.942809 18.6666667,14.6666667 L18.6666667,5.79487179 C18.6666667,5.51872942 18.442809,5.29487179 18.1666667,5.29487179 L10.4577542,5.29487179 C9.80006963,5.29487179 9.18442097,4.97152664 8.81112762,4.43004618 L7.96187203,3.19815895 C7.58857868,2.65667849 6.97293003,2.33333333 6.31524544,2.33333333 L2.83333333,2.33333333 C2.55719096,2.33333333 2.33333333,2.55719096 2.33333333,2.83333333 Z M2,0 L7.34140537,-8.8817842e-16 C8.00361484,-1.00982433e-15 8.62289135,0.327781397 8.99525837,0.875379957 L10.3511854,2.8693903 C10.4442772,3.00628994 10.5990963,3.08823529 10.7646487,3.08823529 L19,3.08823529 C20.1045695,3.08823529 21,3.98366579 21,5.08823529 L21,15.5 C21,16.6045695 20.1045695,17.5 19,17.5 L2,17.5 C0.8954305,17.5 1.3527075e-16,16.6045695 0,15.5 L0,2 C-1.3527075e-16,0.8954305 0.8954305,2.02906125e-16 2,0 Z" id="path-4"></path>
		    </defs>
		    <g id="Asset-Artboard-Page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
		        <g id="icono-/-settings-copy-2-icono-/-Informe">
		            <g id="Color-/-Purple" transform="translate(3.500000, 4.666667)">
		                <mask id="mask-4" fill="white">
		                    <use xlinkHref="#path-4"></use>
		                </mask>
		                <use id="Mask" fill="#000000" fillRule="nonzero" xlinkHref="#path-4"></use>
		                <g id="Color-/-Purple-Color-/White" mask="url(#mask-4)" fill="#9B9B9B">
		                    <g transform="translate(-3.500000, -4.666667)" id="Rectangle">
		                        <rect x="0" y="0" width="28" height="28"></rect>
		                    </g>
		                </g>
		            </g>
		        </g>
		    </g>
		</svg>
	)
  )
}

export default Informes;