import React from "react"

const ResultOk = (props) => {
  return (
  	<svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
	    <title>8B6A3677-01AA-4E7F-986F-A40A4A58E547</title>
	    <desc>Created with sketchtool.</desc>
	    <defs>
	        <path d="M15.0002432,0 C23.2711326,0 29.9997568,6.72875833 30,15 C30,23.271728 23.2713758,30 15.0002432,30 C6.72935378,30 -1.77635684e-14,23.271728 -1.77635684e-14,15 C-1.77635684e-14,6.72875833 6.72935378,0 15.0002432,0 Z M22.6823509,10.5394845 C21.9413983,9.82017201 20.7628595,9.82017201 20.0219069,10.5394845 L13.3849955,16.9825592 L10.9320473,14.6012515 C10.1910947,13.8819391 9.01255602,13.881939 8.27160345,14.6012515 C8.25884597,14.6136363 8.24627271,14.6262096 8.23388782,14.6389671 C7.54151273,15.3521719 7.55839859,16.4916196 8.27160345,17.1839947 L12.6884442,21.4718378 C13.0764333,21.8484953 13.693558,21.8484953 14.0815471,21.4718378 L22.6823509,13.1222279 C22.6951084,13.109843 22.7076817,13.0972697 22.7200666,13.0845123 C23.4124417,12.3713074 23.3955558,11.2318596 22.6823509,10.5394845 Z" id="path-ok"></path>
	    </defs>
	    <g id="Asset-Artboard-Page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
	        <g id="icono-/-Confirmación">
	            <g id="Color-/-Purple" transform="translate(5.000000, 5.000000)">
	                <mask id="mask-ok" fill="white">
	                    <use xlinkHref="#path-ok"></use>
	                </mask>
	                <use id="Mask" fill="#000000" fillRule="nonzero" xlinkHref="#path-ok"></use>
	                <g mask="url(#mask-ok)" fill="#5E44F4" id="Rectangle">
	                    <g transform="translate(-5.000000, -5.000000)">
	                        <rect x="0" y="0" width="40" height="40"></rect>
	                    </g>
	                </g>
	            </g>
	        </g>
	    </g>
	</svg>
  )
}

export default ResultOk;