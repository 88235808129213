import React from "react"

const Muestras = (props) => {
  return (
  	props.active ? (
		<svg width="28px" height="28px" viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
		    <title>6B02EC83-2230-4BE1-A776-449A5AC75671</title>
		    <desc>Created with sketchtool.</desc>
		    <defs>
		        <path d="M11.9184034,6.91058604 C12.2080018,7.56611122 12.4695951,8.01706845 12.678146,8.24760781 C12.8634056,8.45240013 13.2330632,8.73775724 13.7745359,9.08465522 L17.1775757,5.99415803 L15.1009987,3.91758097 C13.9548228,4.99107549 12.893941,5.98876082 11.9184034,6.91058604 Z M10.1396954,8.5973136 C8.89078987,9.78634675 7.81962263,10.8173848 6.92634448,11.6902748 C5.02322879,13.5499538 3.87276414,14.7589694 3.77254556,14.9454892 C3.35862059,15.7158573 3.39020913,16.0615962 4.02772069,16.6274918 C4.33425821,16.8995939 4.88311521,16.9405956 5.51453315,16.5519473 C6.16773473,16.1498909 8.38483925,14.1640186 12.0108146,10.711707 C11.5549752,10.3912636 11.2025423,10.0945673 10.947775,9.81293865 C10.6706107,9.50655168 10.4041383,9.10317124 10.1396954,8.5973136 Z M18.829364,7.64594624 L14.1557472,11.8898919 C10.0402348,15.8225845 7.6345722,17.9869424 6.73761737,18.539033 C5.23982592,19.4609486 3.56387496,19.3357486 2.47873355,18.3725085 C0.965000759,17.0288235 0.825386427,15.5007344 1.71712419,13.8410947 C2.17362028,12.9914963 5.85863036,9.37650086 13.4501635,2.26674574 L13.1750423,1.99162458 C12.7194306,1.5360129 12.7194306,0.797320429 13.1750423,0.341708755 C13.630654,-0.113902918 14.3693464,-0.113902918 14.8249581,0.341708755 L20.6582915,6.17504209 C21.1139031,6.63065376 21.1139031,7.36934624 20.6582915,7.82495791 C20.2026798,8.28056959 19.4639873,8.28056959 19.0083756,7.82495791 L18.829364,7.64594624 Z" id="path-1"></path>
		    </defs>
		    <g id="Asset-Artboard-Page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
		        <g id="icono-/-settings-copy-2-icono-/-test-tube">
		            <g id="Color-/-black" transform="translate(3.500000, 4.666667)">
		                <mask id="mask-2" fill="white">
		                    <use xlinkHref="#path-1"></use>
		                </mask>
		                <use id="Mask" fill="#979797" fillRule="nonzero" xlinkHref="#path-1"></use>
		                <g id="Color-/-black-Color-/-grey" mask="url(#mask-2)" fill="#FFFFFF">
		                    <g transform="translate(-3.500000, -4.666667)" id="Rectangle">
		                        <rect x="0" y="0" width="28" height="28"></rect>
		                    </g>
		                </g>
		            </g>
		        </g>
		    </g>
		</svg>
	):(
		<svg width="28px" height="28px" viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
		    <title>6B02EC83-2230-4BE1-A776-449A5AC75671</title>
		    <desc>Created with sketchtool.</desc>
		    <defs>
		        <path d="M11.9184034,6.91058604 C12.2080018,7.56611122 12.4695951,8.01706845 12.678146,8.24760781 C12.8634056,8.45240013 13.2330632,8.73775724 13.7745359,9.08465522 L17.1775757,5.99415803 L15.1009987,3.91758097 C13.9548228,4.99107549 12.893941,5.98876082 11.9184034,6.91058604 Z M10.1396954,8.5973136 C8.89078987,9.78634675 7.81962263,10.8173848 6.92634448,11.6902748 C5.02322879,13.5499538 3.87276414,14.7589694 3.77254556,14.9454892 C3.35862059,15.7158573 3.39020913,16.0615962 4.02772069,16.6274918 C4.33425821,16.8995939 4.88311521,16.9405956 5.51453315,16.5519473 C6.16773473,16.1498909 8.38483925,14.1640186 12.0108146,10.711707 C11.5549752,10.3912636 11.2025423,10.0945673 10.947775,9.81293865 C10.6706107,9.50655168 10.4041383,9.10317124 10.1396954,8.5973136 Z M18.829364,7.64594624 L14.1557472,11.8898919 C10.0402348,15.8225845 7.6345722,17.9869424 6.73761737,18.539033 C5.23982592,19.4609486 3.56387496,19.3357486 2.47873355,18.3725085 C0.965000759,17.0288235 0.825386427,15.5007344 1.71712419,13.8410947 C2.17362028,12.9914963 5.85863036,9.37650086 13.4501635,2.26674574 L13.1750423,1.99162458 C12.7194306,1.5360129 12.7194306,0.797320429 13.1750423,0.341708755 C13.630654,-0.113902918 14.3693464,-0.113902918 14.8249581,0.341708755 L20.6582915,6.17504209 C21.1139031,6.63065376 21.1139031,7.36934624 20.6582915,7.82495791 C20.2026798,8.28056959 19.4639873,8.28056959 19.0083756,7.82495791 L18.829364,7.64594624 Z" id="path-1"></path>
		    </defs>
		    <g id="Asset-Artboard-Page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
		        <g id="icono-/-settings-copy-2-icono-/-test-tube">
		            <g id="Color-/-black" transform="translate(3.500000, 4.666667)">
		                <mask id="mask-2" fill="white">
		                    <use xlinkHref="#path-1"></use>
		                </mask>
		                <use id="Mask" fill="#979797" fillRule="nonzero" xlinkHref="#path-1"></use>
		                <g id="Color-/-black-Color-/-grey" className="muestras-icon" mask="url(#mask-2)" fill="#9B9B9B">
		                    <g transform="translate(-3.500000, -4.666667)" id="Rectangle">
		                        <rect x="0" y="0" width="28" height="28"></rect>
		                    </g>
		                </g>
		            </g>
		        </g>
		    </g>
		</svg>
	)
  )
}

export default Muestras;