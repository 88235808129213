import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { types } from '../../constants/constants';

import { FileDrop } from 'react-file-drop';

import Menu from '../../components/menu';

import { ExclamationCircleOutlined,
            LoadingOutlined,
            ItalicOutlined,
            BoldOutlined,
            CloseCircleOutlined } from '@ant-design/icons';
import Add from "../../components/icons/add";

import Loader from '../../components/loader';
import InputText from '../../components/input';
import TextAreaInput from '../../components/textarea';
import ContentEditable from 'react-contenteditable'
import sanitizeHtml from "sanitize-html"
import ImageMapper from 'react-image-mapper'

import { Modal, Checkbox, Select, Radio, Tooltip } from 'antd';

import InfoIcon from "../../components/icons/info";
import animalMap from '../../assets/img/animal_map.jpg';

import { saveTarifa,
            getTarifas } from '../../actions/tarifa.js';

import { getReplies } from '../../actions/settings.js';
import { inputFieldChanged,
            saveReport,
            savePicture,
            getReport } from '../../actions/report.js';

import { getMuestra } from '../../actions/muestra.js';
import { isLogged,
            getUser,
            getCustomer } from '../../actions/user.js';

import { withTranslation } from 'react-i18next';

const { confirm } = Modal;
const { Option } = Select;

class AdminReportModify extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded : false,
            uploading : false,
            formError : {},
            templates : ['T-Cito', 'Cliente especial'],
            titles : [
                'Informe de citología', 
                'Informe de citología y frotis', 
                'Informe de frotis', 
                'Informe de hematología', 
                'Informe de médula ósea', 
                'Informe de citología básica'
            ],
            description_answers : [],
            comment_answers : [],
            showModal : false
        };
    }

    componentDidMount() {
        const { i18n } = this.props;

        let lang = typeof this.props.match.params.lang !== 'undefined' ? this.props.match.params.lang : null;

        if(lang === 'es' || lang === 'en'){ 
            i18n.changeLanguage(lang) 
        }

        this._getApiData()

        /* Prevenir que la imagen se abra en el navegador cuando hacer drop */
        window.addEventListener("dragover",function(e){
          e.preventDefault();
        },false);
        window.addEventListener("drop",function(e){
          e.preventDefault();
        },false);
    }

    _getApiData(){
        let id = typeof this.props.match.params.id !== 'undefined' ? this.props.match.params.id : null;

        Promise.resolve(this.props.isLogged())
        .then((response)=> {
            if(this.props.isLoggedUser){
                if(this.props.user.role.code != 0){
                    this.props.history.push('/404')
                }

                Promise.resolve(this.props.getReport(id))
                .then((response) => {
                    if(this.props.dataError){
                        alert(this.props.dataErrorMessage)
                    }else{
                        this.props.inputFieldChanged('template', this.props.report.template)
                        this.props.inputFieldChanged('title', this.props.report.title)
                        this.props.inputFieldChanged('clinical_history', this.props.report.clinical_history)
                        this.props.inputFieldChanged('description', this.props.report.description)
                        this.props.inputFieldChanged('interpretation', this.props.report.interpretation)
                        this.props.inputFieldChanged('comments', this.props.report.comments)
                        this.props.inputFieldChanged('pictures', JSON.parse(this.props.report.pictures))

                        Promise.resolve(this.props.getMuestra(this.props.report.muestra._id))
                        .then((response) => {
                            if(this.props.dataErrorMuestra){
                                alert(this.props.dataErrorMuestraMessage)
                            }else{
                                this.props.inputFieldChanged('clinical_history', this.props.muestra.clinical_history)
                                Promise.resolve(this.props.getCustomer(this.props.muestra.customer_id))
                                .then((response) => {
                                    if(this.props.dataErrorClinica){
                                        alert(this.props.dataErrorClinicaMessage)
                                    }

                                    Promise.resolve(this.props.getReplies())
                                    .then((response)=> {
                                        if(this.props.answers){
                                            let comment_answers = [];
                                            let description_answers = [];

                                            this.props.answers.map((answer, key) =>{
                                                JSON.parse(answer.comment_replies).map((comment_answer, key) =>{
                                                    comment_answers.push(comment_answer)
                                                })

                                                JSON.parse(answer.description_replies).map((description_answer, key) =>{
                                                    description_answers.push(description_answer)
                                                })
                                            })

                                            comment_answers.unshift({title : 'Respuesta libre', reply : ''})
                                            description_answers.unshift({title : 'Respuesta libre', reply : ''})

                                            this.setState({ 
                                                comment_answers : comment_answers,
                                                description_answers : description_answers,
                                                isLoaded : true 
                                            })
                                        }
                                    })
                                })
                            }
                        })
                    }
                })
            }else{
                this.props.history.push('/login')
            }
        });
    }

    _saveData(status, id) {
        const { t } = this.props;
        let error = false;
        const formError = {};
        let requestedTests = {};
        this.setState({ formError : formError })
        this.setState({ isLoaded : false })

        if (typeof this.props.title === 'undefined' || this.props.title === ''){
            formError.title = t('El nombre del informe es obligatorio')
            this.setState({ formError : formError })
            error = true
        }
        
        if (typeof this.props.template === 'undefined' || this.props.template === ''){
            formError.template = t('La plantilla de informe es obligatoria')
            this.setState({ formError : formError })
            error = true
        }

        if(error === false){
            Promise.resolve(this.props.saveReport(
                this.props.template,
                this.props.title,
                this.props.clinical_history,
                this.props.description,
                this.props.interpretation,
                this.props.comments,
                JSON.stringify(this.props.pictures),
                this.props.muestra.requested_test._id,
                this.props.muestra.id,
                this.props.customer._id,
                status,
                id
            ))
            .then((response)=> {
                if(this.props.dataError === false){
                    // Limpiamos la Store
                    this.props.inputFieldChanged('template', undefined)
                    this.props.inputFieldChanged('title', undefined)
                    this.props.inputFieldChanged('clinical_history', '')
                    this.props.inputFieldChanged('description', '')
                    this.props.inputFieldChanged('interpretation', '')
                    this.props.inputFieldChanged('comments', '')
                    this.props.inputFieldChanged('pictures', [])

                    this.props.history.push('/admin/muestra/' + this.props.muestra.id)
                }
            });
        }

        this.setState({ isLoaded : true })
    }

    _fillDescriptionAnswer(data) {
        let content = typeof this.props.description === 'undefined' ? '' : this.props.description
        content = content === '' ? data : content + '\n\n' + data
        this.props.inputFieldChanged('description', content)
    }

    _fillCommentAnswer(data) {
        let content = typeof this.props.comments === 'undefined' ? '' : this.props.comments
        content = content === '' ? data : content + '\n\n' + data
        this.props.inputFieldChanged('comments', content)
    }

    _uploadPicture = event => {
        const { t } = this.props;

        const arrFiles = Array.from(event.target.files);

        arrFiles.map((file, key) => {
            var data = new FormData()
            data.append('folder', 'report')
            data.append('file', file)     

            this.setState({ uploading : true })   

            fetch(types.API_URL + 'upload', {
                  method: 'POST',
                  body: data
            })
            .then(function(response) {
                if (response.status === 404 || response.status === 200) {
                    return response.json()
                } else {
                    if(response.status === 401){  // If unauthorized
                        window.location.href = '/login'; 
                    }else{
                           alert('Error subiendo el fichero')
                    }
                }
            })
            .then((responseJson) => {
                let pictures = this.props.pictures

                if(typeof pictures === 'undefined'){
                    pictures = []
                }

                pictures.push(responseJson)

                this.props.inputFieldChanged('pictures', pictures)

                this.setState({ uploading : false });
            })
            .catch((error) => {
                alert(error)
            });
        })
    }

    _uploadPictureDrop = (files) => {
        const { t } = this.props;

        const arrFiles = Array.from(files);

        arrFiles.map((file, key) => {
            var data = new FormData()
            data.append('folder', 'report')
            data.append('file', file)        

            this.setState({ uploading : true })

            fetch(types.API_URL + 'upload', {
                  method: 'POST',
                  body: data
            })
            .then(function(response) {
                if (response.status === 404 || response.status === 200) {
                    return response.json()
                } else {
                    if(response.status === 401){  // If unauthorized
                        window.location.href = '/login'; 
                    }else{
                           alert('Error subiendo el fichero')
                    }
                }
            })
            .then((responseJson) => {
                let pictures = this.props.pictures

                if(typeof pictures === 'undefined'){
                    pictures = []
                }

                pictures.push(responseJson)

                this.props.inputFieldChanged('pictures', pictures)

                this.setState({ uploading : false });
            })
            .catch((error) => {
                alert(error)
            });
        })
    }

    _deletePicture(key) {
        let pictures = this.props.pictures

        pictures.splice(key, 1)

        this.props.inputFieldChanged('pictures', pictures)

        this.setState({ isLoaded : true });
    }

    _renderForm() {
        const { t, muestra, customer } = this.props;
        const textInfoCito = t('Las citologías de líquido sinovial se consideran como una citología completa cuando se remiten muestras de 2 o más articulaciones. Las muestras que no sean de líquido sinovial remitidas a partir de 2 articulaciones se consideran localizaciones adicionales. Ej. Citologías de 4 articulaciones y bazo') + ':' + t('1 citología normal + localización adicional. Citología de 1 articulación + hígado: 1 citología normal.')
        const { description_answers, comment_answers } = this.state

        return(
            <React.Fragment>
                <div className={'box-with-shadow pt-4 pl-5 pr-5 pb-2 border'}>
                    <div className={'row mt-3'}>
                        
                        <div className={'col-3 border-bottom-gray-min pl-0 pb-3'}>
                            <h2 className={'fs-14 font-weight-normal'}>
                                {muestra.customer}
                            </h2>
                            <p className={'edit-gray'}>
                                {customer.address} {' '} {customer.address_number} {', '} {customer.zipcode} {' '} {customer.city}
                            </p>
                        </div>
                        <div className={'col-9 border-bottom-gray-min pr-0 pb-3'}>

                        </div>

                    </div>

                    <div className={'row pt-3'}>
                        <div className={'col-1 pl-0'}><p className={'fs-12 edit-gray'}>{t('Especie')}:</p></div>
                        <div className={'col-2 pl-0'}><p className={'fs-12'}>{muestra.animal_species}</p></div>
                        <div className={'col-1'}><p className={'fs-12 edit-gray'}>{t('Sexo')}:</p></div>
                        <div className={'col-2 pl-0'}><p className={'fs-12'}>{muestra.animal_gender}</p></div>
                        <div className={'col-1'}><p className={'fs-12 edit-gray'}>{t('Nombre')}:</p></div>
                        <div className={'col-2 pl-0'}><p className={'fs-12'}>{muestra.animal_name}</p></div>
                        <div className={'col-1 text-right'}><p className={'fs-12 edit-gray'}>{t('ID')}:</p></div>
                        <div className={'col-2 px-0'}><p className={'fs-12 lh-2 text-uppercase ellipsis'}>{muestra.lab_id}</p></div>
                    </div>

                    <div className={'row'}>
                        <div className={'col-1 pl-0'}><p className={'fs-12 edit-gray'}>{t('Raza')}:</p></div>
                        <div className={'col-2 pl-0'}><p className={'fs-12'}>{muestra.animal_race}</p></div>
                        <div className={'col-1'}><p className={'fs-12 edit-gray'}>{t('Edad')}:</p></div>
                        <div className={'col-2 pl-0'}><p className={'fs-12'}>{muestra.animal_age}</p></div>
                        <div className={'col-1'}><p className={'fs-12 edit-gray'}>{t('Dueño')}:</p></div>
                        <div className={'col-2 pl-0'}><p className={'fs-12'}>{muestra.animal_owner}</p></div>
                        <div className={'col-1 text-right pl-0'}><p className={'fs-12 edit-gray'}>{t('Historia')}:</p></div>
                        <div className={'col-2 px-0'}><p className={'fs-12 text-uppercase ellipsis'}>{muestra.animal_history}</p></div>
                    </div>

                </div>

                <div className={'box-with-shadow pt-4 pl-5 pr-5 pb-2 border'}>
                    <div className={'row mt-4'}>
                        
                        <div className={'col-3 border-bottom-gray-min pl-0 pb-3'}>
                            <h2 className={'fs-14 font-weight-normal'}>
                                {t('Plantilla de informe')}
                            </h2>
                        </div>
                        <div className={'col-9 border-bottom-gray-min pr-0 pb-3'}>
                            <div className={'row'}>
                                <div className={'col-12'}>
                                    <Select 
                                    id={'template'}
                                    placeholder={t('Selecciona el tipo de informe')} 
                                    className={'w-100 mb-3'}
                                    value={this.props.template}
                                    onChange={this.props.inputFieldChanged.bind(this, 'template')}>

                                        {typeof this.state.templates !== 'undefined' ? 
                                            Object.entries(this.state.templates).map(([key, template]) => {
                                                return(
                                                    <Option key={key} value={template}>{template}</Option>
                                                );
                                            })
                                        : null}

                                    </Select>

                                    {(typeof this.state.formError.template !== 'undefined' && this.state.formError.template !== '') ?
                                        <p className={'red fs-09 mt-0 pl-3'}>{this.state.formError.template}</p>
                                    : null }
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className={'row mt-4'}>
                        
                        <div className={'col-3 border-bottom-gray-min pl-0'}>
                            <h2 className={'fs-14 font-weight-normal'}>
                                {t('Título')}
                            </h2>
                        </div>
                        <div className={'col-9 border-bottom-gray-min pr-0 pb-3'}>
                            <div className={'row'}>

                                <div className={'col-12'}>
                                    <Select 
                                    id={'title'}
                                    placeholder={t('Selecciona el título del informe')} 
                                    className={'w-100 mb-3'}
                                    value={this.props.title}
                                    onChange={this.props.inputFieldChanged.bind(this, 'title')}>

                                        {typeof this.state.titles !== 'undefined' ? 
                                            Object.entries(this.state.titles).map(([key, title]) => {
                                                return(
                                                    <Option key={key} value={title}>{title}</Option>
                                                );
                                            })
                                        : null}

                                    </Select>

                                    {(typeof this.state.formError.title !== 'undefined' && this.state.formError.title !== '') ?
                                        <p className={'red fs-09 mt-0 pl-3'}>{this.state.formError.title}</p>
                                    : null }
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className={'row mt-4'}>
                        
                        <div className={'col-3 border-bottom-gray-min pl-0 pb-3'}>
                            <h2 className={'fs-14 font-weight-normal'}>
                                {t('Historia clínica / muestra')}
                            </h2>
                        </div>
                        <div className={'col-9 border-bottom-gray-min pr-0 pb-3'}>
                            <div className={'row'}>
                                <div className={'col-12'}>
                                    <TextAreaInput
                                        id={'clinical_history'}
                                        name={'clinical_history'}
                                        placeholder={t('Escribe aquí la información')}
                                        classes={'w-100 mb-3'}
                                        label={t('Historia clínica')}
                                        value={this.props.clinical_history}
                                        onChange={this.props.inputFieldChanged.bind(this)}
                                        rows={5}
                                        error={this.state.formError.clinical_history }  
                                        />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className={'row mt-4'}>
                        
                        <div className={'col-3 border-bottom-gray-min pl-0 pb-3'}>
                            <h2 className={'fs-14 font-weight-normal'}>
                                {t('Descripción')}
                            </h2>
                        </div>
                        <div className={'col-9 border-bottom-gray-min pr-0 pb-3'}>
                            <div className={'row'}>
                                <div className={'col-12'}>
                                    <Select 
                                    id={'description_answer'}
                                    placeholder={t('Respuesta libre')} 
                                    className={'w-100 mb-3'}
                                    onChange={ this._fillDescriptionAnswer.bind(this) }>

                                        {   
                                            Object.entries(description_answers).map(([key, answer]) => {
                                                return(
                                                    <Option key={key} value={answer.reply}>{answer.title}</Option>
                                                );
                                            })
                                        }

                                    </Select>
                                </div>

                                <div className={'col-12'}>
                                    <TextAreaInput
                                        id={'description'}
                                        name={'description'}
                                        placeholder={t('Escribe aquí la información')}
                                        classes={'w-100 mb-3'}
                                        label={t('Descripción')}
                                        value={this.props.description}
                                        onChange={this.props.inputFieldChanged.bind(this)}
                                        rows={5}
                                        error={this.state.formError.description }  
                                        />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className={'row mt-4'}>
                        
                        <div className={'col-3 border-bottom-gray-min pl-0 pb-3'}>
                            <h2 className={'fs-14 font-weight-normal'}>
                                {t('Interpretación')}
                            </h2>
                        </div>
                        <div className={'col-9 border-bottom-gray-min pr-0 pb-3'}>
                            <div className={'row'}>
                                <div className={'col-12'}>
                                    <TextAreaInput
                                        id={'interpretation'}
                                        name={'interpretation'}
                                        placeholder={t('Escribe aquí la información')}
                                        classes={'w-100 mb-3'}
                                        label={t('Interpretación')}
                                        value={this.props.interpretation}
                                        onChange={this.props.inputFieldChanged.bind(this)}
                                        rows={5}
                                        error={this.state.formError.interpretation }  
                                        />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className={'row mt-4'}>
                        
                        <div className={'col-3 border-bottom-gray-min pl-0 pb-3'}>
                            <h2 className={'fs-14 font-weight-normal'}>
                                {t('Comentarios')}
                            </h2>
                        </div>
                        <div className={'col-9 border-bottom-gray-min pr-0 pb-3'}>
                            <div className={'row'}>
                                <div className={'col-12'}>
                                    <Select 
                                    id={'comment_answer'}
                                    placeholder={t('Respuesta libre')} 
                                    className={'w-100 mb-3'}
                                    onChange={ this._fillCommentAnswer.bind(this) }>

                                        {   
                                            Object.entries(comment_answers).map(([key, answer]) => {
                                                return(
                                                    <Option key={key} value={answer.reply}>{answer.title}</Option>
                                                );
                                            })
                                        }

                                    </Select>
                                </div>

                                <div className={'col-12'}>
                                    <TextAreaInput
                                        id={'comments'}
                                        name={'comments'}
                                        placeholder={t('Escribe aquí la información')}
                                        classes={'w-100 mb-3'}
                                        label={t('Comentarios')}
                                        value={this.props.comments}
                                        onChange={this.props.inputFieldChanged.bind(this)}
                                        rows={5}
                                        error={this.state.formError.comments }  
                                        />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className={'row mt-4'}>
                        
                        <div className={'col-3 border-bottom-gray-min pl-0 pb-3'}>
                            <h2 className={'fs-14 font-weight-normal'}>
                                {t('Fotos')}
                            </h2>
                        </div>
                        <div className={'col-9 border-bottom-gray-min pr-0 pb-3'}>
                            <div className={'row'}>
                                { typeof this.props.pictures !== 'undefined' ?
                                    this.props.pictures.map((picture, key) => {
                                        let filename = picture.split('/')
                                        filename = filename[filename.length - 1]
                                        return(
                                            <React.Fragment key={key}>
                                            <div className={'col-4'}>
                                                <div className={'img-square-little-wrapper m-2'}>
                                                    <img src={picture} key={key} className={'img-square-little border'} />
                                                    <div onClick={() => { this._deletePicture(key) }} className={'img-hover-delete fs-3 d-flex align-items-center justify-content-center'}>
                                                        <CloseCircleOutlined style={{color: '#fff'}} />
                                                    </div>
                                                </div>
                                                <p className={'mx-2'}>{ filename }</p>
                                            </div>
                                            </React.Fragment>
                                        )
                                    })
                                : null }
                            </div>
                            <div className={'row'}>
                                <div className={'col flex-basis-15 p-3'}>
                                    { this.state.uploading ?
                                        <React.Fragment>
                                            <input type="file" ref={input => this.pictures = input} className={'d-none'} onChange={this._uploadPicture.bind(this)} accept=".jpg,.png,.heic" multiple />
                                            <div className={'d-flex upload-placeholder align-items-center justify-content-center p-5 fs-2'} role={'button'} onClick={ ()=>{this.pictures.click(); this.pictures.value=null} }>
                                                <Loader t={t} message={t('Guardando...')} />
                                            </div>
                                        </React.Fragment>
                                    :
                                        <React.Fragment>
                                            <FileDrop
                                                onDrop={(files) => this._uploadPictureDrop(files)}>
                                                <input type="file" ref={input => this.pictures = input} className={'d-none'} onChange={this._uploadPicture.bind(this)} accept=".jpg,.png,.heic" multiple />
                                                <div className={'d-flex upload-placeholder align-items-center justify-content-center p-5 fs-2'} role={'button'} onClick={ ()=>{this.pictures.click(); this.pictures.value=null} }>
                                                   <Add role={'button'} /> <span className={'ml-2'}>{ t('Subir fotos') }</span>
                                                </div>
                                            </FileDrop>
                                        </React.Fragment>
                                    }
                                </div>
                            </div>

                            <p className={'mt-1 mb-3'}>{ t('El tamaño máximo de cada imagen no puede superar los 10MB') }</p>
                        </div>

                    </div>
                    

                    <div className={'row mt-5 mb-4'}>
                        
                        <div className={'col-3 text-left'}>
                            <a href={'/admin/cliente/' + this.props.clinica._id} className={'btn-white'}>
                                {t('Cancelar')}
                            </a>
                        </div>

                        <div className={'col-4 text-center'}>
                            {(typeof this.props.dataErrorMessage !== 'undefined' && this.props.dataErrorMessage !== '') ?
                                <p className={'red fs-09 mt-0 pl-3'}>{this.props.dataErrorMessage}</p>
                            : null }
                        </div>

                        <div className={'col-3 text-right'}>
                            <button className={'btn-purple'} onClick={() => {this._saveData(1, this.props.report.id)}}>
                                {t('Guardar y enviar')}
                            </button>
                        </div>

                        <div className={'col-2 text-right'}>
                            <button className={'btn-purple'} onClick={() => {this._saveData(0, this.props.report.id)}}>
                                {t('Guardar')}
                            </button>
                        </div>

                    </div>
                </div>
            </React.Fragment>
        )
    }

    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <div className={"split-container"}>
                    <Menu history={this.props.history} />
                    <div className={"container coachee"}>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <header className={"managementPanel__content-header mb-3"}>
                                    <div className={"col-5"}>
                                        <h1 className={"fs-14 poppins"}>{t('Nuevo informe')}</h1>
                                    </div>

                                    <div className={"col-7 text-right"}>
                                    </div>
                                </header>
                                
                                <section className={"coachee__content-body"}>
                                    { this.state.isLoaded ?
                                        this._renderForm() 
                                    :
                                        <React.Fragment>
                                            <Loader t={t} />
                                        </React.Fragment>
                                    }
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

function mapStateToProps(state) {
    const storeUsers = state.users;
    const storeTarifas = state.tarifas;
    const storeMuestras = state.muestras;
    const storeSettings = state.settings;
    const storeReports = state.reports;

    return {
        isLoggedUser : storeUsers.isLogged,
        muestra : storeMuestras.muestra,
        tarifas : storeTarifas.tarifas,
        answers : storeSettings.replies,
        customer: storeUsers.customer,
        clinica: storeUsers.customer,
        address: storeUsers.address,
        address_number: storeUsers.address_number,
        zipcode: storeUsers.zipcode,
        city: storeUsers.city,
        user: storeUsers.user,
        report : storeReports.report,
        title : storeReports.title,
        template : storeReports.template,
        tarifa : storeReports.tarifa,
        description : storeReports.description,
        clinical_history : storeReports.clinical_history,
        interpretation : storeReports.interpretation,
        comments : storeReports.comments,
        pictures : storeReports.pictures,
        dataError : storeTarifas.dataError,
        dataErrorMessage : storeTarifas.dataErrorMessage,
        dataErrorClinica : storeUsers.dataError,
        dataErrorClinicaMessage : storeUsers.dataErrorMessage,
        dataErrorMuestra : storeMuestras.dataError,
        dataErrorMuestraMessage : storeMuestras.dataErrorMessage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        isLogged: redirectTo => dispatch(isLogged()),
        inputFieldChanged: (field, value) => dispatch(inputFieldChanged(field, value)),
        getUser: (id) => dispatch(getUser(id)),
        getCustomer: (id) => dispatch(getCustomer(id)),
        getMuestra: (id) => dispatch(getMuestra(id)),
        getReport: (id) => dispatch(getReport(id)),
        getTarifas: () => dispatch(getTarifas()),
        saveTarifa: (service, description, time, price) => dispatch(saveTarifa(service, description, time, price)),
        saveReport: (template, title, clinical_history, description, interpretation, comments, pictures, tarifa, muestra, user, status, id) => dispatch(saveReport(template, title, clinical_history, description, interpretation, comments, pictures, tarifa, muestra, user, status, id)),
        getReplies: () => dispatch(getReplies())
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(AdminReportModify));



