import React from "react"

const Tarifas = (props) => {
  return (
	<svg width="28px" height="28px" viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
	    <title>E7CDA4F7-43F0-4A36-9E1C-2C93D934BAB8</title>
	    <desc>Created with sketchtool.</desc>
	    <defs>
	        <path d="M13.1180507,6.9999997 L6.31658231,6.9999997 C5.6722501,6.9999997 5.14991564,6.47766525 5.14991564,5.83333304 C5.14991564,5.18900083 5.6722501,4.66666637 6.31658231,4.66666637 L13.1118546,4.66666637 L11.2055907,2.81478122 C10.7499012,2.37209093 10.7393633,1.64381026 11.1820536,1.18812081 C11.1858183,1.18424556 11.1896103,1.18039688 11.1934292,1.17657506 C11.6488684,0.720790962 12.3875609,0.720511382 12.8433449,1.1759506 L17.5,5.82908211 L12.8433408,10.5062782 C12.3887327,10.9628913 11.650042,10.9645166 11.193429,10.5099085 C10.7432017,10.0616581 10.7415991,9.33329799 11.1898495,8.88307071 C11.1936597,8.87924376 11.1974967,8.87544369 11.2013604,8.87167077 L13.1180507,6.9999997 Z M7.19140624,5.41788836e-14 C7.83573845,5.77698522e-13 8.35807292,0.522334464 8.35807292,1.16666668 C8.35807292,1.81099889 7.83573845,2.33333336 7.19140624,2.33333336 L2.83333333,2.33333336 C2.55719096,2.33333336 2.33333333,2.55719098 2.33333333,2.83333336 L2.33333333,8.84360391 C2.33333333,9.11974628 2.55719096,9.34360391 2.83333333,9.34360391 L7.19140624,9.34360391 C7.83573845,9.34360391 8.35807292,9.86593837 8.35807292,10.5102706 C8.35807292,11.1546028 7.83573845,11.6769373 7.19140624,11.6769373 L2,11.6769373 C0.8954305,11.6769373 3.21655859e-13,10.7815068 3.21520588e-13,9.67693726 L3.21298543e-13,2 C3.21163273e-13,0.8954305 0.8954305,5.43817897e-14 2,5.41788836e-14 L7.19140624,5.41788836e-14 Z" id="path-7"></path>
	    </defs>
	    <g id="Asset-Artboard-Page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
	        <g id="icono-/-logout-2">
	            <g id="Color-/-Purple-Copy-3" transform="translate(5.833333, 8.166667)">
	                <mask id="mask-7" fill="white">
	                    <use xlinkHref="#path-7"></use>
	                </mask>
	                <use id="Mask" fill="#000000" fillRule="nonzero" xlinkHref="#path-7"></use>
	                <g mask="url(#mask-7)" fill="#DD9DEF" id="Rectangle">
	                    <g transform="translate(-5.876828, -8.166667)">
	                        <rect x="0" y="0" width="28" height="28"></rect>
	                    </g>
	                </g>
	            </g>
	        </g>
	    </g>
	</svg>
  )
}

export default Tarifas;