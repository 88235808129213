import React from 'react';
import { types } from '../constants/constants';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { isLogged } from '../actions/user.js';
import { getReportImages } from '../actions/report.js';

import { withTranslation } from 'react-i18next';

import ReactToPdf from "react-to-pdf";
import QRCode from "react-qr-code";

import logo from '../assets/img/logo-t-cito-black-typo.svg'

class InformeImagenes extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded : false,
            documentHeight : 980
        };
    }

    componentDidMount() {
        const { i18n } = this.props;

        let lang = typeof this.props.match.params.lang != 'undefined' ? this.props.match.params.lang : null;

        if(lang === 'es' || lang === 'en'){ 
            i18n.changeLanguage(lang) 
        }

        Promise.resolve(this.props.isLogged())
        .then((response)=> {
            let id = typeof this.props.match.params.id !== 'undefined' ? this.props.match.params.id : null;

            if(id){
                Promise.resolve(this.props.getReportImages(id))
                .then((response)=> {
                    this.setState({isLoaded : true});
                });
            }   
            
        });

    }

    render() {
        const { t, report } = this.props;

        return (
            <React.Fragment>
                { this.state.isLoaded ?
                    <div className={'container'}>
                        <div className={'col-12'}>
                            { typeof report.pictures !== 'undefined' && JSON.parse(report.pictures).length !== 0  ?
                                JSON.parse(report.pictures).map((picture, key) => {
                                    let filename = picture.split('/')
                                    filename = filename[filename.length - 1]

                                    return(
                                        <React.Fragment>
                                            <img src={picture} className={'w-100 mb-2'} />
                                            <p className={'fs-12 text-center mb-5'}>{ filename }</p>
                                        </React.Fragment> 
                                    )
                                })
                            : 
                                <p className={'fs-14'}>{ t('Este informe no tiene imágenes adjuntas') }</p>
                            }
                        </div>
                    </div>
                : null }
            </React.Fragment>
        )
    }

}

function mapStateToProps(state) {
    const storeUsers = state.users;
    const storeReports = state.reports;

    return {
        isLoggedUser : storeUsers.isLogged,
        user: storeUsers.user,
        report: storeReports.report_images
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        isLogged: redirectTo => dispatch(isLogged()),
        getReportImages: (id) => dispatch(getReportImages(id))
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(InformeImagenes));



