import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import Menu from '../../components/menu';

import { Modal, Checkbox } from 'antd';
import { ExclamationCircleOutlined,
            LoadingOutlined,
            ItalicOutlined,
            BoldOutlined } from '@ant-design/icons';

import Loader from '../../components/loader';
import InputText from '../../components/input';
import TextAreaInput from '../../components/textarea';
import ContentEditable from 'react-contenteditable'
import sanitizeHtml from "sanitize-html"

import { isLogged } from '../../actions/user.js';
import { inputFieldChanged, 
            doRegisterAdmin } from '../../actions/register.js';

import { withTranslation } from 'react-i18next';

const { confirm } = Modal;

class AdminClienteCreate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded : true,
            formError : {},
            sanitizeConf : {
                transformTags: {
                    "div": "p",
                },
            },
        };
    }

    componentDidMount() {
        const { i18n } = this.props;

        let lang = typeof this.props.match.params.lang !== 'undefined' ? this.props.match.params.lang : null;

        if(lang === 'es' || lang === 'en'){ 
            i18n.changeLanguage(lang) 
        }

        this._getApiData()
    }

    _getApiData(){
        this.setState({ isLoaded : false})

        Promise.resolve(this.props.isLogged())
        .then((response)=> {
            if(this.props.isLoggedUser){
                if(this.props.user.role.code != 0){
                    this.props.history.push('/404')
                }

                this.setState({ isLoaded : true})
            }else{
                this.props.history.push('/login')
            }
        });
    }

    _saveData() {
        const { t } = this.props;
        let error = false;
        const formError = {};
        let requestedTests = {};
        this.setState({ formError : formError })
        this.setState({ isLoaded : false })

        if (typeof this.props.business === 'undefined' || this.props.business === ''){
            formError.business = t('El nombre de facturación es obligatorio')
            this.setState({ formError : formError })
            error = true
        }

        if (typeof this.props.company === 'undefined' || this.props.company === ''){
            formError.company = t('El nombre la clínica es obligatorio')
            this.setState({ formError : formError })
            error = true
        }
        
        if (typeof this.props.address === 'undefined' || this.props.address === ''){
            formError.address = t('La dirección de la clínica es obligatoria')
            this.setState({ formError : formError })
            error = true
        }
        
        if (typeof this.props.city === 'undefined' || this.props.city === ''){
            formError.city = t('La ciudad es obligatoria')
            this.setState({ formError : formError })
            error = true
        }

        if (typeof this.props.province === 'undefined' || this.props.province === ''){
            formError.province = t('La provincia es obligatoria')
            this.setState({ formError : formError })
            error = true
        }

        if (typeof this.props.phone === 'undefined' || this.props.phone === ''){
            formError.phone = t('El teléfono es obligatorio')
            this.setState({ formError : formError })
            error = true
        }

        if (typeof this.props.cif === 'undefined' || this.props.cif === ''){
            formError.cif = t('El CIF/NIF es obligatorio')
            this.setState({ formError : formError })
            error = true
        }

        if (typeof this.props.iban === 'undefined' || this.props.iban === ''){
            formError.iban = t('El IBAN es obligatorio')
            this.setState({ formError : formError })
            error = true
        }

        if(error === false){
            Promise.resolve(this.props.doRegisterAdmin(
                this.props.company,
                this.props.email, 
                'Tc1T0P4SS',
                this.props.address,
                '',
                this.props.city,
                this.props.zipcode,
                this.props.province,
                this.props.cif,
                this.props.phone,
                this.props.business,
                this.props.business_address,
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                this.props.iban,
                (typeof this.props.send_email != 'undefined' && typeof this.props.send_email.target != 'undefined' ? this.props.send_email.target.checked : false),
            ))
            .then((response)=> {
                if(this.props.dataError === false){
                    // Limpiamos la Store
                    this.props.inputFieldChanged('company', '')
                    this.props.inputFieldChanged('address', '')
                    this.props.inputFieldChanged('zipcode', '')
                    this.props.inputFieldChanged('city', '')
                    this.props.inputFieldChanged('province', '')
                    this.props.inputFieldChanged('email', '')
                    this.props.inputFieldChanged('send_email', '')
                    this.props.inputFieldChanged('phone', '')
                    this.props.inputFieldChanged('city', '')
                    this.props.inputFieldChanged('business_address', '')
                    this.props.inputFieldChanged('business', '')
                    this.props.inputFieldChanged('cif', '')
                    this.props.inputFieldChanged('iban', '')

                    this.props.history.push('/admin/clientes')
                }
            });
        }

        this.setState({ isLoaded : true })
    }

    _cancelData() {
        // Limpiamos la Store
        this.props.inputFieldChanged('company', '')
        this.props.inputFieldChanged('address', '')
        this.props.inputFieldChanged('zipcode', '')
        this.props.inputFieldChanged('city', '')
        this.props.inputFieldChanged('province', '')
        this.props.inputFieldChanged('email', '')
        this.props.inputFieldChanged('send_email', '')
        this.props.inputFieldChanged('phone', '')
        this.props.inputFieldChanged('city', '')
        this.props.inputFieldChanged('business_address', '')
        this.props.inputFieldChanged('business', '')
        this.props.inputFieldChanged('cif', '')
        this.props.inputFieldChanged('iban', '')

        this.props.history.push('/admin/clientes')
    }


    _renderForm() {
        const { t } = this.props;

        return(
            <React.Fragment>
                <div className={'box-with-shadow pt-4 pl-5 pr-5 pb-2 border'}>
                    <div className={'row mt-3'}>
                        
                        <div className={'col-3 border-bottom-gray-min pl-0'}>
                            <h2 className={'fs-14 font-weight-normal'}>
                                {t('Detalles clínica')}
                            </h2>
                        </div>
                        <div className={'col-9 border-bottom-gray-min pr-0'}>
                            <div className={'row'}>
                                <div className={'col-12 mb-2'}>
                                    <InputText
                                        id={'company'}
                                        name={'company'}
                                        placeholder={t('Nombre de la clínica *')}
                                        classes={'w-100 mb-3'}
                                        label={t('Nombre clínica')}
                                        onChange={this.props.inputFieldChanged.bind(this)}
                                        value={this.props.company}
                                        error={this.state.formError.company}
                                        />
                                </div>

                                <div className={'col-8 mb-2'}>
                                    <InputText
                                        id={'address'}
                                        name={'address'}
                                        placeholder={t('Dirección *')}
                                        classes={'w-100 mb-3'}
                                        label={t('Dirección')}
                                        onChange={this.props.inputFieldChanged.bind(this)}
                                        value={this.props.address}
                                        error={this.state.formError.address}
                                        />
                                </div>

                                <div className={'col-4 mb-2'}>
                                    <InputText
                                        id={'zipcode'}
                                        name={'zipcode'}
                                        placeholder={t('Código postal')}
                                        classes={'w-100 mb-3'}
                                        label={t('Código postal')}
                                        onChange={this.props.inputFieldChanged.bind(this)}
                                        value={this.props.zipcode}
                                        error={this.state.formError.zipcode}
                                        />
                                </div>

                                <div className={'col-6 mb-2'}>
                                    <InputText
                                        id={'city'}
                                        name={'city'}
                                        placeholder={t('Ciudad *')}
                                        classes={'w-100 mb-3'}
                                        label={t('Ciudad')}
                                        onChange={this.props.inputFieldChanged.bind(this)}
                                        value={this.props.city}
                                        error={this.state.formError.city}
                                        />
                                </div>

                                <div className={'col-6 mb-2'}>
                                    <InputText
                                        id={'province'}
                                        name={'province'}
                                        placeholder={t('Provincia')}
                                        classes={'w-100 mb-3'}
                                        label={t('Provincia')}
                                        onChange={this.props.inputFieldChanged.bind(this)}
                                        value={this.props.province}
                                        error={this.state.formError.province}
                                        />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className={'row mt-4'}>
                        
                        <div className={'col-3 border-bottom-gray-min pl-0'}>
                            <h2 className={'fs-14 font-weight-normal'}>
                                {t('Contacto')}
                            </h2>
                        </div>
                        <div className={'col-9 border-bottom-gray-min pr-0'}>
                            <div className={'row'}>
                                <div className={'col-12 mb-2'}>
                                    <InputText
                                        id={'email'}
                                        name={'email'}
                                        placeholder={t('Email *')}
                                        classes={'w-100 mb-3'}
                                        label={t('Email')}
                                        onChange={this.props.inputFieldChanged.bind(this)}
                                        value={this.props.email}
                                        error={this.state.formError.email}
                                        />

                                    <Checkbox 
                                        id={'send_email'}
                                        name={'send_email'}
                                        className={'mt-0 mb-2'}
                                        checked={ typeof this.props.send_email !== 'undefined' && typeof this.props.send_email.target != 'undefined' ? this.props.send_email.target.checked : false }
                                        onChange={this.props.inputFieldChanged.bind(this, 'send_email')}
                                        >{t('Enviar email de bienvenida')}
                                    </Checkbox>
                                </div>

                                <div className={'col-12 mb-2'}>
                                    <InputText
                                        id={'phone'}
                                        name={'phone'}
                                        placeholder={t('Teléfono *')}
                                        classes={'w-100 mb-3'}
                                        label={t('Teléfono')}
                                        onChange={this.props.inputFieldChanged.bind(this)}
                                        value={this.props.phone}
                                        error={this.state.formError.phone}
                                        />
                                </div>
                             </div>
                        </div>


                    </div>

                    <div className={'row mt-4'}>
                        
                        <div className={'col-3 border-bottom-gray-min pl-0'}>
                            <h2 className={'fs-14 font-weight-normal'}>
                                {t('Facturación')}
                            </h2>
                        </div>
                        <div className={'col-9 border-bottom-gray-min pr-0'}>
                            <div className={'row'}>
                                <div className={'col-12 mb-2'}>
                                    <InputText
                                        id={'business'}
                                        name={'business'}
                                        placeholder={t('Razón social *')}
                                        classes={'w-100 mb-3'}
                                        label={t('Razón social')}
                                        onChange={this.props.inputFieldChanged.bind(this)}
                                        value={this.props.business}
                                        error={this.state.formError.business}
                                        />
                                </div>

                                <div className={'col-12 mb-2'}>
                                    <InputText
                                        id={'business_address'}
                                        name={'business_address'}
                                        placeholder={t('Dirección de facturación')}
                                        classes={'w-100 mb-3'}
                                        label={t('Dirección de facturación')}
                                        onChange={this.props.inputFieldChanged.bind(this)}
                                        value={this.props.business_address}
                                        error={this.state.formError.business_address}
                                        />
                                </div>

                                <div className={'col-12 mb-2'}>
                                    <InputText
                                        id={'cif'}
                                        name={'cif'}
                                        placeholder={t('CIF/NIF *')}
                                        classes={'w-100 mb-3'}
                                        label={t('CIF/NIF')}
                                        onChange={this.props.inputFieldChanged.bind(this)}
                                        value={this.props.cif}
                                        error={this.state.formError.cif}
                                        />
                                </div>

                                <div className={'col-12 mb-2'}>
                                    <InputText
                                        id={'iban'}
                                        name={'iban'}
                                        placeholder={t('IBAN *')}
                                        classes={'w-100 mb-3'}
                                        label={t('IBAN')}
                                        onChange={this.props.inputFieldChanged.bind(this)}
                                        value={this.props.iban}
                                        error={this.state.formError.iban}
                                        />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className={'row mt-5 mb-4'}>
                        
                        <div className={'col-6 text-left'}>
                            <button className={'btn-white'} onClick={() => {this._cancelData()}}>
                                {t('Cancelar')}
                            </button>
                        </div>

                        <div className={'col-6 text-right'}>
                            <button className={'btn-purple'} onClick={() => {this._saveData()}}>
                                {t('Guardar')}
                            </button>
                        </div>

                    </div>
                </div>
            </React.Fragment>
        )
    }

    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <div className={"split-container"}>
                    <Menu history={this.props.history} />
                    <div className={"container coachee"}>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <header className={"managementPanel__content-header mb-3"}>
                                    <div className={"col-5"}>
                                        <h1 className={"fs-14 poppins"}>{t('Nuevo cliente')}</h1>
                                    </div>

                                    <div className={"col-7 text-right"}>
                                    </div>
                                </header>
                                
                                <section className={"coachee__content-body"}>
                                    { this.state.isLoaded ?
                                        this._renderForm() 
                                    :
                                        <React.Fragment>
                                            <Loader t={t} />
                                        </React.Fragment>
                                    }
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

function mapStateToProps(state) {
    const storeUsers = state.users;
    const storeRegister = state.register;

    return {
        isLoggedUser : storeUsers.isLogged,
        user: storeUsers.user,
        company: storeRegister.company,
        email: storeRegister.email,
        send_email: storeRegister.send_email,
        address: storeRegister.address,
        city: storeRegister.city,
        zipcode: storeRegister.zipcode,
        province: storeRegister.province,
        cif: storeRegister.cif,
        phone: storeRegister.phone,
        business: storeRegister.business,
        business_address: storeRegister.business_address,
        iban: storeRegister.iban,
        dataError : storeRegister.dataError,
        dataErrorMessage : storeRegister.dataErrorMessage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        isLogged: redirectTo => dispatch(isLogged()),
        inputFieldChanged: (field, value) => dispatch(inputFieldChanged(field, value)),
        doRegisterAdmin: (company,email,password,address,address_number,city,zipcode,province,cif,phone,business,business_address,business_address_number,business_city,business_zipcode,business_province,invoice_contacts,report_contacts,communication_contacts,iban,send_email) => dispatch(doRegisterAdmin(company,email,password,address,address_number,city,zipcode,province,cif,phone,business,business_address,business_address_number,business_city,business_zipcode,business_province,invoice_contacts,report_contacts,communication_contacts,iban,send_email)),
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(AdminClienteCreate));

