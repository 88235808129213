import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import Menu from '../../components/menu';
import CustomTable from '../../components/table';
import MuestrasIcon from "../../components/icons/muestras";
import RecogidasIcon from "../../components/icons/recogidas";
import deleteIcon from '../../assets/img/delete-icon.svg';
import User from '../../components/icons/user'

import { Modal } from 'antd';
import { ExclamationCircleOutlined,
            LoadingOutlined } from '@ant-design/icons';

import { getUsers,
            deleteUser } from '../../actions/user.js';
import { isLogged } from '../../actions/user.js';

import { withTranslation } from 'react-i18next';

import moment from 'moment';
import 'moment/locale/es';

const { confirm } = Modal;

class AdminClientes extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded : true,
            dataSource : []
        };
    }

    componentDidMount() {
        const { i18n } = this.props;

        let lang = typeof this.props.match.params.lang !== 'undefined' ? this.props.match.params.lang : null;

        if(lang === 'es' || lang === 'en'){ 
            i18n.changeLanguage(lang) 
        }

        this._getApiData()
    }

    _getApiData(){
        this.setState({ isLoaded : false})

        Promise.resolve(this.props.isLogged())
        .then((response)=> {
            if(this.props.isLoggedUser){
                if(this.props.user.role.code != 0){
                    this.props.history.push('/404')
                }
                
                Promise.resolve(this.props.getUsers('customer'))
                .then((response)=> {
                    if(this.props.customers){
                        let dataSource = [];

                        this.props.customers.map((user, key) =>{
                            let live = 'no'
                            if(typeof user.live_date !== 'undefined' && user.live_date !== ''){
                                const today = moment()
                                let liveEnd = moment(user.live_date)
                                if(liveEnd >= today){ live = 'si' }
                            }

                            let date = new Date(user.date);
                            let fecha = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
                            let picture = typeof user.picture !== 'undefined' ? <img src={user.picture} className={'w-100 rounded-circle'} /> : <User />;
                            let item = {
                                id : user.id,
                                fecha : fecha,
                                timestamp : date.getTime(),
                                cliente : user.business,
                                phone : user.phone,
                                picture : picture,
                                tcito_live : live
                            }

                            dataSource.push(item);
                        })

                        this.setState({ dataSource : dataSource })
                    }

                    this.setState({ isLoaded : true })
                });

            }else{
                this.props.history.push('/login')
            }
        });
    }

    _renderTable() {
        const { t } = this.props;
        const { dataSource } = this.state;

        //console.table(dataSource)

        const columns = [
            {
                title: '',
                dataIndex: "picture",
                key: "picture",
                align: "center",
                width: 50,
                className: 'py-0 px-1',
                render: (text, record) => (
                    <React.Fragment>
                        { record.picture }
                    </React.Fragment>
                ),
            },
            {
                title: t('Cliente'),
                dataIndex: "cliente",
                key: "cliente",
                ellipsis: true,
                width: 500,
                defaultSortOrder: 'ascend',
                sorter: (a, b) => a.cliente.localeCompare(b.cliente),
            },
            {
                title: t('Teléfono'),
                dataIndex: "phone",
                key: "phone",
                ellipsis: true,
                width: 100            
            },
            {
                title: t('Fecha de alta'),
                dataIndex: "fecha",
                key: "fecha",
                align: "center",
                width: 150,
                sorter: (a, b) => a.timestamp - b.timestamp,
            },
            {
                title: t('T-Cito Live'),
                dataIndex: "tcito_live",
                key: "tcito_live",
                align: "center",
                width: 120,
                sorter: (a, b) => a.tcito_live.localeCompare(b.tcito_live),
            },
            {
                title: "",
                key: "herramientas",
                width: 140,
                align: "center",
                render: (text, record) => (
                    <React.Fragment>
                        <a href={'/admin/cliente/' + record.id} className={'btn-purple w-100 lh-24'}>
                            {t('Ver cliente')}
                        </a>
                    </React.Fragment>
                ),
            }
        ]

        const pagination = {  }

        return (
            <React.Fragment>

                <CustomTable
                    columns={columns}
                    dataSource={dataSource}
                    pagination={pagination}
                    loaded={this.state.isLoaded}
                    t={t}
                />
                                
            </React.Fragment>
        )
    }

    _renderLoader(){
        return ('')
    }

    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <div className={"split-container"}>
                    <Menu history={this.props.history} />
                    <div className={"container coachee"}>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <header className={"managementPanel__content-header mb-3"}>
                                    <div className={"col-5"}>
                                        <h1 className={"fs-14 poppins"}>{t('Mis clientes')}</h1>
                                    </div>

                                    <div className={"col-7 text-right"}>
                                        <a href={'/admin/cliente/create'} className={'btn-purple'}>
                                            {t('Añadir cliente')}
                                        </a>
                                    </div>
                                </header>
                                
                                <section className={"coachee__content-body"}>
                                    { this._renderTable() }
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

function mapStateToProps(state) {
    const storeUsers = state.users;

    return {
        isLoggedUser : storeUsers.isLogged,
        user: storeUsers.user,
        customers : storeUsers.users,
        dataError : storeUsers.dataError,
        dataErrorMessage : storeUsers.dataErrorMessage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        isLogged: redirectTo => dispatch(isLogged()),
        getUsers: (type) => dispatch(getUsers(type)),
        deleteUser: (id) => dispatch(deleteUser(id))
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(AdminClientes));



