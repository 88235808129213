import React from "react"

const Empty = (props) => {
  return (
	<svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
	    <title>968A9E9A-BE98-46FB-80CF-48A9A40C8DFE</title>
	    <desc>Created with sketchtool.</desc>
	    <defs>
	        <path d="M2,2.5 L2,33.5 C2,33.7761424 2.22385763,34 2.5,34 L40.5,34 C40.7761424,34 41,33.7761424 41,33.5 L41,8.5 C41,8.22385763 40.7761424,8 40.5,8 L21.258524,8 C20.6098316,8 20.0014306,7.68538364 19.6264751,7.15603468 L16.5720926,2.84396532 C16.1971371,2.31461636 15.5887362,2 14.9400437,2 L2.5,2 C2.22385763,2 2,2.22385763 2,2.5 Z M2,0 L16.1386439,1.33226763e-15 C16.8023795,1.21034139e-15 17.4228766,0.3292842 17.7949176,0.878948076 L21.3512706,6.13320416 C21.4442808,6.27062013 21.5994051,6.35294118 21.765339,6.35294118 L41,6.35294118 C42.1045695,6.35294118 43,7.24837168 43,8.35294118 L43,34 C43,35.1045695 42.1045695,36 41,36 L2,36 C0.8954305,36 1.3527075e-16,35.1045695 0,34 L0,2 C-1.3527075e-16,0.8954305 0.8954305,2.02906125e-16 2,0 Z M17.5,19 C16.6715729,19 16,18.3284271 16,17.5 C16,16.6715729 16.6715729,16 17.5,16 C18.3284271,16 19,16.6715729 19,17.5 C19,18.3284271 18.3284271,19 17.5,19 Z M25.5,19 C24.6715729,19 24,18.3284271 24,17.5 C24,16.6715729 24.6715729,16 25.5,16 C26.3284271,16 27,16.6715729 27,17.5 C27,18.3284271 26.3284271,19 25.5,19 Z M17.0513167,21.3162278 C16.8766689,20.7922844 17.1598289,20.2259645 17.6837722,20.0513167 C18.2077156,19.8766689 18.7740355,20.1598289 18.9486833,20.6837722 L19.1295484,21.2263675 C19.4826138,22.2855636 20.4738413,23 21.590332,23 C22.6689828,23 23.6142663,22.2782643 23.8984507,21.2377227 L24.0353304,20.7365372 C24.1808369,20.2037649 24.7306905,19.889824 25.2634628,20.0353304 C25.7962351,20.1808369 26.110176,20.7306905 25.9646696,21.2634628 L25.8277898,21.7646484 C25.3060578,23.6749709 23.5706189,25 21.590332,25 C19.6129805,25 17.8574753,23.7347034 17.2321818,21.858823 L17.0513167,21.3162278 Z" id="path-empty"></path>
	    </defs>
	    <g id="Asset-Artboard-Page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
	        <g id="icono-/-selecciona-informe">
	            <g id="Color-/-Pink" transform="translate(2.000000, 5.000000)">
	                <mask id="mask-empty" fill="white">
	                    <use xlinkHref="#path-empty"></use>
	                </mask>
	                <use id="Mask" fill="#000000" fillRule="nonzero" xlinkHref="#path-empty"></use>
	                <g mask="url(#mask-empty)" fill="#DD9DEF" id="Rectangle">
	                    <g transform="translate(-2.000000, -5.000000)">
	                        <rect x="0" y="0" width="48" height="48"></rect>
	                    </g>
	                </g>
	            </g>
	        </g>
	    </g>
	</svg>
  )
}

export default Empty;