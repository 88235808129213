export const $$initialState = {
    shipment_method : {
        target : {
            value : 'mrw'
        }
    },
    zipcodes : {},
    dataError : false,
    dataErrorMessage : ''

};

export default function registerReducer($$state = $$initialState, action = null) {
    switch (action.type) {
        case 'RECOGIDA_INPUT_FIELD_CHANGED':
            return Object.assign({}, $$state, {
                [action.payload.field]: action.payload.value,
                dataErrorMessage: { [action.payload.field] : '' }
            });

        case 'CREATE_RECOGIDA':
            return Object.assign({}, $$state, {
                recogida : action.payload.recogida,
                dataError: false,
                dataErrorMessage : '',
            });

        case 'CREATE_RECOGIDA_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage : action.payload.message,
            });

        case 'USER_RECOGIDA_LIST':
            return Object.assign({}, $$state, {
                recogidas : action.payload,
                dataError: false,
                dataErrorMessage : '',
            });

        case 'USER_RECOGIDA_LIST_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage : action.payload.message,
            });

        case 'EXPRESS_ZIPCODES':
            return Object.assign({}, $$state, {
                zipcodes : action.payload,
                dataError: false,
                dataErrorMessage : '',
            });

        case 'EXPRESS_ZIPCODES_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage : action.payload.message,
            });

        case 'DELETE_RECOGIDA':
            return Object.assign({}, $$state, {
                dataError: false,
                dataErrorMessage : '',
            });

        case 'DELETE_RECOGIDA_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage : action.payload.message,
            });

        default: {
            return $$state;
        }
    }
}
