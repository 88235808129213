export const $$initialState = {
    dataError : false,
    dataErrorMessage : ''

};

export default function registerReducer($$state = $$initialState, action = null) {
    switch (action.type) {
        case 'SETTINGS_INPUT_FIELD_CHANGED':
            return Object.assign({}, $$state, {
                [action.payload.field]: action.payload.value,
                dataErrorMessage: { [action.payload.field] : '' }
            });

        case 'CREATE_REPLY':
            return Object.assign({}, $$state, {
                reply : action.payload.reply,
                dataError: false,
                dataErrorMessage : '',
            });

        case 'CREATE_REPLY_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage : action.payload.message,
            });

        case 'UPDATE_REPLY':
            return Object.assign({}, $$state, {
                reply : action.payload.reply,
                dataError: false,
                dataErrorMessage : '',
            });

        case 'UPDATE_REPLY_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage : action.payload.message,
            });

        case 'REPLY_LIST':
            return Object.assign({}, $$state, {
                replies : action.payload,
                dataError: false,
                dataErrorMessage : '',
            });

        case 'REPLY_LIST_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage : action.payload.message,
            });

        case 'REPLY_LIST_BY_REPORT':
            return Object.assign({}, $$state, {
                replies : action.payload,
                dataError: false,
                dataErrorMessage : '',
            });

        case 'REPLY_LIST_BY_REPORT_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage : action.payload.message,
            });

        case 'GET_REPLY':
            return Object.assign({}, $$state, {
                reply : action.payload,
                dataError: false,
                dataErrorMessage : '',
            });

        case 'GET_REPLY_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage : action.payload.message,
            });

        case 'DELETE_REPLY':
            return Object.assign({}, $$state, {
                dataError: false,
                dataErrorMessage : '',
            });

        case 'DELETE_REPLY_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage : action.payload.message,
            });

        default: {
            return $$state;
        }
    }
}
