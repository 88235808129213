import React from 'react';
import { connect } from 'react-redux';
import { types } from '../../constants/constants';
import { push } from 'react-router-redux';

import Menu from '../../components/menu';
import Submenu from '../../components/submenu';
import ResultOk from "../../components/icons/resultok";
import Add from "../../components/icons/add";
import PDF from "../../components/icons/pdf";

import InputText from '../../components/input';

import { Modal, Avatar, Spin } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { inputFieldChanged,
            getUser,
            saveProfile,
            saveSepa,
            changePassword } from '../../actions/user.js';
import { isLogged } from '../../actions/user.js';
import { getInitials } from '../../helpers/helpers.js';

import { withTranslation } from 'react-i18next';

const { confirm } = Modal;

class AdminMiCuenta extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded : false,
            formError : {},
            showModal : false,
            modal : ''
        };
    }

    componentDidMount() {
        const { i18n } = this.props;

        let lang = typeof this.props.match.params.lang !== 'undefined' ? this.props.match.params.lang : null;

        if(lang === 'es' || lang === 'en'){ 
            i18n.changeLanguage(lang) 
        }

        this._getApiData()
    }

    _getApiData(){
        Promise.resolve(this.props.isLogged())
        .then((response)=> {
            if(this.props.isLoggedUser){
                this.setState({ isLoaded : true })

            }else{
                this.props.history.push('/login')
            }
        });
    }

    _renderCuenta() {
        const { t } = this.props;

        return(
            <React.Fragment>
                <div className={'row pt-2'}>
                    <div className={'col-12'}>
                        <h2 className={'fs-12 poppins purple font-weight-normal mb-3'}>{t('PERFIL')}</h2>
                    </div>
                </div>

                <div className={'row d-flex border rounded mb-5 mx-0 px-2 bg-white'}>
                    <div className={'col flex-basis-15 p-3 mt-2'}>
                        { typeof this.props.picture != 'undefined' && this.props.picture != '' ?
                            <img src={this.props.picture} className={'w-100 h-100'} onClick={ ()=>{this.picture.click(); this.picture.value=null} } />
                        :
                            <Avatar shape={"square"} role={'button'} className={'fs-25 lh-3 w-100 h-100'} onClick={ ()=>{this.picture.click(); this.picture.value=null} }>
                                { getInitials(this.props.user.company) }
                            </Avatar>
                        }
                    </div>

                    <div className={'col flex-basis-85 py-3 px-2 mt-2'}>
                        <p className={'fs-11 mb-5'}>{t('Personifica tu perfil añadiendo el logotipo de tu centro')}</p>
                        <input type="file" ref={input => this.picture = input} className={'d-none'} onChange={this._uploadProfilePicture.bind(this)}/>
                        <button role={'button'} className={'btn-purple-text purple-light'} onClick={ ()=>{this.picture.click(); this.picture.value=null} }>{t('Elige foto')}</button>
                    </div>

                    <div className={'col-12 mt-2'}>
                        <InputText
                            id={'company'}
                            name={'company'}
                            placeholder={t('Nombre de la clínica')}
                            classes={'w-100 mb-3'}
                            label={t('Nombre de la clínica')}
                            value={this.props.company}
                            onChange={this.props.inputFieldChanged.bind(this)}
                            error={this.state.formError.company }  
                            />
                    </div>

                    <div className={'col-12 mt-2'}>
                        <InputText
                            id={'email'}
                            name={'email'}
                            placeholder={t('Email')}
                            classes={'w-100 mb-3'}
                            label={t('Email')}
                            value={this.props.email}
                            onChange={this.props.inputFieldChanged.bind(this)}
                            error={this.state.formError.email }  
                            />
                    </div>

                    { typeof this.props.dataError != 'undefined' && this.props.dataError === true ?

                        <p className={'red fs-09 mt-3 pl-4'}>{ this.props.dataErrorMessage }</p>

                    : null }

                    <div className={'col-12 mt-2 mb-4'}>
                        <button role={'button'} className={'btn-purple'} onClick={this._saveProfile.bind(this)}>{t('Guardar')}</button>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    _saveProfile() {
        const { t } = this.props;
        let error = false;
        const formError = {};
        this.setState({ formError : formError })

        if (typeof this.props.company === 'undefined' || this.props.company === ''){
            formError.company = t('El nombre de empresa es obligatorio')
            this.setState({ formError : formError })
            error = true
        }

        if (typeof this.props.email === 'undefined' || this.props.email === ''){
            formError.email = t('El email es obligatorio')
            this.setState({ formError : formError })
            error = true
        }else{
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (this.props.email.match(pattern) === null) {
                formError.email = t('El email introducido no es válido')
                this.setState({ formError : formError })
                error = true
            }
        }

        if(error === false){
            this.setState({ isLoaded : false })

            Promise.resolve(this.props.saveProfile(this.props.user._id, this.props.company, this.props.email, this.props.picture)
            .then(response =>{
                if(this.props.dataError === false){
                    this.setState({ 
                        showModal : true,
                        modal : 'profile',
                    })

                    this._getApiData()
                }

                this.setState({ isLoaded : true })
            }))
        }
    }

    _uploadProfilePicture = event=>{
        const { t } = this.props;

        var data = new FormData()
        data.append('folder', 'users')
        data.append('file', event.target.files[0])        

        fetch(types.API_URL + 'upload', {
              method: 'POST',
              body: data
        })
        .then(function(response) {
            if (response.status === 404 || response.status === 200) {
                return response.json()
            } else {
                if(response.status === 401){  // If unauthorized
                    window.location.href = '/login'; 
                }else{
                       alert('Error subiendo el fichero')
                }
            }
        })
        .then((responseJson) => {
            this.props.inputFieldChanged('picture', responseJson)
        })
        .catch((error) => {
            alert(error)
        });
    }

    _renderSepa() {
        const { t } = this.props;

        return(
            <React.Fragment>
                <div className={'row pt-2'}>
                    <div className={'col-12'}>
                        <h2 className={'fs-12 poppins purple font-weight-normal mb-4'}>{t('DOCUMENTO SEPA')}</h2>
                    </div>
                </div>

                <div className={'row d-flex border rounded mb-5 mx-0 px-2 bg-white'}>
                    <div className={'col flex-basis-15 p-3'}>
                        { typeof this.props.sepa === 'undefined' || this.props.sepa === '' ?
                            <div className={'d-flex upload-placeholder align-items-center justify-content-center'} role={'button'} onClick={ ()=>{this.sepa.click(); this.sepa.value=null} }>
                               <Add role={'button'} />
                            </div>
                        :
                            <div className={'d-flex w-100 h-100 align-items-center justify-content-center'}>
                                <PDF title={this.props.sepa} />
                            </div>
                        }
                    </div>

                    <div className={'col flex-basis-85 py-3 px-2'}>
                        <input type="file" ref={input => this.sepa = input} className={'d-none'} onChange={this._uploadSepa.bind(this)} accept="application/pdf" />
                        { typeof this.props.sepa === 'undefined' || this.props.sepa === '' ?
                            <React.Fragment>
                                <p className={'fs-11 mb-5'}>{t('Adjunta el documento SEPA firmado para poder recibir los informes')}</p>
                                <button role={'button'} className={'btn-purple-text purple-light'} onClick={ ()=>{this.sepa.click(); this.sepa.value=null} }>{t('Subir archivo')}</button>
                            </React.Fragment>
                        :
                            <React.Fragment>
                                <p className={'fs-11 mb-5'}>{t('Documento guardado')}: <br /> { this.props.sepa }</p>
                                <button role={'button'} className={'btn-purple-text purple-light'} onClick={ ()=>{this.sepa.click(); this.sepa.value=null} }>{t('Cambiar archivo')}</button>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }

    _uploadSepa = event=>{
        const { t } = this.props;

        var data = new FormData()
        data.append('folder', 'pdf')
        data.append('file', event.target.files[0])        

        fetch(types.API_URL + 'upload', {
              method: 'POST',
              body: data
        })
        .then(function(response) {
            if (response.status === 404 || response.status === 200) {
                return response.json()
            } else {
                if(response.status === 401){  // If unauthorized
                    window.location.href = '/login'; 
                }else{
                       alert('Error subiendo el fichero')
                }
            }
        })
        .then((responseJson) => {
            Promise.resolve(this.props.saveSepa(this.props.user._id, responseJson))
            .then(response=>{
                this.setState({ 
                    showModal : true,
                    modal : 'sepa',
                })
            })
        })
        .catch((error) => {
            alert(error)
        });
    }

    _renderPassword() {
        const { t } = this.props;

        return(
            <React.Fragment>
                <div className={'row mb-2 pt-2'}>
                    <div className={'col-12'}>
                        <h2 className={'fs-12 poppins purple font-weight-normal mb-4'}>{t('CAMBIAR CONTRASEÑA')}</h2>
                    </div>
                </div>

                <div className={'row border rounded mb-5 mx-0 px-2 bg-white'}>
                    <div className={'col-12 mt-4'}>
                        <InputText
                            id={'current_password'}
                            name={'current_password'}
                            placeholder={t('Contraseña actual')}
                            classes={'w-100 mb-3'}
                            label={t('Contraseña actual')}
                            value={this.props.current_password}
                            onChange={this.props.inputFieldChanged.bind(this)}
                            error={this.state.formError.current_password } 
                            type={'password'} 
                            />
                    </div>

                    <div className={'col-12 mt-2'}>
                        <InputText
                            id={'new_password'}
                            name={'new_password'}
                            placeholder={t('Nueva contraseña')}
                            classes={'w-100 mb-3'}
                            label={t('Nueva contraseña')}
                            value={this.props.new_password}
                            onChange={this.props.inputFieldChanged.bind(this)}
                            error={this.state.formError.new_password }
                            type={'password'}
                            />
                    </div>

                    <div className={'col-12 mt-2'}>
                        <InputText
                            id={'repeat_password'}
                            name={'repeat_password'}
                            placeholder={t('Repetir contraseña')}
                            classes={'w-100 mb-3'}
                            label={t('Repetir contraseña')}
                            value={this.props.repeat_password}
                            onChange={this.props.inputFieldChanged.bind(this)}
                            error={this.state.formError.repeat_password }
                            type={'password'}  
                            />
                    </div>


                    { typeof this.props.dataError != 'undefined' && this.props.dataError === true ?

                        <p className={'red fs-09 mt-3 pl-4'}>{ this.props.dataErrorMessage }</p>

                    : null }

                    <div className={'col-12 mt-2 mb-4'}>
                        <button role={'button'} className={'btn-purple'} onClick={this._changePassword.bind(this)}>{t('Guardar')}</button>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    _changePassword() {
        const { t } = this.props;
        let error = false;
        const formError = {};
        this.setState({ formError : formError })

        if (typeof this.props.current_password === 'undefined' || this.props.current_password === ''){
            formError.current_password = t('La contraseña actual es obligatoria')
            this.setState({ formError : formError })
            error = true
        }

        if (typeof this.props.new_password === 'undefined' || this.props.new_password === ''){
            formError.new_password = t('La contraseña nueva es obligatoria')
            this.setState({ formError : formError })
            error = true
        }

        if (this.props.new_password !== this.props.repeat_password){
            formError.repeat_password = t('Las contraseñas no coinciden')
            this.setState({ formError : formError })
            error = true
        }

        if(error === false){
            this.setState({ isLoaded : false })

            Promise.resolve(this.props.changePassword(this.props.current_password, this.props.new_password)
            .then(response =>{
                if(this.props.dataError === false){
                    this.setState({ 
                        showModal : true,
                        modal : 'password',
                    })

                    this.props.inputFieldChanged('current_password', '')
                    this.props.inputFieldChanged('new_password', '')
                    this.props.inputFieldChanged('repeat_password', '')
                }

                this.setState({ isLoaded : true })
            }))
        }
    }

    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <div className={"split-container"}>
                    <Menu history={this.props.history} />
                    <div className={"container coachee p-0"}>
                        <div className={"row m-0"}>
                            <div className={"col-12 py-4 px-4"}>
                                { this.state.isLoaded ? this._renderPassword() : null }
                            </div>
                        </div>
                    </div>
                </div>

                <Modal visible={ this.state.showModal } footer={ null } onCancel={ ()=>{ this.setState({ showModal : false }) } } >
                    <div className={'row mt-3'}>
                        
                        { this.state.modal === 'profile' ?
                            <React.Fragment>
                                <div className={'col-12 text-center'}>
                                    <ResultOk />
                                    <p className={'poppins text-uppercase font-weight-bold fs-13 my-2 px-4'}>{t('PERFIL ACTUALIZADO')}</p>
                                </div>
                                
                                <div className={'col-12 text-center'}>
                                    <p className={'gray07 fs-12 lh-12 my-3 px-4'}>{t('Los datos de tu perfil se han guardado correctamente.')}</p>
                                </div>
                            </React.Fragment>
                        : null }

                        { this.state.modal === 'sepa' ?
                            <React.Fragment>
                                <div className={'col-12 text-center'}>
                                    <ResultOk />
                                    <p className={'poppins text-uppercase font-weight-bold fs-13 my-2 px-4'}>{t('DOCUMENTO SEPA GUARDADO')}</p>
                                </div>
                                
                                <div className={'col-12 text-center'}>
                                    <p className={'gray07 fs-12 lh-12 my-3 px-4'}>{t('El documento SEPA proporcionado se ha guardado correctamente.')}</p>
                                </div>
                            </React.Fragment>
                        : null }

                        { this.state.modal === 'password' ?
                            <React.Fragment>
                                <div className={'col-12 text-center'}>
                                    <ResultOk />
                                    <p className={'poppins text-uppercase font-weight-bold fs-13 my-2 px-4'}>{t('CONTRASEÑA ACTUALIZADA')}</p>
                                </div>
                                
                                <div className={'col-12 text-center'}>
                                    <p className={'gray07 fs-12 lh-12 my-3 px-4'}>{t('La nueva contraseña se ha guardado correctamente.')}</p>
                                </div>
                            </React.Fragment>
                        : null }
                    </div>
                </Modal>
            </React.Fragment>
        )
    }

}

function mapStateToProps(state) {
    const storeUsers = state.users;
    const storeMuestras = state.muestras;

    return {
        isLoggedUser : storeUsers.isLogged,
        user : storeUsers.user,
        company : storeUsers.company,
        picture : storeUsers.picture,
        email : storeUsers.email,
        current_password : storeUsers.current_password,
        new_password : storeUsers.new_password,
        repeat_password : storeUsers.repeat_password,
        dataError : storeUsers.dataError,
        dataErrorMessage : storeUsers.dataErrorMessage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        isLogged: redirectTo => dispatch(isLogged()),
        inputFieldChanged: (field, text) => dispatch(inputFieldChanged(field, text)),
        getUser: (id) => dispatch(getUser(id)),
        saveProfile: (id, company, email, picture) => dispatch(saveProfile(id, company, email, picture)),
        saveSepa: (id, sepa) => dispatch(saveSepa(id, sepa)),
        changePassword: (current_password, new_password) => dispatch(changePassword(current_password, new_password))
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(AdminMiCuenta));



