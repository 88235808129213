import React from 'react';

import logo from '../assets/img/logo-t-cito-blanco.png';

import { withTranslation } from 'react-i18next';

class Testimonials extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            index: 1
        };
    }

    _selectSlide(value, e){
        if(value !== this.state.index){
            this.setState({
                index: value
            });
        }
    }

    render() {
        const { t, i18n } = this.props
        const current_lang = i18n.language

        if(current_lang.indexOf('en-GB') !== -1){ i18n.changeLanguage('en') }
        if(current_lang.indexOf('en-US') !== -1){ i18n.changeLanguage('en') }
        if(current_lang.indexOf('es-ES') !== -1){ i18n.changeLanguage('es') }

        const logoURL = i18n.language === 'en' ? '/' + i18n.language : '/';

        return (
            <React.Fragment>
                <div className={'login-background webp text-center fixed-left d-none d-md-block bg-purple'}>
                    <a href={logoURL}>
                        <img src={logo} className={'logo'} alt="Logo { types.COMPANY }" />
                    </a>

                    <div className={'row mt-5'}>
                        <div className={'col-12 px-5 pt-4'}>
                            <p className={'fs-8 white text-left mt-3'}></p>
                        </div>

                    </div>

                    <div className={'row mt-5'}>
                        <div className={'col-8 offset-2 px-5 pt-5'}>
                            <h3 className={'fs-35 poppins lh-14 bold white text-left'}>{t('Bienvenido a T-CITO. Especialistas en citología veterinaria.')}</h3>
                        </div>
                    </div>

                    <div className={'row mt-3'}>
                        <div className={'col-8 offset-2 px-5'}>
                            <p className={'fs-15 white text-left pr-3'}>
                                {t('Descubre los servicios especializados adaptados a tus necesidades, desde el modelo de diagnóstico tradicional, el tele-diagnóstico o el diagnóstico en tiempo real.')}
                            </p>
                        </div>

                        { this.props.changeLanguage && (

                            <React.Fragment>

                                {/*<div className={'col-12 px-5 mt-5 text-left'}>
                                    <p>
                                        <a 
                                            onClick={() => { 
                                                i18n.changeLanguage('es')
                                                if(window.location.pathname.indexOf('sesiones') != -1){ window.location.reload(); }
                                            }} 
                                            className={(current_lang === "es") ? "bold white mr-3" : "white mr-3"}
                                        >
                                            ES
                                        </a>

                                        <a 
                                            onClick={() => { 
                                                i18n.changeLanguage('en')
                                                if(window.location.pathname.indexOf('sesiones') != -1){ window.location.reload(); }
                                            }}
                                            className={(current_lang === "en") ? "bold white mr-3" : "white mr-3"}
                                        >
                                            EN
                                        </a>
                                    </p>
                                </div>*/}

                            </React.Fragment>

                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withTranslation()(Testimonials);
