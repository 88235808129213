import React from 'react';

/** Método que devuelve HTML para imagenes de nueva generacion
**/
export function nextGenImage({
  srcWebp,
  srcJrx,
  srcJp2,
  fallback,
  alt,
  ...props
}) {
	return(
	    <picture>
	       <source srcset={srcWebp} type="image/webp" />
	       <source srcset={srcJrx} type="image/jxr" />
	       <source srcset={srcJp2} type="image/jp2" />
	       <source srcset={fallback} type="image/jpeg" />
	       <img src={fallback} alt={alt} {...props} />
	    </picture>
	)
}

/** Método que devuelve las iniciales de un nombre dado como string
**/
export function getInitials(text) {
    if(typeof text !== 'undefined'){
      var names = text.split(' '),
          initials = names[0].substring(0, 1).toUpperCase();
      
      if (names.length > 1) {
          initials += names[names.length - 1].substring(0, 1).toUpperCase();
      }
      return initials;
    }else{
      return ''
    }
}