import React from 'react';
import { types } from '../../constants/constants';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import Menu from '../../components/menu';
import MenuReports from '../../components/menu-reports'
import Loader from '../../components/loader';
import Empty from "../../components/icons/empty"
import DownloadTable from "../../components/icons/download-table"
import { Carousel } from '@trendyol-js/react-carousel'

import { isLogged } from '../../actions/user.js';
import { getReport,
            cleanStore } from '../../actions/report.js';

import { withTranslation } from 'react-i18next';

import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import saveAs from 'save-as';

import ReactToPdf from "react-to-pdf";
import QRCode from "react-qr-code";

import logo from '../../assets/img/logo-t-cito-black-typo.svg'
import logoPuchol from '../../assets/img/logo-puchol.jpg'


class AdminInformes extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded : false,
            documentwidth : 1000,
            documentHeight : 980
        };
    }

    componentDidMount() {
        this.props.cleanStore()
        
        const { i18n } = this.props;

        let lang = typeof this.props.match.params.lang != 'undefined' ? this.props.match.params.lang : null;

        if(lang === 'es' || lang === 'en'){ 
            i18n.changeLanguage(lang) 
        }

        Promise.resolve(this.props.isLogged())
        .then((response)=> {
            if(this.props.isLoggedUser){
                if(this.props.user.role.code != 0){
                    this.props.history.push('/404')
                }

                let id = typeof this.props.match.params.id !== 'undefined' ? this.props.match.params.id : null;

                if(id){
                    this._loadReport(id)
                }   

                this.setState({isLoaded : true});
            }else{
                this.props.history.push('/login')
            }
        });

    }

    _loadReport(id){
        this.setState({isLoaded : false});

        Promise.resolve(this.props.getReport(id))
        .then((response)=> {
            window.history.replaceState('', '', '/admin/informes/' + id);
            this.setState({isLoaded : true});
        });
    }

    _zipImages(pictures){
        const zip = new JSZip();
        let count = 0;
        const zipFilename = 'imagenes-informe-' + this.props.report.lab_id + '.zip';
        
        pictures.forEach(async function (url){
            const urlArr = url.split('/');
            const filename = urlArr[urlArr.length - 1];
              
            try {
                const file = await JSZipUtils.getBinaryContent(url + '?date=' + Date.now())
                zip.file(filename, file, { binary: true});
                count++;
                
                if(count === pictures.length) {
                  zip.generateAsync({type:'blob'}).then(function(content) {
                    saveAs(content, zipFilename);
                  });
                }
            } catch (err) {
                alert(err);
            }
        });
    }

    _renderPDFEspecial(){
        const { t, report } = this.props;
        let date = typeof report !== 'undefined' ? new Date(report.muestra.created_at) : null
        let fecha_muestra = date !== null ? date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear() : null
        let tipo_muestra = '';
        const refPDF = React.createRef();
        const options = {
              format: [this.state.documentWidth, this.state.documentHeight]
        };

        if(report.muestra.other === 'true'){ tipo_muestra = tipo_muestra + 'Otros (' + report.muestra.other_details + ')\n' }
        if(report.muestra.cytology === 'true'){ tipo_muestra = tipo_muestra + 'Citología\n' }
        if(report.muestra.fluid === 'true'){ tipo_muestra = tipo_muestra + 'Fluido (' + report.muestra.fluid_type + ' ' + report.muestra.fluid_origin + ')\n' }

        return (
            <React.Fragment>
                { this.state.isLoaded ?
                    <React.Fragment>
                        <div>
                            <div style={{'width' : '100%'}}>
                                <div className={"row mb-4"}>
                                    <div className={'col-6 text-left'}>
                                        <img
                                            src={logoPuchol}
                                            className={"logo w-50"}
                                            alt={"Logo Puchol"}
                                          />
                                    </div>
                                    
                                    <div className={'col-6 text-right'}>
                                        <img
                                            src={logo}
                                            className={"logo w-50"}
                                            alt={"Logo " + types.COMPANY}
                                          />
                                    </div>
                                </div>

                                <div className={"row mb-3"}>
                                    <div className={'col-12 text-center'}>
                                        <p className={'fs-3 text-uppercase'}>{ report.title }</p>
                                    </div>
                                </div>

                                <div className={"row mb-1"}>
                                    <div className={'col-12 text-left'}>
                                        <p className={'fs-22 m-0 text-uppercase'}>{ t('Datos del paciente') }</p>
                                    </div>
                                </div>

                                <div className={"row mx-0 mb-5 border"}>
                                    <div className={'col-3 text-left border'}>
                                        <p className={'fs-14 mb-0'}><b>{ t('Nombre') }:</b> { report.muestra.animal_name }</p>
                                    </div>

                                    <div className={'col-4 text-left border'}>
                                        <p className={'fs-14 mb-0'}><b>{ t('ID') }:</b> { report.lab_id }</p>
                                    </div>

                                    <div className={'col-5 text-left border'}>
                                        <p className={'fs-14 mb-0'}><b>{ t('Responsable') }:</b> { report.muestra.contact_person }</p>
                                    </div>

                                    <div className={'col-3 text-left border'}>
                                        <p className={'fs-14 mb-0'}><b>{ t('Especie') }:</b> { report.muestra.animal_species }</p>
                                    </div>

                                    <div className={'col-4 text-left border'}>
                                        <p className={'fs-14 mb-0'}><b>{ t('Raza') }:</b> { report.muestra.animal_race }</p>
                                    </div>

                                    <div className={'col-2 text-left border'}>
                                        <p className={'fs-14 mb-0'}><b>{ t('Edad') }:</b> { report.muestra.animal_age }</p>
                                    </div>

                                    <div className={'col-3 text-left border'}>
                                        <p className={'fs-14 mb-0'}><b>{ t('Sexo') }:</b> { report.muestra.animal_gender }</p>
                                    </div>
                                </div>

                                <div className={"row mb-1"}>
                                    <div className={'col-7 text-left'}>
                                        <p className={'fs-22 m-0 text-uppercase'}>{ t('Historia Clínica/Muestra') }</p>
                                    </div>

                                    <div className={'col-5 text-left'}>
                                        <p className={'fs-22 m-0'}>{ t('Fecha') }: { fecha_muestra }</p>
                                    </div>
                                </div>

                                <div className={"row mx-0 mb-5 border"}>
                                    <div className={'col-12 text-left border'}>
                                        <p className={'fs-14 mb-0'}>{ report.clinical_history }</p>
                                    </div>
                                </div>

                                <div className={"row mb-1"}>
                                    <div className={'col-12 text-left'}>
                                        <p className={'fs-22 m-0 text-uppercase'}>{ t('Informe') }</p>
                                    </div>
                                </div>

                                <div className={"row mx-0 mb-5 border"}>
                                    <div className={'col-12 text-left border'}>
                                        {typeof report.description !== 'undefined' && report.description !== '' ?
                                            <React.Fragment>
                                                <p className={'fs-14 mb-0 font-weight-bold'}>{ t('Examen microscópico/descripción') }:</p>
                                                <p className={'fs-14 mb-3'}>
                                                    { typeof report.description === 'undefined' || report.description === 'undefined' ? '--' : report.description }
                                                </p>
                                            </React.Fragment>
                                        : null}

                                        {typeof report.interpretation !== 'undefined' && report.interpretation !== '' ?
                                            <React.Fragment>
                                                <p className={'fs-14 mb-0 font-weight-bold'}>{ t('Interpretación') }:</p>
                                                <p className={'fs-14 mb-3'}>
                                                    { typeof report.interpretation === 'undefined' || report.interpretation === 'undefined' ? '--' : report.interpretation }
                                                </p>
                                            </React.Fragment>
                                        : null}

                                        {typeof report.comments !== 'undefined' && report.comments !== '' ?
                                            <React.Fragment>
                                                <p className={'fs-14 mb-0 font-weight-bold'}>{ t('Comentarios') }:</p>
                                                <p className={'fs-14 mb-3'}>
                                                    { typeof report.comments === 'undefined' || report.comments === 'undefined' ? '--' : report.comments }
                                                </p>
                                            </React.Fragment>
                                        : null}

                                        {typeof report.pictures !== 'undefined' && report.pictures !== '' && JSON.parse(report.pictures).length > 0 ?
                                            <React.Fragment>
                                                <p className={'fs-14 mb-0 font-weight-bold'}>{ t('Imagenes') }:</p>
                                                <p className={'fs-14 mb-3'}>
                                                    { t('Para visualizar las imágenes de este examen escanear el siguiente QR') }:
                                                </p>

                                            
                                                <div className={'text-center mb-4'}>
                                                    <QRCode 
                                                    value={types.SITE_URL + 'informe-imagenes/' + report.id} 
                                                    size={180}
                                                    title={'T-Cito | Ver las imágenes de tu informe'} />
                                                </div>
                                            </React.Fragment>
                                        : null}

                                        <p className={'fs-12 edit-gray mb-3 text-center'}>
                                            { t('Se prohíbe la difusión total o parcial del contenido o las imágenes del presente informe de forma pública o con fines científicos sin autorización expresa de T-CITO (contacto@t-cito.com)') }:
                                        </p>
                                    </div>
                                </div>

                                <div className={'row mt-5'}>
                                    <div className={'col-6 text-center'}>
                                        <p className={'fs-15 mb-1'}>Dr. Antonio Meléndez Lazo</p>
                                        <p className={'fs-15 edit-gray mb-1'}>LV, MSc, PhD, DipECVCP</p>
                                        <p className={'fs-15 edit-gray'}>Nº Col: 6298 COVM</p>
                                    </div>

                                    <div className={'col-6 text-center'}>
                                        <p className={'fs-15 mb-1'}>Javier Martínez Caro</p>
                                        <p className={'fs-15 edit-gray mb-1'}>Veterinario supervisado</p>
                                        <p className={'fs-15 edit-gray'}>Nº Col: 2125 COVL</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*<div className={'row my-4'}>
                            <div className={'col-12 text-center'}>
                                <a href={'/informe-pdf/' + report.id} target={'_blank'} className={'btn-purple'}>
                                    { t('Descargar informe en PDF') }
                                </a>
                            </div>
                        </div>*/}
                    </React.Fragment>
                : null }
            </React.Fragment>
        )
    }

    _renderPDFTCito(){
        const { t, report } = this.props;
        let date = typeof report !== 'undefined' ? new Date(report.muestra.created_at) : null
        let fecha_muestra = date !== null ? date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear() : null
        let tipo_muestra = '';
        const refPDF = React.createRef();
        const options = {
              format: [this.state.documentWidth, this.state.documentHeight]
        };

        if(report.muestra.other === 'true'){ tipo_muestra = tipo_muestra + 'Otros (' + report.muestra.other_details + ')\n' }
        if(report.muestra.cytology === 'true'){ tipo_muestra = tipo_muestra + 'Citología\n' }
        if(report.muestra.fluid === 'true'){ tipo_muestra = tipo_muestra + 'Fluido (' + report.muestra.fluid_type + ' ' + report.muestra.fluid_origin + ')\n' }

        return (
            <React.Fragment>
                { this.state.isLoaded ?
                    <React.Fragment>
                        <div>
                            <div style={{'width' : '100%'}}>
                                <div className={"row mb-4"}>
                                    <div className={'col-6 text-left'}>
                                        <p className={'fs-15 edit-gray mb-1'}>{ report.title }</p>
                                        <p className={'fs-15 edit-gray mb-1'}>{ report.lab_id }</p>
                                    </div>
                                    
                                    <div className={'col-6 text-right'}>
                                        <img
                                            src={logo}
                                            className={"logo w-50"}
                                            alt={"Logo " + types.COMPANY}
                                          />
                                    </div>
                                </div>

                                <div className={"row mb-3"}>
                                    <div className={'col-12 text-center'}>
                                        <p className={'fs-25'}>{ report.title }</p>
                                    </div>
                                </div>

                                <div className={'border mb-5'}>
                                    <div className={"row m-0"}>
                                        <div className={'col-5 border px-4 py-1'}>
                                            
                                            <div className={'row'}>
                                                <div className={'col-6'}>
                                                    <p className={'fs-15 font-weight-bold'}>{t('Remitente')}:</p>
                                                </div>

                                                <div className={'col-6'}>
                                                    <p className={'fs-15'}>{report.user.company}</p>
                                                </div>

                                                <div className={'col-6'}>
                                                    <p className={'fs-15 font-weight-bold'}>{t('Dirección')}:</p>
                                                </div>

                                                <div className={'col-6'}>
                                                    <p className={'fs-15'}>
                                                        { report.user.address } {' '} { report.user.address_number }, {' '}
                                                        { report.user.zipcode }, {' '} { report.user.city }, {' '}
                                                        { report.user. province }
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                        
                                        <div className={'col-7 border px-4 py-1'}>

                                            <div className={'row'}>
                                                <div className={'col-4'}>
                                                    <p className={'fs-15 font-weight-bold'}>{t('Datos del paciente')}:</p>
                                                </div>

                                                <div className={'col-2'}>
                                                    <p className={'fs-15 font-weight-bold mb-2'}>{t('Nombre')}:</p>
                                                    <p className={'fs-15 font-weight-bold mb-2'}>{t('Especie')}:</p>
                                                    <p className={'fs-15 font-weight-bold mb-2'}>{t('Raza')}:</p>
                                                    <p className={'fs-15 font-weight-bold mb-2'}>{t('Sexo')}:</p>
                                                    <p className={'fs-15 font-weight-bold mb-2'}>{t('Edad')}:</p>
                                                    <p className={'fs-15 font-weight-bold mb-0'}>{t('Historia')}:</p>
                                                </div>

                                                <div className={'col-6'}>
                                                    <p className={'fs-15 mb-2'}>{report.muestra.animal_name}</p>
                                                    <p className={'fs-15 mb-2'}>{report.muestra.animal_species}</p>
                                                    <p className={'fs-15 mb-2'}>{report.muestra.animal_race}</p>
                                                    <p className={'fs-15 mb-2'}>{report.muestra.animal_gender}</p>
                                                    <p className={'fs-15 mb-2'}>{report.muestra.animal_age}</p>
                                                    <p className={'fs-15 mb-0'}>{report.muestra.animal_history}</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className={"row m-0"}>
                                        <div className={'col-5 border px-4 py-1'}>
                                            
                                            <div className={'row'}>
                                                <div className={'col-6'}>
                                                    <p className={'fs-15 font-weight-bold m-0'}>{t('Atención de')}:</p>
                                                </div>

                                                <div className={'col-6'}>
                                                    <p className={'fs-15 m-0'}>{ report.muestra.contact_person }</p>
                                                </div>
                                            </div>

                                        </div>
                                        
                                        <div className={'col-7 border px-4 py-1'}>

                                            <div className={'row'}>
                                                <div className={'col-6'}>
                                                    <p className={'fs-15 font-weight-bold mb-0'}>{t('Propiertario del animal')}:</p>
                                                </div>

                                                <div className={'col-6'}>
                                                    <p className={'fs-15 mb-0'}>{ report.muestra.animal_owner }</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className={"row m-0"}>
                                        <div className={'col-5 border px-4 py-1'}>
                                            
                                            <div className={'row'}>
                                                <div className={'col-6'}>
                                                    <p className={'fs-15 font-weight-bold m-0'}>{t('Toma de la muestra')}:</p>
                                                </div>

                                                <div className={'col-6'}>
                                                    <p className={'fs-15 m-0'}>{ fecha_muestra }</p>
                                                </div>
                                            </div>

                                        </div>
                                        
                                        <div className={'col-7 border px-4 py-1'}>

                                            <div className={'row'}>
                                                <div className={'col-6'}>
                                                    <p className={'fs-15 font-weight-bold mb-0'}>{t('Tipo de muestra')}:</p>
                                                </div>

                                                <div className={'col-6'}>
                                                    <p className={'fs-15 mb-0'}>{ tipo_muestra }</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                {typeof report.clinical_history !== 'undefined' && report.clinical_history !== '' ?
                                    <div className={'row mt-5 mb-5 pt-5'}>

                                        <div className={'col-3 pr-5'}>
                                            <p className={'fs-2 pdf-title'}>{ t('Historia clínica / muestra') }</p>
                                        </div>

                                        <div className={'col-9 px-2'}>
                                            <p className={'fs-15 pdf-content pt-3'}>{ report.clinical_history }</p>
                                        </div>

                                    </div>
                                : null}

                                {typeof report.description !== 'undefined' && report.description !== '' ?
                                    <div className={'row mb-5'}>

                                        <div className={'col-3 pr-5'}>
                                            <p className={'fs-2 pdf-title'}>{ t('Descripción') }</p>
                                        </div>

                                        <div className={'col-9 px-2'}>
                                            <p className={'fs-15 pdf-content pt-3'}>
                                                { typeof report.description === 'undefined' || report.description === 'undefined' ? '--' : report.description }
                                            </p>
                                        </div>

                                    </div>
                                : null}

                                {typeof report.interpretation !== 'undefined' && report.interpretation !== '' ?
                                    <div className={'row mb-5'}>

                                        <div className={'col-3 pr-5'}>
                                            <p className={'fs-2 pdf-title'}>{ t('Interpretación') }</p>
                                        </div>

                                        <div className={'col-9 px-2'}>
                                            <p className={'fs-15 pdf-content pt-3'}>
                                                { typeof report.interpretation === 'undefined' || report.interpretation === 'undefined' ? '--' : report.interpretation }
                                            </p>
                                        </div>

                                    </div>
                                : null}

                                {typeof report.comments !== 'undefined' && report.comments !== '' ?
                                    <div className={'row mb-5'}>

                                        <div className={'col-3 pr-5'}>
                                            <p className={'fs-2 pdf-title'}>{ t('Comentarios') }</p>
                                        </div>

                                        <div className={'col-9 px-2'}>
                                            <p className={'fs-15 pdf-content pt-3'}>
                                                { typeof report.comments === 'undefined' || report.comments === 'undefined' ? '--' : report.comments }
                                            </p>
                                        </div>

                                    </div>
                                : null}

                                {typeof report.pictures !== 'undefined' && report.pictures !== '' && JSON.parse(report.pictures).length > 0 ?
                                    <React.Fragment>
                                        <div className={'row mb-5'}>

                                            <div className={'col-3 pr-5'}>
                                                <p className={'fs-2 pdf-title'}>{ t('Imágenes') }</p>
                                            </div>

                                            <div className={'col-9 px-2'}>
                                                <p className={'fs-15 pdf-content pt-3'}>
                                                   { t('Para visualizar las imágenes de este examen escanear el siguiente QR') }:
                                                </p>
                                            </div>

                                        </div>

                                        <div className={'row mb-2'}>

                                            <div className={'col-12 px-2 text-center'}>
                                                <QRCode 
                                                value={types.SITE_URL + 'informe-imagenes/' + report.id} 
                                                size={180}
                                                title={'T-Cito | Ver las imágenes de tu informe'} />
                                            </div>

                                        </div>
                                    </React.Fragment>
                                : null}

                                <div className={'row mt-5 pt-5'}>
                                    <div className={'col-6 text-left'}>
                                        <p className={'fs-15 mb-1'}>Dr. Antonio Meléndez Lazo</p>
                                        <p className={'fs-15 edit-gray mb-1'}>LV, MSc, PhD, DipECVCP</p>
                                        <p className={'fs-15 edit-gray'}>Nº Col: 6298 COVM</p>
                                    </div>

                                    <div className={'col-6 text-right'}>
                                        <p className={'fs-15 mb-1'}>Javier Martínez Caro</p>
                                        <p className={'fs-15 edit-gray mb-1'}>Veterinario supervisado</p>
                                        <p className={'fs-15 edit-gray'}>Nº Col: 2125 COVL</p>
                                    </div>
                                </div>

                                <div className={'row mt-5'}>
                                    <div className={'col-12 text-center'}>
                                        <p className={'fs-12 edit-gray mb-1'}>{ t('Se prohíbe la difusión total o parcial del contenido o las imágenes del presente informe de forma pública o con fines científicos sin autorización expresa de T-CITO . Hospital Veterinario. Av. de Pérez Galdós 51, 46018 Valencia.') }</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*<div className={'row my-4'}>
                            <div className={'col-12 text-center'}>
                                <a href={'/informe-pdf/' + report.id} target={'_blank'} className={'btn-purple'}>
                                    { t('Descargar informe en PDF') }
                                </a>
                            </div>
                        </div>*/}
                    </React.Fragment>
                : null }
            </React.Fragment>
        )
    }

    _renderReport(){
        const { t, report } = this.props
        let date = typeof report !== 'undefined' ? new Date(report.muestra.created_at) : null
        let fecha = date !== null ? date.getDate() + ' - ' + (date.getMonth() + 1) + ' - ' + date.getFullYear() : null
        let totalPictures = 0

        if(typeof report !== 'undefined' && typeof report.pictures !== 'undefined'){
            JSON.parse(report.pictures).map((picture, key) => {
                if(picture !== '') { totalPictures ++ }
            })
        }

        return(
            <React.Fragment>
                { typeof report !== 'undefined' ?
                    <div className={'box-with-shadow py-4 px-0'}>
                        <div className={'row border-bottom-gray-min pb-3 m-0 px-2'}>
                            <div className={'col-12'}>
                                <span>ID { report.lab_id }</span>
                                <h3 className={'fs-14 mt-1 mb-3'}>{ report.title }</h3>
                                <p className={'fs-1 font-weight-bold m-0'}>{ report.muestra.animal_name } ({ report.muestra.animal_history })</p>
                                <p className={'edit-gray m-0'}>{ t('Fecha de solicitud') }: { fecha }</p>
                            </div>
                        </div>

                        <div className={'row pb-3 mx-0 mb-5 px-2'}>
                            
                            <div className={'col-12 text-right py-4'}>
                                <a href={'/informe-pdf/' + report.id} target={'_blank'} className={'btn-purple mr-3'}>
                                    <span className={"pr-1"}>{ t('Descargar informe en PDF') }</span><DownloadTable />
                                </a>

                                { totalPictures !== 0 ? 
                                    <button className={"btn-purple"} onClick={() => { this._zipImages(JSON.parse(report.pictures)) }}>
                                        <span className={"pr-1"}>{t('Descargar imagenes en Zip')}</span><DownloadTable /> 
                                    </button>
                                : null }
                            </div>        


                            { totalPictures !== 0 ? 
                                <Carousel show={4} slide={3} swiping={true} infinite={false}>
                                    { typeof report.pictures !== 'undefined' ?
                                        JSON.parse(report.pictures).map((picture, key) => {
                                            return(
                                                picture !== '' ?
                                                    <React.Fragment key={key}>
                                                        <div className={'img-square-little-wrapper m-2'}>
                                                            <img src={picture} key={key} className={'img-square-little border'} />
                                                        </div>
                                                    </React.Fragment>
                                                : null
                                            )
                                        })
                                    : null}
                                </Carousel>
                            : null}

                            { totalPictures === 0 ? 
                                <React.Fragment>
                                    <p className={'p-4'}>{t('No hay ninguna imagen adjunta')}</p>
                                </React.Fragment>
                            : null}
                        </div>

                        <div className={'row pb-3 m-0 px-3'}>
                            <div className={'col-12 px-4'}>
                                { this.state.isLoaded ? 
                                    report.template.indexOf('Cliente especial') !== -1 ?
                                      this._renderPDFEspecial()  
                                    : this._renderPDFTCito()
                                : null }
                            </div>
                        </div>
                    </div>
                :
                    <div className={'d-flex w-100 h-100 align-items-stretch'}>
                        <div className={'d-flex w-100 h-100 align-items-center'}>
                            <div className={'w-100 text-center'}>
                                <Empty />
                                <p>{ t('No tienes ningún informe seleccionado') }</p>
                            </div>
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }

    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <div className={"split-container"}>
                    <Menu history={this.props.history} />
                    
                    <div className={"container coachee row p-0"}>
                        <div className={"col-12 py-4 px-4"}>
                            <header className={"managementPanel__content-header mb-3"}>
                                <div className={"col-5"}>
                                </div>

                                <div className={"col-7 text-right"}>
                                    <button type="button" className={'btn-purple'} onClick={() => {this.props.history.goBack()}}>{ t('Volver') }</button>
                                </div>
                            </header>

                            { this.state.isLoaded ?
                                this._renderReport() 
                            :
                                
                                <Loader t={t} />
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

function mapStateToProps(state) {
    const storeUsers = state.users;
    const storeReports = state.reports;

    return {
        isLoggedUser : storeUsers.isLogged,
        user: storeUsers.user,
        report: storeReports.report
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        isLogged: redirectTo => dispatch(isLogged()),
        getReport: (id) => dispatch(getReport(id)),
        cleanStore: () => dispatch(cleanStore())
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(AdminInformes));



