import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import Menu from '../components/menu';
import Submenu from '../components/submenu';
import ResultOk from "../components/icons/resultok";
import Add from "../components/icons/add";
import User from '../components/icons/user'
import Close from '../components/icons/close'
import DownloadTable from '../components/icons/download-table'

import CustomTable from '../components/table';

import InputText from '../components/input';

import { Modal, Select, Checkbox } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { inputFieldChanged,
            getUser,
            saveProfile } from '../actions/user.js';
import { getUserFacturas } from '../actions/factura.js';
import { isLogged } from '../actions/user.js';
import { getInitials } from '../helpers/helpers.js';

import { withTranslation } from 'react-i18next';

const { confirm } = Modal;
const { Option } = Select;

class MisFacturas extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded : false,
            formError : {},
            showModal : false,
            modal : '',
            dataSource : [],
        };
    }

    componentDidMount() {
        const { i18n } = this.props;

        let lang = typeof this.props.match.params.lang !== 'undefined' ? this.props.match.params.lang : null;

        if(lang === 'es' || lang === 'en'){ 
            i18n.changeLanguage(lang) 
        }

        this._getApiData()
    }

    _getApiData(){
        Promise.resolve(this.props.isLogged())
        .then((response)=> {
            if(this.props.isLoggedUser){
                
                Promise.resolve(this.props.getUser(this.props.user._id))
                .then((response)=> {

                    // Get user facturas
                        Promise.resolve(this.props.getUserFacturas(this.props.user._id))
                        .then((response) => {
                            if(this.props.facturas){
                                let dataSourceFacturas = [];

                                this.props.facturas.map((factura, key) =>{
                                    let date = new Date(factura.date);
                                    let fecha = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
                                    let item = {
                                        id : factura.id,
                                        fecha : fecha,
                                        timestamp : factura.timestamp,
                                        number : factura.number,
                                        tarifa : factura.tarifa,
                                        amount : factura.amount,
                                        file : factura.file,
                                    }

                                    dataSourceFacturas.push(item);
                                })

                                this.setState({ 
                                    dataSource : dataSourceFacturas,
                                    isLoadedFacturas : true
                                })
                            }
                        })

                    this.setState({ isLoaded : true })
                });

            }else{
                this.props.history.push('/login')
            }
        });
    }

    _doSearch(){

    }

    _renderFacturas() {
        const { t } = this.props;
        const { dataSource } = this.state;

        const columns = [
            {
                title: t('Fecha'),
                dataIndex: "fecha",
                key: "fecha",
                width: 160,
                sorter: (a, b) => a.timestamp - b.timestamp,
            },
            {
                title: t('Tarifa'),
                dataIndex: "tarifa",
                key: "tarifa",
                sorter: (a, b) => a.tarifa.localeCompare(b.tarifa),
            },
            {
                title: "Número",
                dataIndex: "number",
                key: "number",
            },
            {
                title: t('Importe'),
                dataIndex: "amount",
                key: "amount",
                align: 'center',
                width: 120,
                sorter: (a, b) => a.amount.localeCompare(b.amount),
            },
            {
                title: t('Descarga'),
                dataIndex: "descarga",
                width: 190,
                key: "descarga",
                align: 'center',
                render: (text, record) => (
                    <a href={ record.file } target={'_blank'}>
                        <DownloadTable role={'button'}  />
                    </a>
                )
            }
        ]

        const pagination = { }

        return(
            <React.Fragment>
                <div className={'row pt-2'}>
                    <div className={'col-12'}>
                        <h2 className={'fs-12 poppins purple font-weight-normal mb-3'}>{t('FACTURAS')}</h2>
                    </div>
                </div>

                <div className={'row border rounded mb-5 mx-0 px-2 py-3 bg-white'}>
                    <div className={'col-12 col-md-9'}>
                        <InputText
                            id={'keyword'}
                            name={'keyword'}
                            placeholder={t('Buscar')}
                            classes={'w-100 my-2'}
                            label={t('Buscar')}
                            value={this.props.keyword}
                            onChange={this.props.inputFieldChanged.bind(this)}
                            error={this.state.formError.keyword }
                            />
                    </div>

                    <div className={'col-12 col-md-3 py-2'}>
                        <button role={'button'} className={'btn-purple w-100 h-100'} onClick={this._doSearch.bind(this)}>{t('Buscar')}</button>
                    </div>
                </div>

                <CustomTable
                    columns={columns}
                    dataSource={dataSource}
                    pagination={pagination}
                    loaded={this.state.isLoaded}
                    t={t}
                />
            </React.Fragment>
        )
    }

    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <div className={"split-container"}>
                    <Menu history={this.props.history} />
                    <div className={"container coachee p-0"}>
                        <div className={"row m-0"}>
                            <Submenu />

                            <div className={"col-10 py-4 px-4"}>
                                { this._renderFacturas() }
                            </div>
                        </div>
                    </div>
                </div>

                <Modal visible={this.state.showModal} footer={null} onCancel={ ()=>{ this.setState({ showModal : false }) } } >
                    <div className={'row mt-3'}>
                        
                        { this.state.modal === 'profile' ?
                            <React.Fragment>
                                <div className={'col-12 text-center'}>
                                    <ResultOk />
                                    <p className={'poppins text-uppercase font-weight-bold fs-13 my-2 px-4'}>{t('PERFIL ACTUALIZADO')}</p>
                                </div>
                                
                                <div className={'col-12 text-center'}>
                                    <p className={'gray07 fs-12 lh-12 my-3 px-4'}>{t('Los datos de tu perfil se han guardado correctamente.')}</p>
                                </div>
                            </React.Fragment>
                        : null }

                        { this.state.modal === 'sepa' ?
                            <React.Fragment>
                                <div className={'col-12 text-center'}>
                                    <ResultOk />
                                    <p className={'poppins text-uppercase font-weight-bold fs-13 my-2 px-4'}>{t('DOCUMENTO SEPA GUARDADO')}</p>
                                </div>
                                
                                <div className={'col-12 text-center'}>
                                    <p className={'gray07 fs-12 lh-12 my-3 px-4'}>{t('El documento SEPA proporcionado se ha guardado correctamente.')}</p>
                                </div>
                            </React.Fragment>
                        : null }

                        { this.state.modal === 'password' ?
                            <React.Fragment>
                                <div className={'col-12 text-center'}>
                                    <ResultOk />
                                    <p className={'poppins text-uppercase font-weight-bold fs-13 my-2 px-4'}>{t('CONTRASEÑA ACTUALIZADA')}</p>
                                </div>
                                
                                <div className={'col-12 text-center'}>
                                    <p className={'gray07 fs-12 lh-12 my-3 px-4'}>{t('La nueva contraseña se ha guardado correctamente.')}</p>
                                </div>
                            </React.Fragment>
                        : null }
                    </div>
                </Modal>
            </React.Fragment>
        )
    }

}

function mapStateToProps(state) {
    const storeUsers = state.users;
    const storeMuestras = state.muestras;
    const storeFacturas = state.facturas;

    return {
        isLoggedUser : storeUsers.isLogged,
        user : storeUsers.user,
        address: storeUsers.address,
        address_number: storeUsers.address_number,
        city: storeUsers.city,
        zipcode: storeUsers.zipcode,
        province: storeUsers.province,
        cif: storeUsers.cif,
        phone: storeUsers.phone,
        business: storeUsers.business,
        business_address: storeUsers.business_address,
        business_address_number: storeUsers.business_address_number,
        business_city: storeUsers.business_city,
        business_zipcode: storeUsers.business_zipcode,
        business_province: storeUsers.business_province,
        same_address: storeUsers.same_address,
        invoice_contact: storeUsers.invoice_contact, /* form value */
        report_contact: storeUsers.report_contact, /* form value */
        comunication_contact: storeUsers.comunication_contact, /* form value */
        invoice_contacts: storeUsers.invoice_contacts, /* array of contacts */
        report_contacts: storeUsers.report_contacts, /* array of contacts */
        comunication_contacts: storeUsers.comunication_contacts, /* array of contacts */
        facturas: storeFacturas.facturas,
        dataError : storeUsers.dataError,
        dataErrorMessage : storeUsers.dataErrorMessage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        isLogged: redirectTo => dispatch(isLogged()),
        inputFieldChanged: (field, text) => dispatch(inputFieldChanged(field, text)),
        getUser: (id) => dispatch(getUser(id)),
        getUserFacturas: (id) => dispatch(getUserFacturas(id)),
        saveProfile: (id, company, email) => dispatch(saveProfile(id, company, email))
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(MisFacturas));



