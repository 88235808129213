import React from "react"

const User = (props) => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
	    <title>E1C04D05-60E9-4FCC-AB76-7B9996CE2E05</title>
	    <desc>Created with sketchtool.</desc>
	    <defs>
	        <path d="M10,13.3333333 L10,10.8333333 C10,9.45262146 8.88071187,8.33333333 7.5,8.33333333 L4.16666667,8.33333333 C2.78595479,8.33333333 1.66666667,9.45262146 1.66666667,10.8333333 L1.66666667,13.3333333 L10,13.3333333 Z M2.77999845e-13,14.1666667 L2.77999845e-13,10.8333333 C2.77999845e-13,8.53214688 1.86548021,6.66666667 4.16666667,6.66666667 L7.5,6.66666667 C9.80118646,6.66666667 11.6666667,8.53214688 11.6666667,10.8333333 L11.6666667,14.1666667 C11.6666667,14.626904 11.2935706,15 10.8333333,15 L0.833333333,15 C0.373096042,15 2.78739994e-13,14.626904 2.77999845e-13,14.1666667 Z M5.83333333,5.83333333 C4.22250281,5.83333333 2.91666667,4.52749719 2.91666667,2.91666667 C2.91666667,1.30583615 4.22250281,0 5.83333333,0 C7.44416385,0 8.75,1.30583615 8.75,2.91666667 C8.75,4.52749719 7.44416385,5.83333333 5.83333333,5.83333333 Z M5.83333333,4.16666667 C6.52368927,4.16666667 7.08333333,3.6070226 7.08333333,2.91666667 C7.08333333,2.22631073 6.52368927,1.66666667 5.83333333,1.66666667 C5.1429774,1.66666667 4.58333333,2.22631073 4.58333333,2.91666667 C4.58333333,3.6070226 5.1429774,4.16666667 5.83333333,4.16666667 Z" id="path-people"></path>
	    </defs>
	    <g id="Asset-Artboard-Page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
	        <g id="People">
	            <circle id="Oval" fill="#DD9DEF" cx="12" cy="12" r="12"></circle>
	            <g id="icono-/-Usuario" opacity="0.378348214" transform="translate(2.000000, 2.000000)">
	                <g id="Color-/-Purple-Copy" transform="translate(4.166667, 2.500000)">
	                    <mask id="mask-people" fill="white">
	                        <use xlinkHref="#path-people"></use>
	                    </mask>
	                    <use id="Mask" fill="#000000" fillRule="nonzero" xlinkHref="#path-people"></use>
	                    <g mask="url(#mask-people)" fill="#5E44F4" id="Rectangle">
	                        <g transform="translate(-4.166667, -2.500000)">
	                            <rect x="0" y="0" width="20" height="20"></rect>
	                        </g>
	                    </g>
	                </g>
	            </g>
	        </g>
	    </g>
	</svg>
  )
}

export default User;