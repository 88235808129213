import React from 'react';

import {
    Switch,
    Route
} from "react-router-dom";

import 'antd/dist/antd.css';

import './App.scss';

import Login from './containers/login';
import LoginAdmin from './containers/login-admin';
import Registro from './containers/registro';
import Logout from './containers/logout';
import NotFound from './containers/not-found';
import Recovery from './containers/recovery';
import UserProfile from './containers/user-profile';
import Informes from './containers/informes';
import InformePDF from './containers/informe-pdf';
import InformeImagenes from './containers/informe-imagenes';
import Muestras from './containers/muestras';
import Muestra from './containers/muestra';
import MuestraView from './containers/muestra-view';
import Tarifas from './containers/tarifas';
import Recogida from './containers/recogida';
import MiCuenta from './containers/mi-cuenta';
import DatosCentro from './containers/datos-centro';
import MisFacturas from './containers/mis-facturas';
import MisPortacitos from './containers/mis-portacitos';
/*import Live from './containers/live';*/

import AdminMiCuenta from './containers/admin/mi-cuenta';
import AdminMuestras from './containers/admin/muestras';
import AdminMuestrasPrint from './containers/admin/muestras-print';
import AdminMuestra from './containers/admin/muestra';
import AdminMuestraCreate from './containers/admin/create-muestra';
import AdminTarifas from './containers/admin/tarifas';
import AdminTarifaCreate from './containers/admin/create-tarifa';
import AdminTarifaEdit from './containers/admin/edit-tarifa';
import AdminClientes from './containers/admin/clientes';
import AdminCliente from './containers/admin/cliente';
import AdminClienteCreate from './containers/admin/create-cliente';
import AdminFacturaCreate from './containers/admin/create-factura';
import AdminFacturaEdit from './containers/admin/edit-factura';
import AdminPortacitos from './containers/admin/portacitos';
import AdminSettings from './containers/admin/settings';
import AdminReplyCreate from './containers/admin/create-reply';
import AdminReplyEdit from './containers/admin/edit-reply';
import AdminReportCreate from './containers/admin/create-report';
import AdminReportModify from './containers/admin/modify-report';
import AdminInformes from './containers/admin/informes';
/*import AdminTCitoLive from './containers/admin/tcito-live';*/


function App() {
    let LoginComponent = window.location.hostname.indexOf('admin') !== -1 ? LoginAdmin : Login
    return (
      <Switch>
        {/* Rutas para el panel de clinicas */}
        <Route exact path="/" component={Informes} />
        <Route exact path="/login" component={LoginComponent} />
        <Route exact path="/registro" component={Registro} />
        <Route exact path="/cerrar-sesion" component={Logout} />
        <Route exact path="/recuperar/:token" component={Recovery} />
        <Route exact path="/user-profile" component={UserProfile} />
        <Route exact path="/muestras" component={Muestras} />
        <Route exact path="/nueva-muestra" component={Muestra} />
        <Route exact path="/muestra/:id" component={MuestraView} />
        <Route exact path="/tarifas" component={Tarifas} />
        <Route exact path="/nueva-recogida" component={Recogida} />
        <Route exact path="/mi-cuenta" component={MiCuenta} />
        <Route exact path="/datos-centro" component={DatosCentro} />
        <Route exact path="/mis-facturas" component={MisFacturas} />
        <Route exact path="/mis-portacitos" component={MisPortacitos} />
        <Route exact path="/informes" component={Informes} />
        <Route exact path="/informes/:id" component={Informes} />
        <Route exact path="/informe-imagenes/:id" component={InformeImagenes} />
        <Route exact path="/informe-pdf/:id" component={InformePDF} />
        {/*<Route exact path="/t-cito-live" component={Live} />*/}

        {/* Rutas para el panel de administracion */}
        <Route exact path="/admin/mi-cuenta" component={AdminMiCuenta} />
        <Route exact path="/admin/muestras" component={AdminMuestras} />
        <Route exact path="/admin/muestras-print" component={AdminMuestrasPrint} />
        <Route exact path="/admin/muestra/create/:id" component={AdminMuestraCreate} />
        <Route exact path="/admin/muestra/:id" component={AdminMuestra} />
        <Route exact path="/admin/tarifas" component={AdminTarifas} />
        <Route exact path="/admin/tarifa/create" component={AdminTarifaCreate} />
        <Route exact path="/admin/tarifa/:id" component={AdminTarifaEdit} />
        <Route exact path="/admin/clientes" component={AdminClientes} />
        <Route exact path="/admin/cliente/create" component={AdminClienteCreate} />
        <Route exact path="/admin/cliente/:id" component={AdminCliente} />
        <Route exact path="/admin/factura/create/:id" component={AdminFacturaCreate} />
        <Route exact path="/admin/factura/:id" component={AdminFacturaEdit} />
        <Route exact path="/admin/portacitos" component={AdminPortacitos} />
        <Route exact path="/admin/ajustes" component={AdminSettings} />
        <Route exact path="/admin/respuesta/create" component={AdminReplyCreate} />
        <Route exact path="/admin/respuesta/:id" component={AdminReplyEdit} />
        <Route exact path="/admin/informe/create/:id" component={AdminReportCreate} />
        <Route exact path="/admin/informe/modificar/:id" component={AdminReportModify} />
        <Route exact path="/admin/informes/:id" component={AdminInformes} />
        {/*<Route exact path="/admin/t-cito-live" component={AdminTCitoLive} />*/}

        {/* Ruta 404 not found */}
        <Route component={NotFound} />
        <Route onEnter={() => window.location.reload()} />
      </Switch>
    )
}

export default App;
