export const $$initialState = {
    user : [],
    isLogged : false,
    email : '',
    password : '',
    dataError : false,
    dataErrorMessage : '',
    redirectTo : '',
    successMessage : '',
    first_name : '',
    last_name : '',
    phone : '',
    mobile : '',
    city : '',
    country : '',
    company : '',
    department : '',
    position : '',
    invoice_contacts: [],
    communication_contacts: [],
    report_contacts: [],
    step : '1',

};

export default function registerReducer($$state = $$initialState, action = null) {
    switch (action.type) {
        case 'CLEAN_STORE':
            return Object.assign({}, $$state, {
                company : '',
                email : '',
                password : '',
                address : '',
                address_number : '',
                address_floor : '',
                address_other : '',
                city : '',
                zipcode : '',
                province : undefined,
                cif : '',
                phone : '',
                business : '',
                business_address : '',
                business_address_number : '',
                business_address_floor : '',
                business_address_other : '',
                business_city : '',
                business_zipcode : '',
                business_province : '',
                invoice_contacts : [],
                report_contacts : [],
                communication_contacts : [],
                iban : '',
                step : '1',
                dataError : false,
                dataErrorMessage : '',
            })
        case 'REGISTER_INPUT_FIELD_CHANGED':
            return Object.assign({}, $$state, {
                [action.payload.field]: action.payload.value,
                dataErrorMessage: { [action.payload.field] : '' }
            });

        case 'REGISTER':
            return Object.assign({}, $$state, {
                user : action.payload.user,
                dataError: false,
                dataErrorMessage : '',
            });

        case 'REGISTER_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage : action.payload.message,
            });

        case 'REGISTER_ADMIN':
            return Object.assign({}, $$state, {
                dataError: false,
                dataErrorMessage : '',
            });

        case 'REGISTER_ADMIN_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage : action.payload.message,
            });

        default: {
            return $$state;
        }
    }
}
