import React from 'react';

const DownloadTable = (props) => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
	    <title>B77741E4-0630-45DD-A679-CF40CF6BDB76</title>
	    <desc>Created with sketchtool.</desc>
	    <defs>
	        <path d="M9.14914572,6.19968775 C9.30763352,6.3909734 9.28944639,6.68390841 9.10930725,6.85209192 L5.68579753,10.0503509 C5.52211341,10.2028249 5.27875236,10.2028249 5.11506824,10.0503509 L1.69069246,6.85209192 C1.51055332,6.68390841 1.49236619,6.3909734 1.650854,6.19876367 L2.22158329,5.50662384 C2.38007109,5.3153382 2.65287796,5.29685649 2.83215105,5.46411592 L4.5339463,7.05539067 L4.5339463,0.462042611 C4.5339463,0.20699509 4.72794229,-1.38555833e-14 4.96697308,-1.38555833e-14 L5.83302664,-1.38555833e-14 C6.07205742,-1.38555833e-14 6.26605342,0.20699509 6.26605342,0.462042611 L6.26605342,7.05539067 L7.96784866,5.46411592 C8.1479878,5.29685649 8.42079467,5.3153382 8.57841642,5.50662384 L9.14914572,6.19968775 Z M10.35,12.7058819 C10.5984,12.7058819 10.8,12.8956231 10.8,13.1294113 L10.8,13.9764701 C10.8,14.2102584 10.5984,14.3999996 10.35,14.3999996 L0.45,14.3999996 C0.2016,14.3999996 0,14.2102584 0,13.9764701 L0,13.1294113 C0,12.8956231 0.2016,12.7058819 0.45,12.7058819 L10.35,12.7058819 Z" id="pathDownloadTable"></path>
	    </defs>
	    <g id="Asset-Artboard-Page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
	        <g id="icono-/-download-2">
	            <g id="Color-/-black" transform="translate(6.600000, 4.800000)">
	                <mask id="maskDownloadTable" fill="white">
	                    <use xlinkHref="#pathDownloadTable"></use>
	                </mask>
	                <use id="Mask" fill="#9B9B9B" xlinkHref="#pathDownloadTable"></use>
	                <g mask="url(#maskDownloadTable)" fill="#DD9DEF" id="Rectangle">
	                    <g transform="translate(-1.800000, 0.000000)">
	                        <rect x="0" y="0" width="14.3999996" height="15"></rect>
	                    </g>
	                </g>
	            </g>
	        </g>
	    </g>
	</svg>
  )
}

export default DownloadTable;