import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';


const Loader = (props) => {
  const { t, message } = props;

  let text = typeof message === 'undefined' || message === '' ? t('Cargando...') : message
  
  return (
    <div className={'d-flex w-100'}>
        <Spin 
        tip={<p className={'purple fs-14 poppins mt-3'}>{text}</p>} 
        className={'my-5 mx-auto purple'}
        indicator={<LoadingOutlined className={'purple fs-2'} spin />} />
    </div>
  )
}


export default Loader;