import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import Menu from '../../components/menu';
import CustomTable from '../../components/table';
import MuestrasIcon from "../../components/icons/muestras";
import RecogidasIcon from "../../components/icons/recogidas";
import deleteIcon from '../../assets/img/delete-icon.svg';
import User from '../../components/icons/user'

import { Modal } from 'antd';
import Loader from '../../components/loader';
import { ExclamationCircleOutlined,
            LoadingOutlined } from '@ant-design/icons';

import { getCustomer,
            deleteUser,
            getPortacitos } from '../../actions/user.js';
import { getUserMuestras,
            deleteMuestra } from '../../actions/muestra.js'; 
import { getUserFacturas,
            deleteFactura } from '../../actions/factura.js';       
import { isLogged } from '../../actions/user.js';

import { withTranslation } from 'react-i18next';

const { confirm } = Modal;

class AdminCliente extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded : false,
            isLoadedMuestras : false,
            isLoadedFacturas : false,
            isLoadedPortas : false,
            showMore : false,
            dataSourceMuestras : [],
            dataSourceFacturas : [],
            dataSourcePortacitos : []
        };
    }

    componentDidMount() {
        const { i18n } = this.props;

        let lang = typeof this.props.match.params.lang !== 'undefined' ? this.props.match.params.lang : null;

        if(lang === 'es' || lang === 'en'){ 
            i18n.changeLanguage(lang) 
        }

        this._getApiData()
    }

    _getApiData(){
        Promise.resolve(this.props.isLogged())
        .then((response)=> {
            if(this.props.isLoggedUser){
                if(this.props.user.role.code != 0){
                    this.props.history.push('/404')
                }

                let id = typeof this.props.match.params.id !== 'undefined' ? this.props.match.params.id : null;
                
                Promise.resolve(this.props.getCustomer(id))
                .then((response)=> {
                    if(this.props.customer){
                        // Get user muestras
                        Promise.resolve(this.props.getUserMuestras('', id))
                        .then((response) => {
                            if(this.props.muestras){
                                let dataSourceMuestras = [];

                                this.props.muestras.map((muestra, key) =>{
                                    let date = new Date(muestra.date);
                                    let fecha = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
                                    let item = {
                                        id : muestra.id,
                                        lab_id : muestra.lab_id,
                                        fecha : fecha,
                                        tipo : muestra.title,
                                        paciente : muestra.animal_name,
                                        historia : muestra.animal_history,
                                        estado : muestra.status,
                                        envio : muestra.envio
                                    }

                                    dataSourceMuestras.push(item);
                                })

                                this.setState({ 
                                    dataSourceMuestras : dataSourceMuestras,
                                    isLoadedMuestras : true
                                })
                            }
                        })

                        // Get user facturas
                        Promise.resolve(this.props.getUserFacturas(id))
                        .then((response) => {
                            if(this.props.facturas){
                                let dataSourceFacturas = [];

                                this.props.facturas.map((factura, key) =>{
                                    let date = new Date(factura.date);
                                    let fecha = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
                                    let item = {
                                        id : factura.id,
                                        fecha : fecha,
                                        timestamp : date.getTime(),
                                        number : factura.number,
                                        tarifa : factura.tarifa,
                                        amount : factura.amount,
                                        file : factura.file,
                                    }

                                    dataSourceFacturas.push(item);
                                })

                                this.setState({ 
                                    dataSourceFacturas : dataSourceFacturas,
                                    isLoadedFacturas : true
                                })
                            }
                        })

                        // Get user portacitos
                        Promise.resolve(this.props.getPortacitos(id))
                        .then((response) => {
                            if(this.props.portacitos){
                                let dataSourcePortacitos = [];

                                this.props.portacitos.map((portacito, key) =>{
                                    let date = new Date(portacito.date);
                                    let fecha = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
                                    let item = {
                                        id : portacito.id,
                                        key : key,
                                        fecha : fecha,
                                        timestamp : date.getTime(),
                                        cantidad : portacito.amount,
                                        importe : portacito.price,
                                        estado : portacito.status
                                    }

                                    dataSourcePortacitos.push(item);
                                })

                                this.setState({ 
                                    dataSourcePortacitos : dataSourcePortacitos,
                                    isLoadedPortas : true 
                                })
                            }
                        })

                        this.setState({ isLoaded : true })
                    }
                });

            }else{
                this.props.history.push('/login')
            }
        });
    }

    _renderHeader() {
        const { t, customer } = this.props
        let formatedDate = new Date(customer.date)
        formatedDate = formatedDate.getDate() + ' - ' + (formatedDate.getMonth() + 1) + ' - ' + formatedDate.getFullYear()
        let picture = typeof customer.picture !== 'undefined' ? <img src={customer.picture} className={'w-100 h-100 rounded-circle'} /> : <User />;

        return(
            <React.Fragment>

                <div className={'row row-eq-height align-items-center'}>
                    <div className={'col-1 border-bottom-gray-min px-1 pb-2'}>
                        { picture }
                    </div>

                    <div className={'col-7 border-bottom-gray-min px-1 pb-2'}>
                        <p className={'fs-14 lh-2 my-0'}>{ customer.business }</p>
                        <p className={'edit-gray my-1'}>
                            { customer.address }
                            { customer.address_number !== '' && typeof customer.address_number !== 'undefined' ? (', ' + customer.address_number) : '' }
                            { customer.address_floor !== '' && typeof customer.address_floor !== 'undefined' ? (', ' + customer.address_floor) : '' }
                            { customer.zipcode !== '' && typeof customer.zipcode !== 'undefined' ? (', ' + customer.zipcode) : '' }
                            { customer.city !== '' && typeof customer.city !== 'undefined' ? (', ' + customer.city) : '' }
                        </p>
                    </div>

                    <div className={'col-4 border-bottom-gray-min px-0 text-right py-4'}>
                        { typeof customer.sepa !== 'undefined' && customer.sepa !== '' ?
                            <a href={customer.sepa} target={'_blank'} className={'btn-purple'}>{t('Descargar Doc. SEPA')}</a>
                        :
                            <span className={'btn-purple disabled'}>{t('SEPA no adjuntado')}</span>
                        }
                    </div>
                </div>

                <div className={'row pt-3'}>
                    <div className={'col-1 pl-0'}><p className={'fs-12 edit-gray'}>{t('Nombre')}:</p></div>
                    <div className={'col-3 pl-0'}><p className={'fs-12'}>{ customer.company }</p></div>
                    <div className={'col-1'}><p className={'fs-12 edit-gray'}>{t('Teléfono')}:</p></div>
                    <div className={'col-3 pl-0'}><p className={'fs-12'}>{ customer.phone }</p></div>
                    <div className={'col-1'}><p className={'fs-12 edit-gray'}>{t('Email')}:</p></div>
                    <div className={'col-3 pl-0'}><p className={'fs-12'}>{ customer.email }</p></div>
                </div>

                <div className={'row border-bottom-gray-min'}>
                    <div className={'col-1 pl-0'}><p className={'fs-12 edit-gray'}>{t('Dir. fiscal')}:</p></div>
                    <div className={'col-3 pl-0'}><p className={'fs-12'}>
                        { customer.business_address !== '' && typeof customer.business_address !== 'undefined' ? (
                            <React.Fragment>
                            { customer.business_address }
                            { customer.business_address_number !== '' && typeof customer.business_address_number !== 'undefined' ? (', ' + customer.business_address_number) : '' }
                            { customer.business_address_floor !== '' && typeof customer.business_address_floor !== 'undefined' ? (', ' + customer.business_address_floor) : '' }
                            { customer.business_zipcode !== '' && typeof customer.business_zipcode !== 'undefined' ? (', ' + customer.business_zipcode) : '' }
                            { customer.business_city !== '' && typeof customer.business_city !== 'undefined' ? (', ' + customer.business_city) : '' }
                            </React.Fragment>
                        ):(
                            <React.Fragment>
                            { customer.address }
                            { customer.address_number !== '' && typeof customer.address_number !== 'undefined' ? (', ' + customer.address_number) : '' }
                            { customer.address_floor !== '' && typeof customer.address_floor !== 'undefined' ? (', ' + customer.address_floor) : '' }
                            { customer.zipcode !== '' && typeof customer.zipcode !== 'undefined' ? (', ' + customer.zipcode) : '' }
                            { customer.city !== '' && typeof customer.city !== 'undefined' ? (', ' + customer.city) : '' }
                            </React.Fragment>
                        )}
                     </p></div>                    
                    <div className={'col-1'}><p className={'fs-12 edit-gray'}>{t('CIF/NIF')}:</p></div>
                    <div className={'col-3 pl-0'}><p className={'fs-12'}>{ customer.cif }</p></div>
                    <div className={'col-1'}><p className={'fs-12 edit-gray'}>{t('IBAN')}:</p></div>
                    <div className={'col-3 pl-0'}><p className={'fs-12'}>{ customer.iban }</p></div>
                </div>

                { this.state.showMore ?
                    <div className={'row border-bottom-gray-min pt-3'}>
                        <div className={'col-6 pl-0'}>
                            <p className={'fs-12 edit-gray'}>{t('Razón social')}:
                            <span className={'black-text ml-2'}>{ customer.business }</span></p>
                        </div>
                        <div className={'col-6 pl-0'}>
                            <p className={'fs-12 edit-gray'}>{t('Contacto informes')}:
                            <span className={'black-text ml-2 '}>
                                { typeof customer.report_contacts !== 'undefined' && customer.report_contacts !== 'undefined' && JSON.parse(customer.report_contacts) !== '' ? 

                                    JSON.parse(customer.report_contacts).map((email, key) =>{
                                        return(
                                            <React.Fragment key={key}>
                                                <span>{ email }</span>
                                                { key === (JSON.parse(customer.report_contacts).length - 1) ? '' : ', ' }
                                            </React.Fragment>
                                        )
                                    })

                                :
                                    '-'
                                }
                            </span></p>
                        </div>
                        <div className={'col-6 pl-0'}>
                            <p className={'fs-12 edit-gray'}>{t('Contacto facturas')}:
                            <span className={'black-text ml-2'}>
                                { typeof customer.invoice_contacts !== 'undefined' && customer.invoice_contacts !== 'undefined' && JSON.parse(customer.invoice_contacts) !== '' ? 

                                    JSON.parse(customer.invoice_contacts).map((email, key) =>{
                                        return(
                                            <React.Fragment key={key}>
                                                <span>{ email }</span>
                                                { key === JSON.parse(customer.invoice_contacts).length - 1 ? '' : ', ' }
                                            </React.Fragment>
                                        )
                                    })

                                :
                                    '-'
                                }
                            </span></p>
                        </div>
                        <div className={'col-6 pl-0'}>
                            <p className={'fs-12 edit-gray'}>{t('Contacto comunicación')}:
                            <span className={'black-text ml-2'}>
                                { typeof customer.communication_contacts !== 'undefined' && customer.communication_contacts !== 'undefined' && JSON.parse(customer.communication_contacts) !== '' ? 

                                    JSON.parse(customer.communication_contacts).map((email, key) =>{
                                        return(
                                            <React.Fragment key={key}>
                                                <span>{ email }</span>
                                                { key === JSON.parse(customer.communication_contacts).length - 1 ? '' : ', ' }
                                            </React.Fragment>
                                        )
                                    })

                                :
                                    '-'
                                }
                            </span></p>
                        </div>
                    </div>
                :
                    null
                }

                { this.state.showMore ?
                    <div className={'row pt-3'}>
                        <div className={'col-12 pl-0'}>
                            <p className={'bold'} role={'button'} onClick={()=>{this.setState({ 'showMore' : false })}}>{t('Mostrar menos')}</p>
                        </div>
                    </div>
                :
                    <div className={'row pt-3'}>
                        <div className={'col-12 pl-0'}>
                            <p className={'bold'} role={'button'} onClick={()=>{this.setState({ 'showMore' : true })}}>{t('Mostrar más')}</p>
                        </div>
                    </div>
                }

            </React.Fragment>
        )
    }

    _renderCustomer() {
        const { t } = this.props

        return (
            <React.Fragment>

                <div className={'box-with-shadow pt-4 pl-5 pr-5 pb-2 mb-5 border'}>
                    { this._renderHeader() }
                </div>

                <div className={'row align-items-center mt-5'}>
                    <div className={'col-6'}>
                        <h2 className={"fs-14 poppins mb-3"}>{t('Muestras')}</h2>
                    </div>

                    <div className={'col-6 text-right mb-3'}>
                        <a href={'/admin/muestra/create/' + this.props.customer._id} className={'btn-purple'}>
                            {t('Añadir muestra')}
                        </a>
                    </div>
                </div>

                { this._renderTableMuestras() }

                <div className={'row align-items-center mt-5'}>
                    <div className={'col-6'}>
                        <h2 className={"fs-14 poppins mb-3"}>{t('Facturas')}</h2>
                    </div>

                    <div className={'col-6 text-right mb-3'}>
                        <a href={'/admin/factura/create/' + this.props.customer._id} className={'btn-purple'}>
                            {t('Añadir factura')}
                        </a>
                    </div>
                </div>

                { this._renderTableFacturas() }

                <div className={'row align-items-center mt-5'}>
                    <div className={'col-6'}>
                        <h2 className={"fs-14 poppins mb-4 mt-5"}>{t('Porta-citos solicitados')}</h2>
                    </div>

                    <div className={'col-6 text-right mb-3'}>
                    </div>
                </div>

                { this._renderTablePortacitos() }

            </React.Fragment>
        )
    }

    _deleteFactura(id) {
        const { t } = this.props;

        confirm({
            title: t('¿Seguro que quieres eliminar esta factura?'),
            icon: <ExclamationCircleOutlined style={{ color: '#FF866B' }} />,
            content: t('Si la eliminas se borra definitivamente de tu listado'),
            okText: t('Si, borrar'),
            cancelText: t('No'),
            onOk : () => {

                Promise.resolve(this.props.deleteFactura(id))
                .then((response)=> {
                    if(this.props.dataError === true){
                        alert( this.props.dataErrorMessage )
                    }

                    this._getApiData()
                });
                
            },
            onCancel() {
                
            },
        });
    }

    _renderTableMuestras() {
        const { t } = this.props;
        const { dataSourceMuestras } = this.state;

        const columns = [
            {
                title: t('ID'),
                dataIndex: "lab_id",
                key: "lab_id",
                ellipsis: true,
                width: 120,
                sorter: (a, b) => a.lab_id.localeCompare(b.lab_id),
            },
            {
                title: t('Tipo de análisis'),
                dataIndex: "tipo",
                key: "tipo",
                ellipsis: true,
                width: 180,
            },
            {
                title: "Paciente",
                dataIndex: "paciente",
                key: "paciente",
                ellipsis: true,
                width: 180,
                sorter: (a, b) => a.paciente.localeCompare(b.paciente),
            },
            {
                title: t('Fecha'),
                dataIndex: "fecha",
                key: "fecha",
                align: "center",
                width: 120,
                sorter: (a, b) => a.timestamp - b.timestamp,
            },
            {
                title: t('Informe'),
                dataIndex: "estado",
                width: 180,
                key: "estado",
                align: "center",
                render: (text, record) => (
                    <React.Fragment>
                        { record.estado === 0 ? (
                            <span className={"white-badge poppins w-100 d-inline-block"}>{t('Pendiente recogida')}</span>
                        ) : null }

                        { record.estado === 1 ? (
                            <span className={"purple-light-badge poppins opacity-3 w-100 d-inline-block"}>{t('Pendiente')}</span>
                        ) : null }

                        { record.estado === 2 ? (
                            <span className={"gray-badge w-100 poppins d-inline-block"}>{t('Finalizado')}</span>
                        ) : null }
                    </React.Fragment>
                )
            },
            {
                title: "Método envío",
                dataIndex: "envio",
                key: "envio",
                ellipsis: true,
                width: 130,
                sorter: (a, b) => a.envio.localeCompare(b.envio),
            },
            {
                title: "",
                key: "herramientas",
                width: 140,
                align: "center",
                render: (text, record) => (
                    <React.Fragment>
                        <a href={'/admin/muestra/' + record.id} className={'btn-white w-100 lh-24'}>
                            {t('Ver muestra')}
                        </a>
                    </React.Fragment>
                ),
            }
        ]

        const pagination = { pageSize: 5 }

        return (
            <React.Fragment>

                <CustomTable
                    className={'mb-5'}
                    columns={columns}
                    dataSource={dataSourceMuestras}
                    pagination={pagination}
                    loaded={this.state.isLoaded}
                    t={t}
                />
                                
            </React.Fragment>
        )
    }

    _renderTableFacturas() {
        const { t } = this.props;
        const { dataSourceFacturas } = this.state;

        const columns = [
            {
                title: t('Fecha'),
                dataIndex: "fecha",
                key: "fecha",
                align: "left",
                width: 180,
                sorter: (a, b) => a.timestamp - b.timestamp,
            },
            {
                title: t('Tarifa/Servicio'),
                dataIndex: "tarifa",
                key: "tarifa",
                ellipsis: true,
                width: 220,
                sorter: (a, b) => a.tarifa.localeCompare(b.tarifa),
            },
            {
                title: t('Nº de factura'),
                dataIndex: "number",
                key: "number",
                ellipsis: true,
                width: 160,
                sorter: (a, b) => a.number.localeCompare(b.number),
            },
            {
                title: "Importe",
                dataIndex: "amount",
                key: "amount",
                ellipsis: true,
                width: 130,
                sorter: (a, b) => a.amount.localeCompare(b.amount),
            },
            {
                title: "",
                key: "editar",
                width: 180,
                align: "center",
                render: (text, record) => (
                    <React.Fragment>
                        <a href={'/admin/factura/' + record.id} className={'btn-white w-100 lh-24'}>
                            {t('Editar factura')}
                        </a>
                    </React.Fragment>
                ),
            },
            {
                title: "",
                key: "herramientas",
                width: 180,
                align: "center",
                render: (text, record) => (
                    <React.Fragment>
                        <button className={'btn-white w-100 lh-24'} onClick={()=>{ this._deleteFactura(record.id) }}>
                            {t('Borrar factura')}
                        </button>
                    </React.Fragment>
                ),
            }
        ]

        const pagination = { pageSize: 5 }

        return (
            <React.Fragment>

                <CustomTable
                    columns={columns}
                    dataSource={dataSourceFacturas}
                    pagination={pagination}
                    loaded={this.state.isLoaded}
                    t={t}
                />
                                
            </React.Fragment>
        )
    }

    _renderTablePortacitos() {
        const { t } = this.props;
        const { dataSourcePortacitos } = this.state;

        const columns = [
            {
                title: t('Fecha'),
                dataIndex: "fecha",
                key: "fecha",
                align: "left",
                width: 170,
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.timestamp - b.timestamp,
            },
            {
                title: t('Cantidad'),
                dataIndex: "cantidad",
                key: "cantidad",
                ellipsis: true,
                width: 380,
                sorter: (a, b) => a.cantidad - b.cantidad,
            },
            {
                title: t('Importe'),
                dataIndex: "importe",
                key: "importe",
                ellipsis: true,
                width: 370,
                sorter: (a, b) => a.importe.localeCompare(b.importe),
            },
            {
                title: t('Estado'),
                dataIndex: "estado",
                width: 170,
                key: "estado",
                align: "center",
                render: (text, record) => (
                    <React.Fragment>
                        { record.estado === 0 ? (
                            <span className={"white-badge poppins w-100 d-inline-block"}>{t('Solicitado')}</span>
                        ) : null }

                        { record.estado === 1 ? (
                            <span className={"purple-light-badge poppins opacity-3 w-100 d-inline-block"}>{t('Enviado')}</span>
                        ) : null }
                    </React.Fragment>
                )
            }

        ]

        const pagination = { pageSize: 5 }

        return (
            <React.Fragment>

                <CustomTable
                    columns={columns}
                    dataSource={dataSourcePortacitos}
                    pagination={pagination}
                    loaded={this.state.isLoaded}
                    t={t}
                />
                                
            </React.Fragment>
        )
    }

    _renderLoader(){
        return ('')
    }

    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <div className={"split-container"}>
                    <Menu history={this.props.history} />
                    <div className={"container coachee"}>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <header className={"managementPanel__content-header mb-3"}>
                                    <div className={"col-5 pl-0"}>
                                        <h1 className={"fs-16 poppins"}>{t('Detalle cliente')}</h1>
                                    </div>

                                    <div className={"col-7 text-right"}>
                                    </div>
                                </header>
                                
                                <section className={"coachee__content-body"}>
                                    { this.state.isLoaded ?
                                        this._renderCustomer() 
                                    :
                                        <React.Fragment>
                                            <Loader t={t} />
                                        </React.Fragment>
                                    }
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

function mapStateToProps(state) {
    const storeUsers = state.users;
    const storeMuestras = state.muestras;
    const storeFacturas = state.facturas;

    return {
        isLoggedUser : storeUsers.isLogged,
        user: storeUsers.user,
        customer: storeUsers.customer,
        portacitos: storeUsers.portacitos,
        facturas: storeFacturas.facturas,
        muestras: storeMuestras.muestras,
        dataError : storeUsers.dataError,
        dataErrorMessage : storeUsers.dataErrorMessage,
        dataErrorMuestras : storeMuestras.dataError,
        dataErrorMessageMuestras : storeMuestras.dataErrorMessage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        isLogged: redirectTo => dispatch(isLogged()),
        getCustomer: (id) => dispatch(getCustomer(id)),
        deleteUser: (id) => dispatch(deleteUser(id)),
        getPortacitos: (id) => dispatch(getPortacitos(id)),
        getUserMuestras: (status, id) => dispatch(getUserMuestras(status, id)),
        getUserFacturas: (id) => dispatch(getUserFacturas(id)),
        deleteFactura: (id) => dispatch(deleteFactura(id))
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(AdminCliente));



