import React from 'react';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';
import {getInitials} from '../helpers/helpers.js';

import { isLogged } from '../actions/user.js';
import { inputFieldChanged,
            requestPortas } from '../actions/app.js';

import logo from '../assets/img/logo-t-cito.svg'
import logoMobile from '../assets/img/logo-mini.svg'
import menuIcon from '../assets/img/icon-menu.svg'
import Tarifas from "../components/icons/tarifas"
import Muestras from "../components/icons/muestras"
import Portacitos from "../components/icons/portacitos"
import Settings from "../components/icons/settings"
import Cliente from "../components/icons/cliente"
import Informes from "../components/icons/informes"
import Live from "../components/icons/live"
import Logout from "../components/icons/logout"
import ResultOk from "../components/icons/resultok";
import { Modal, Avatar, Select, Spin } from 'antd';
import { LoadingOutlined, ControlOutlined } from '@ant-design/icons';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';

import { withTranslation } from 'react-i18next';

const { Option } = Select;

class Menu extends React.Component {
    constructor(props) {
        super(props)

        this.wrapperRef = React.createRef()
        this.handleClickOutside = this.handleClickOutside.bind(this)

        this.state = {
            isLoaded: false,
            menuItems: [],
            mobileMenu: false,
            formError : {},
            showModalPorta: false,
            modalSent: false
        }
    }

    componentDidMount() {
        const { t, i18n } = this.props;
        const current_lang = i18n.language

        if(current_lang.indexOf('en-GB') !== -1){ i18n.changeLanguage('en') }
        if(current_lang.indexOf('en-US') !== -1){ i18n.changeLanguage('en') }
        if(current_lang.indexOf('es-ES') !== -1){ i18n.changeLanguage('es') }

        Promise.resolve(this.props.isLogged()).then((response) => {
          if (this.props.isLoggedUser === true) {
            if (this.props.user.role.code === 0) { // Admin
              // User is admin
              this.setState({
                menuItems: [
                  {
                    title: t('Muestras'),
                    icon: () => <Muestras active={ this._isCurrentLink("/muestras") || this._isCurrentLink("/nueva-muestra") || this._isCurrentLink("/muestra/") } />,
                    link: "/admin/muestras",
                    isActive: this._isCurrentLink("/muestras") || this._isCurrentLink("/nueva-muestra") || this._isCurrentLink("/muestra/"),
                  },
                  
                  {
                    title: t('Porta-citos'),
                    icon: () => <Portacitos active={ this._isCurrentLink("/portacitos") } style={ { paddingLeft : '3px' } } />,
                    link: "/admin/portacitos",
                    isActive: this._isCurrentLink("/portacitos"),
                  },

                  /*{
                    title: t('T-CITO Live!'),
                    icon: () => <Live active={ this._isCurrentLink("/t-cito-live") } />,
                    link: "/admin/t-cito-live",
                    isActive: this._isCurrentLink("/t-cito-live"),
                  },*/

                  {
                    title: t('Clientes'),
                    icon: () => <Cliente active={ this._isCurrentLink("/cliente") } />,
                    link: "/admin/clientes",
                    isActive: this._isCurrentLink("/cliente"),
                  },

                  {
                    title: t('Tarifas'),
                    icon: () => <Tarifas active={ this._isCurrentLink("/tarifa") } />,
                    link: "/admin/tarifas",
                    isActive: this._isCurrentLink("/tarifa"),
                  },
                  
                  {
                    title: t('Ajustes'),
                    icon: () => <Settings active={ this._isCurrentLink("/ajustes") } style={ { paddingLeft : '3px' } } />,
                    link: "/admin/ajustes",
                    isActive: this._isCurrentLink("/ajustes"),
                  },
                ],
              })
            } else if (this.props.user.role.code === 1){ // Clinica
              this.setState({
                menuItems: [
                  {
                    title: t('Tarifas'),
                    icon: () => <Tarifas active={ this._isCurrentLink("/tarifa") } />,
                    link: "/tarifas",
                    isActive: this._isCurrentLink("/tarifa"),
                  },

                  {
                    title: t('Muestras'),
                    icon: () => <Muestras active={ this._isCurrentLink("/muestras") || this._isCurrentLink("/nueva-muestra") } />,
                    link: "/muestras",
                    isActive: this._isCurrentLink("/muestras") || this._isCurrentLink("/nueva-muestra"),
                  },

                  {
                    title: t('Informes'),
                    icon: () => <Informes active={ this._isCurrentLink("/informes") } />,
                    link: "/informes",
                    isActive: this._isCurrentLink("/informes"),
                  },

                  /*{
                    title: t('T-CITO Live!'),
                    icon: () => <Live active={ this._isCurrentLink("/t-cito-live") } />,
                    link: "/t-cito-live",
                    isActive: this._isCurrentLink("/t-cito-live"),
                  },*/
                ],
              })
            }

            this.setState({ isLoaded: true })
          }
        })
        document.addEventListener("mousedown", this.handleClickOutside)
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside)
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
          this.setState({ 'mobileMenu': false })
        }
    }

    _isCurrentLink(path) {
        if (window.location.pathname === path) {
          return true
        }

        if (path !== '/' && path.length > 0 && window.location.pathname.indexOf(path) !== -1) {
          return true
        }

        return false
    }

    _requestPortas() {
        const { t } = this.props;
        let error = false;
        const formError = {};
        this.setState({ formError : formError })
        this.setState({ isLoaded : false })

        if(typeof this.props.cantidad_portacitos === 'undefined'){
            formError.cantidad_portacitos = t('Tienes que elegir el número de porta-citos.')
            this.setState({ formError : formError })
            error = true
        }

        if(error === false){
            Promise.resolve(this.props.requestPortas(this.props.cantidad_portacitos, this.props.user._id))
            .then((response)=> {
                this.setState({ isLoaded : true })

                if(this.props.dataError === false){
                    this.setState({ modalSent : true })
                }
            });
        }else{
            this.setState({ isLoaded : true })
        }
    }

    _renderMenuItems() {
        const { menuItems } = this.state
        const { t } = this.props
        
        return (
          <React.Fragment>

            {/* Menu */}
            <div className={"row menu-items mt-3"}>
              <div className={"col-12 px-0"}>
                <ul>
                  {menuItems
                    ? menuItems.map((menuItem, key) => {
                        return (
                          <React.Fragment key={key}>
                            <li
                              className={menuItem.isActive ? "active" : ""}
                              key={key}
                            >
                              <a href={menuItem.link}>
                                <menuItem.icon />
                                {menuItem.title}
                              </a>
                            </li>

                            {menuItem.options &&
                              menuItem.options.map((option, key) => {
                                return (
                                  <p className={"fs-08 ml-5"} key={key}>
                                    <a
                                      href={option.link}
                                      className={option.isActive ? "orange" : ""}
                                    >
                                      {option.title}
                                    </a>
                                  </p>
                                )
                              })}
                          </React.Fragment>
                        )
                      })
                    : null}

                    {/*}
                    <li className={'mt-5'}>
                        <a 
                            onClick={() => { i18n.changeLanguage('es'); window.location.reload()}} 
                            className={(current_lang === "es") ? "text-dark mr-3" : "mr-3"}
                        >
                            ES
                        </a>

                        <a 
                            onClick={() => { i18n.changeLanguage('en'); window.location.reload()}}
                            className={(current_lang === "en") ? "text-dark mr-3" : "mr-3"}
                        >
                            EN
                        </a>
                    </li>
                  */}
                </ul>
              </div>
            </div>

            {/* Menu Footer */}

            <div className={"row pt-2 pl-3 mx-0 menu-footer"}>
              { typeof this.props.user !== 'undefined' && typeof this.props.user.role !== 'undefined' && this.props.user.role.code !== 0 ?
                <div className={"col-12 info-box p-3 my-2"}>
                  <p className={"white mb-2"}><b>{t('¿Necesitas porta-citos?')}</b></p>
                  <p className={"mb-2"}>{t('Solicita porta-citos de forma gratuita para enviar tus portaobjetos de forma segura.')}</p>
                  <p><button className={"btn-clean purple-light"} onClick={ ()=>{ this.setState({ showModalPorta : true }) } }>
                    {t('Solicitar')}
                  </button></p>
                </div>
              : null }

              <div className={"col-12 info-box p-3 my-2"}>
                { typeof this.props.user !== 'undefined' && typeof this.props.user.role !== 'undefined' && this.props.user.role.code !== 0 ?
                    <div className={"row align-items-center"} role={'button'}>
                      <div className={"col-3"}>
                        <a href={'/mi-cuenta'} className={'text-decoration-none'}>
                          <Avatar shape={"square"}>{ getInitials(this.props.user.company) }</Avatar>
                        </a>
                      </div>

                      <div className={"col-6"}>
                        <a href={'/mi-cuenta'} className={'text-decoration-none'}>
                          <p className={"white"}>{ this.props.user.company }</p>
                        </a>
                      </div>

                      <div className={"col-3"}>
                        <a href={"/cerrar-sesion"}>
                          <Logout />
                        </a>
                      </div>
                    </div>
                :       
                    <div className={"row align-items-center"} role={'button'}>
                      <div className={"col-3"}>
                        <a href={'/admin/mi-cuenta'} className={'text-decoration-none'}>
                          <Avatar shape={"square"}>{ getInitials(this.props.user.company) }</Avatar>
                        </a>
                      </div>

                      <div className={"col-6"}>
                        <a href={'/admin/mi-cuenta'} className={'text-decoration-none'}>
                          <p className={"white"}>{ this.props.user.company }</p>
                         </a>
                      </div>

                      <div className={"col-3"}>
                        <a href={"/cerrar-sesion"}>
                          <Logout />
                        </a>
                      </div>
                    </div>
                }
              </div>
            </div>
          </React.Fragment>
        )
    }

    render() {
        const { t } = this.props

        return (
          <React.Fragment>
            <div className={`menu-container ${this.state.mobileMenu ? "active" : ""}`}>
              <div className={"menu-mobile"}>
                <img
                  src={menuIcon}
                  className={"menu-icon"}
                  alt="menu icon"
                  onClick={() => {
                    this.setState({ mobileMenu: !this.state.mobileMenu })
                  }}
                />
                <img
                  src={logoMobile}
                  className={"logo-mobile"}
                  alt="Logo T-cito"
                />
              </div>
              <div
                ref={this.wrapperRef}
                className={`menu-content ${this.state.mobileMenu ? "active" : ""}`}
              >
                {/* Logo */}
                <a href="/">
                  <img
                    src={logo}
                    className={"logo"}
                    alt="Logo T-Cito"
                  />
                </a>

                { this.state.isLoaded ? 
                  this._renderMenuItems() 
                  : null }
              </div>
            </div>

            <Modal 
                visible={this.state.showModalPorta}
                footer={null}
                onCancel={ ()=>{ this.setState({ showModalPorta : false, modalSent: false }) } }
            >
                <div className={'row mt-3'}> 
                    { this.state.modalSent ? 
                      <React.Fragment>
                          <div className={'col-12 text-center'}>
                              <ResultOk />
                              <p className={'poppins text-uppercase font-weight-bold fs-13 my-2 px-4'}>{t('CONFIRMACIÓN DE SOLICITUD DE PORTA-CITOS')}</p>
                          </div>

                          <div className={'col-12 text-center'}>
                              <p className={'gray07 fs-12 lh-12 my-3 px-4'}>{t('Gracias por solicitar porta-citos para enviar tus muestras. Te los enviaremos lo antes posible.')}</p>
                          </div>

                          <div className={'col-12 pt-5 pb-3 text-center'}>
                              <a href={'/mis-portacitos'} className={'btn-purple-text'}>
                                {t('Listo')}
                              </a>
                          </div>
                      </React.Fragment>
                    :
                      <React.Fragment>
                        <div className={'col-12 px-5'}>
                            <p className={'poppins purple font-weight-bold fs-15 mb-4'}>
                              {t('¿Cuántos porta-citos necesitas?')}
                            </p>

                            <p className={'fs-08 edit-gray mb-4'}>
                              { t('Los porta-citos solicitados se enviarán a la dirección asociada a tu perfil. Ten en cuenta que estos pueden tardar varios días en llegar a tu centro, ya que solo se realizan envíos una vez a la semana.') }
                            </p>

                            <Select 
                                id={'hora_fin'}
                                placeholder={t('Cantidad de portacitos')}
                                className={'w-100 fs-09 mb-5'}
                                value={this.props.cantidad_portacitos}
                                onChange={this.props.inputFieldChanged.bind(this, 'cantidad_portacitos')}>

                                    <Option key={0} value={10}>{'10'}</Option>
                                    <Option key={1} value={15}>{'15'}</Option>
                                    <Option key={2} value={20}>{'20'}</Option>
                                    <Option key={3} value={25}>{'25'}</Option>
                                    <Option key={4} value={30}>{'30'}</Option>

                            </Select>

                            { this.props.dataError && typeof this.props.dataErrorMessage !== 'undefined' ?

                              <div className={'col-12 text-center'}>
                                 <p className={'red fs-09 mb-3'}>{ this.props.dataErrorMessage.message }</p>
                              </div>

                            : null }

                            {(typeof this.state.formError.cantidad_portacitos !== 'undefined' && this.state.formError.cantidad_portacitos !== '') ?
                                <p className={'red fs-09 mb-3 pl-3'}>{this.state.formError.cantidad_portacitos}</p>
                            : null }

                            <button className={'btn-purple w-100 lh-4 d-flex align-items-center justify-center'} onClick={ ()=>{ this._requestPortas() } }>
                              <div className={'m-auto'}>
                                { t('Pedir porta-citos') }
                                <Spin size="small" spinning={!this.state.isLoaded} className={'ml-2 pb-1'} indicator={<LoadingOutlined style={{ color: 'white' }} spin />} /> 
                              </div>
                            </button>
                          </div>
                      </React.Fragment>
                    }
                </div>
            </Modal>
          </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const storeApp = state.app;
    const storeUsers = state.users;

    return {
        isLoggedUser : storeUsers.isLogged,
        user: storeUsers.user,
        cantidad_portacitos: storeApp.cantidad_portacitos,
        dataError: storeApp.dataError,
        dataErrorMessage: storeApp.dataErrorMessage,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        isLogged: redirectTo => dispatch(isLogged()),
        inputFieldChanged: (field, text) => dispatch(inputFieldChanged(field, text)),
        requestPortas: (amount, user_id) => dispatch(requestPortas(amount, user_id))
    };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Menu));



