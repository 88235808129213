import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import Menu from '../components/menu';
import Submenu from '../components/submenu';
import ResultOk from "../components/icons/resultok";
import Add from "../components/icons/add";
import User from '../components/icons/user'
import Close from '../components/icons/close'

import InputText from '../components/input';

import { Modal, Select, Checkbox } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { inputFieldChanged,
            getUser,
            saveUserData } from '../actions/user.js';
import { isLogged } from '../actions/user.js';
import { getInitials } from '../helpers/helpers.js';

import { withTranslation } from 'react-i18next';

const { confirm } = Modal;
const { Option } = Select;

class DatosCentro extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded : false,
            formError : {},
            showModal : false,
            modal : '',
            provinces : ['Álava','Albacete','Alicante','Almería','Asturias','Avila','Badajoz','Barcelona','Burgos','Cáceres',
                            'Cádiz','Cantabria','Castellón','Ciudad Real','Córdoba','La Coruña','Cuenca','Gerona','Granada','Guadalajara',
                            'Guipúzcoa','Huelva','Huesca','Islas Baleares','Jaén','León','Lérida','Lugo','Madrid','Málaga','Murcia','Navarra',
                            'Orense','Palencia','Las Palmas','Pontevedra','La Rioja','Salamanca','Segovia','Sevilla','Soria','Tarragona',
                            'Santa Cruz de Tenerife','Teruel','Toledo','Valencia','Valladolid','Vizcaya','Zamora','Zaragoza'],
        };
    }

    componentDidMount() {
        const { i18n } = this.props;

        let lang = typeof this.props.match.params.lang !== 'undefined' ? this.props.match.params.lang : null;

        if(lang === 'es' || lang === 'en'){ 
            i18n.changeLanguage(lang) 
        }

        this._getApiData()
    }

    _getApiData(){
        Promise.resolve(this.props.isLogged())
        .then((response)=> {
            if(this.props.isLoggedUser){
                
                Promise.resolve(this.props.getUser(this.props.user._id))
                .then((response)=> {
                    this.setState({ isLoaded : true })
                });

            }else{
                this.props.history.push('/login')
            }
        });
    }

    _renderDatos() {
        const { t } = this.props;

        return(
            <React.Fragment>
                <div className={'row pt-2'}>
                    <div className={'col-12'}>
                        <h2 className={'fs-12 poppins purple font-weight-normal mb-3'}>{t('DATOS DEL CENTRO')}</h2>
                    </div>
                </div>

                <div className={'row d-flex border rounded mb-5 mx-0 px-2 bg-white'}>
                    <div className={'col-12 mt-4'}>
                        <InputText
                            id={'address'}
                            name={'address'}
                            placeholder={t('Dirección')}
                            classes={'w-100 mb-3'}
                            label={t('Dirección')}
                            value={this.props.address}
                            onChange={this.props.inputFieldChanged.bind(this)}
                            error={this.state.formError.address }
                            />
                    </div>

                    <div className={'col-12 col-md-3 mt-2'}>
                        <InputText
                            id={'address_number'}
                            name={'address_number'}
                            placeholder={t('Nº')}
                            classes={'w-100 mb-3'}
                            label={t('Número')}
                            value={this.props.address_number}
                            onChange={this.props.inputFieldChanged.bind(this)}
                            error={this.state.formError.address_number }
                            />
                    </div>

                    <div className={'col-12 col-md-3 mt-2'}>
                        <InputText
                            id={'address_floor'}
                            name={'address_floor'}
                            placeholder={t('Planta')}
                            classes={'w-100 mb-3'}
                            label={t('Planta/Piso')}
                            value={this.props.address_floor}
                            onChange={this.props.inputFieldChanged.bind(this)}
                            error={this.state.formError.address_floor }
                            />
                    </div>

                    <div className={'col-12 col-md-6 mt-2'}>
                        <InputText
                            id={'address_other'}
                            name={'address_other'}
                            placeholder={t('Ej. Centro comercial')}
                            classes={'w-100 mb-3'}
                            label={t('Datos adicionales')}
                            value={this.props.address_other}
                            onChange={this.props.inputFieldChanged.bind(this)}
                            error={this.state.formError.address_other }
                            />
                    </div>

                    <div className={'col-12 mt-2'}>
                        <InputText
                            id={'city'}
                            name={'city'}
                            placeholder={t('Ciudad')}
                            classes={'w-100 mb-3'}
                            label={t('Ciudad')}
                            value={this.props.city}
                            onChange={this.props.inputFieldChanged.bind(this)}
                            error={this.state.formError.city }
                            />
                    </div>

                    <div className={'col-12 col-md-6 mt-2'}>
                        <InputText
                            id={'zipcode'}
                            name={'zipcode'}
                            placeholder={t('Código postal')}
                            classes={'w-100 mb-3'}
                            label={t('Código postal')}
                            value={this.props.zipcode}
                            onChange={this.props.inputFieldChanged.bind(this)}
                            error={this.state.formError.zipcode }
                            />
                    </div>

                    <div className={'col-12 col-md-6 mt-2'}>
                        <Select 
                        id={'province'}
                        placeholder={t('Provincia')} 
                        className={'w-100 mb-3'}
                        value={this.props.province}
                        onChange={this.props.inputFieldChanged.bind(this, 'province')}>

                            {typeof this.state.provinces !== 'undefined' ? 
                                Object.entries(this.state.provinces).map(([key, province]) => {
                                    return(
                                        <Option key={key} value={province}>{province}</Option>
                                    );
                                })
                            : null}

                        </Select>
                    </div>

                    <div className={'col-12 col-md-6 mt-2'}>
                        <InputText
                            id={'cif'}
                            name={'cif'}
                            placeholder={t('CIF/NIE')}
                            classes={'w-100 mb-3'}
                            label={t('CIF/NIE')}
                            value={this.props.cif}
                            onChange={this.props.inputFieldChanged.bind(this)}
                            error={this.state.formError.cif }
                            />
                    </div>

                    <div className={'col-12 col-md-6 mt-2'}>
                        <InputText
                            id={'phone'}
                            name={'phone'}
                            placeholder={t('Teléfono')}
                            classes={'w-100 mb-3'}
                            label={t('Teléfono')}
                            value={this.props.phone}
                            onChange={this.props.inputFieldChanged.bind(this)}
                            error={this.state.formError.phone }
                            />
                    </div>

                    <div className={'col-12 mt-2'}>
                        <InputText
                            id={'business'}
                            name={'business'}
                            placeholder={t('Razón Social')}
                            classes={'w-100 mb-3'}
                            label={t('Razón Social')}
                            value={this.props.business}
                            onChange={this.props.inputFieldChanged.bind(this)}
                            error={this.state.formError.business }
                            />
                    </div>

                    <div className={'col-12 mt-2'}>
                        <Checkbox 
                            id={'same_address'}
                            name={'same_address'}
                            className={'mt-2 mb-5'}
                            checked={ typeof this.props.same_address !== 'undefined' ? this.props.same_address.target.checked : false }
                            onChange={this.props.inputFieldChanged.bind(this, 'same_address')}
                            >{t('Usar a dirección del centro como direccción de facturación')}
                        </Checkbox>
                    </div>


                    { typeof this.props.same_address == 'undefined' || this.props.same_address.target.checked === false ?

                        <React.Fragment>
                            <div className={'col-12'}>
                                <h2 className={'fs-12 poppins purple font-weight-normal mb-3'}>{t('DATOS DE FACTURACIÓN')}</h2>
                            </div>

                            <div className={'col-12 mt-2'}>
                                <InputText
                                    id={'business_address'}
                                    name={'business_address'}
                                    placeholder={t('Dirección')}
                                    classes={'w-100 mb-3'}
                                    label={t('Dirección')}
                                    value={this.props.business_address}
                                    onChange={this.props.inputFieldChanged.bind(this)}
                                    error={this.state.formError.business_address }
                                    />
                            </div>

                            <div className={'col-12 col-md-3 mt-2'}>
                                <InputText
                                    id={'business_address_number'}
                                    name={'business_address_number'}
                                    placeholder={t('Nº')}
                                    classes={'w-100 mb-3'}
                                    label={t('Número')}
                                    value={this.props.business_address_number}
                                    onChange={this.props.inputFieldChanged.bind(this)}
                                    error={this.state.formError.business_address_number }
                                    />
                            </div>
                            
                            <div className={'col-12 col-md-3 mt-2'}>
                                <InputText
                                    id={'business_address_floor'}
                                    name={'business_address_floor'}
                                    placeholder={t('Planta')}
                                    classes={'w-100 mb-3'}
                                    label={t('Piso/Planta')}
                                    value={this.props.business_address_floor}
                                    onChange={this.props.inputFieldChanged.bind(this)}
                                    error={this.state.formError.business_address_floor }
                                    />
                            </div>

                            <div className={'col-12 col-md-6 mt-2'}>
                                <InputText
                                    id={'business_address_other'}
                                    name={'business_address_other'}
                                    placeholder={t('Datos adicionales')}
                                    classes={'w-100 mb-3'}
                                    label={t('Ej. Centro comercial')}
                                    value={this.props.business_address_other}
                                    onChange={this.props.inputFieldChanged.bind(this)}
                                    error={this.state.formError.business_address_other }
                                    />
                            </div>

                            <div className={'col-12 mt-2'}>
                                <InputText
                                    id={'business_city'}
                                    name={'business_city'}
                                    placeholder={t('Ciudad')}
                                    classes={'w-100 mb-3'}
                                    label={t('Ciudad')}
                                    value={this.props.business_city}
                                    onChange={this.props.inputFieldChanged.bind(this)}
                                    error={this.state.formError.business_city }
                                    />
                            </div>

                            <div className={'col-12 col-md-6 mt-2'}>
                                <InputText
                                    id={'business_zipcode'}
                                    name={'business_zipcode'}
                                    placeholder={t('Código postal')}
                                    classes={'w-100 mb-3'}
                                    label={t('Código postal')}
                                    value={this.props.business_zipcode}
                                    onChange={this.props.inputFieldChanged.bind(this)}
                                    error={this.state.formError.business_zipcode }
                                    />
                            </div>

                            <div className={'col-12 col-md-6 mt-2'}>
                                <Select 
                                id={'business_province'}
                                placeholder={t('Provincia')} 
                                className={'w-100 mb-3'}
                                value={this.props.business_province}
                                onChange={this.props.inputFieldChanged.bind(this, 'business_province')}>

                                    {typeof this.state.provinces !== 'undefined' ? 
                                        Object.entries(this.state.provinces).map(([key, province]) => {
                                            return(
                                                <Option key={key} value={province}>{province}</Option>
                                            );
                                        })
                                    : null}

                                </Select>
                            </div>
                        </React.Fragment>
                    
                    : null}
                </div>
            </React.Fragment>
        )
    }

    _saveDatos() {
        const { t } = this.props;
        let error = false;
        const formError = {};
        this.setState({ formError : formError })

        if (typeof this.props.address === 'undefined' || this.props.address === ''){
            formError.address = t('El dirección es obligatoria')
            this.setState({ formError : formError })
            error = true
        }

        if (typeof this.props.address_number === 'undefined' || this.props.address_number === ''){
            formError.address_number = t('Obligatorio')
            this.setState({ formError : formError })
            error = true
        }

        if (typeof this.props.city === 'undefined' || this.props.city === ''){
            formError.city = t('La ciudad es obligatoria')
            this.setState({ formError : formError })
            error = true
        }
        
        if (typeof this.props.zipcode === 'undefined' || this.props.zipcode === ''){
            formError.zipcode = t('La cod. postal es obligatorio')
            this.setState({ formError : formError })
            error = true
        }

        if (typeof this.props.province === 'undefined' || this.props.province === ''){
            formError.province = t('La provincia es obligatoria')
            this.setState({ formError : formError })
            error = true
        }

        if (typeof this.props.cif === 'undefined' || this.props.cif === ''){
            formError.cif = t('El CIF/NIE es obligatorio')
            this.setState({ formError : formError })
            error = true
        }

        if (typeof this.props.phone === 'undefined' || this.props.phone === ''){
            formError.phone = t('El teléfono es obligatorio')
            this.setState({ formError : formError })
            error = true
        }

        if (typeof this.props.business === 'undefined' || this.props.business === ''){
            formError.business = t('La Razón Social es obligatoria')
            this.setState({ formError : formError })
            error = true
        }

        if (typeof this.props.same_address === 'undefined' || typeof this.props.same_address.target === 'undefined' || this.props.same_address.target.checked === false){
            
            if (typeof this.props.business_address === 'undefined' || this.props.business_address === ''){
                formError.business_address = t('El dirección facturación es obligatoria')
                this.setState({ formError : formError })
                error = true
            }

            if (typeof this.props.business_address_number === 'undefined' || this.props.business_address_number === ''){
                formError.business_address_number = t('Obligatorio')
                this.setState({ formError : formError })
                error = true
            }

            if (typeof this.props.business_city === 'undefined' || this.props.business_city === ''){
                formError.business_city = t('La ciudad de facturación es obligatoria')
                this.setState({ formError : formError })
                error = true
            }
            
            if (typeof this.props.business_zipcode === 'undefined' || this.props.business_zipcode === ''){
                formError.business_zipcode = t('La cod. postal es obligatorio')
                this.setState({ formError : formError })
                error = true
            }

            if (typeof this.props.business_province === 'undefined' || this.props.business_province === ''){
                formError.business_province = t('La provincia de facturación es obligatoria')
                this.setState({ formError : formError })
                error = true
            }

        }

        if(error === false){
            this.setState({ isLoaded : false })

            Promise.resolve(this.props.saveUserData(
                this.props.address,
                this.props.address_number,
                this.props.address_floor,
                this.props.address_other,
                this.props.city,
                this.props.zipcode,
                this.props.province,
                this.props.cif,
                this.props.phone,
                this.props.business,
                this.props.business_address,
                this.props.business_address_number,
                this.props.business_address_floor,
                this.props.business_address_other,
                this.props.business_city,
                this.props.business_zipcode,
                this.props.business_province,
                this.props.invoice_contacts,
                this.props.report_contacts,
                this.props.comunication_contacts
            ))
            .then((response)=> {
                if(this.props.dataError === false){
                    this.props.inputFieldChanged('invoice_contact', [])
                    this.props.inputFieldChanged('report_contact', [])
                    this.props.inputFieldChanged('comunication_contact', [])
                    this._getApiData()
                }

                this.setState({ isLoaded : true })
            });
        }
    }

    _renderContactos() {
        const { t } = this.props;

        return(
            <React.Fragment>
                <div className={'row pt-2'}>
                    <div className={'col-12'}>
                        <h2 className={'fs-12 poppins purple font-weight-normal mb-4'}>{t('CONTACTOS DE FACTURACIÓN')}</h2>
                    </div>
                </div>

                <div className={'row d-flex border rounded mb-5 mx-0 px-2 py-4 bg-white'}>
                    <div className={'col-12'}>
                        <InputText
                            id={'invoice_contact'}
                            name={'invoice_contact'}
                            value={this.props.invoice_contact}
                            placeholder={t('Agrega el correo aquí y pulsa enter')}
                            classes={'w-100 mb-1'}
                            label={t('Email')}
                            onChange={this.props.inputFieldChanged.bind(this)}
                            onKeyPress={event => { this._addContact(event, 'facturacion') } }
                            error={this.state.formError.invoice_contact }
                            />

                        <p className={'poppins purple font-weight-normal mb-4'}>{t('Pulsa enter para incluir el email')}</p>

                        { typeof this.props.invoice_contacts !== 'undefined' && this.props.invoice_contacts !== 'undefined' && this.props.invoice_contacts !== '' ? 

                            this.props.invoice_contacts.map((email, key) =>{
                                return(
                                    <React.Fragment key={key}>
                                        <div className={'row align-items-center border-bottom mx-0 py-3'}>
                                            <div className={'col-2 text-center'}>
                                                <User />
                                            </div>

                                            <div className={'col-9'}>
                                                { email }
                                            </div>

                                            <div className={'col-1'}>
                                                <a onClick={() => { this._removeContact('facturacion', key) } }>
                                                    <Close />
                                                </a>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            })

                        : null}
                    </div>
                </div>

                <div className={'row pt-2'}>
                    <div className={'col-12'}>
                        <h2 className={'fs-12 poppins purple font-weight-normal mb-4'}>{t('CONTACTOS DE RECEPCIÓN DE INFORMES')}</h2>
                    </div>
                </div>

                <div className={'row d-flex border rounded mb-5 mx-0 px-2 py-4 bg-white'}>
                    <div className={'col-12'}>
                        <InputText
                            id={'report_contact'}
                            name={'report_contact'}
                            value={this.props.report_contact}
                            placeholder={t('Agrega el correo aquí y pulsa enter')}
                            classes={'w-100 mb-1'}
                            label={t('Email')}
                            onChange={this.props.inputFieldChanged.bind(this)}
                            onKeyPress={event => { this._addContact(event, 'reportes') } }
                            error={this.state.formError.report_contact }
                            />

                        <p className={'poppins purple font-weight-normal mb-4'}>{t('Pulsa enter para incluir el email')}</p>

                        { typeof this.props.report_contacts !== 'undefined' && this.props.report_contacts !== 'undefined' && this.props.report_contacts !== '' ? 

                            this.props.report_contacts.map((email, key) =>{
                                return(
                                    <React.Fragment key={key}>
                                        <div className={'row align-items-center border-bottom mx-0 py-3'}>
                                            <div className={'col-2 text-center'}>
                                                <User />
                                            </div>

                                            <div className={'col-9'}>
                                                { email }
                                            </div>

                                            <div className={'col-1'}>
                                                <a onClick={() => { this._removeContact('reportes', key) } }>
                                                    <Close />
                                                </a>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            })

                        : null}
                    </div>
                </div>

                <div className={'row pt-2'}>
                    <div className={'col-12'}>
                        <h2 className={'fs-12 poppins purple font-weight-normal mb-4'}>{t('CONTACTOS DE COMUNICACIÓN')}</h2>
                    </div>
                </div>

                <div className={'row d-flex border rounded mb-5 mx-0 px-2 py-4 bg-white'}>
                    <div className={'col-12'}>
                        <InputText
                            id={'comunication_contact'}
                            name={'comunication_contact'}
                            value={this.props.comunication_contact}
                            placeholder={t('Agrega el correo aquí y pulsa enter')}
                            classes={'w-100 mb-1'}
                            label={t('Email')}
                            onChange={this.props.inputFieldChanged.bind(this)}
                            onKeyPress={event => { this._addContact(event, 'comunicaciones') } }
                            error={this.state.formError.comunication_contact }
                            />

                        <p className={'poppins purple font-weight-normal mb-4'}>{t('Pulsa enter para incluir el email')}</p>

                        { typeof this.props.comunication_contacts !== 'undefined' && this.props.comunication_contacts !== 'undefined' && this.props.comunication_contacts !== '' ?

                            this.props.comunication_contacts.map((email, key) =>{
                                return(
                                    <React.Fragment key={key}>
                                        <div className={'row align-items-center border-bottom mx-0 py-3'}>
                                            <div className={'col-2 text-center'}>
                                                <User />
                                            </div>

                                            <div className={'col-9'}>
                                                { email }
                                            </div>

                                            <div className={'col-1'}>
                                                <a onClick={() => { this._removeContact('comunicaciones', key) } }>
                                                    <Close />
                                                </a>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            })

                        : null}
                    </div>
                </div>

                { typeof this.props.dataError != 'undefined' && this.props.dataError === true ?

                    <p className={'red fs-09 mt-3 pl-4'}>{ this.props.dataErrorMessage }</p>

                : null }

                <div className={'col-12 mt-2 mb-4'}>
                    <button role={'button'} className={'btn-purple'} onClick={this._saveDatos.bind(this)}>{t('Guardar')}</button>
                </div>
            </React.Fragment>
        )
    }

    _addContact(event, type){
        const { t } = this.props;
        let error = false;
        const formError = {};
        this.setState({ formError : formError })

        if (event.key === 'Enter') {
            if(type === 'facturacion'){
                const invoice_contacts = typeof this.props.invoice_contacts === 'undefined' ? [] : this.props.invoice_contacts
                
                if (typeof this.props.invoice_contact === 'undefined' || this.props.invoice_contact === ''){
                    formError.invoice_contact = t('El email es obligatorio')
                    this.setState({ formError : formError })
                    error = true
                }else{
                    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                    if (this.props.invoice_contact.match(pattern) === null) {
                        formError.invoice_contact = t('El email introducido no es válido')
                        this.setState({ formError : formError })
                        error = true
                    }
                }

                if(invoice_contacts.indexOf(this.props.invoice_contact) !== -1){
                    formError.invoice_contact = t('Este email ya existe como contacto')
                    this.setState({ formError : formError })
                    error = true
                }

                if (error === false) {
                    invoice_contacts.push(this.props.invoice_contact)
                    this.props.inputFieldChanged('invoice_contact', '')
                    this.props.inputFieldChanged('invoice_contacts', invoice_contacts)
                }
            }

            if(type === 'reportes'){
                const report_contacts = typeof this.props.report_contacts === 'undefined' ? [] : this.props.report_contacts
                
                if (typeof this.props.report_contact === 'undefined' || this.props.report_contact === ''){
                    formError.report_contact = t('El email es obligatorio')
                    this.setState({ formError : formError })
                    error = true
                }else{
                    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                    if (this.props.report_contact.match(pattern) === null) {
                        formError.report_contact = t('El email introducido no es válido')
                        this.setState({ formError : formError })
                        error = true
                    }
                }

                if(report_contacts.indexOf(this.props.report_contact) !== -1){
                    formError.report_contact = t('Este email ya existe como contacto')
                    this.setState({ formError : formError })
                    error = true
                }

                if (error === false) {
                    report_contacts.push(this.props.report_contact)
                    this.props.inputFieldChanged('report_contact', '')
                    this.props.inputFieldChanged('report_contacts', report_contacts)
                }
            }

            if(type === 'comunicaciones'){
                const comunication_contacts = typeof this.props.comunication_contacts === 'undefined' ? [] : this.props.comunication_contacts
                
                if (typeof this.props.comunication_contact === 'undefined' || this.props.comunication_contact === ''){
                    formError.comunication_contact = t('El email es obligatorio')
                    this.setState({ formError : formError })
                    error = true
                }else{
                    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                    if (this.props.comunication_contact.match(pattern) === null) {
                        formError.comunication_contact = t('El email introducido no es válido')
                        this.setState({ formError : formError })
                        error = true
                    }
                }

                if(comunication_contacts.indexOf(this.props.comunication_contact) !== -1){
                    formError.comunication_contact = t('Este email ya existe como contacto')
                    this.setState({ formError : formError })
                    error = true
                }

                if (error === false) {
                    comunication_contacts.push(this.props.comunication_contact)
                    this.props.inputFieldChanged('comunication_contact', '')
                    this.props.inputFieldChanged('comunication_contacts', comunication_contacts)
                }
            }
        }
    }

    _removeContact(type, key){
        if(type === 'facturacion'){
            const invoice_contacts = typeof this.props.invoice_contacts === 'undefined' ? [] : this.props.invoice_contacts
            invoice_contacts.splice(key, 1)
            this.props.inputFieldChanged('invoice_contacts', invoice_contacts)
        }

        if(type === 'reportes'){
            const report_contacts = typeof this.props.report_contacts === 'undefined' ? [] : this.props.report_contacts
            report_contacts.splice(key, 1)
            this.props.inputFieldChanged('report_contacts', report_contacts)
        }

        if(type === 'comunicaciones'){
            const comunication_contacts = typeof this.props.comunication_contacts === 'undefined' ? [] : this.props.comunication_contacts
            comunication_contacts.splice(key, 1)
            this.props.inputFieldChanged('comunication_contacts', comunication_contacts)
        }
    }

    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <div className={"split-container"}>
                    <Menu history={this.props.history} />
                    <div className={"container coachee p-0"}>
                        <div className={"row m-0"}>
                            <Submenu />

                            <div className={"col-10 py-4 px-4"}>
                                { this.state.isLoaded ? this._renderDatos() : null }

                                { this.state.isLoaded ? this._renderContactos() : null }
                            </div>
                        </div>
                    </div>
                </div>

                <Modal visible={this.state.showModal} footer={null} onCancel={ ()=>{ this.setState({ showModal : false }) } } >
                    <div className={'row mt-3'}>
                        
                        { this.state.modal === 'profile' ?
                            <React.Fragment>
                                <div className={'col-12 text-center'}>
                                    <ResultOk />
                                    <p className={'poppins text-uppercase font-weight-bold fs-13 my-2 px-4'}>{t('PERFIL ACTUALIZADO')}</p>
                                </div>
                                
                                <div className={'col-12 text-center'}>
                                    <p className={'gray07 fs-12 lh-12 my-3 px-4'}>{t('Los datos de tu perfil se han guardado correctamente.')}</p>
                                </div>
                            </React.Fragment>
                        : null }

                        { this.state.modal === 'sepa' ?
                            <React.Fragment>
                                <div className={'col-12 text-center'}>
                                    <ResultOk />
                                    <p className={'poppins text-uppercase font-weight-bold fs-13 my-2 px-4'}>{t('DOCUMENTO SEPA GUARDADO')}</p>
                                </div>
                                
                                <div className={'col-12 text-center'}>
                                    <p className={'gray07 fs-12 lh-12 my-3 px-4'}>{t('El documento SEPA proporcionado se ha guardado correctamente.')}</p>
                                </div>
                            </React.Fragment>
                        : null }

                        { this.state.modal === 'password' ?
                            <React.Fragment>
                                <div className={'col-12 text-center'}>
                                    <ResultOk />
                                    <p className={'poppins text-uppercase font-weight-bold fs-13 my-2 px-4'}>{t('CONTRASEÑA ACTUALIZADA')}</p>
                                </div>
                                
                                <div className={'col-12 text-center'}>
                                    <p className={'gray07 fs-12 lh-12 my-3 px-4'}>{t('La nueva contraseña se ha guardado correctamente.')}</p>
                                </div>
                            </React.Fragment>
                        : null }
                    </div>
                </Modal>
            </React.Fragment>
        )
    }

}

function mapStateToProps(state) {
    const storeUsers = state.users;
    const storeMuestras = state.muestras;

    return {
        isLoggedUser : storeUsers.isLogged,
        user : storeUsers.user,
        address: storeUsers.address,
        address_number: storeUsers.address_number,
        address_floor: storeUsers.address_floor,
        address_other: storeUsers.address_other,
        city: storeUsers.city,
        zipcode: storeUsers.zipcode,
        province: storeUsers.province,
        cif: storeUsers.cif,
        phone: storeUsers.phone,
        business: storeUsers.business,
        business_address: storeUsers.business_address,
        business_address_number: storeUsers.business_address_number,
        business_address_floor: storeUsers.business_address_floor,
        business_address_other: storeUsers.business_address_other,
        business_city: storeUsers.business_city,
        business_zipcode: storeUsers.business_zipcode,
        business_province: storeUsers.business_province,
        same_address: storeUsers.same_address,
        invoice_contact: storeUsers.invoice_contact, /* form value */
        report_contact: storeUsers.report_contact, /* form value */
        comunication_contact: storeUsers.comunication_contact, /* form value */
        invoice_contacts: storeUsers.invoice_contacts, /* array of contacts */
        report_contacts: storeUsers.report_contacts, /* array of contacts */
        comunication_contacts: storeUsers.comunication_contacts, /* array of contacts */
        dataError : storeUsers.dataError,
        dataErrorMessage : storeUsers.dataErrorMessage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        isLogged: redirectTo => dispatch(isLogged()),
        inputFieldChanged: (field, text) => dispatch(inputFieldChanged(field, text)),
        getUser: (id) => dispatch(getUser(id)),
        saveUserData: (address, address_number, address_floor, address_other, city, zipcode, province, cif, phone, business, business_address, business_address_number, business_address_floor, business_address_other, business_city, business_zipcode, business_province, invoice_contacts, report_contacts, comunication_contacts) => dispatch(saveUserData(address, address_number, address_floor, address_other, city, zipcode, province, cif, phone, business, business_address, business_address_number, business_address_floor, business_address_other, business_city, business_zipcode, business_province, invoice_contacts, report_contacts, comunication_contacts)),
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(DatosCentro));



