import { types } from '../constants/constants';
import { push } from 'react-router-redux';

/** Método que almacena en el store de Redux los inputs que
    el usuario va rellenando, para garantizar la persistencia
    de los datos.
    @param string $field el nombre del input
    @param string $value el valor del input
**/
export function inputFieldChanged(field, value) {
    return function(dispatch) {
        dispatch({ type: "SETTINGS_INPUT_FIELD_CHANGED", payload: {field : field, value : value} });
    }
}

/** Método que manda a la API los datos para crear
    una nueva reply
**/
export function saveReply(title, tarifa, comment_replies, description_replies) {
    return async function(dispatch) {
        try
        {
            var formBody = [];
            
            var details={ 
                title : title,
                tarifa : tarifa,
                comment_replies : comment_replies,
                description_replies: description_replies,
            };

            for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(details[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
            }

            var status = null;
            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'reply/create', {
                method: 'post',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    "token": token
                },
                body: formBody.join("&"),
            })
            .then(function(response) {
                status = response.status; // Get HTTP status code
                return response.json();
            })
            .then((responseJson, response) => {
                if(status === 200){ // If success
                    dispatch({ 
                        type: "CREATE_REPLY", 
                        payload: { 
                            reply : responseJson.reply
                        }
                    });
                }else{
                    if(status === 401){ 
                        push('/') 
                    }else{
                        dispatch({ type: "CREATE_REPLY_ERROR", payload: { message : responseJson } });
                    }
                }
            })
            .catch((error) => {
                let errorMessage = 'Hubo un problema conectando con la API: ' + error;
                dispatch({ type: "CREATE_REPLY_ERROR", payload: { message : errorMessage } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que manda a la API los datos para editar
	una reply
**/
export function updateReply(id, title, tarifa, comment_replies, description_replies) {
    return async function(dispatch) {
        try
        {
            var formBody = [];
            
            var details={ 
                id : id,
                title : title,
                tarifa : tarifa,
                comment_replies : comment_replies,
                description_replies: description_replies,
            };

            for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(details[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
            }

            var status = null;
            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'reply/update', {
                method: 'post',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    "token": token
                },
                body: formBody.join("&"),
            })
            .then(function(response) {
                status = response.status; // Get HTTP status code
                return response.json();
            })
            .then((responseJson, response) => {
                if(status === 200){ // If success
                	dispatch({ 
                        type: "UPDATE_REPLY", 
                        payload: { 
                            reply : responseJson.reply
                        }
                    });
                }else{
                    if(status === 401){ 
                        push('/') 
                    }else{
                        dispatch({ type: "UPDATE_REPLY_ERROR", payload: { message : responseJson } });
                    }
                }
            })
            .catch((error) => {
                let errorMessage = 'Hubo un problema conectando con la API: ' + error;
                dispatch({ type: "UPDATE_REPLY_ERROR", payload: { message : errorMessage } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que obtiene el listado de todas las replies
    desde la API
**/
export function getReplies(id=null) {
    return async function(dispatch) {
        try
        {
            let url = ''

            if(id === null){
                url = types.API_URL + 'reply/list'
            }else{
                url = types.API_URL + 'reply/' + id + '/list'
            }

            var status = null;
            const token = localStorage.getItem('token');

            return fetch(url, {
                method: 'get',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    "token": token
                }
            })
            .then(function(response) {
                status = response.status; // Get HTTP status code
                return response.json();
            })
            .then((responseJson, response) => {
                if(status === 200){ // If success
                    dispatch({ 
                        type: "REPLY_LIST", 
                        payload: responseJson
                    });
                }else{
                    if(status === 401){ 
                        push('/') 
                    }else{
                        dispatch({ type: "REPLY_LIST_ERROR", payload: { message : responseJson } });
                    }
                }
            })
            .catch((error) => {
                let errorMessage = 'Hubo un problema conectando con la API: ' + error;
                dispatch({ type: "REPLY_LIST_ERROR", payload: { message : errorMessage } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que obtiene el listado de todas las replies
    desde la API
**/
export function getRepliesByReport(report_id) {
    return async function(dispatch) {
        try
        {
            let url = types.API_URL + 'reply-by-report/' + report_id

            var status = null;
            const token = localStorage.getItem('token');

            return fetch(url, {
                method: 'get',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    "token": token
                }
            })
            .then(function(response) {
                status = response.status; // Get HTTP status code
                return response.json();
            })
            .then((responseJson, response) => {
                if(status === 200){ // If success
                    dispatch({ 
                        type: "REPLY_LIST_BY_REPORT", 
                        payload: responseJson
                    });
                }else{
                    if(status === 401){ 
                        push('/') 
                    }else{
                        dispatch({ type: "REPLY_LIST_BY_REPORT_ERROR", payload: { message : responseJson } });
                    }
                }
            })
            .catch((error) => {
                let errorMessage = 'Hubo un problema conectando con la API: ' + error;
                dispatch({ type: "REPLY_LIST_BY_REPORT_ERROR", payload: { message : errorMessage } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que obtiene una reply concreta por ID dado
    desde la API
**/
export function getReply(id) {
    return async function(dispatch) {
        try
        {
            let url = types.API_URL + 'reply/' + id

            var status = null;
            const token = localStorage.getItem('token');

            return fetch(url, {
                method: 'get',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    "token": token
                }
            })
            .then(function(response) {
                status = response.status; // Get HTTP status code
                return response.json();
            })
            .then((responseJson, response) => {
                if(status === 200){ // If success
                    dispatch({ 
                        type: "GET_REPLY", 
                        payload: responseJson
                    });
                }else{
                    if(status === 401){ 
                        push('/') 
                    }else{
                        dispatch({ type: "GET_REPLY_ERROR", payload: { message : responseJson } });
                    }
                }
            })
            .catch((error) => {
                let errorMessage = 'Hubo un problema conectando con la API: ' + error;
                dispatch({ type: "GET_REPLY_ERROR", payload: { message : errorMessage } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que manda a la API el id para eliminar
    una reply
**/
export function deleteReply(id) {
    return async function(dispatch) {
        try
        {
            var formBody = [];
            
            var details={ 
                id : id
            };

            for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(details[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
            }

            var status = null;
            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'reply/delete', {
                method: 'post',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    "token": token
                },
                body: formBody.join("&"),
            })
            .then(function(response) {
                status = response.status; // Get HTTP status code
                return response.json();
            })
            .then((responseJson, response) => {
                if(status === 200){ // If success
                    dispatch({ 
                        type: "DELETE_REPLY", 
                        payload: {}
                    });
                }else{
                    if(status === 401){ 
                        push('/') 
                    }else{
                        dispatch({ type: "DELETE_REPLY_ERROR", payload: { message : responseJson } });
                    }
                }
            })
            .catch((error) => {
                let errorMessage = 'Hubo un problema conectando con la API: ' + error;
                dispatch({ type: "DELETE_REPLY_ERROR", payload: { message : errorMessage } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}