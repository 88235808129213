import { types } from '../constants/constants';
import { push } from 'react-router-redux';

/** Método para limpiar la store
**/
export function cleanStore() {
    return function(dispatch) {
        dispatch({ type: "CLEAN_STORE", payload: '' });
    }
}

/** Método que almacena en el store de Redux los inputs que
    el usuario va rellenando, para garantizar la persistencia
    de los datos.
    @param string $field el nombre del input
    @param string $value el valor del input
**/
export function inputFieldChanged(field, value) {
    return function(dispatch) {
        dispatch({ type: "MUESTRA_INPUT_FIELD_CHANGED", payload: {field : field, value : value} });
    }
}

/** Método que manda a la API los datos para crear
    una nueva muestra
**/
export function saveMuestra(
    contact_person,
    animal_name,
    animal_history,
    animal_species,
    animal_race,
    animal_gender,
    animal_age,
    animal_owner,
    priority,
    cytology,
    cytology_number,
    fluid,
    fluid_origin,
    fluid_type,
    other,
    other_details,
    requested_test,
    requested_test_extra,
    aditional_location,
    previous_report,
    clinical_history,
    aditional_tests,
    differential_diagnoses,
    injury_location,
    user_id=''
    ) {
    return async function(dispatch) {
        try
        {
            var formBody = [];
                        
            var details={ 
                contact_person : contact_person,
                animal_name : animal_name,
                animal_history: animal_history,
                animal_species : animal_species,
                animal_race : animal_race,
                animal_gender : animal_gender,
                animal_age : animal_age,
                animal_owner : animal_owner,
                priority : priority,
                cytology : cytology,
                cytology_number : cytology_number,
                fluid : fluid,
                fluid_origin : fluid_origin,
                fluid_type : fluid_type,
                other : other,
                other_details : other_details,
                requested_test : requested_test,
                requested_test_extra : requested_test_extra,
                aditional_location : aditional_location,
                previous_report : previous_report,
                clinical_history : clinical_history,
                aditional_tests : aditional_tests,
                differential_diagnoses : differential_diagnoses,
                injury_location : JSON.stringify(injury_location),
                user_id : user_id
            };

            for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(details[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
            }

            var status = null;
            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'muestra/create', {
                method: 'post',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    "token": token
                },
                body: formBody.join("&"),
            })
            .then(function(response) {
                status = response.status; // Get HTTP status code
                return response.json();
            })
            .then((responseJson, response) => {
                if(status === 200){ // If success
                    dispatch({ 
                        type: "CREATE_MUESTRA", 
                        payload: { 
                            muestra : responseJson.muestra
                        }
                    });
                }else{
                    if(status === 401){ 
                        push('/') 
                    }else{
                        dispatch({ type: "CREATE_MUESTRA_ERROR", payload: { message : responseJson } });
                    }
                }
            })
            .catch((error) => {
                let errorMessage = 'Hubo un problema conectando con la API: ' + error;
                dispatch({ type: "CREATE_MUESTRA_ERROR", payload: { message : errorMessage } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que manda a la API los datos para crear
	una nueva muestra
**/
export function saveMuestraAdmin(
	contact_person,
    animal_name,
    animal_history,
    animal_species,
    animal_race,
    animal_gender,
    animal_age,
    animal_owner,
    priority,
    cytology,
    cytology_number,
    fluid,
    fluid_origin,
    fluid_type,
    other,
    other_details,
    requested_test,
    requested_test_extra,
    previous_report,
    clinical_history,
    aditional_tests,
    differential_diagnoses,
    injury_location,
    user_id=''
	) {
    return async function(dispatch) {
        try
        {
            var formBody = [];
                        
            var details={ 
                contact_person : contact_person,
                animal_name : animal_name,
                animal_history: animal_history,
                animal_species : animal_species,
                animal_race : animal_race,
                animal_gender : animal_gender,
                animal_age : animal_age,
                animal_owner : animal_owner,
                priority : priority,
                cytology : cytology,
                cytology_number : cytology_number,
                fluid : fluid,
                fluid_origin : fluid_origin,
                fluid_type : fluid_type,
                other : other,
                other_details : other_details,
                requested_test : requested_test.target.id,
                requested_test_extra : requested_test_extra,
                previous_report : previous_report,
                clinical_history : clinical_history,
                aditional_tests : aditional_tests,
                differential_diagnoses : differential_diagnoses,
                injury_location : JSON.stringify(injury_location),
                user_id : user_id
            };

            for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(details[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
            }

            var status = null;
            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'muestra/create-admin', {
                method: 'post',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    "token": token
                },
                body: formBody.join("&"),
            })
            .then(function(response) {
                status = response.status; // Get HTTP status code
                return response.json();
            })
            .then((responseJson, response) => {
                if(status === 200){ // If success
                	dispatch({ 
                        type: "CREATE_MUESTRA", 
                        payload: { 
                            muestra : responseJson.muestra
                        }
                    });
                }else{
                    if(status === 401){ 
                        push('/') 
                    }else{
                        dispatch({ type: "CREATE_MUESTRA_ERROR", payload: { message : responseJson } });
                    }
                }
            })
            .catch((error) => {
                let errorMessage = 'Hubo un problema conectando con la API: ' + error;
                dispatch({ type: "CREATE_MUESTRA_ERROR", payload: { message : errorMessage } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que obtiene el listado de todas las muestras
    desde la API
**/
export function getMuestras() {
    return async function(dispatch) {
        try
        {
            let url = types.API_URL + 'muestra/list'

            var status = null;
            const token = localStorage.getItem('token');

            return fetch(url, {
                method: 'get',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    "token": token
                }
            })
            .then(function(response) {
                status = response.status; // Get HTTP status code
                return response.json();
            })
            .then((responseJson, response) => {
                if(status === 200){ // If success
                    dispatch({ 
                        type: "MUESTRA_LIST", 
                        payload: responseJson
                    });
                }else{
                    if(status === 401){ 
                        push('/') 
                    }else{
                        dispatch({ type: "MUESTRA_LIST_ERROR", payload: { message : responseJson } });
                    }
                }
            })
            .catch((error) => {
                let errorMessage = 'Hubo un problema conectando con la API: ' + error;
                dispatch({ type: "MUESTRA_LIST_ERROR", payload: { message : errorMessage } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que obtiene una muestra concreta por ID dado
    desde la API
**/
export function getMuestra(id) {
    return async function(dispatch) {
        try
        {
            let url = types.API_URL + 'muestra/' + id

            var status = null;
            const token = localStorage.getItem('token');

            return fetch(url, {
                method: 'get',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    "token": token
                }
            })
            .then(function(response) {
                status = response.status; // Get HTTP status code
                return response.json();
            })
            .then((responseJson, response) => {
                if(status === 200){ // If success
                    dispatch({ 
                        type: "GET_MUESTRA", 
                        payload: responseJson
                    });
                }else{
                    if(status === 401){ 
                        push('/') 
                    }else{
                        dispatch({ type: "GET_MUESTRA_ERROR", payload: { message : responseJson } });
                    }
                }
            })
            .catch((error) => {
                let errorMessage = 'Hubo un problema conectando con la API: ' + error;
                dispatch({ type: "GET_MUESTRA_ERROR", payload: { message : errorMessage } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que obtiene el listado de muestras de un usuario
    desde la API
**/
export function getUserMuestras(statusMuestra='', userId='') {
    return async function(dispatch) {
        try
        {
            let url = types.API_URL + 'muestra/user-list'
            
            if(typeof statusMuestra !== 'undefined' && statusMuestra !== '')
                url = url + '?status=' + statusMuestra

            if(typeof userId !== 'undefined' && userId !== '')
                url = url + '?userid=' + userId

            var status = null;
            const token = localStorage.getItem('token');

            return fetch(url, {
                method: 'get',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    "token": token
                }
            })
            .then(function(response) {
                status = response.status; // Get HTTP status code
                return response.json();
            })
            .then((responseJson, response) => {
                if(status === 200){ // If success
                    dispatch({ 
                        type: "USER_MUESTRA_LIST", 
                        payload: responseJson
                    });
                }else{
                    if(status === 401){ 
                        push('/') 
                    }else{
                        dispatch({ type: "USER_MUESTRA_LIST_ERROR", payload: { message : responseJson } });
                    }
                }
            })
            .catch((error) => {
                let errorMessage = 'Hubo un problema conectando con la API: ' + error;
                dispatch({ type: "USER_MUESTRA_LIST_ERROR", payload: { message : errorMessage } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que manda a la API el id para eliminar
    una muestra
**/
export function deleteMuestra(id) {
    return async function(dispatch) {
        try
        {
            var formBody = [];
            
            var details={ 
                id : id
            };

            for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(details[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
            }

            var status = null;
            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'muestra/delete', {
                method: 'post',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    "token": token
                },
                body: formBody.join("&"),
            })
            .then(function(response) {
                status = response.status; // Get HTTP status code
                return response.json();
            })
            .then((responseJson, response) => {
                if(status === 200){ // If success
                    dispatch({ 
                        type: "DELETE_MUESTRA", 
                        payload: {}
                    });
                }else{
                    if(status === 401){ 
                        push('/') 
                    }else{
                        dispatch({ type: "DELETE_MUESTRA_ERROR", payload: { message : responseJson } });
                    }
                }
            })
            .catch((error) => {
                let errorMessage = 'Hubo un problema conectando con la API: ' + error;
                dispatch({ type: "DELETE_MUESTRA_ERROR", payload: { message : errorMessage } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}