import React from "react"

const Close = (props) => {
  return (
    <svg width="10px" height="11px" viewBox="0 0 10 11" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
	    <title>113617DA-4235-4CBD-ADA2-A8AA87BF2EF2</title>
	    <desc>Created with sketchtool.</desc>
	    <g id="Acceso" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
	        <g id="registro-/-step4" transform="translate(-1000.000000, -461.000000)" fill="#9B9B9B">
	            <g id="Facturacion" transform="translate(416.000000, 343.000000)">
	                <g id="Contacto-añadido" transform="translate(0.000000, 104.000000)">
	                    <g id="icon-/-perfil-/-descarga-copy" transform="translate(584.000000, 14.000000)">
	                        <path d="M5,4.29581042 L8.57142857,0.615819637 L9.85996388,1.94649534 L6.28997759,5.625 L9.85996388,9.30350466 L8.57142857,10.6341804 L5,6.95418958 L1.42857143,10.6341804 L0.140036123,9.30350466 L3.71002241,5.625 L0.140036123,1.94649534 L1.42857143,0.615819637 L5,4.29581042 Z" id="Combined-Shape"></path>
	                    </g>
	                </g>
	            </g>
	        </g>
	    </g>
	</svg>
  )
}

export default Close;