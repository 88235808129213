import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import Menu from '../components/menu';
import CustomTable from '../components/table';
import MuestrasIcon from "../components/icons/muestras";
import RecogidasIcon from "../components/icons/recogidas";
import deleteIcon from '../assets/img/delete-icon.svg';

import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { getUserMuestras,
            deleteMuestra } from '../actions/muestra.js';
import { isLogged } from '../actions/user.js';

import { withTranslation } from 'react-i18next';

const { confirm } = Modal;

class Muestras extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded : true,
            dataSource : []
        };
    }

    componentDidMount() {
        const { i18n } = this.props;

        let lang = typeof this.props.match.params.lang !== 'undefined' ? this.props.match.params.lang : null;

        if(lang === 'es' || lang === 'en'){ 
            i18n.changeLanguage(lang) 
        }

        this._getApiData()
    }

    _getApiData(){
        this.setState({ isLoaded : false})

        Promise.resolve(this.props.isLogged())
        .then((response)=> {
            if(this.props.isLoggedUser){
                
                Promise.resolve(this.props.getUserMuestras())
                .then((response)=> {
                    if(this.props.muestras){
                        let dataSource = [];

                        this.props.muestras.map((muestra, key) =>{
                            let date = new Date(muestra.date);
                            let fecha = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
                            let item = {
                                id : muestra.id,
                                lab_id : muestra.lab_id,
                                fecha : fecha,
                                tipo : muestra.title,
                                paciente : muestra.animal_name,
                                historia : muestra.animal_history,
                                estado : muestra.status
                            }

                            dataSource.push(item);
                        })

                        this.setState({ dataSource : dataSource })
                        this.setState({ isLoaded : true })
                    }
                });

            }else{
                this.props.history.push('/login')
            }
        });
    }

    _deleteMuestra(id) {
        const { t } = this.props;

        confirm({
            title: t('¿Seguro que quieres eliminar esta muestra?'),
            icon: <ExclamationCircleOutlined style={{ color: '#FF866B' }} />,
            content: t('Si la eliminas se borra definitivamente de tu listado'),
            okText: t('Si, borrar'),
            cancelText: t('No'),
            onOk : () => {

                Promise.resolve(this.props.deleteMuestra(id))
                .then((response)=> {
                    if(this.props.dataError === true){
                        alert( this.props.dataErrorMessage )
                    }

                    this._getApiData()
                });
                
            },
            onCancel() {
                
            },
        });
    }

    _renderTable() {
        const { t } = this.props;
        const { dataSource } = this.state;

        const columns = [
            {
                title: t('Fecha'),
                dataIndex: "fecha",
                key: "fecha",
                width: 160,
            },
            {
                title: t('Tipo de muestra'),
                dataIndex: "tipo",
                key: "tipo",
            },
            {
                title: "Paciente",
                dataIndex: "paciente",
                key: "paciente",
            },
            {
                title: t('Hª clínica'),
                dataIndex: "historia",
                key: "historia",
                width: 120
            },
            {
                title: t('Estado'),
                dataIndex: "estado",
                width: 190,
                key: "estado",
                render: (text, record) => (
                    <React.Fragment>
                        { record.estado === 0 ? (
                            <span className={"white-badge poppins w-100 d-inline-block"}>{t('Pendiente de recogida')}</span>
                        ) : null }

                        { record.estado === 1 ? (
                            <span className={"purple-light-badge poppins opacity-3 w-100 d-inline-block"}>{t('Enviado')}</span>
                        ) : null }

                        { record.estado === 2 ? (
                            <span className={"gray-badge w-100 poppins d-inline-block"}>{t('Finalizado')}</span>
                        ) : null }
                    </React.Fragment>
                )
            },
            {
                title: "",
                key: "herramientas",
                width: 180,
                align: "center",
                render: (text, record) => (
                    <React.Fragment>
                        <a href={'/muestra/' + record.id} className={'btn-purple w-100 lh-24'}>
                            {t('Ver muestra')}
                        </a>
                    </React.Fragment>
                ),
            },
            {/*
                title: "",
                key: "herramientas",
                width: 90,
                align: "center",
                render: (text, record) => (
                    <React.Fragment>
                        <img src={deleteIcon} role={"button"} onClick={this._deleteMuestra.bind(this, record.id)} alt={t('Borrar este registro')} title={t('Borrar')} />
                    </React.Fragment>
                ),
            */}
        ]

        const pagination = {  }

        return (
            <React.Fragment>

                <CustomTable
                    columns={columns}
                    dataSource={dataSource}
                    pagination={pagination}
                    loaded={this.state.isLoaded}
                    t={t}
                />
                                
            </React.Fragment>
        )
    }

    _renderLoader(){
        return ('')
    }

    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <div className={"split-container"}>
                    <Menu history={this.props.history} />
                    <div className={"container coachee"}>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <header className={"managementPanel__content-header"}>
                                    <div className={"col-5"}>
                                        <h1 className={"fs-14 poppins"}>{t('MUESTRAS')}</h1>
                                    </div>

                                    <div className={"col-7 text-right"}>
                                        <a href={"/nueva-muestra"} className={"btn-purple"}>
                                            <MuestrasIcon /> <span className={"pl-1"}>{t('Nueva muestra')}</span>
                                        </a>

                                        <a href={"/nueva-recogida"} className={"btn-white ml-3"}>
                                            <RecogidasIcon /> <span className={"pl-1"}>{t('Solicitar recogida')}</span>
                                        </a>
                                    </div>
                                </header>
                                
                                <section className={"coachee__content-body"}>
                                    { this._renderTable() }
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

function mapStateToProps(state) {
    const storeUsers = state.users;
    const storeMuestras = state.muestras;

    return {
        isLoggedUser : storeUsers.isLogged,
        user: storeUsers.user,
        muestras : storeMuestras.muestras,
        dataError : storeMuestras.dataError,
        dataErrorMessage : storeMuestras.dataErrorMessage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        isLogged: redirectTo => dispatch(isLogged()),
        getUserMuestras: (status) => dispatch(getUserMuestras(status)),
        deleteMuestra: (id) => dispatch(deleteMuestra(id))
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(Muestras));



