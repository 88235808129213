import React from 'react';
import { Table, Empty } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';


const CustomTable = (props) => {
  // Add hover effect
  props.columns.push(
    {
      title: "",
      key: "hoverEffect",
      width: "0px",
    }
  )
  const dataSource = props.dataSource.map(el => { el.hoverEffect = ''; return el })
  const { t } = props;
  const tableLoading = {
      spinning: !props.loaded,
      indicator: <LoadingOutlined className={'purple fs-2'} />,
      tip: <p className={'purple fs-14 poppins mt-3'}>{t('Cargando...')}</p>,
  }

  return (
    <Table
      columns={props.columns}
      pagination={props.pagination}
      dataSource={dataSource}
      loading={tableLoading}
      rowKey='id'
      locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
        <span>
          {t('No hay registros')}
        </span>
      } />,
      triggerDesc : t('Click para ordenar'),
      triggerAsc : t('Click para ordenar'),
      cancelSort : t('Click para quitar el orden'), }}
    />
  )
}


export default CustomTable;