import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import Menu from '../components/menu';
import Submenu from '../components/submenu';
import ResultOk from "../components/icons/resultok";
import Add from "../components/icons/add";
import User from '../components/icons/user'
import Close from '../components/icons/close'
import DownloadTable from '../components/icons/download-table'
import Loader from '../components/loader';

import CustomTable from '../components/table';

import InputText from '../components/input';

import { Modal, Select, Checkbox } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { inputFieldChanged,
            getUser,
            saveProfile,
            getPortacitos } from '../actions/user.js';
import { isLogged } from '../actions/user.js';
import { getInitials } from '../helpers/helpers.js';

import { withTranslation } from 'react-i18next';

const { confirm } = Modal;
const { Option } = Select;

class MisPortacitos extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded : false,
            formError : {},
            showModal : false,
            modal : '',
            dataSource : [],
        };
    }

    componentDidMount() {
        const { i18n } = this.props;

        let lang = typeof this.props.match.params.lang !== 'undefined' ? this.props.match.params.lang : null;

        if(lang === 'es' || lang === 'en'){ 
            i18n.changeLanguage(lang) 
        }

        this._getApiData()
    }

    _getApiData(){
        Promise.resolve(this.props.isLogged())
        .then((response)=> {
            if(this.props.isLoggedUser){
                
                Promise.resolve(this.props.getPortacitos(this.props.user._id))
                .then((response)=> {
                    if(this.props.dataError === false){
                        let dataSource = [];

                        this.props.portacitos.map((portacito, key) =>{
                            let date = new Date(portacito.date);
                            let fecha = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
                            let item = {
                                id : portacito.id,
                                key : key,
                                fecha : fecha,
                                timestamp : date.getTime(),
                                cantidad : portacito.amount,
                                importe : portacito.price,
                                estado : portacito.status
                            }

                            dataSource.push(item);
                        })

                        this.setState({ dataSource : dataSource })
                    }

                    this.setState({ isLoaded : true })
                });

            }else{
                this.props.history.push('/login')
            }
        });
    }

    _doSearch(){

    }

    _renderPortacitos() {
        const { t } = this.props;
        const { dataSource, isLoaded } = this.state;

        const columns = [
            {
                title: t('Fecha'),
                dataIndex: "fecha",
                key: "fecha",
                width: 180,
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.timestamp - b.timestamp,  
            },
            {
                title: t('Cantidad'),
                dataIndex: "cantidad",
                key: "cantidad",
            },
            {
                title: "Importe",
                dataIndex: "importe",
                key: "importe",
                render: (text, record) => (
                    <span className={"purple-light d-block py-1"}>{record.importe}</span>
                )
            }
        ]

        const pagination = { }

        return(
            <React.Fragment>
                <div className={'row pt-2'}>
                    <div className={'col-12'}>
                        <h2 className={'fs-12 poppins purple font-weight-normal mb-4'}>{t('PORTA-CITOS')}</h2>
                    </div>
                </div>

                <CustomTable
                    columns={columns}
                    dataSource={dataSource}
                    pagination={pagination}
                    loaded={isLoaded}
                    t={t}
                />
            </React.Fragment>
        )
    }

    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <div className={"split-container"}>
                    <Menu history={this.props.history} />
                    <div className={"container coachee p-0"}>
                        <div className={"row m-0"}>
                            <Submenu />

                            <div className={"col-10 py-4 px-4"}>
                                { this.state.isLoaded ?
                                    this._renderPortacitos()
                                :
                                    <React.Fragment>
                                        <Loader t={t} />
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <Modal visible={this.state.showModal} footer={null} onCancel={ ()=>{ this.setState({ showModal : false }) } } >
                    <div className={'row mt-3'}>
                        
                        { this.state.modal === 'profile' ?
                            <React.Fragment>
                                <div className={'col-12 text-center'}>
                                    <ResultOk />
                                    <p className={'poppins text-uppercase font-weight-bold fs-13 my-2 px-4'}>{t('PERFIL ACTUALIZADO')}</p>
                                </div>
                                
                                <div className={'col-12 text-center'}>
                                    <p className={'gray07 fs-12 lh-12 my-3 px-4'}>{t('Los datos de tu perfil se han guardado correctamente.')}</p>
                                </div>
                            </React.Fragment>
                        : null }

                        { this.state.modal === 'sepa' ?
                            <React.Fragment>
                                <div className={'col-12 text-center'}>
                                    <ResultOk />
                                    <p className={'poppins text-uppercase font-weight-bold fs-13 my-2 px-4'}>{t('DOCUMENTO SEPA GUARDADO')}</p>
                                </div>
                                
                                <div className={'col-12 text-center'}>
                                    <p className={'gray07 fs-12 lh-12 my-3 px-4'}>{t('El documento SEPA proporcionado se ha guardado correctamente.')}</p>
                                </div>
                            </React.Fragment>
                        : null }

                        { this.state.modal === 'password' ?
                            <React.Fragment>
                                <div className={'col-12 text-center'}>
                                    <ResultOk />
                                    <p className={'poppins text-uppercase font-weight-bold fs-13 my-2 px-4'}>{t('CONTRASEÑA ACTUALIZADA')}</p>
                                </div>
                                
                                <div className={'col-12 text-center'}>
                                    <p className={'gray07 fs-12 lh-12 my-3 px-4'}>{t('La nueva contraseña se ha guardado correctamente.')}</p>
                                </div>
                            </React.Fragment>
                        : null }
                    </div>
                </Modal>
            </React.Fragment>
        )
    }

}

function mapStateToProps(state) {
    const storeUsers = state.users;
    const storeMuestras = state.muestras;

    return {
        isLoggedUser : storeUsers.isLogged,
        user : storeUsers.user,
        portacitos : storeUsers.portacitos,
        dataError : storeUsers.dataError,
        dataErrorMessage : storeUsers.dataErrorMessage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        isLogged: redirectTo => dispatch(isLogged()),
        inputFieldChanged: (field, text) => dispatch(inputFieldChanged(field, text)),
        getUser: (id) => dispatch(getUser(id)),
        getPortacitos: (id) => dispatch(getPortacitos(id)),
        saveProfile: (id, company, email) => dispatch(saveProfile(id, company, email))
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(MisPortacitos));



