import React from "react"

const Tarifas = (props) => {
  return (
  	props.active ? (
		<svg width="28px" height="28px" viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
		    <title>3A8ABD29-37CF-4644-9969-B2649EDDFE8E</title>
		    <desc>Created with sketchtool.</desc>
		    <defs>
		        <path d="M16.3333333,4.66666667 L16.3333333,9.33333333 L21,9.33333333 L21,9.13316498 L16.5335017,4.66666667 L16.3333333,4.66666667 Z M14,4.66666667 L8.66666667,4.66666667 C8.39052429,4.66666667 8.16666667,4.89052429 8.16666667,5.16666667 L8.16666667,22.8333333 C8.16666667,23.1094757 8.39052429,23.3333333 8.66666667,23.3333333 L20.5,23.3333333 C20.7761424,23.3333333 21,23.1094757 21,22.8333333 L21,11.6666667 L16.3333333,11.6666667 C15.0446689,11.6666667 14,10.6219977 14,9.33333333 L14,4.66666667 Z M8.16666667,25.6666667 C6.87800225,25.6666667 5.83333333,24.6219977 5.83333333,23.3333333 L5.83333333,4.66666667 C5.83333333,3.37800225 6.87800225,2.33333333 8.16666667,2.33333333 L17.0167508,2.33333333 C17.3261701,2.33333333 17.6229163,2.45624965 17.8417088,2.67504209 L22.9916246,7.82495791 C23.210417,8.04375035 23.3333333,8.34049658 23.3333333,8.64991582 L23.3333333,23.3333333 C23.3333333,24.6219977 22.2886644,25.6666667 21,25.6666667 L8.16666667,25.6666667 Z M10.5,15.1666667 C10.5,14.5223345 11.0223345,14 11.6666667,14 L17.5,14 C18.1443322,14 18.6666667,14.5223345 18.6666667,15.1666667 C18.6666667,15.8109989 18.1443322,16.3333333 17.5,16.3333333 L11.6666667,16.3333333 C11.0223345,16.3333333 10.5,15.8109989 10.5,15.1666667 Z M10.5,18.6666667 C10.5,18.0223345 11.0223345,17.5 11.6666667,17.5 L17.5,17.5 C18.1443322,17.5 18.6666667,18.0223345 18.6666667,18.6666667 C18.6666667,19.3109989 18.1443322,19.8333333 17.5,19.8333333 L11.6666667,19.8333333 C11.0223345,19.8333333 10.5,19.3109989 10.5,18.6666667 Z" id="path-2"></path>
		    </defs>
		    <g id="Asset-Artboard-Page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
		        <g id="icono-/-settings-copy-2-icono-/-Tarifa-Copy">
		            <mask id="mask-3" fill="white">
		                <use xlinkHref="#path-2"></use>
		            </mask>
		            <use id="Combined-Shape" fill="#000000" fillRule="nonzero" xlinkHref="#path-2"></use>
		            <g id="Color-/-Purple-Color-/-grey" mask="url(#mask-3)" fill="#FFFFFF">
		                <g transform="translate(2.333333, 0.000000)" id="Rectangle">
		                    <rect x="0" y="0" width="28" height="28"></rect>
		                </g>
		            </g>
		        </g>
		    </g>
		</svg>
	):(
		<svg width="28px" height="28px" viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
		    <title>3A8ABD29-37CF-4644-9969-B2649EDDFE8E</title>
		    <desc>Created with sketchtool.</desc>
		    <defs>
		        <path d="M16.3333333,4.66666667 L16.3333333,9.33333333 L21,9.33333333 L21,9.13316498 L16.5335017,4.66666667 L16.3333333,4.66666667 Z M14,4.66666667 L8.66666667,4.66666667 C8.39052429,4.66666667 8.16666667,4.89052429 8.16666667,5.16666667 L8.16666667,22.8333333 C8.16666667,23.1094757 8.39052429,23.3333333 8.66666667,23.3333333 L20.5,23.3333333 C20.7761424,23.3333333 21,23.1094757 21,22.8333333 L21,11.6666667 L16.3333333,11.6666667 C15.0446689,11.6666667 14,10.6219977 14,9.33333333 L14,4.66666667 Z M8.16666667,25.6666667 C6.87800225,25.6666667 5.83333333,24.6219977 5.83333333,23.3333333 L5.83333333,4.66666667 C5.83333333,3.37800225 6.87800225,2.33333333 8.16666667,2.33333333 L17.0167508,2.33333333 C17.3261701,2.33333333 17.6229163,2.45624965 17.8417088,2.67504209 L22.9916246,7.82495791 C23.210417,8.04375035 23.3333333,8.34049658 23.3333333,8.64991582 L23.3333333,23.3333333 C23.3333333,24.6219977 22.2886644,25.6666667 21,25.6666667 L8.16666667,25.6666667 Z M10.5,15.1666667 C10.5,14.5223345 11.0223345,14 11.6666667,14 L17.5,14 C18.1443322,14 18.6666667,14.5223345 18.6666667,15.1666667 C18.6666667,15.8109989 18.1443322,16.3333333 17.5,16.3333333 L11.6666667,16.3333333 C11.0223345,16.3333333 10.5,15.8109989 10.5,15.1666667 Z M10.5,18.6666667 C10.5,18.0223345 11.0223345,17.5 11.6666667,17.5 L17.5,17.5 C18.1443322,17.5 18.6666667,18.0223345 18.6666667,18.6666667 C18.6666667,19.3109989 18.1443322,19.8333333 17.5,19.8333333 L11.6666667,19.8333333 C11.0223345,19.8333333 10.5,19.3109989 10.5,18.6666667 Z" id="path-2"></path>
		    </defs>
		    <g id="Asset-Artboard-Page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
		        <g id="icono-/-settings-copy-2-icono-/-Tarifa-Copy">
		            <mask id="mask-3" fill="white">
		                <use xlinkHref="#path-2"></use>
		            </mask>
		            <use id="Combined-Shape" fill="#000000" fillRule="nonzero" xlinkHref="#path-2"></use>
		            <g id="Color-/-Purple-Color-/-grey" mask="url(#mask-3)" fill="#9B9B9B">
		                <g transform="translate(2.333333, 0.000000)" id="Rectangle">
		                    <rect x="0" y="0" width="28" height="28"></rect>
		                </g>
		            </g>
		        </g>
		    </g>
		</svg>
	)
  )
}

export default Tarifas;