export const $$initialState = {
    user : [],
    users : [],
    isLogged : false,
    email : '',
    password : '',
    dataError : false,
    dataErrorMessage : '',
    redirectTo : '',
    successMessage : '',
    phone : '',
    city : '',
    company : '',
    email : '',

};

export default function usersReducer($$state = $$initialState, action = null) {
    switch (action.type) {
        case 'USER_INPUT_FIELD_CHANGED':
            return Object.assign({}, $$state, {
                [action.payload.field]: action.payload.value,
                dataErrorMessage: { [action.payload.field] : '' }
            });

        case 'DO_LOGIN':
            return Object.assign({}, $$state, {
                isLogged: true,
                user : action.payload.user,
                email : '',
                password : '',
                dataError: false,
                dataErrorMessage : '',
                redirectTo: action.payload.redirectTo,
            });

        case 'DO_LOGIN_ERROR':
            return Object.assign({}, $$state, {
                user : [],
                dataError: true,
                dataErrorMessage: action.payload.message
            });

        case 'DO_LOGOUT':
            return Object.assign({}, $$state, {
                user : {},
                report_contact : [],
                invoice_contact : [],
                comunication_contact : [],
                email : '',
                password : '',
                dataError: false,
                dataErrorMessage : '',
                redirectTo: ''
            });

        case 'DO_RECOVER':
            return Object.assign({}, $$state, {
                dataError: false,
                dataErrorMessage : '',
                successMessage : action.payload.successMessage
            });

        case 'DO_RECOVER_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message,
                successMessage: ''
            });

        case 'GET_USER':
            return Object.assign({}, $$state, {
                user: action.payload.user,
                address: action.payload.user.address,
                address_number: action.payload.user.address_number,
                address_floor: action.payload.user.address_floor,
                address_other: action.payload.user.address_other,
                zipcode: action.payload.user.zipcode,
                cif: action.payload.user.cif,
                business: action.payload.user.business,
                same_address: (action.payload.user.business_address === 'undefined' || action.payload.user.business_address === '' ? { target : { checked : true } } : { target : { checked : false } }),
                business_address: (action.payload.user.business_address !== 'undefined' ? action.payload.user.business_address : ''),
                business_address_number: (action.payload.user.business_address_number !== 'undefined' ? action.payload.user.business_address_number : ''),
                business_address_floor: (action.payload.user.business_address_floor !== 'undefined' ? action.payload.user.business_address_floor : ''),
                business_address_other: (action.payload.user.business_address_other !== 'undefined' ? action.payload.user.business_address_other : ''),
                business_city: (action.payload.user.business_city !== 'undefined' ? action.payload.user.business_city : ''),
                business_province: (action.payload.user.business_province !== 'undefined' ? action.payload.user.business_province : ''),
                business_zipcode: (action.payload.user.business_zipcode !== 'undefined' ? action.payload.user.business_zipcode : ''),
                report_contacts: (action.payload.user.report_contacts === 'undefined' ? JSON.parse('[]') : JSON.parse(action.payload.user.report_contacts)),
                comunication_contacts: (action.payload.user.communication_contacts === 'undefined' ? JSON.parse('[]') : JSON.parse(action.payload.user.communication_contacts)),
                invoice_contacts: (action.payload.user.invoice_contacts === 'undefined' ? JSON.parse('[]') : JSON.parse(action.payload.user.invoice_contacts)),
                company: action.payload.user.company,
                email: action.payload.user.email,
                phone: action.payload.user.phone,
                city: action.payload.user.city,
                province: action.payload.user.province,
                picture: action.payload.user.picture,
                dataError: false,
                dataErrorMessage : '',
                successMessage : ''
            });

        case 'GET_USER_ERROR':
            return Object.assign({}, $$state, {
                user : [],
                dataError: true,
                dataErrorMessage: action.payload.message,
                successMessage : ''
            });

        case 'DO_USER_UPDATE':
            return Object.assign({}, $$state, {
                user: action.payload.user,
                first_name: action.payload.user.first_name,
                last_name: action.payload.user.last_name,
                email: action.payload.user.email,
                department: action.payload.user.department,
                position: action.payload.user.position,
                phone: action.payload.user.phone,
                mobile: action.payload.user.mobile,
                city: action.payload.user.city,
                country: action.payload.user.country,
                dataError: false,
                dataErrorMessage : '',
                successMessage : action.payload.successMessage
            });

        case 'DO_USER_UPDATE_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message,
                successMessage : ''
            });

        case 'IS_LOGGED_SUCCESS':
            return Object.assign({}, $$state, {
                isLogged: true,
                user: action.payload.user,
                redirectTo: '/dashboard',
                dataError: false,
                dataErrorMessage : '',
            });

        case 'IS_LOGGED_ERROR':
            return Object.assign({}, $$state, {
                isLogged: false,
                user : [],
                redirectTo: '/login',
                dataError: true,
                dataErrorMessage: action.payload.message
            });

        case 'SAVE_PROFILE':
            return Object.assign({}, $$state, {
                user: action.payload.user,
                company: action.payload.user.company,
                email: action.payload.user.email,
                dataError: false,
                dataErrorMessage : '',
            });

        case 'SAVE_PROFILE_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message
            });

        case 'SAVE_SEPA':
            return Object.assign({}, $$state, {
                sepa: action.payload,
                dataError: false,
                dataErrorMessage : '',
            });

        case 'SAVE_SEPA_ERROR':
            return Object.assign({}, $$state, {
                sepa: '',
                dataError: true,
                dataErrorMessage: action.payload.message
            });

        case 'UPDATE_PASSWORD':
            return Object.assign({}, $$state, {
                dataError: false,
                dataErrorMessage : '',
            });

        case 'UPDATE_PASSWORD_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message
            });

        case 'SAVE_DATA':
            return Object.assign({}, $$state, {
                dataError: false,
                dataErrorMessage : '',
            });

        case 'SAVE_DATA_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message
            });

        case 'GET_PORTACITOS':
            return Object.assign({}, $$state, {
                portacitos: action.payload,
                dataError: false,
                dataErrorMessage : ''
            });

        case 'GET_PORTACITOS_ERROR':
            return Object.assign({}, $$state, {
                portacitos : [],
                dataError: true,
                dataErrorMessage: action.payload.message,
            });

        case 'GET_USERS':
            return Object.assign({}, $$state, {
                users: action.payload.users
            });

        case 'GET_USERS_ERROR':
            return Object.assign({}, $$state, {
                users : [],
                dataError: true,
                dataErrorMessage: action.payload.message
            });

        case 'GET_CUSTOMER':
            return Object.assign({}, $$state, {
                customer: action.payload.customer,
                dataError: false,
                dataErrorMessage : ''
            });

        case 'GET_CUSTOMER_ERROR':
            return Object.assign({}, $$state, {
                customer : [],
                dataError: true,
                dataErrorMessage: action.payload.message,
                successMessage : ''
            });

        case 'DELETE_USER':
            return Object.assign({}, $$state, {
                dataError: false,
                dataErrorMessage : '',
            });

        case 'DELETE_USER_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message
            });

        default: {
            return $$state;
        }
    }
}
