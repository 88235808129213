import { types } from '../constants/constants';
import { push } from 'react-router-redux';

/** Método que almacena en el store de Redux los inputs que
    el usuario va rellenando, para garantizar la persistencia
    de los datos.
    @param string $field el nombre del input
    @param string $value el valor del input
**/
export function inputFieldChanged(field, value) {
    return function(dispatch) {
        dispatch({ type: "FACTURA_INPUT_FIELD_CHANGED", payload: {field : field, value : value} });
    }
}

/** Método que manda a la API los datos para crear
    una nueva factura
**/
export function saveFactura(number, tarifa, amount, file, user_id) {
    return async function(dispatch) {
        try
        {
            var formBody = [];
            
            var details={ 
                number : number,
                tarifa : tarifa,
                amount: amount,
                file : file,
                user_id : user_id
            };

            for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(details[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
            }

            var status = null;
            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'factura/create', {
                method: 'post',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    "token": token
                },
                body: formBody.join("&"),
            })
            .then(function(response) {
                status = response.status; // Get HTTP status code
                return response.json();
            })
            .then((responseJson, response) => {
                if(status === 200){ // If success
                    dispatch({ 
                        type: "CREATE_FACTURA", 
                        payload: { 
                            muestra : responseJson.muestra
                        }
                    });
                }else{
                    if(status === 401){ 
                        push('/') 
                    }else{
                        dispatch({ type: "CREATE_FACTURA_ERROR", payload: { message : responseJson } });
                    }
                }
            })
            .catch((error) => {
                let errorMessage = 'Hubo un problema conectando con la API: ' + error;
                dispatch({ type: "CREATE_FACTURA_ERROR", payload: { message : errorMessage } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que manda a la API los datos para editar
	una factura
**/
export function updateFactura(id, number, tarifa, amount, file) {
    return async function(dispatch) {
        try
        {
            var formBody = [];
            
            var details={ 
                id : id,
                number : number,
                tarifa : tarifa,
                amount: amount,
                file : file,
            };

            for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(details[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
            }

            var status = null;
            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'factura/update', {
                method: 'post',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    "token": token
                },
                body: formBody.join("&"),
            })
            .then(function(response) {
                status = response.status; // Get HTTP status code
                return response.json();
            })
            .then((responseJson, response) => {
                if(status === 200){ // If success
                	dispatch({ 
                        type: "UPDATE_FACTURA", 
                        payload: { 
                            muestra : responseJson.muestra
                        }
                    });
                }else{
                    if(status === 401){ 
                        push('/') 
                    }else{
                        dispatch({ type: "UPDATE_FACTURA_ERROR", payload: { message : responseJson } });
                    }
                }
            })
            .catch((error) => {
                let errorMessage = 'Hubo un problema conectando con la API: ' + error;
                dispatch({ type: "UPDATE_FACTURA_ERROR", payload: { message : errorMessage } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que obtiene el listado de todas las facturas
    desde la API
**/
export function getFacturas() {
    return async function(dispatch) {
        try
        {
            let url = types.API_URL + 'factura/list'

            var status = null;
            const token = localStorage.getItem('token');

            return fetch(url, {
                method: 'get',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    "token": token
                }
            })
            .then(function(response) {
                status = response.status; // Get HTTP status code
                return response.json();
            })
            .then((responseJson, response) => {
                if(status === 200){ // If success
                    dispatch({ 
                        type: "FACTURA_LIST", 
                        payload: responseJson
                    });
                }else{
                    if(status === 401){ 
                        push('/') 
                    }else{
                        dispatch({ type: "FACTURA_LIST_ERROR", payload: { message : responseJson } });
                    }
                }
            })
            .catch((error) => {
                let errorMessage = 'Hubo un problema conectando con la API: ' + error;
                dispatch({ type: "FACTURA_LIST_ERROR", payload: { message : errorMessage } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que obtiene el listado de todas las facturas
    desde la API
**/
export function getUserFacturas(id) {
    return async function(dispatch) {
        try
        {
            let url = types.API_URL + 'factura/user-list?userid=' + id

            var status = null;
            const token = localStorage.getItem('token');

            return fetch(url, {
                method: 'get',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    "token": token
                }
            })
            .then(function(response) {
                status = response.status; // Get HTTP status code
                return response.json();
            })
            .then((responseJson, response) => {
                if(status === 200){ // If success
                    dispatch({ 
                        type: "FACTURA_USER_LIST", 
                        payload: responseJson
                    });
                }else{
                    if(status === 401){ 
                        push('/') 
                    }else{
                        dispatch({ type: "FACTURA_USER_LIST_ERROR", payload: { message : responseJson } });
                    }
                }
            })
            .catch((error) => {
                let errorMessage = 'Hubo un problema conectando con la API: ' + error;
                dispatch({ type: "FACTURA_USER_LIST_ERROR", payload: { message : errorMessage } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que obtiene una factura concreta por ID dado
    desde la API
**/
export function getFactura(id) {
    return async function(dispatch) {
        try
        {
            let url = types.API_URL + 'factura/' + id

            var status = null;
            const token = localStorage.getItem('token');

            return fetch(url, {
                method: 'get',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    "token": token
                }
            })
            .then(function(response) {
                status = response.status; // Get HTTP status code
                return response.json();
            })
            .then((responseJson, response) => {
                if(status === 200){ // If success
                    dispatch({ 
                        type: "GET_FACTURA", 
                        payload: responseJson
                    });
                }else{
                    if(status === 401){ 
                        push('/') 
                    }else{
                        dispatch({ type: "GET_FACTURA_ERROR", payload: { message : responseJson } });
                    }
                }
            })
            .catch((error) => {
                let errorMessage = 'Hubo un problema conectando con la API: ' + error;
                dispatch({ type: "GET_FACTURA_ERROR", payload: { message : errorMessage } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que manda a la API el id para eliminar
    una factura
**/
export function deleteFactura(id) {
    return async function(dispatch) {
        try
        {
            var formBody = [];
            
            var details={ 
                id : id
            };

            for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(details[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
            }

            var status = null;
            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'factura/delete', {
                method: 'post',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    "token": token
                },
                body: formBody.join("&"),
            })
            .then(function(response) {
                status = response.status; // Get HTTP status code
                return response.json();
            })
            .then((responseJson, response) => {
                if(status === 200){ // If success
                    dispatch({ 
                        type: "DELETE_FACTURA", 
                        payload: {}
                    });
                }else{
                    if(status === 401){ 
                        push('/') 
                    }else{
                        dispatch({ type: "DELETE_FACTURA_ERROR", payload: { message : responseJson } });
                    }
                }
            })
            .catch((error) => {
                let errorMessage = 'Hubo un problema conectando con la API: ' + error;
                dispatch({ type: "DELETE_FACTURA_ERROR", payload: { message : errorMessage } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}