import { types } from '../constants/constants';

/** Método para limpiar la store
**/
export function cleanStore() {
    return function(dispatch) {
        dispatch({ type: "CLEAN_STORE", payload: '' });
    }
}

/** Método que almacena en el store de Redux los inputs que
    el usuario va rellenando, para garantizar la persistencia
    de los datos.
    @param string $field el nombre del input
    @param string $value el valor del input
**/
export function inputFieldChanged(field, value) {
    return function(dispatch) {
        dispatch({ type: "REGISTER_INPUT_FIELD_CHANGED", payload: {field : field, value : value} });
    }
}

/** Método que manda a la API los datos para registrar
	un nuevo usuario
**/
export function doRegister(
	company,
	email, 
	password,
	address,
    address_number,
    address_floor,
	address_other,
	city,
	zipcode,
	province,
	cif,
	phone,
	business,
	business_address,
    business_address_number,
    business_address_floor,
	business_address_other,
	business_city,
	business_zipcode,
	business_province,
	invoice_contacts,
	report_contacts,
	communication_contacts,
	iban
	) {
    return async function(dispatch) {
        try
        {
            var formBody = [];
            
            var details={ 
                company : company,
				email : email,
				password : password,
				address : address,
                address_number : address_number,
                address_floor : address_floor,
				address_other : address_other,
				city : city,
				zipcode : zipcode,
				province : province,
				cif : cif,
				phone : phone,
				business : business,
				business_address : business_address,
                business_address_number : business_address_number,
                business_address_floor : business_address_floor,
				business_address_other : business_address_other,
				business_city : business_city,
				business_zipcode : business_zipcode,
				business_province : business_province,
				invoice_contacts : JSON.stringify(invoice_contacts),
				report_contacts : JSON.stringify(report_contacts),
				communication_contacts : JSON.stringify(communication_contacts),
				iban : iban
            };

            for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(details[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
            }

            var status = null;

            return fetch(types.API_URL + 'user/register', {
                method: 'post',
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: formBody.join("&"),
            })
            .then(function(response) {
                status = response.status; // Get HTTP status code
                return response.json();
            })
            .then((responseJson, response) => {
                if(status === 200){ // If success
                	localStorage.setItem('token', responseJson.token);

                	dispatch({ 
                        type: "REGISTER", 
                        payload: { 
                            user : responseJson.user
                        }
                    });
                }else{
                    dispatch({ type: "REGISTER_ERROR", payload: { message : responseJson } });
                }
            })
            .catch((error) => {
                let errorMessage = 'Hubo un problema conectando con la API: ' + error;
                dispatch({ type: "REGISTER_ERROR", payload: { message : errorMessage } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que manda a la API los datos para registrar
    un nuevo usuario
**/
export function doRegisterAdmin(
    company,
    email, 
    password,
    address,
    address_number,
    city,
    zipcode,
    province,
    cif,
    phone,
    business,
    business_address,
    business_address_number,
    business_city,
    business_zipcode,
    business_province,
    invoice_contacts,
    report_contacts,
    communication_contacts,
    iban,
    send_email
    ) {
    return async function(dispatch) {
        try
        {
            var formBody = [];
            
            var details={ 
                company : company,
                email : email,
                password : password,
                address : address,
                address_number : address_number,
                city : city,
                zipcode : zipcode,
                province : province,
                cif : cif,
                phone : phone,
                business : business,
                business_address : business_address,
                business_address_number : business_address_number,
                business_city : business_city,
                business_zipcode : business_zipcode,
                business_province : business_province,
                invoice_contacts : JSON.stringify(invoice_contacts),
                report_contacts : JSON.stringify(report_contacts),
                communication_contacts : JSON.stringify(communication_contacts),
                iban : iban,
                send_email : send_email
            };

            for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(details[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
            }

            var status = null;

            return fetch(types.API_URL + 'user/register', {
                method: 'post',
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: formBody.join("&"),
            })
            .then(function(response) {
                status = response.status; // Get HTTP status code
                return response.json();
            })
            .then((responseJson, response) => {
                if(status === 200){ // If success

                    dispatch({ 
                        type: "REGISTER_ADMIN", 
                        payload: { 
                            user : responseJson.user
                        }
                    });
                }else{
                    dispatch({ type: "REGISTER_ADMIN_ERROR", payload: { message : responseJson } });
                }
            })
            .catch((error) => {
                let errorMessage = 'Hubo un problema conectando con la API: ' + error;
                dispatch({ type: "REGISTER_ADMIN_ERROR", payload: { message : errorMessage } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}