import React from 'react';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';

import { isLogged } from '../actions/user.js';
import { getReports } from '../actions/report.js';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from '../components/loader';

import { withTranslation } from 'react-i18next';

class MenuReports extends React.Component {
    constructor(props) {
        super(props)

        this.wrapperRef = React.createRef()

        this.state = {
            isLoaded: false,
            dataSource: []
        }
    }

    componentDidMount() {
        const { t, i18n } = this.props;
        const current_lang = i18n.language

        if(current_lang.indexOf('en-GB') !== -1){ i18n.changeLanguage('en') }
        if(current_lang.indexOf('en-US') !== -1){ i18n.changeLanguage('en') }
        if(current_lang.indexOf('es-ES') !== -1){ i18n.changeLanguage('es') }

        Promise.resolve(this.props.isLogged()).then((response) => {
          if (this.props.isLoggedUser === true) {

            Promise.resolve(this.props.getReports(this.props.user._id))
            .then(response => {
                if(this.props.reports){
                    let dataSource = [];

                    this.props.reports.map((report, key) =>{
                        if(report.status > 0){ // Solo reports ya enviados
                            let date = new Date(report.date);
                            let fecha = date.toLocaleDateString('es-ES', { timeZone: 'Europe/Madrid' });
                            let item = {
                                id : report.id,
                                lab_id : report.lab_id,
                                fecha : fecha,
                                timestamp : date.getTime(),
                                title : report.title,
                                status : report.status,
                                muestra : report.muestra,
                                date : report.created_at
                            }

                            dataSource.push(item);
                        }
                    })

                    this.setState({ dataSource : dataSource })
                    this.setState({ isLoaded : true })
                }
            })
          }
        })
    }

    _loadReport(id) {
        Promise.resolve(this.props.loadReport(id))
        .then(response =>{
            let dataSource = this.state.dataSource
            dataSource.map(item =>{
                if(item.id === id){
                    item.status = 2
                }
            })

            this.setState({ dataSource : dataSource })
        })
    }

    _isCurrentLink(id) {
        if (window.location.pathname.indexOf(id) !== -1) {
          return true
        }

        return false
    }

    _renderList(){
        const { t } = this.props;
        const reports = this.state.dataSource;

        return(
            <React.Fragment>
                <div className={"col-3 submenu px-0"}>
                    <h2 className={'black-text fs-14 font-weight-normal border-bottom-gray-min pb-4 pl-3'}>{t('INFORMES')} ({ reports.length })</h2>

                    { typeof reports !== 'undefined' ? 
                        reports.map((report, key) => {
                            let date = new Date(report.muestra.created_at);
                            let fecha = date.getDate() + ' - ' + (date.getMonth() + 1) + ' - ' + date.getFullYear();

                            return(
                                <div 
                                onClick={ (id) => this._loadReport(report.id) } 
                                className={'row border-bottom-gray-min mx-0 py-2' + (this._isCurrentLink(report.id) ? ' bg-box' : '')}
                                role={'button'}
                                key={key}>
                                    <div className={'col-2 pr-0 text-center'}>
                                        { report.status < 2 ? 
                                        <span className={'purple-badge-point'}></span>
                                        
                                        : null }
                                    </div>

                                    <div className={'col-10 pl-0'}>
                                        <h3 className={'fs-1 m-0'}>{ report.title }</h3>
                                        <p className={'mb-2'}>ID { report.lab_id }</p>
                                        <p className={'fs-1 font-weight-bold m-0'}>{ report.muestra.animal_name } ({ report.muestra.animal_history })</p>
                                        <p className={'edit-gray m-0'}>{ t('Fecha de solicitud') }: { fecha }</p>
                                    </div>
                                </div>
                            )
                        })
                    : null }
                </div>
            </React.Fragment>
        )
    }

    render() {
        const { t } = this.props
        return (
            <React.Fragment>
                { this.state.isLoaded ?
                    this._renderList() 
                :
                    
                    <Loader t={t} />
                }
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const storeApp = state.app;
    const storeUsers = state.users;
    const storeReports = state.reports;

    return {
        isLoggedUser : storeUsers.isLogged,
        user: storeUsers.user,
        coach: storeApp.coach,
        reports: storeReports.reports
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        isLogged: redirectTo => dispatch(isLogged()),
        getReports: (id) => dispatch(getReports(id))
    };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(MenuReports));



