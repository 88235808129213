import React from 'react';
import { push } from 'react-router-redux';

import logo from '../assets/img/logo-t-cito-black-typo.svg'

import { withTranslation } from 'react-i18next';

class NotFound extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
    	const { t } = this.props;

	    return (
	      	<React.Fragment>
						<div className={'container vh100'}>
	      			<div className={'row'}>
	      				<div className={'col-12 mt-5'}>
			      			<img src={logo} alt={t('Logo')} />
		        		</div>

						<div className={'col-12 mt-5 d-flex flex-column align-items-center text-center'}>
							<p className={'fs-140 mb-0 dark-gray bold'}>404</p>
							<p className={'fs-14 dark-gray bold'}>WOOPS...</p>
							<p className={'fs-12 mb-5'}>{t('Lo siento, no podemos encontrar la página que estás buscando')}...</p>

							{ JSON.stringify(document).indexOf('404') != -1 ?
								<button className={'btn-purple'} onClick={ () => { this.props.history.push('/') } }>
									{t('Volver')}
								</button>
							:
								<button className={'btn-purple'} onClick={ this.props.history.goBack }>
									{t('Volver')}
								</button>
							}
						</div>
		        	</div>
		        </div>
	      	</React.Fragment>
	    );
	 }

}

export default withTranslation()(NotFound);