import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { types } from '../constants/constants';
import jwt from 'jsonwebtoken';

import logo from '../assets/img/logo-t-cito-black-typo.svg'


import { isLogged,
            inputFieldChanged,
            updatePassword } from '../actions/user.js';

import InputText from '../components/input';
import Testimonials from '../components/testimonials';

import { withTranslation } from 'react-i18next';

class Recovery extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            'showForm' : true,
            'showError' : false
        };
    }

    componentDidMount() {
        const { i18n } = this.props;
        
        let lang = typeof this.props.match.params.lang !== 'undefined' ? this.props.match.params.lang : null;

        if(lang && i18n.language !== lang){ 
            i18n.changeLanguage(lang)
        }

        let token = typeof this.props.match.params.token !== 'undefined' ? this.props.match.params.token : null;

        if(token == null){
            this.setState({
                'showForm' : false,
                'showError' : true
            });

            console.log("No token");
        }else{
            let token_decoded = jwt.decode(token);

            if (typeof token_decoded === 'undefined' || Date.now() > token_decoded.exp * 1000) {
                this.setState({
                    'showForm' : false,
                    'showError' : true
                });
            }

            this.props.inputFieldChanged("email", token_decoded.user.email);
        }

    }

    _saveData(){
        this.props.updatePassword(this.props.email, this.props.password);
    }

    _renderRecoveryForm() {
        const { t } = this.props;
        
        return (
            <React.Fragment>

                <form name="login-form" method="post">
                    <div className={'row'}>
                        <div className={'col-12 text-left mb-2 mt-5'}>
                            <p className={'bold dark-gray'}>{t('Introduce tu nueva contraseña')}</p>
                        </div>

                        <div className={'col-12'}>
                            <InputText
                                id={'password'}
                                name={'password'}
                                type={'password'}
                                placeholder={t('Contraseña')}
                                classes={'w-100'}
                                label={t('Contraseña')}
                                value={this.props.password}
                                onChange={this.props.inputFieldChanged.bind(this)}
                                error={this.props.dataErrorMessage.password} 
                                />
                        </div>

                        { typeof this.props.dataErrorMessage.recovery !== 'undefined' 
                            && this.props.dataErrorMessage.recovery !== ''
                            && this.props.dataErrorMessage.recovery.length ?

                            <div className="col-12 mt-4">
                                <p className={'red fs-09 mt-1'}>{ this.props.dataErrorMessage.recovery }</p>
                            </div>

                        : null }

                        { typeof this.props.successMessage !== 'undefined' 
                            && this.props.successMessage !== '' ?

                            <div className="col-12 mt-4">
                                <p className={'text-success fs-09 mt-1'}>{ this.props.successMessage }</p>
                            </div>

                        : null }

                        <div className={'col-12 text-center mt-4'}>
                            <button type={'button'} className={'btn-purple text-center'} onClick={this._saveData.bind(this)}>
                                {t('Guardar')}
                            </button>
                        </div>

                        <div className={'col-12 text-center mt-4'}>
                            <span className={'button-text'}>{t('Si no quieres cambiar la contraseña puedes')}</span>
                            <a className={'purple'} href={'/login'}>
                                {' '}{t('volver atrás')}
                            </a>
                        </div>
                    </div>
                </form>

            </React.Fragment>
        )
    }

    _renderError() {
        const { t } = this.props;

        return (
            <React.Fragment>

                <div className={'row'}>
                    <div className={'col-12 text-left mb-2'}>
                        <p className={'bold dark-gray'}>{t('Hay problemas para recuperar tu contraseña')}</p>
                    </div>    

                    <div className={'col-12'}>
                        <p>{t('El enlace es erroneo o ha caducado')}. {t('Recuerda que dispones de 10 minutos desde que solicitas recuperar la contraseña hasta que completas el proceso')}. {t('Si han pasado más de 10 minutos tienes que volver a solicitar el email')}.</p>
                    </div>
                </div>

            </React.Fragment>
        )
    }

    render () {
        return (
            <React.Fragment>
                        
                <div className={'container'}>
                    <div className={'d-flex flex-column justify-content-center'} style={{height: '100vh'}}>
                        <div className={'row mt-5'}>
                            <div className={"col-12 text-center"}>
                                <a href="/">
                                  <img
                                    src={logo}
                                    className={"logo"}
                                    alt={"Logo " + types.COMPANY}
                                  />
                                </a>
                            </div>
                        </div>

                        <div className={'row'}>
                            <div className={'col-4 offset-4 align-self-center'}>

                                <div className={'row mt-5 mb-5'}>
                                  <div className={'col-12 text-left'}>
                                  </div>
                                </div>

                                { 
                                    this.state.showForm ?
                                        this._renderRecoveryForm()
                                    :
                                        this._renderError()
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const storeUsers = state.users;

    return {
        isLoggedUser : storeUsers.isLogged,
        user : storeUsers.user,
        email : storeUsers.email,
        password : storeUsers.password,
        dataError : storeUsers.dataError,
        dataErrorMessage: storeUsers.dataErrorMessage,
        redirectTo: storeUsers.redirectTo,
        successMessage : storeUsers.successMessage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        inputFieldChanged: (field, text) => dispatch(inputFieldChanged(field, text)),
        updatePassword: (email, password) => dispatch(updatePassword(email, password)),
        isLogged: redirectTo => dispatch(isLogged())
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(Recovery));