import React from 'react';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';

import { isLogged } from '../actions/user.js';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';

import { withTranslation } from 'react-i18next';

class Submenu extends React.Component {
    constructor(props) {
        super(props)

        this.wrapperRef = React.createRef()

        this.state = {
            isLoaded: false
        }
    }

    componentDidMount() {
        const { t, i18n } = this.props;
        const current_lang = i18n.language

        if(current_lang.indexOf('en-GB') !== -1){ i18n.changeLanguage('en') }
        if(current_lang.indexOf('en-US') !== -1){ i18n.changeLanguage('en') }
        if(current_lang.indexOf('es-ES') !== -1){ i18n.changeLanguage('es') }

        Promise.resolve(this.props.isLogged()).then((response) => {
          if (this.props.isLoggedUser === true) {

            if (this.props.user.role.code === 0) { // Admin
              // User is admin
              this.setState({
                menuItems: [
                ],
              })
            } else if (this.props.user.role.code === 1){ // Clinica
              this.setState({
                menuItems: [
                ],
              })
            }

            this.setState({ isLoaded: true })
          }
        })
    }

    _isCurrentLink(path) {
        if (window.location.pathname === path) {
          return true
        }

        if (path !== '/' && path.length > 0 && window.location.pathname.indexOf(path) !== -1) {
          return true
        }

        return false
    }

    render() {
        const { t } = this.props
        return (
            <React.Fragment>
                <div className={"col-2 submenu px-2"}>
                    <h2 className={'black-text fs-1 font-weight-normal border-bottom pb-2 pl-3 mb-3'}>{t('GENERAL')}</h2>
                    <a href={"/mi-cuenta"} className={'btn-submenu d-block mb-2' + (this._isCurrentLink("/mi-cuenta") ? ' active' : '') }>{t('Mi cuenta')}</a>
                    <a href={"/datos-centro"} className={'btn-submenu d-block mb-2' + (this._isCurrentLink("/datos-centro") ? ' active' : '')}>{t('Datos del centro')}</a>
                    <a href={"/mis-facturas"} className={'btn-submenu d-block mb-2' + (this._isCurrentLink("/mis-facturas") ? ' active' : '')}>{t('Mis facturas')}</a>
                    <a href={"/mis-portacitos"} className={'btn-submenu d-block' + (this._isCurrentLink("/mis-portacitos") ? ' active' : '')}>{t('Porta-citos')}</a>
                </div>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const storeApp = state.app;
    const storeUsers = state.users;

    return {
        isLoggedUser : storeUsers.isLogged,
        user: storeUsers.user,
        coach: storeApp.coach,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        isLogged: redirectTo => dispatch(isLogged())
    };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Submenu));



