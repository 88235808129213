import React from "react"

const Add = (props) => {
  return (
  	<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
	    <title>B7F6D5D7-7DFD-48B9-BF94-279186054892</title>
	    <desc>Created with sketchtool.</desc>
	    <defs>
	        <path d="M11,9.01143783 L15,9.01143783 C15.5522847,9.01143783 16,9.45915308 16,10.0114378 C16,10.5637226 15.5522847,11.0114378 15,11.0114378 L11,11.0114378 L11,15.0990047 C11,15.6512894 10.5522847,16.0990047 10,16.0990047 C9.44771525,16.0990047 9,15.6512894 9,15.0990047 L9,11.0114378 L4.98174271,11.0114378 C4.42945796,11.0114378 3.98174271,10.5637226 3.98174271,10.0114378 C3.98174271,9.45915308 4.42945796,9.01143783 4.98174271,9.01143783 L9,9.01143783 L9,5 C9,4.44771525 9.44771525,4 10,4 C10.5522847,4 11,4.44771525 11,5 L11,9.01143783 Z M10,20 C4.4771525,20 0,15.5228475 0,10 C0,4.4771525 4.4771525,0 10,0 C15.5228475,0 20,4.4771525 20,10 C20,15.5228475 15.5228475,20 10,20 Z M10,18 C14.418278,18 18,14.418278 18,10 C18,5.581722 14.418278,2 10,2 C5.581722,2 2,5.581722 2,10 C2,14.418278 5.581722,18 10,18 Z" id="pathAdd"></path>
	    </defs>
	    <g id="Asset-Artboard-Page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
	        <g id="icono-/-descargar-copy" transform="translate(12.000000, 12.000000) scale(-1, -1) translate(-12.000000, -12.000000) ">
	            <g id="Color-/-Purple" transform="translate(2.000000, 2.000000)">
	                <mask id="maskAdd" fill="white">
	                    <use xlinkHref="#pathAdd"></use>
	                </mask>
	                <use id="Mask" fill="#000000" fillRule="nonzero" xlinkHref="#pathAdd"></use>
	                <g mask="url(#maskAdd)" fill="#DD9DEF" id="Rectangle">
	                    <g transform="translate(-2.000000, -2.000000)">
	                        <rect x="0" y="0" width="24" height="24"></rect>
	                    </g>
	                </g>
	                <g id="Color-/-grey" mask="url(#maskAdd)" fill="#DD9DEF">
	                    <g transform="translate(-2.000000, -2.000000)" id="Rectangle">
	                        <rect x="0" y="0" width="24" height="24"></rect>
	                    </g>
	                </g>
	            </g>
	        </g>
	    </g>
	</svg>
  )
}

export default Add;