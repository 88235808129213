import React from 'react';

import { CloseOutlined } from '@ant-design/icons';

class MuestraCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  _onDelete(itemId) {
    if(this.props.onDelete){
      this.props.onDelete(itemId)
    }
  }
  
  render(){
    const { t, tipo, paciente, propietario, historia, id } = this.props;
    return (
      <div className={'row mb-2 py-3 border-bottom-gray-bg'}>
        <div className={'col-6 text-left'}>
          <h2 className={'fs-14 mb-3'}>{ tipo }</h2>
          <p className={'fs-12 edit-gray mb-2'}>Paciente: <span className={'black-text pl-2'}>{ paciente }</span></p>
          <p className={'fs-12 edit-gray mb-2'}>Propietario:  <span className={'black-text pl-2'}>{ propietario }</span></p>
          <p className={'fs-12 edit-gray mb-2'}>Historia clínica:  <span className={'black-text pl-2'}>{ historia }</span></p>
        </div>

        <div className={'col-6 text-right d-flex flex-column align-items-end'}>
            <div className={'mb-auto'}><CloseOutlined role={'button'} onClick={() => this._onDelete(id)} /></div>
            <div className={'mt-auto'}><button role={'button'} className={'mb-2 btn-purple-text'}>Editar</button></div>
        </div>
      </div>
    )
  }
}

export default MuestraCard;