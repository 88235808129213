import { createMemoryHistory } from 'history';
import { applyMiddleware, compose, createStore, combineReducers } from 'redux';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import thunkMiddleware from 'redux-thunk';

import appReducer, { $$initialState as $$appState } from '../reducers/app';
import usersReducer, { $$initialState as $$usersState } from '../reducers/users';
import registerReducer, { $$initialState as $$registerState } from '../reducers/register';
import muestrasReducer, { $$initialState as $$muestrasState } from '../reducers/muestra';
import recogidasReducer, { $$initialState as $$recogidasState } from '../reducers/recogida';
import tarifasReducer, { $$initialState as $$tarifasState } from '../reducers/tarifa';
import facturasReducer, { $$initialState as $$facturasState } from '../reducers/factura';
import settingsReducer, { $$initialState as $$settingsState } from '../reducers/settings';
import reportsReducer, { $$initialState as $$reportsState } from '../reducers/report';
import liveReducer, { $$initialState as $$liveState } from '../reducers/live';

export const history = createMemoryHistory();

export const loadState = () => {
    try {
        const serializedState = sessionStorage.getItem('state');
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        alert("No esta permitido sessionStorage: " + err);
        return undefined;
    }
};

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        sessionStorage.setItem('state', serializedState);
    } catch (err) {
        alert("No esta permitido sessionStorage: " + err);
    }
};

export default () => {
    const persistedState = loadState();

    const store = createStore(
        combineReducers({
            router : connectRouter(history),
            app : appReducer,
            users: usersReducer,
            register: registerReducer,
            muestras: muestrasReducer,
            recogidas: recogidasReducer,
            tarifas: tarifasReducer,
            facturas: facturasReducer,
            settings: settingsReducer,
            reports: reportsReducer,
            live: liveReducer,
        }),
        persistedState,
        compose(
            applyMiddleware(
                thunkMiddleware,
                routerMiddleware(history), // for dispatching history actions
            ),
            ...(window.__REDUX_DEVTOOLS_EXTENSION__ ? [window.__REDUX_DEVTOOLS_EXTENSION__()] : [])
        ),
    );

    store.subscribe(() => {
        saveState(store.getState());
    });

    return store;
};
