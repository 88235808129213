import React from "react"

const Info = (props) => {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
	    <title>iconmonstr-info-thin</title>
	    <desc>Created with Sketch.</desc>
	    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
	        <g id="iconmonstr-info-thin" fill="#DD9DEF">
	            <path d="M8,0 C12.4153333,0 16,3.58466667 16,8 C16,12.4153333 12.4153333,16 8,16 C3.58466667,16 0,12.4153333 0,8 C0,3.58466667 3.58466667,0 8,0 Z M8,0.666666667 C12.0473333,0.666666667 15.3333333,3.95266667 15.3333333,8 C15.3333333,12.0473333 12.0473333,15.3333333 8,15.3333333 C3.95266667,15.3333333 0.666666667,12.0473333 0.666666667,8 C0.666666667,3.95266667 3.95266667,0.666666667 8,0.666666667 Z M8.33333333,12 L7.66666667,12 L7.66666667,6 L8.33333333,6 L8.33333333,12 Z M8,4 C8.31066667,4 8.56333333,4.252 8.56333333,4.56333333 C8.56333333,4.874 8.31066667,5.126 8,5.126 C7.68933333,5.126 7.43666667,4.874 7.43666667,4.56333333 C7.43666667,4.252 7.68933333,4 8,4 Z" id="Shape"></path>
	        </g>
	    </g>
	</svg>
  )
}

export default Info;
