import React from 'react';

const Download = (props) => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
	    <title>EE27BB34-50C9-47CC-957E-AF0D4D11FE23</title>
	    <desc>Created with sketchtool.</desc>
	    <defs>
	        <path d="M5.74311485,5.5 C6.22412002,2.38490993 8.91688056,8.8817842e-16 12.1666667,0 L12.5,4.4408921e-15 C16.0898509,3.55271368e-15 19,2.91014913 19,6.5 C19,10.0898509 16.0898509,13 12.5,13 L12.3597409,13 L12.3597409,11 L12.5,11 C14.9852814,11 17,8.98528137 17,6.5 C17,4.01471863 14.9852814,2 12.5,2 L12.1666667,2 C9.68138529,2 7.66666667,4.01471863 7.66666667,6.5 L7.66666667,7.5 L3.75,7.5 C2.78350169,7.5 2,8.28350169 2,9.25 C2,10.2164983 2.78350169,11 3.75,11 L6.66666667,11 L6.66666667,13 L3.75,13 C1.67893219,13 0,11.3210678 0,9.25 C0,7.17893219 1.67893219,5.5 3.75,5.5 L5.74311485,5.5 Z M8.88913463,16.3347867 L8.88913463,10.8735574 C8.88913463,10.3911049 9.28023958,10 9.76269198,10 C10.2451444,10 10.6362493,10.3911049 10.6362493,10.8735574 L10.6362493,16.316714 L12.0163314,14.8961072 C12.3478017,14.5549036 12.8931116,14.5470132 13.2343152,14.8784835 C13.2372169,14.8813024 13.2400986,14.8841416 13.2429602,14.8870011 C13.5842347,15.2280173 13.584444,15.7811232 13.2434278,16.1223977 L9.75933297,19.6091309 L6.25721962,16.1223948 C5.91532448,15.7820009 5.91410751,15.2288963 6.25450144,14.8870011 C6.59013496,14.5498874 7.13550436,14.5486874 7.4726181,14.884321 C7.47548358,14.8871738 7.47832893,14.8900469 7.48115395,14.8929399 L8.88913463,16.3347867 Z" id="path-download"></path>
	    </defs>
	    <g id="Asset-Artboard-Page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
	        <g id="icono-/-descargar-copy">
	            <g id="Color-/-Purple" transform="translate(3.000000, 3.000000)">
	                <mask id="mask-download" fill="white">
	                    <use xlinkHref="#path-download"></use>
	                </mask>
	                <use id="Mask" fill="#000000" fillRule="nonzero" xlinkHref="#path-download"></use>
	                <g mask="url(#mask-download)" fill="#5E44F4" id="Rectangle">
	                    <g transform="translate(-3.000000, -3.000000)">
	                        <rect x="0" y="0" width="24" height="24"></rect>
	                    </g>
	                </g>
	            </g>
	        </g>
	    </g>
	</svg>
  )
}

export default Download;