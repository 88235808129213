import React from 'react';
import { types } from '../constants/constants';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import InfoIcon from "../components/icons/info";
import ResultOk from "../components/icons/resultok";
import Loader from "../components/loader";
import DownloadTable from "../components/icons/download-table";
import animalMap from '../assets/img/animal_map.jpg';
import logo from '../assets/img/logo-t-cito-black-typo.svg'

import InputText from '../components/input';
import TextAreaInput from '../components/textarea';
import MuestraCard from '../components/muestraCard';
import { Modal, Radio, Button, Select } from 'antd';
import { ExclamationCircleOutlined,
            PlusCircleOutlined } from '@ant-design/icons';

import ImageMapper from 'react-image-mapper';

import { isLogged } from '../actions/user.js';
import { getUserMuestras } from '../actions/muestra.js';
import { inputFieldChanged,
            saveRecogida,
            getExpressZipcodes } from '../actions/recogida.js';

import { withTranslation } from 'react-i18next';

const { confirm } = Modal;
const { Option } = Select;

class Recogida extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded : true,
            formError : {},
            locations : [],
            dataSource : [],
            showModal : false,
            horas : {
                '9' : '09:00',
                '10' : '10:00',
                '11' : '11:00',
                '12' : '12:00',
                '13' : '13:00',
                '14' : '14:00',
                '15' : '15:00',
                '16' : '16:00',
                '17' : '17:00',
                '18' : '18:00'
            },
        };
    }

    componentDidMount() {
        const { i18n } = this.props;

        let lang = typeof this.props.match.params.lang !== 'undefined' ? this.props.match.params.lang : null;

        if(lang === 'es' || lang === 'en'){ 
            i18n.changeLanguage(lang) 
        }

        Promise.resolve(this.props.isLogged())
        .then((response)=> {
            if(this.props.isLoggedUser){
                 this._getApiData()
            }else{
                this.props.history.push('/login')
            }
        });
    }

    _getApiData(){
        this.setState({ isLoaded : false})

        Promise.resolve(this.props.isLogged())
        .then((response)=> {
            if(this.props.isLoggedUser){
                
                Promise.resolve(this.props.getUserMuestras(0))
                .then((response)=> {
                    if(this.props.muestras){
                        let dataSource = [];

                        this.props.muestras.map((muestra, key) =>{
                            let date = new Date(muestra.date);
                            let fecha = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
                            let item = {
                                id : muestra.id,
                                fecha : fecha,
                                tipo : muestra.title,
                                paciente : muestra.animal_name,
                                historia : muestra.animal_history,
                                propietario : muestra.animal_owner,
                                estado : muestra.status
                            }

                            dataSource.push(item);
                        })

                        Promise.resolve(this.props.getExpressZipcodes())
                        .then((response)=> {
                            if(this.props.dataError){ console.log(this.props.dataErrorMessage) }

                            // Si el cliente puede elegir Recogida express lo marcamos por defecto
                            let shipment_method = {};
                            if(this.props.zipcodes.toString().indexOf(this.props.user.zipcode) !== -1){
                                shipment_method = {
                                    target : {
                                        value : 'express'
                                    }
                                }
                            }else{
                                shipment_method = {
                                    target : {
                                        value : 'mrw'
                                    }
                                }
                            }

                            this.props.inputFieldChanged('shipment_method', shipment_method)

                            this.setState({ dataSource : dataSource })
                            this.setState({ isLoaded : true })
                        })
                    }
                });

            }else{
                this.props.history.push('/login')
            }
        });
    }

    _onDeleteMuestra(id){
        const { t } = this.props;

        confirm({
            title: t('¿Seguro que quieres eliminar esta muestra?'),
            icon: <ExclamationCircleOutlined style={{ color: '#FF866B' }} />,
            content: t('Si la eliminas no se gestionará y por tanto no recibirás el informe en este envío'),
            okText: t('Si, quítala'),
            cancelText: t('No'),
            onOk : () => {

                let dataSource = this.state.dataSource;

                dataSource = dataSource.filter(el => {
                    console.log(el['id'])
                    console.log(id)
                    return el['id'] != id;
                })

                console.log(dataSource)

                this.setState({ dataSource : dataSource });
                
            },
            onCancel() {
                
            },
        });
    }

    _renderForm() {
        const { t } = this.props
        const { dataSource } = this.state

        return (
            <React.Fragment>

                <div className={'row mb-2'}>
                    <div className={'col-12'}>
                        <h2 className={'fs-12 poppins purple font-weight-normal mb-4'}>{t('MUESTRAS PARA ENVIAR')}</h2>
                    </div>
                </div>

                <div className={'row mb-5 mx-0'}>
                    <div className={'col-12 box-with-shadow px-5 pt-5 pb-2'}>
                        { dataSource.map((muestra, key) =>{
                            return(
                                <MuestraCard key={key}
                                    id={muestra.id}
                                    tipo={muestra.tipo}
                                    paciente={muestra.paciente}
                                    propietario={muestra.propietario}
                                    historia={muestra.historia}
                                    onDelete={(id) => this._onDeleteMuestra(muestra.id)} />
                            )
                        }) }

                        { Object.keys(dataSource).length === 0 ?
                            <p className={'mb-3'}>{t('No tienes muestras pendientes de enviar')}</p>
                        : null
                        }

                        <div className={'row'}>
                            <div className={'col-12'}>
                                <a href="nueva-muestra" className={'fw-100 btn-add-item w-100'}>
                                    <PlusCircleOutlined style={{ fontSize: '1.6em', marginRight: '0.5em' }} />
                                    {t('Añadir otra muestra')}
                                </a>
                            </div>
                        </div>

                        {(typeof this.state.formError.muestras !== 'undefined' && this.state.formError.muestras !== '') ?
                            <p className={'red fs-09 mt-0 pl-3'}>{this.state.formError.muestras}</p>
                        : null }
                    </div>
                </div>

                <div className={'row mb-5'}>
                    <div className={'col-12'}>
                        <h2 className={'fs-12 poppins purple font-weight-normal mb-4'}>{t('MÉTODO DE ENVÍO')}</h2>
                    </div>
                </div>

                <div className={'row mb-5'}>
                    <div className={'col-12'}>
                        <Radio.Group 
                        onChange={this.props.inputFieldChanged.bind(this, 'shipment_method')} 
                        value={typeof this.props.shipment_method != 'undefined' ? this.props.shipment_method.target.value : 'custom'}>
                            {/* Recogida MRW */}
                            { this.props.zipcodes.toString().indexOf(this.props.user.zipcode) === -1 ?
                                <React.Fragment>
                                    <Radio 
                                        value={'mrw'}>
                                        <span className={'pl-2 font-weight-bold'}>{t('MRW')}</span><span className={'pl-3 font-weight-bold'}>{t('5,00€ + IVA')}</span>
                                    </Radio>
                                    <div className={'row'}>
                                        <div className={'col-12 pl-5'}>
                                            <p className={'fs-1 edit-gray my-1'}>
                                                {t('Las muestras se entregan al día siguiente de la recogida. Solo disponible para la península')}.
                                            </p>
                                        </div>
                                    </div>
                                    <div className={'row mb-3'}>
                                        <div className={'col-12 pl-5'}>
                                            <p className={'fs-08 bg-box rounded p-4'}>
                                                {t(' Las muestras deben estar preparadas antes de gestionar la solicitud de recogida')}. 
                                                <b>{t('Se pueden aplicar cargos')}</b> {t('en caso de que el mensajero pase a recoger las muestras y estas no estén listas. Por favor, ')} 
                                                <b>{t('asegúrate de que las muestras están debidamente identificadas en los portaobjetos')}</b> 
                                                {t('o las cajas de porta-citos, especialemente si remites muestras de más de un paciente. Si ya has solicitado una recogida y el mensajero no ha pasado aún, no es necesario volver a solicitarla. En ese caso selecciona la opción ')}
                                                <b>{t('"no necesito recogida"')}</b>.
                                            </p>
                                        </div>
                                    </div>
                                    <div className={'row mb-5'}>
                                        <div className={'col-12 pl-5'}>
                                            <p className={'fs-09 poppins'}>{t('Horario de recogida')}</p>
                                            <Select 
                                                id={'hora_inicio'}
                                                placeholder={this.state.horas['9']}
                                                className={'w-25 fs-09 mb-3 mr-3'}
                                                value={this.props.hora_inicio}
                                                onChange={this.props.inputFieldChanged.bind(this, 'hora_inicio')}>

                                                    {typeof this.state.horas !== 'undefined' ? 
                                                        Object.entries(this.state.horas).map(([key, hora]) => {
                                                            return(
                                                                <Option key={key} value={key}>{hora}</Option>
                                                            );
                                                        })
                                                    : null}

                                            </Select>

                                            { t(' a ') }

                                            <Select 
                                                id={'hora_fin'}
                                                placeholder={this.state.horas['9']}
                                                className={'w-25 fs-09 mb-3 ml-3'}
                                                value={this.props.hora_fin}
                                                onChange={this.props.inputFieldChanged.bind(this, 'hora_fin')}>

                                                    {typeof this.state.horas !== 'undefined' ? 
                                                        Object.entries(this.state.horas).map(([key, hora]) => {
                                                            return(
                                                                <Option key={key} value={key}>{hora}</Option>
                                                            );
                                                        })
                                                    : null}

                                            </Select>

                                            {(typeof this.state.formError.horario !== 'undefined' && this.state.formError.horario !== '') ?
                                                <p className={'red fs-09 mt-0 pl-3'}>{this.state.formError.horario}</p>
                                            : null }
                                        </div>
                                    </div>
                                </React.Fragment>
                            : null }

                            {/* Recogida express */}
                            { this.props.zipcodes.toString().indexOf(this.props.user.zipcode) !== -1 ?
                                <React.Fragment>
                                    <Radio 
                                        value={'express'}>
                                        <span className={'pl-2 font-weight-bold'}>{t('Recogida Express')}</span>
                                    </Radio>
                                    <div className={'row mb-4'}>
                                        <div className={'col-12 pl-5'}>
                                            <p className={'fs-1 edit-gray my-1'}>
                                                {t('Para la entrega el mismo día (en horario de 10h a 15h y de 16h a 19h)')}.
                                            </p>
                                            <p className={'fs-1 purple my-1'}>
                                                {t('Contáctanos vía WhatsApp o llamando al +34 640 690 463')}.
                                            </p>
                                        </div>
                                    </div>
                                </React.Fragment>
                            : null }
                            

                            {/* Recogida propia */}
                            <Radio 
                                value={'custom'}>
                                <span className={'pl-2 font-weight-bold'}>{t('No necesito recogida')}</span>
                            </Radio>
                            <div className={'row'}>
                                <div className={'col-12 pl-5'}>
                                    <p className={'fs-1 edit-gray my-1'}>
                                        {t('Voy a utilizar mi propio servicio de mensajería')}.
                                    </p>
                                </div>
                            </div>
                        </Radio.Group>
                    </div>
                </div>
                                
            </React.Fragment>
        )
    }

    _saveData() {
        const { t } = this.props;
        let error = false;
        const formError = {};
        this.setState({ formError : formError })
        this.setState({ isLoaded : false })

        if (typeof this.state.dataSource === 'undefined' || this.state.dataSource === '' || Object.keys(this.state.dataSource).length === 0){
            formError.muestras = t('No puedes solicitar una recogida sin muestras. Por favor añade alguna.')
            this.setState({ formError : formError })
            error = true
        }

        if(
            this.props.shipment_method.target.value === 'mrw' && 
            (typeof this.props.hora_inicio === 'undefined' || typeof this.props.hora_fin === 'undefined')
        ){
            formError.horario = t('Debes seleccionar un horario para la recogida, con hora de inicio y hora de fin.')
            this.setState({ formError : formError })
            error = true
        }

        if(this.props.shipment_method.target.value === 'mrw' && (parseInt(this.props.hora_inicio) >= parseInt(this.props.hora_fin))){
            formError.horario = t('La hora de inicio tiene que ser menor que la hora de fin.')
            this.setState({ formError : formError })
            error = true
        }

        if(error === false){
            Promise.resolve(this.props.saveRecogida(
                this.state.dataSource, 
                (typeof this.props.shipment_method !== 'undefined' && typeof this.props.shipment_method.target !== 'undefined' ? this.props.shipment_method.target.value : false),
                this.props.hora_inicio,
                this.props.hora_fin
            ))
            .then((response)=> {
                this.setState({ isLoaded : true })

                if(this.props.dataError === false){
                    this.setState({ showModal : true })
                }
            });
        }else{
            this.setState({ isLoaded : true })
        }
    }

    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <div className={"row align-items-center box-with-shadow py-3 mb-5"}>
                    <div className={"col-12 col-md-6 text-left"}>
                        <a href="/">
                          <img
                            src={logo}
                            className={"logo"}
                            alt={"Logo " + types.COMPANY}
                          />
                        </a>
                    </div>

                    <div className={"col-12 col-md-6 text-right"}>
                    </div>
                </div>
                <div className={'container'}>
                    <div className={"row mb-5"}>
                        <div className={"col-12 col-md-8 offset-md-2 mb-5 pb-5"}>
                            { this.state.isLoaded ?
                                this._renderForm()
                            :
                                <Loader t={t} />
                            }
                        </div>
                    </div>
                </div>
                <div className={"row align-items-center box-with-shadow py-3 fixed-bottom"}>
                    { Object.keys(this.state.formError).length ? 
                        <div className={'col-12 text-center'}>
                           <p className={'red fs-09 mt-3'}>{t('El formulario contiene errores. Revísalo por favor.')}</p>
                        </div>
                    : null
                    }

                    { this.props.dataError ? 
                        <div className={'col-12 text-center'}>
                           <p className={'red fs-09 mt-3'}>{ this.props.dataErrorMessage.message }</p>
                        </div>
                    : null
                    }

                    <div className={"col-6 text-left"}>
                        <a href="/muestras" className={'btn-white py-3 d-none d-md-inline-block'}>
                          {t('Cancelar')}
                        </a>

                        <a href="/muestras" className={'btn-white py-3 d-md-none'}>
                          {t('Cancelar')}
                        </a>
                    </div>

                    <div className={"col-6 text-right"}>
                        { this.state.isLoaded ?
                            <button className={'btn-purple py-3'} onClick={this._saveData.bind(this)}>
                              {t('Solicitar recogida')}
                            </button>
                        :
                            <button className={'btn-purple disabled py-3'} onClick={()=>{ return false }}>
                              {t('Solicitar recogida')}
                            </button>
                        }
                    </div>
                </div>

                <Modal 
                    visible={this.state.showModal}
                    footer={null}
                    closable={false}
                >
                    <div className={'row mt-3'}>
                        <div className={'col-12 text-center'}>
                            <ResultOk />
                            <p className={'poppins text-uppercase font-weight-bold fs-13 my-2 px-4'}>{t('TU SOLICITUD HA SIDO CONFIRMADA')}</p>
                        </div>

                        { typeof this.props.shipment_method !== 'undefined' && typeof this.props.recogida !== 'undefined' && this.props.shipment_method.target.value === 'mrw' ?
                            <React.Fragment>
                                <div className={'col-12 text-center'}>
                                    <p className={'gray07 fs-12 lh-12 my-3 px-4'}>{t('Gracias por solicitar la recogida de una nueva muestra. Tu envío estará con nosotros pronto.')}</p>
                                </div>
                                <div className={'col-12 py-4 text-center'}>
                                    <a href={types.API_URL + 'get-etiqueta/' + this.props.recogida.envio} className={'btn-white'} target={'_blank'}>
                                        <DownloadTable /> <span className={"pl-1"}>{t('Descargar etiqueta de envío')}</span>
                                    </a>
                                    <p className={'purple fs-1 lh-1 my-3 px-4'}>{t('No es necesario imprimir las etiquetas.')}</p>
                                </div>
                            </React.Fragment>
                        : null }

                        { typeof this.props.shipment_method !== 'undefined' && this.props.shipment_method.target.value === 'express' ?
                            <React.Fragment>
                                <div className={'col-12 text-center'}>
                                    <p className={'gray07 fs-12 lh-12 my-3 px-4'}>{t('Para que pueda ser realizada la recogida express contacta vía WhatsApp o llamando al +34 640 690 463 para que podamos gestionarla.')}</p>
                                </div>
                            </React.Fragment>
                        : null }

                        { typeof this.props.shipment_method !== 'undefined' && this.props.shipment_method.target.value === 'custom' ?
                            <React.Fragment>
                                <div className={'col-12 text-center'}>
                                    <p className={'gray07 fs-12 lh-12 my-3 px-4'}>{t('Recuerda que no se ha solicitado ninguna recogida, por lo que deberás gestionar el envío de muestras por tu cuenta. Si has solicitado una recogida anterior y aún no ha pasado el mensajero, este se llevará todas las muestras a la vez.')}</p>
                                </div>
                            </React.Fragment>
                        : null }

                        <div className={'col-12 pt-5 pb-3 text-center'}>
                            <a href={'/muestras'} className={'btn-purple-text'}>{t('Volver a muestras')}</a>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }

}

function mapStateToProps(state) {
    const storeUsers = state.users;
    const storeMuestras = state.muestras;
    const storeRecogidas = state.recogidas;

    return {
        isLoggedUser : storeUsers.isLogged,
        user: storeUsers.user,
        shipment_method: storeRecogidas.shipment_method,
        hora_inicio: storeRecogidas.hora_inicio,
        hora_fin: storeRecogidas.hora_fin,
        muestras : storeMuestras.muestras,
        recogida : storeRecogidas.recogida,
        zipcodes : storeRecogidas.zipcodes,
        dataError : storeRecogidas.dataError,
        dataErrorMessage : storeRecogidas.dataErrorMessage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        isLogged: redirectTo => dispatch(isLogged()),
        inputFieldChanged: (field, text) => dispatch(inputFieldChanged(field, text)),
        getUserMuestras: (status) => dispatch(getUserMuestras(status)),
        getExpressZipcodes: redirectTo => dispatch(getExpressZipcodes()),
        saveRecogida: (muestras, shipment_method, hora_inicio, hora_fin) => dispatch(saveRecogida(muestras, shipment_method, hora_inicio, hora_fin)),
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(Recogida));



