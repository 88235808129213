import { types } from '../constants/constants';
import { push } from 'react-router-redux';

/** Método que almacena en el store de Redux los inputs que
    el usuario va rellenando, para garantizar la persistencia
    de los datos.
    @param string $field el nombre del input
    @param string $value el valor del input
**/
export function inputFieldChanged(field, value) {
    return function(dispatch) {
        dispatch({ type: "TARIFA_INPUT_FIELD_CHANGED", payload: {field : field, value : value} });
    }
}

/** Método que manda a la API los datos para crear
    una nueva tarifa
**/
export function saveTarifa(service, description, time, price) {
    return async function(dispatch) {
        try
        {
            var formBody = [];
            
            var details={ 
                service : service,
                description : description,
                time: time,
                price : price,
            };

            for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(details[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
            }

            var status = null;
            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'tarifa/create', {
                method: 'post',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    "token": token
                },
                body: formBody.join("&"),
            })
            .then(function(response) {
                status = response.status; // Get HTTP status code
                return response.json();
            })
            .then((responseJson, response) => {
                if(status === 200){ // If success
                    dispatch({ 
                        type: "CREATE_TARIFA", 
                        payload: { 
                            muestra : responseJson.muestra
                        }
                    });
                }else{
                    if(status === 401){ 
                        push('/') 
                    }else{
                        dispatch({ type: "CREATE_TARIFA_ERROR", payload: { message : responseJson } });
                    }
                }
            })
            .catch((error) => {
                let errorMessage = 'Hubo un problema conectando con la API: ' + error;
                dispatch({ type: "CREATE_TARIFA_ERROR", payload: { message : errorMessage } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que manda a la API los datos para editar
	una tarifa
**/
export function updateTarifa(id, service, description, time, price) {
    return async function(dispatch) {
        try
        {
            var formBody = [];
            
            var details={ 
                id : id,
                service : service,
                description : description,
                time: time,
                price : price,
            };

            for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(details[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
            }

            var status = null;
            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'tarifa/update', {
                method: 'post',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    "token": token
                },
                body: formBody.join("&"),
            })
            .then(function(response) {
                status = response.status; // Get HTTP status code
                return response.json();
            })
            .then((responseJson, response) => {
                if(status === 200){ // If success
                	dispatch({ 
                        type: "UPDATE_TARIFA", 
                        payload: { 
                            muestra : responseJson.muestra
                        }
                    });
                }else{
                    if(status === 401){ 
                        push('/') 
                    }else{
                        dispatch({ type: "UPDATE_TARIFA_ERROR", payload: { message : responseJson } });
                    }
                }
            })
            .catch((error) => {
                let errorMessage = 'Hubo un problema conectando con la API: ' + error;
                dispatch({ type: "UPDATE_TARIFA_ERROR", payload: { message : errorMessage } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que obtiene el listado de todas las tarifas
    desde la API
**/
export function getTarifas() {
    return async function(dispatch) {
        try
        {
            let url = types.API_URL + 'tarifa/list'

            var status = null;
            const token = localStorage.getItem('token');

            return fetch(url, {
                method: 'get',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    "token": token
                }
            })
            .then(function(response) {
                status = response.status; // Get HTTP status code
                return response.json();
            })
            .then((responseJson, response) => {
                if(status === 200){ // If success
                    dispatch({ 
                        type: "TARIFA_LIST", 
                        payload: responseJson
                    });
                }else{
                    if(status === 401){ 
                        push('/') 
                    }else{
                        dispatch({ type: "TARIFA_LIST_ERROR", payload: { message : responseJson } });
                    }
                }
            })
            .catch((error) => {
                let errorMessage = 'Hubo un problema conectando con la API: ' + error;
                dispatch({ type: "TARIFA_LIST_ERROR", payload: { message : errorMessage } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que obtiene una tarifa concreta por ID dado
    desde la API
**/
export function getTarifa(id) {
    return async function(dispatch) {
        try
        {
            let url = types.API_URL + 'tarifa/' + id

            var status = null;
            const token = localStorage.getItem('token');

            return fetch(url, {
                method: 'get',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    "token": token
                }
            })
            .then(function(response) {
                status = response.status; // Get HTTP status code
                return response.json();
            })
            .then((responseJson, response) => {
                if(status === 200){ // If success
                    dispatch({ 
                        type: "GET_TARIFA", 
                        payload: responseJson
                    });
                }else{
                    if(status === 401){ 
                        push('/') 
                    }else{
                        dispatch({ type: "GET_TARIFA_ERROR", payload: { message : responseJson } });
                    }
                }
            })
            .catch((error) => {
                let errorMessage = 'Hubo un problema conectando con la API: ' + error;
                dispatch({ type: "GET_TARIFA_ERROR", payload: { message : errorMessage } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que manda a la API el id para eliminar
    una tarifa
**/
export function deleteTarifa(id) {
    return async function(dispatch) {
        try
        {
            var formBody = [];
            
            var details={ 
                id : id
            };

            for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(details[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
            }

            var status = null;
            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'tarifa/delete', {
                method: 'post',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    "token": token
                },
                body: formBody.join("&"),
            })
            .then(function(response) {
                status = response.status; // Get HTTP status code
                return response.json();
            })
            .then((responseJson, response) => {
                if(status === 200){ // If success
                    dispatch({ 
                        type: "DELETE_TARIFA", 
                        payload: {}
                    });
                }else{
                    if(status === 401){ 
                        push('/') 
                    }else{
                        dispatch({ type: "DELETE_TARIFA_ERROR", payload: { message : responseJson } });
                    }
                }
            })
            .catch((error) => {
                let errorMessage = 'Hubo un problema conectando con la API: ' + error;
                dispatch({ type: "DELETE_TARIFA_ERROR", payload: { message : errorMessage } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}