const prod = {
	COMPANY: 'T-CITO',
  	API_URL: 'https://api.t-cito.com/',
  	SITE_URL: 'https://clientes.t-cito.com/',
  	TRANSLATION_URL: 'https://clientes.t-cito.com/'
};

const dev = {
	COMPANY: 'T-CITO',
  	API_URL: 'http://api.t-cito.local/',
  	SITE_URL: 'http://localhost:3000/',
  	TRANSLATION_URL: 'https://clientes.t-cito.com/'
};

export const types = process.env.NODE_ENV === 'development' ? dev : prod;