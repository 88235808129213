import React from "react"

const Recogidas = (props) => {
  return (
  	<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
	    <title>5FAF28AE-AD07-43BC-ACBA-CEFDF207B3AE</title>
	    <desc>Created with sketchtool.</desc>
	    <defs>
	        <path d="M2,9 L1,9 C0.44771525,9 1.11910481e-13,8.55228475 1.11910481e-13,8 C1.11799459e-13,7.44771525 0.44771525,7 1,7 L2,7 L2,6 L1,6 C0.44771525,6 1.11910481e-13,5.55228475 1.11910481e-13,5 C1.11799459e-13,4.44771525 0.44771525,4 1,4 L2,4 L2,1 C2,0.44771525 2.44771525,0 3,0 L12.9415283,0 C13.4938131,8.8817842e-16 13.9415283,0.44771525 13.9415283,1 L13.9415283,3 L18.6791047,3 C18.9660184,3 19.2391108,3.12323742 19.42894,3.33837551 L20.7498353,4.83537871 C20.9110425,5.01807871 21,5.25334971 21,5.4970032 L21,12 C21,12.5522847 20.5522847,13 20,13 L18.9499909,13 C18.7183558,14.1411202 17.709479,15 16.5,15 C15.290521,15 14.2816442,14.1411202 14.0500091,13 L13,13 C12.9902213,13 12.9804754,12.9998596 12.9707642,12.9995808 C12.9610529,12.9998596 12.951307,13 12.9415283,13 L9.94999094,13 C9.71835578,14.1411202 8.70947896,15 7.5,15 C6.29052104,15 5.28164422,14.1411202 5.05000906,13 L3,13 C2.44771525,13 2,12.5522847 2,12 L2,9 Z M2,8 L1,8 L2,8 Z M4,7 L7,7 C7.55228475,7 8,7.44771525 8,8 C8,8.55228475 7.55228475,9 7,9 L4,9 L4,11 L5.49981691,11 C5.95592007,10.3927766 6.68209108,10 7.5,10 C8.31790892,10 9.04407993,10.3927766 9.50018309,11 L11.9415283,11 L11.9415283,2 L4,2 L4,4 L5,4 C5.55228475,4 6,4.44771525 6,5 C6,5.55228475 5.55228475,6 5,6 L4,6 L4,7 Z M4,8 L7,8 L4,8 Z M2,5 L1,5 L2,5 Z M4,5 L5,5 L4,5 Z M14.4998169,11 C14.9559201,10.3927766 15.6820911,10 16.5,10 C17.3179089,10 18.0440799,10.3927766 18.5001831,11 L19,11 L19,5.87510992 L18.2278383,5 L14,5 L14,11 L14.4998169,11 Z M7.5,13 C7.77614237,13 8,12.7761424 8,12.5 C8,12.2238576 7.77614237,12 7.5,12 C7.22385763,12 7,12.2238576 7,12.5 C7,12.7761424 7.22385763,13 7.5,13 Z M16.5,13 C16.7761424,13 17,12.7761424 17,12.5 C17,12.2238576 16.7761424,12 16.5,12 C16.2238576,12 16,12.2238576 16,12.5 C16,12.7761424 16.2238576,13 16.5,13 Z" id="path-recogidas"></path>
	    </defs>
	    <g id="Asset-Artboard-Page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
	        <g id="icono-/-test-tube">
	            <g id="Color-/-Purple" transform="translate(1.000000, 4.000000)">
	                <mask id="mask-recogidas" fill="white">
	                    <use xlinkHref="#path-recogidas"></use>
	                </mask>
	                <use id="Mask" fill="#979797" fillRule="nonzero" xlinkHref="#path-recogidas"></use>
	                <g mask="url(#mask-recogidas)" className="recogidas-icon" fill="#DD9DEF" id="Rectangle">
	                    <g transform="translate(-1.000000, -4.000000)">
	                        <rect x="0" y="0" width="24" height="24"></rect>
	                    </g>
	                </g>
	            </g>
	        </g>
	    </g>
	</svg>
  )
}

export default Recogidas;