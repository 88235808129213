export const $$initialState = {
    pictures : [],
    dataError : false,
    dataErrorMessage : ''

};

export default function registerReducer($$state = $$initialState, action = null) {
    switch (action.type) {
        case 'CLEAN_STORE':
            return Object.assign({}, $$state, {
                report : undefined,
                template : undefined,
                title : undefined,
                clinical_history : '',
                description : '',
                interpretation : '',
                comments : '',
                pictures : [],
                dataError : false,
                dataErrorMessage : '',
            })
        case 'REPORT_INPUT_FIELD_CHANGED':
            return Object.assign({}, $$state, {
                [action.payload.field]: action.payload.value,
                dataErrorMessage: { [action.payload.field] : '' }
            });

        case 'CREATE_REPORT':
            return Object.assign({}, $$state, {
                report : action.payload.report,
                dataError: false,
                dataErrorMessage : '',
            });

        case 'CREATE_REPORT_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage : action.payload.message,
            });

        case 'UPDATE_REPORT':
            return Object.assign({}, $$state, {
                report : action.payload.report,
                dataError: false,
                dataErrorMessage : '',
            });

        case 'UPDATE_REPORT_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage : action.payload.message,
            });

        case 'REPORT_LIST':
            return Object.assign({}, $$state, {
                reports : action.payload,
                dataError: false,
                dataErrorMessage : '',
            });

        case 'REPORT_LIST_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage : action.payload.message,
            });

        case 'REPORT_LIST_BY_REPORT':
            return Object.assign({}, $$state, {
                reports : action.payload,
                dataError: false,
                dataErrorMessage : '',
            });

        case 'REPORT_LIST_BY_REPORT_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage : action.payload.message,
            });

        case 'GET_REPORT':
            return Object.assign({}, $$state, {
                report : action.payload,
                dataError: false,
                dataErrorMessage : '',
            });

        case 'GET_REPORT_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage : action.payload.message,
            });

        case 'GET_REPORT_IMAGES':
            return Object.assign({}, $$state, {
                report_images : action.payload,
                dataError: false,
                dataErrorMessage : '',
            });

        case 'GET_REPORT_IMAGES_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage : action.payload.message,
            });

        case 'DELETE_REPORT':
            return Object.assign({}, $$state, {
                dataError: false,
                dataErrorMessage : '',
            });

        case 'DELETE_REPORT_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage : action.payload.message,
            });

        default: {
            return $$state;
        }
    }
}
