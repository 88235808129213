import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import Menu from '../../components/menu';
import CustomTable from '../../components/table';
import MuestrasIcon from "../../components/icons/muestras";
import RecogidasIcon from "../../components/icons/recogidas";
import deleteIcon from '../../assets/img/delete-icon.svg';

import { Modal } from 'antd';
import { ExclamationCircleOutlined,
            LoadingOutlined } from '@ant-design/icons';

import { getMuestras,
            deleteMuestra } from '../../actions/muestra.js';
import { isLogged } from '../../actions/user.js';

import { withTranslation } from 'react-i18next';

const { confirm } = Modal;

class AdminMuestrasPrint extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded : true,
            dataSource : [],
            filteredInfo: null
        };
    }

    componentDidMount() {
        const { i18n } = this.props;

        let lang = typeof this.props.match.params.lang !== 'undefined' ? this.props.match.params.lang : null;

        if(lang === 'es' || lang === 'en'){ 
            i18n.changeLanguage(lang) 
        }

        this._getApiData()
    }

    _getApiData(){
        this.setState({ isLoaded : false})

        Promise.resolve(this.props.isLogged())
        .then((response)=> {
            if(this.props.isLoggedUser){
                if(this.props.user.role.code != 0){
                    this.props.history.push('/404')
                }
                
                Promise.resolve(this.props.getMuestras())
                .then((response)=> {
                    if(this.props.muestras){
                        let dataSource = [];

                        Promise.resolve(this.props.muestras.map((muestra, key) =>{
                            if(muestra.status > 0){ // Solo muestras ya recogidas
                                let date = new Date(muestra.date);
                                let fecha = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
                                let item = {
                                    id : muestra.id,
                                    lab_id : muestra.lab_id,
                                    fecha : fecha,
                                    timestamp : date.getTime(),
                                    cliente : muestra.customer,
                                    tipo : muestra.title,
                                    paciente : muestra.animal_name,
                                    estado : muestra.status
                                }

                                dataSource.push(item);
                            }
                        })).then(result=>{
                            this.setState({ dataSource : dataSource })
                            this.setState({ isLoaded : true })
                            
                            setTimeout(()=>{
                                window.open("about:blank", "_self")
                                window.print()
                                window.close()
                            }, 2000)
                        })  
                    }
                });

            }else{
                this.props.history.push('/login')
            }
        });
    }

    _renderTable() {
        const { t } = this.props;
        const { dataSource } = this.state;
        let { filteredInfo } = this.state;

        return (
            <React.Fragment>
                <div className={'row'}>
                    <div className={'col-12 pt-3 pl-0'}>
                        <h1 className={'mb-3'}>{t('Listado de muestras pendientes')}</h1>
                    </div>
                </div>

                <div className={'row border bg-box'}>
                    <div className={'col fs-11 p-1'}><b>{t('ID')}</b></div>
                    <div className={'col fs-11 p-1'}><b>{t('Cliente')}</b></div>
                    <div className={'col fs-11 p-1'}><b>{t('Tipo análisis')}</b></div>
                    <div className={'col fs-11 p-1'}><b>{t('Paciente')}</b></div>
                    <div className={'col fs-11 p-1'}><b>{t('Fecha')}</b></div>
                </div>

                { dataSource.length ? 
                    dataSource.map((muestra, key) => {
                        if(muestra.estado == 1){
                            return(
                                <React.Fragment>
                                    <div className={'row border-bottom'}>
                                        <div className={'col p-1'}>{muestra.lab_id}</div>
                                        <div className={'col p-1'}>{muestra.cliente}</div>
                                        <div className={'col p-1'}>{muestra.tipo}</div>
                                        <div className={'col p-1'}>{muestra.paciente}</div>
                                        <div className={'col p-1'}>{muestra.fecha}</div>
                                    </div>                
                                </React.Fragment>
                            )
                        }
                    })
                :
                    <p>{ t('No hay ninguna muestra pendiente') }</p>
                }
            </React.Fragment>
        )
    }

    _renderLoader(){
        return ('')
    }

    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <div className={"container"}>
                    { this._renderTable() }
                </div>
            </React.Fragment>
        )
    }

}

function mapStateToProps(state) {
    const storeUsers = state.users;
    const storeMuestras = state.muestras;

    return {
        isLoggedUser : storeUsers.isLogged,
        user: storeUsers.user,
        muestras : storeMuestras.muestras,
        dataError : storeMuestras.dataError,
        dataErrorMessage : storeMuestras.dataErrorMessage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        isLogged: redirectTo => dispatch(isLogged()),
        getMuestras: () => dispatch(getMuestras()),
        deleteMuestra: (id) => dispatch(deleteMuestra(id))
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(AdminMuestrasPrint));



