import React from 'react';
import { types } from '../constants/constants';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { inputFieldChanged,
            doLogin,
            doRecover,
            isLogged } from '../actions/user.js';

import InputText from '../components/input';
import Testimonials from '../components/testimonials';

import logo from '../assets/img/logo-t-cito-black-typo.svg'

import { withTranslation } from 'react-i18next';

class LoginAdmin extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded : true,
            showForm : true,
            showRecover: false,
            showUpdate: false
        };
    }

    componentDidMount() {
        const { i18n } = this.props;

        let lang = typeof this.props.match.params.lang != 'undefined' ? this.props.match.params.lang : null;

        if(lang){ 
            i18n.changeLanguage(lang) 
        }

        this.props.inputFieldChanged('successMessage', '')

        Promise.resolve(this.props.isLogged())
        .then((response)=> {
            if(this.props.isLoggedUser === true){
                this.props.history.push('/')
            }
        });

    }

    _showForm() {
        this.setState({ 'showForm' : true, 'showRecover' : false })
    }

    _showRecover() {
        this.setState({ 'showForm' : false, 'showRecover' : true })
    }

    _saveData(){
        Promise.resolve(this.props.doLogin(this.props.email, this.props.password))
        .then((response)=> {
            if(this.props.isLoggedUser === true){
                if(this.props.redirectTo !== null && this.props.redirectTo !== '/login'){
                    this.props.history.push(this.props.redirectTo);
                }else{
                    if (this.props.user.role.code === 0) { // Admin
                        this.props.history.push('/admin/muestras');
                    }else{    
                        this.props.history.push('/');
                    }
                }
            }
        });
    }

    _saveDataRecover(){
        this.props.inputFieldChanged('successMessage', '')

        Promise.resolve(this.props.doRecover(this.props.email))
        .then((response)=> {
            
        })
    }

    _renderLoginForm() {
        const { t } = this.props;
        return (
            <React.Fragment>

                <form name="login-form" method="post" onSubmit={e => { e.preventDefault(); }}>
                    <div className={'row mt-3 mb-3 mx-5'}>
                        <div className={'col-12 text-left'}>
                            <h1 className={'fs-12'}>{t('Accede a tu cuenta')}</h1>
                        </div>
                    </div>
                    <div className={'row mx-5'}>

                        <div className={'col-12 mb-4'}>
                            <InputText
                                id={'email'}
                                name={'email'}
                                placeholder={t('Email')}
                                classes={'w-100 mb-3'}
                                label={t('Email')}
                                value={this.props.email}
                                onChange={this.props.inputFieldChanged.bind(this)}
                                error={this.props.dataErrorMessage.email}  
                                />

                            <InputText
                                id={'password'}
                                name={'password'}
                                type={'password'}
                                placeholder={t('Contraseña')}
                                classes={'w-100'}
                                label={t('Contraseña')}
                                value={this.props.password}
                                onChange={this.props.inputFieldChanged.bind(this)}
                                error={this.props.dataErrorMessage.password} 
                                />
                        </div>

                        { typeof this.props.dataErrorMessage.login !== 'undefined' 
                            && this.props.dataErrorMessage.login !== ''
                            && this.props.dataErrorMessage.login.length ?

                            <div className="col-12">
                                <p className={'red fs-09 mt-1'}>{ this.props.dataErrorMessage.login }</p>
                            </div>

                        : null }

                        <div className={'col-12 text-center mt-4'}>
                            <button type={'button'} className={'btn-purple text-center'} onClick={this._saveData.bind(this)}>
                                {t('Acceder')}
                            </button>
                        </div>

                        <div className={'col-12 text-center mt-5 mb-3'}>
                            <span className={'button-text'}>{t('¿Has olvidado tu contraseña?')}</span>
                            <a className={'mt-4 purple'} onClick={ this._showRecover.bind(this) }>
                                {' '}{t('Recuperar')}
                            </a>
                        </div>
                    </div>
                </form>

            </React.Fragment>
        )
    }

    _renderRecoverForm() {
        const { t } = this.props;
        return (
            <React.Fragment>

                <form name="login-form" method="post">
                    <div className={'row mx-5'}>
                        <div className={'col-12 text-left mb-2'}>
                            <p className={'bold dark-gray'}>{t('Introduce tu email')}</p>
                        </div>

                        <div className={'col-12'}>
                            <InputText
                                id={'email'}
                                name={'email'}
                                placeholder={t('Email')}
                                classes={'w-100'}
                                label={t('Email')}
                                value={this.props.email}
                                onChange={this.props.inputFieldChanged.bind(this)}
                                error={this.props.dataErrorMessage.email}  
                                />
                        </div>

                        { typeof this.props.dataErrorMessage.recover !== 'undefined' 
                            && this.props.dataErrorMessage.recover !== ''
                            && this.props.dataErrorMessage.recover.length ?

                            <div className="col-12 mt-4">
                                <p className={'red fs-09 mt-1'}>{ this.props.dataErrorMessage.recover }</p>
                            </div>

                        : null }

                        { typeof this.props.successMessage !== 'undefined' 
                            && this.props.successMessage !== '' ?

                            <div className="col-12 mt-4">
                                <p className={'text-success fs-09 mt-1'}>{ this.props.successMessage }</p>
                            </div>

                        : null }

                        <div className={'col-12 text-right mt-4'}>
                            <a className={'mt-4'} onClick={ this._showForm.bind(this) }>
                                <span className={'button-text purple'}>{t('Identificarte')}</span>
                            </a>
                        </div>

                        <div className={'col-12 text-center mt-4'}>
                            <button type={'button'} className={'btn-purple text-center'} onClick={this._saveDataRecover.bind(this)}>
                                {t('Enviar')}
                            </button>
                        </div>
                    </div>
                </form>

            </React.Fragment>
        )
    }

    render () {
        const { t } = this.props;
        return (
            <React.Fragment>            
                <div className={'container'}>
                    <div className={'d-flex flex-column justify-content-center'} style={{height: '90vh'}}>
                        <div className={'row mb-5'}>
                            <div className={"col-12 text-center"}>
                                <a href="/">
                                  <img
                                    src={logo}
                                    className={"logo"}
                                    alt={"Logo " + types.COMPANY}
                                  />
                                </a>
                            </div>
                        </div>

                        <div className={'row pb-5 m-0'}>
                            <div className={'col-6 offset-3 box-with-shadow px-5 pt-5 align-self-center'}>
                                { 
                                    this.state.showForm ?
                                        this._renderLoginForm()
                                    : null
                                }
                                {   
                                    this.state.showRecover ?
                                        this._renderRecoverForm()
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const storeUsers = state.users;

    return {
        isLoggedUser : storeUsers.isLogged,
        user : storeUsers.user,
        email : storeUsers.email,
        password : storeUsers.password,
        dataError : storeUsers.dataError,
        dataErrorMessage: storeUsers.dataErrorMessage,
        redirectTo: storeUsers.redirectTo,
        successMessage : storeUsers.successMessage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        inputFieldChanged: (field, text) => dispatch(inputFieldChanged(field, text)),
        doLogin: (email, password) => dispatch(doLogin(email, password)),
        doRecover: (email) => dispatch(doRecover(email)),
        isLogged: redirectTo => dispatch(isLogged())
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(LoginAdmin));