import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import Menu from '../../components/menu';
import CustomTable from '../../components/table';
import MuestrasIcon from "../../components/icons/muestras";
import RecogidasIcon from "../../components/icons/recogidas";
import deleteIcon from '../../assets/img/delete-icon.svg';

import { Modal } from 'antd';
import { ExclamationCircleOutlined,
            LoadingOutlined } from '@ant-design/icons';

import { getMuestras,
            deleteMuestra } from '../../actions/muestra.js';
import { isLogged } from '../../actions/user.js';

import { withTranslation } from 'react-i18next';

const { confirm } = Modal;

class AdminMuestras extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded : true,
            dataSource : [],
            filteredInfo: null
        };
    }

    componentDidMount() {
        const { i18n } = this.props;

        let lang = typeof this.props.match.params.lang !== 'undefined' ? this.props.match.params.lang : null;

        if(lang === 'es' || lang === 'en'){ 
            i18n.changeLanguage(lang) 
        }

        this._getApiData()
    }

    _getApiData(){
        this.setState({ isLoaded : false})

        Promise.resolve(this.props.isLogged())
        .then((response)=> {
            if(this.props.isLoggedUser){
                if(this.props.user.role.code != 0){
                    this.props.history.push('/404')
                }
                
                Promise.resolve(this.props.getMuestras())
                .then((response)=> {
                    if(this.props.muestras){
                        let dataSource = [];

                        this.props.muestras.map((muestra, key) =>{
                            if(muestra.status > 0){ // Solo muestras ya recogidas
                                let date = new Date(muestra.date);
                                let fecha = date.toLocaleDateString('es-ES', { timeZone: 'Europe/Madrid' });
                                let item = {
                                    id : muestra.id,
                                    lab_id : muestra.lab_id,
                                    fecha : fecha,
                                    timestamp : date.getTime(),
                                    cliente : muestra.customer,
                                    tipo : muestra.title,
                                    paciente : muestra.animal_name,
                                    estado : muestra.status
                                }

                                dataSource.push(item);
                            }
                        })

                        this.setState({ dataSource : dataSource })
                        this.setState({ isLoaded : true })
                    }
                });

            }else{
                this.props.history.push('/login')
            }
        });
    }

    _renderTable() {
        const { t } = this.props;
        const { dataSource } = this.state;
        let { filteredInfo } = this.state;
        filteredInfo = filteredInfo || {};

        const columns = [
            {
                title: t('ID'),
                dataIndex: "lab_id",
                key: "lab_id",
                ellipsis: true,
                width: 140,
                sorter: (a, b) => a.lab_id.localeCompare(b.lab_id),
            },
            {
                title: t('Cliente'),
                dataIndex: "cliente",
                key: "cliente",
                ellipsis: true,
                width: 200,
                sorter: (a, b) => a.cliente.localeCompare(b.cliente),
            },
            {
                title: t('Tipo de análisis'),
                dataIndex: "tipo",
                key: "tipo",
                ellipsis: true,
                width: 160,
            },
            {
                title: "Paciente",
                dataIndex: "paciente",
                key: "paciente",
                ellipsis: true,
                width: 130,
                sorter: (a, b) => a.paciente.localeCompare(b.paciente),
            },
            {
                title: t('Fecha'),
                dataIndex: "fecha",
                key: "fecha",
                align: "center",
                width: 120,
                sorter: (a, b) => a.timestamp - b.timestamp,
            },
            {
                title: t('Estado'),
                dataIndex: "estado",
                width: 120,
                key: "estado",
                align: "center",
                sorter: (a, b) => a.estado - b.estado,
                filters: [
                  { text: 'Pendiente', value: 1 },
                  { text: 'Finalizado', value: 2 },
                ],
                onFilter: (value, record) => record.estado === value,
                render: (text, record) => (
                    <React.Fragment>
                        { record.estado === 0 ? (
                            <span className={"white-badge poppins w-100 d-inline-block"}>{t('Pendiente de recogida')}</span>
                        ) : null }

                        { record.estado === 1 ? (
                            <span className={"purple-light-badge poppins opacity-3 w-100 d-inline-block"}>{t('Pendiente')}</span>
                        ) : null }

                        { record.estado === 2 ? (
                            <span className={"gray-badge w-100 poppins d-inline-block"}>{t('Finalizado')}</span>
                        ) : null }
                    </React.Fragment>
                )
            },
            {
                title: "",
                key: "herramientas",
                width: 180,
                align: "center",
                render: (text, record) => (
                    <React.Fragment>
                        <a href={'/admin/muestra/' + record.id} className={'btn-purple w-100 lh-24'}>
                            {t('Ver muestra')}
                        </a>
                    </React.Fragment>
                ),
            }
        ]

        const pagination = {  }

        return (
            <React.Fragment>

                <CustomTable
                    columns={columns}
                    dataSource={dataSource}
                    pagination={pagination}
                    loaded={this.state.isLoaded}
                    t={t}
                />
                                
            </React.Fragment>
        )
    }

    _renderLoader(){
        return ('')
    }

    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <div className={"split-container"}>
                    <Menu history={this.props.history} />
                    <div className={"container coachee"}>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <header className={"managementPanel__content-header mb-3"}>
                                    <div className={"col-5"}>
                                        <h1 className={"fs-14 poppins"}>{t('Solicitudes de informe')}</h1>
                                    </div>

                                    <div className={"col-7 text-right"}>
                                        <a href={'/admin/muestras-print'} target={'_blank'} className={'btn-purple'}>{t('Imprimir pendientes')}</a>
                                    </div>
                                </header>
                                
                                <section className={"coachee__content-body"}>
                                    { this._renderTable() }
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

function mapStateToProps(state) {
    const storeUsers = state.users;
    const storeMuestras = state.muestras;

    return {
        isLoggedUser : storeUsers.isLogged,
        user: storeUsers.user,
        muestras : storeMuestras.muestras,
        dataError : storeMuestras.dataError,
        dataErrorMessage : storeMuestras.dataErrorMessage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        isLogged: redirectTo => dispatch(isLogged()),
        getMuestras: () => dispatch(getMuestras()),
        deleteMuestra: (id) => dispatch(deleteMuestra(id))
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(AdminMuestras));



