export const $$initialState = {
    dataError : false,
    dataErrorMessage : ''

};

export default function registerReducer($$state = $$initialState, action = null) {
    switch (action.type) {
        case 'FACTURA_INPUT_FIELD_CHANGED':
            return Object.assign({}, $$state, {
                [action.payload.field]: action.payload.value,
                dataErrorMessage: { [action.payload.field] : '' }
            });

        case 'CREATE_FACTURA':
            return Object.assign({}, $$state, {
                factura : action.payload.factura,
                dataError: false,
                dataErrorMessage : '',
            });

        case 'CREATE_FACTURA_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage : action.payload.message,
            });

        case 'UPDATE_FACTURA':
            return Object.assign({}, $$state, {
                factura : action.payload.factura,
                dataError: false,
                dataErrorMessage : '',
            });

        case 'UPDATE_FACTURA_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage : action.payload.message,
            });

        case 'FACTURA_LIST':
            return Object.assign({}, $$state, {
                facturas : action.payload,
                dataError: false,
                dataErrorMessage : '',
            });

        case 'FACTURA_LIST_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage : action.payload.message,
            });

        case 'FACTURA_USER_LIST':
            return Object.assign({}, $$state, {
                facturas : action.payload,
                dataError: false,
                dataErrorMessage : '',
            });

        case 'FACTURA_USER_LIST_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage : action.payload.message,
            });

        case 'GET_FACTURA':
            return Object.assign({}, $$state, {
                factura : action.payload,
                dataError: false,
                dataErrorMessage : '',
            });

        case 'GET_FACTURA_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage : action.payload.message,
            });

        case 'DELETE_FACTURA':
            return Object.assign({}, $$state, {
                dataError: false,
                dataErrorMessage : '',
            });

        case 'DELETE_FACTURA_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage : action.payload.message,
            });

        default: {
            return $$state;
        }
    }
}
