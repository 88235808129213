import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import Menu from '../components/menu';
import InputText from '../components/input';
import TextAreaInput from '../components/textarea';
import Loader from '../components/loader';
import MuestrasIcon from "../components/icons/muestras";
import RecogidasIcon from "../components/icons/recogidas";
import deleteIcon from '../assets/img/delete-icon.svg';
import animalMap from '../assets/img/animal_map.jpg';

import { Modal, Spin, Checkbox, Radio, Select } from 'antd';
import { ExclamationCircleOutlined,
            LoadingOutlined } from '@ant-design/icons';

import { getMuestra } from '../actions/muestra.js';
import { getTarifas } from '../actions/tarifa.js';
import { isLogged } from '../actions/user.js';

import ImageMapper from 'react-image-mapper';

import { withTranslation } from 'react-i18next';

const { confirm } = Modal;
const { Option } = Select;

class MuestraView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded : false,
            dataSource : [],
            locations : ['1', '2', '>2'],
            map : {
                name : 'AnimalMap',
                areas : []
            },
        };
    }

    componentDidMount() {
        const { i18n } = this.props;

        let lang = typeof this.props.match.params.lang !== 'undefined' ? this.props.match.params.lang : null;

        if(lang === 'es' || lang === 'en'){ 
            i18n.changeLanguage(lang) 
        }

        this._getApiData()
    }

    _getApiData(){
        this.setState({ isLoaded : false})

        let id = typeof this.props.match.params.id !== 'undefined' ? this.props.match.params.id : null;

        Promise.resolve(this.props.isLogged())
        .then((response)=> {
            if(this.props.isLoggedUser){
                if(this.props.user.role.code === 0){
                    this.props.history.push('/404')
                }

                Promise.resolve(this.props.getTarifas())
                .then((response)=> {
                })
                
                Promise.resolve(this.props.getMuestra(id))
                .then((response)=> {
                    let map = typeof this.props.muestra.injury_location !== 'undefined' && this.props.muestra.injury_location !== 'undefined' ? JSON.parse(this.props.muestra.injury_location) : this.state.map
                    this.setState({ map : map })
                    this.setState({ isLoaded : true })
                });



            }else{
                this.props.history.push('/login')
            }
        });
    }

    _renderHeader() {
        const { t, muestra } = this.props
        let formatedDate = new Date(muestra.date)
        formatedDate = formatedDate.getDate() + ' - ' + (formatedDate.getMonth() + 1) + ' - ' + formatedDate.getFullYear()

        return(
            <React.Fragment>

                <div className={'row row-eq-height'}>
                    <div className={'col-6 border-bottom-gray-min px-0'}>
                        <p className={'font-weight-bold mb-2'}>
                            ID { muestra.lab_id }
                            <span className={'font-weight-normal edit-gray pl-3'}>
                                {t('Fecha de solicitud')}: { formatedDate }
                            </span>
                        </p>

                        <p className={'fs-14 lh-3 my-0'}>{ muestra.customer }</p>
                    </div>
                    <div className={'col-6 border-bottom-gray-min px-0 text-right'}>
                        <a href={'/muestras/'} className={'btn-purple'}>{t('Volver al listado')}</a>
                    </div>
                </div>

                <div className={'row pt-3'}>
                    <div className={'col-1 pl-0'}><p className={'fs-12 edit-gray'}>{t('Especie')}:</p></div>
                    <div className={'col-2 pl-0'}><p className={'fs-12'}>{muestra.animal_species}</p></div>
                    <div className={'col-1'}><p className={'fs-12 edit-gray'}>{t('Sexo')}:</p></div>
                    <div className={'col-2 pl-0'}><p className={'fs-12'}>{muestra.animal_gender}</p></div>
                    <div className={'col-1'}><p className={'fs-12 edit-gray'}>{t('Nombre')}:</p></div>
                    <div className={'col-2 pl-0'}><p className={'fs-12'}>{muestra.animal_name}</p></div>
                    <div className={'col-2 text-right'}><p className={'fs-12 edit-gray'}>{t('Prioridad')}:</p></div>
                    <div className={'col-1 px-0'}><p className={'fs-12 text-uppercase'}>{muestra.priority}</p></div>
                </div>

                <div className={'row'}>
                    <div className={'col-1 pl-0'}><p className={'fs-12 edit-gray'}>{t('Raza')}:</p></div>
                    <div className={'col-2 pl-0'}><p className={'fs-12'}>{muestra.animal_race}</p></div>
                    <div className={'col-1'}><p className={'fs-12 edit-gray'}>{t('Edad')}:</p></div>
                    <div className={'col-2 pl-0'}><p className={'fs-12'}>{muestra.animal_age}</p></div>
                    <div className={'col-1'}><p className={'fs-12 edit-gray'}>{t('Dueño')}:</p></div>
                    <div className={'col-2 pl-0'}><p className={'fs-12'}>{muestra.animal_owner}</p></div>
                </div>

            </React.Fragment>
        )
    }

    _renderMuestraDetails() {
        const { t, muestra } = this.props
        const { cytology, fluid, other } = muestra
        let requestedTest = muestra.requested_test

        let map = this.state.map === '' ? { name : 'AnimalMap', areas : [] } : this.state.map

        return(
            <React.Fragment>

                { /* Muestra remitida */ }
                <div className={'row'}>
                    <div className={'col-3 border-bottom-gray-min pl-0'}>
                        <h2 className={'fs-14 font-weight-normal'}>
                            {t('Muestra remitida')}:
                        </h2>
                    </div>
                    <div className={'col-9 border-bottom-gray-min pr-0'}>
                        <div className={'row mb-5'}>

                            <div className={'col-12 mb-2'}>
                                <Checkbox 
                                    id={'cytology'}
                                    name={'cytology'}
                                    className={'mt-2'}
                                    checked={ typeof cytology !== 'undefined' && cytology !== 'undefined' && cytology !== 'false' ? true : false }
                                    disabled={true}
                                    >{t('Citologías')}
                                </Checkbox>
                            </div>

                            <div className={'col-12'}>
                                <InputText
                                    id={'cytology_number'}
                                    name={'cytology_number'}
                                    placeholder={t('Nº de preparaciones')}
                                    classes={'w-100 mb-3'}
                                    label={t('Nº de preparaciones')}
                                    value={muestra.cytology_number}
                                    disabled={true}
                                    />
                            </div>

                            <div className={'col-12 mb-2'}>
                                <Checkbox 
                                    id={'fluid'}
                                    name={'fluid'}
                                    className={'mt-2'}
                                    disabled={true}
                                    checked={ typeof fluid !== 'undefined' && fluid !== 'undefined' && fluid !== 'false' ? true : false }
                                    >{t('Fluido. ')} <span className={"edit-gray pr-2"}>{t('Info sobre las citologías de líquido sinovial')}</span> 
                                </Checkbox>
                            </div>

                            <div className={'col-12'}>
                                <InputText
                                    id={'fluid_origin'}
                                    name={'fluid_origin'}
                                    placeholder={t('Indica el origen')}
                                    classes={'w-100 mb-3'}
                                    label={t('Indica el origen')}
                                    value={muestra.fluid_origin}
                                    disabled={true}
                                    />
                            </div>

                            <div className={'col-12 mb-4'}>
                                <Radio.Group 
                                value={typeof muestra.fluid_type != 'undefined' ? muestra.fluid_type : 'sangre'}
                                disabled={true}>
                                    <Radio 
                                        value={'sangre'}>
                                        {t('Sangre')} <span className={"pl-3 pr-2"}>{t('o')}</span></Radio>
                                    <Radio 
                                        value={'edta'}>
                                        {t('MO en EDTA')}</Radio>
                                </Radio.Group>
                            </div>

                            <div className={'col-12 mb-2'}>
                                <Checkbox 
                                    id={'other'}
                                    name={'other'}
                                    className={'mt-2'}
                                    disabled={true}
                                    checked={ typeof other !== 'undefined' && other !== 'undefined' && other !== 'false' ? true : false }
                                    >{t('Otros')}
                                </Checkbox>
                            </div>

                            <div className={'col-12'}>
                                <InputText
                                    id={'other_details'}
                                    name={'other_details'}
                                    placeholder={t('Indica el origen')}
                                    classes={'w-100 mb-3'}
                                    label={t('Es necesario especificar')}
                                    value={muestra.other_details}
                                    disabled={true}
                                    />
                            </div>
                        </div>
                    </div>
                </div>

                { /* Examen solicitado */ }
                <div className={'row mt-5'}>
                    <div className={'col-3 border-bottom-gray-min pl-0'}>
                        <h2 className={'fs-14 font-weight-normal'}>
                            {t('Examen solicitado')}:
                        </h2>
                    </div>
                    <div className={'col-9 border-bottom-gray-min pr-0'}>
                        <div className={'row mb-5'}>

                            <Checkbox.Group 
                            value={typeof requestedTest != 'undefined' ? requestedTest : null}
                            disabled={true}>
                                {typeof this.props.tarifas !== 'undefined' ? 
                                    Object.entries(this.props.tarifas).map(([key, tarifa]) => {
                                        return(
                                            <div className={'col-12 mb-3 order-6 order-md-2'}>
                                                <Checkbox 
                                                    className={'mt-2'}
                                                    value={tarifa.id}
                                                    disabled={true}
                                                    >{tarifa.service}
                                                    { tarifa.service.indexOf('de frotis') !== -1 || tarifa.service.indexOf('médula ósea') !== -1 ?
                                                        <span> *</span>
                                                    : null }
                                                </Checkbox>

                                                {/* Select de localizacion adicional */}
                                                { tarifa.service.indexOf('Localización adicional') !== -1 && this.props.muestra.requested_test.indexOf(tarifa.id) !== -1 ?
                                                    <Select 
                                                        id={'aditional_location'}
                                                        placeholder={t('Localización Adicional')} 
                                                        className={'w-100 little'}
                                                        value={this.props.muestra.aditional_location}
                                                        disabled={true}>

                                                            {typeof this.state.locations !== 'undefined' ? 
                                                                Object.entries(this.state.locations).map(([key, location]) => {
                                                                    return(
                                                                        <Option key={key} value={location}>{location}</Option>
                                                                    );
                                                                })
                                                            : null}

                                                    </Select>
                                                : null }

                                                {/* Input de número de informe previo */}
                                                { tarifa.service.indexOf('Citología control') !== -1 && this.props.muestra.requested_test.indexOf(tarifa.id) !== -1 ?
                                                    <InputText
                                                        id={'previous_report'}
                                                        name={'previous_report'}
                                                        placeholder={t('Nº de informe previo')}
                                                        classes={'w-100 mt-1'}
                                                        label={t('Nº de informe previo')}
                                                        value={this.props.muestra.previous_report}
                                                        disabled={true}
                                                        />
                                                : null }
                                            </div>
                                        );
                                    })
                                : null}
                            </Checkbox.Group>

                            <div className={'col-12 mb-2 order-10 order-md-10'}>
                                <p>{t('* No incluye hemograma, es necesario adjuntarlo con las muestras')}</p>
                            </div>

                        </div>
                    </div>
                </div>

                { /* Historia clínica */ }
                <div className={'row mt-5'}>
                    <div className={'col-3 border-bottom-gray-min pl-0'}>
                        <h2 className={'fs-14 font-weight-normal'}>
                            {t('Historia clínica')}:
                        </h2>
                    </div>
                    <div className={'col-9 border-bottom-gray-min pr-0'}>
                        <div className={'row mb-4'}>
                            <div className={'col-12'}>
                                <TextAreaInput
                                    id={'clinical_history'}
                                    name={'clinical_history'}
                                    placeholder={t('Escribe aquí la información')}
                                    classes={'w-100 mb-3'}
                                    label={t('Historia clínica')}
                                    value={muestra.clinical_history}
                                    rows={5}
                                    disabled={true} 
                                    />
                            </div>
                        </div>
                    </div>
                </div>

                { /* Pruebas adicionales */ }
                <div className={'row mt-5'}>
                    <div className={'col-3 border-bottom-gray-min pl-0'}>
                        <h2 className={'fs-14 font-weight-normal'}>
                            {t('Pruebas adicionales')}:
                        </h2>
                    </div>
                    <div className={'col-9 border-bottom-gray-min pr-0'}>
                        <div className={'row mb-4'}>
                            <div className={'col-12'}>
                                <TextAreaInput
                                    id={'aditional_tests'}
                                    name={'aditional_tests'}
                                    placeholder={t('Escribe aquí la información')}
                                    classes={'w-100 mb-3'}
                                    label={t('Pruebas adicionales')}
                                    value={muestra.aditional_tests}
                                    rows={5}
                                    disabled={true} 
                                    />
                            </div>
                        </div>
                    </div>
                </div>

                { /* Dianósticos diferenciales */ }
                <div className={'row mt-5'}>
                    <div className={'col-3 border-bottom-gray-min pl-0'}>
                        <h2 className={'fs-14 font-weight-normal'}>
                            {t('Dianósticos diferenciales')}:
                        </h2>
                    </div>
                    <div className={'col-9 border-bottom-gray-min pr-0'}>
                        <div className={'row mb-4'}>
                            <div className={'col-12'}>
                                <TextAreaInput
                                    id={'differential_diagnoses'}
                                    name={'differential_diagnoses'}
                                    placeholder={t('Escribe aquí la información')}
                                    classes={'w-100 mb-3'}
                                    label={t('Dianósticos diferenciales')}
                                    value={muestra.differential_diagnoses}
                                    rows={5}
                                    disabled={true} 
                                    />
                            </div>
                        </div>
                    </div>
                </div>

                { /* Localización de la lesión */ }
                <div className={'row mt-5'}>
                    <div className={'col-3 pl-0'}>
                        <h2 className={'fs-14 font-weight-normal'}>
                            {t('Localización de la lesión')}:
                        </h2>
                    </div>
                    <div className={'col-9 pr-0'}>
                        <ImageMapper 
                            width={500}
                            src={ animalMap } 
                            map={ map } />
                    </div>
                </div>

            </React.Fragment>
        )
    }

    _renderMuestra() {
        const { t } = this.props

        return (
            <React.Fragment>

                <div className={'box-with-shadow pt-4 pl-5 pr-5 pb-2 border'}>
                    { this._renderHeader() }
                </div>

                <div className={'box-with-shadow p-5 border-left border-right border-bottom'}>
                    { this._renderMuestraDetails() }
                </div>

                <div className={'box-with-shadow p-5 border-left border-right border-bottom'}>
                    <div className={'col-12 text-right'}>
                        <a href={'/muestras/'} className={'btn-purple'}>{t('Volver al listado')}</a>
                    </div>
                </div>

            </React.Fragment>
        )
    }

    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <div className={"split-container"}>
                    <Menu history={this.props.history} />
                    <div className={"container coachee"}>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <header className={"managementPanel__content-header mb-3"}>
                                    <div className={"col-5"}>
                                        <h1 className={"fs-14 poppins"}>{t('Detalle de la muestra')}</h1>
                                    </div>

                                    <div className={"col-7 text-right"}>
                                        
                                    </div>
                                </header>
                                
                                <section className={"coachee__content-body"}>
                                    { this.state.isLoaded ?
                                        this._renderMuestra() 
                                    :
                                        <React.Fragment>
                                            <Loader t={t} />
                                        </React.Fragment>
                                    }
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

function mapStateToProps(state) {
    const storeUsers = state.users;
    const storeMuestras = state.muestras;
    const storeTarifas = state.tarifas;

    return {
        isLoggedUser : storeUsers.isLogged,
        tarifas : storeTarifas.tarifas,
        user: storeUsers.user,
        muestra : storeMuestras.muestra,
        dataError : storeMuestras.dataError,
        dataErrorMessage : storeMuestras.dataErrorMessage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        isLogged: redirectTo => dispatch(isLogged()),
        getMuestra: (id) => dispatch(getMuestra(id)),
        getTarifas: () => dispatch(getTarifas()),

    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(MuestraView));



