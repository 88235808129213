import React from 'react';

export default class TextAreaInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    _onChange(e) {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;
        this.props.onChange(fieldName, fieldValue);
    }

    render() {
        const {id, name, value, placeholder, classes, label, error, disabled, rows} = this.props;

        return (
            <React.Fragment>
                <div className={'position-relative input-text-wrapper'}>
                    <textarea 
                        id={id}
                        name={name} 
                        value={value}
                        placeholder={placeholder} 
                        className={classes}
                        disabled={disabled}
                        rows={rows}
                        onChange={this._onChange.bind(this)}/>
                    
                    {(typeof error !== 'undefined' && error !== '') ?
                            <p className={'red fs-09 mt-0'}>{error}</p>
                    : null }

                    <label>{label}</label>
                </div>
            </React.Fragment>
        );
    }
}
