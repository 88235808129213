import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import Menu from '../../components/menu';
import CustomTable from '../../components/table';
import deleteIcon from '../../assets/img/delete-icon.svg';

import { Modal } from 'antd';
import { ExclamationCircleOutlined,
            LoadingOutlined } from '@ant-design/icons';

import { isLogged } from '../../actions/user.js';
import { getReplies,
            deleteReply } from '../../actions/settings.js';

import { withTranslation } from 'react-i18next';

const { confirm } = Modal;

class AdminSettings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded : true,
            dataSource : []
        };
    }

    componentDidMount() {
        const { i18n } = this.props;

        let lang = typeof this.props.match.params.lang !== 'undefined' ? this.props.match.params.lang : null;

        if(lang === 'es' || lang === 'en'){ 
            i18n.changeLanguage(lang) 
        }

        this._getApiData()
    }

    _getApiData(){
        this.setState({ isLoaded : false})

        Promise.resolve(this.props.isLogged())
        .then((response)=> {
            if(this.props.isLoggedUser){
                if(this.props.user.role.code != 0){
                    this.props.history.push('/404')
                }
                
                Promise.resolve(this.props.getReplies())
                .then((response)=> {
                    if(this.props.replies){
                        let dataSource = [];

                        this.props.replies.map((reply, key) =>{
                            let date = new Date(reply.date);
                            let fecha = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
                            let item = {
                                id : reply.id,
                                key : key,
                                fecha : fecha,
                                timestamp : date.getTime(),
                                title : reply.title,
                            }

                            dataSource.push(item);
                        })

                        this.setState({ dataSource : dataSource })
                    }

                    this.setState({ isLoaded : true })
                });

            }else{
                this.props.history.push('/login')
            }
        });
    }

    _deleteReply(id) {
        const { t } = this.props;

        confirm({
            title: t('¿Seguro que quieres eliminar este conjunto de respuestas?'),
            icon: <ExclamationCircleOutlined style={{ color: '#FF866B' }} />,
            content: t('Si lo eliminas se borra definitivamente de tu listado'),
            okText: t('Si, borrar'),
            cancelText: t('No'),
            onOk : () => {

                Promise.resolve(this.props.deleteReply(id))
                .then((response)=> {
                    if(this.props.dataError === true){
                        alert( this.props.dataErrorMessage )
                    }

                    this._getApiData()
                });
                
            },
            onCancel() {
                
            },
        });
    }

    _renderTable() {
        const { t } = this.props;
        const { dataSource } = this.state;

        const columns = [
            {
                title: t('Titulo'),
                dataIndex: "title",
                key: "title",
                ellipsis: true,
                width: 700,
                defaultSortOrder: 'descend',
                sorter: (a, b) => b.title.localeCompare(a.title),
            },
            {
                title: "",
                key: "editar",
                width: 180,
                align: "center",
                render: (text, record) => (
                    <React.Fragment>
                        <a href={'/admin/respuesta/' + record.id} className={'btn-white w-100 lh-24'}>
                            {t('Editar respuesta')}
                        </a>
                    </React.Fragment>
                ),
            },
            {
                title: "",
                key: "herramientas",
                width: 180,
                align: "center",
                render: (text, record) => (
                    <React.Fragment>
                        <button className={'btn-white w-100 lh-24'} onClick={()=>{ this._deleteReply(record.id) }}>
                            {t('Borrar respuesta')}
                        </button>
                    </React.Fragment>
                ),
            }

        ]

        const pagination = {  }

        return (
            <React.Fragment>

                <CustomTable
                    columns={columns}
                    dataSource={dataSource}
                    pagination={pagination}
                    loaded={this.state.isLoaded}
                    t={t}
                />
                                
            </React.Fragment>
        )
    }

    _renderLoader(){
        return ('')
    }

    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <div className={"split-container"}>
                    <Menu history={this.props.history} />
                    <div className={"container coachee"}>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <header className={"managementPanel__content-header mb-3"}>
                                    <div className={"col-5"}>
                                        <h1 className={"fs-14 poppins"}>{t('Guardar respuestas genéricas')}</h1>
                                    </div>

                                    <div className={"col-7 text-right"}>
                                        <a href={'/admin/respuesta/create'} className={'btn-purple'}>
                                            {t('Añadir')}
                                        </a>
                                    </div>
                                </header>
                                
                                <section className={"coachee__content-body"}>
                                    { this._renderTable() }
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

function mapStateToProps(state) {
    const storeUsers = state.users;
    const storeSettings = state.settings;

    return {
        isLoggedUser : storeUsers.isLogged,
        user: storeUsers.user,
        replies : storeSettings.replies,
        dataError : storeUsers.dataError,
        dataErrorMessage : storeUsers.dataErrorMessage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        isLogged: redirectTo => dispatch(isLogged()),
        getReplies: (id) => dispatch(getReplies(id)),
        deleteReply: (id) => dispatch(deleteReply(id))
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(AdminSettings));



