import React from "react"

const Portacitos = (props) => {
  return (
  	props.active ? (
		<svg width="28px" height="28px" viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
		    <defs>
		        <path d="M17.3333333,23.1111111 L17.3333333,18.7777777 C17.3333333,16.3845439 15.3932339,14.4444444 13,14.4444444 L7.22222223,14.4444444 C4.8289883,14.4444444 2.88888889,16.3845439 2.88888889,18.7777777 L2.88888889,23.1111111 L17.3333333,23.1111111 Z M2.77999845e-13,24.5555556 L2.77999845e-13,18.7777777 C2.77999845e-13,14.7890546 3.23349903,11.5555556 7.22222223,11.5555556 L13,11.5555556 C16.9887232,11.5555556 20.2222223,14.7890546 20.2222223,18.7777777 L20.2222223,24.5555556 C20.2222223,25.3533003 19.5755224,26 18.7777777,26 L1.44444444,26 C0.646699806,26 2.7928277e-13,25.3533003 2.77999845e-13,24.5555556 Z M10.1111111,10.1111111 C7.31900487,10.1111111 5.05555556,7.8476618 5.05555556,5.05555556 C5.05555556,2.26344933 7.31900487,0 10.1111111,0 C12.9032173,0 15.1666667,2.26344933 15.1666667,5.05555556 C15.1666667,7.8476618 12.9032173,10.1111111 10.1111111,10.1111111 Z M10.1111111,7.22222223 C11.3077281,7.22222223 12.2777778,6.25217251 12.2777778,5.05555556 C12.2777778,3.8589386 11.3077281,2.88888889 10.1111111,2.88888889 C8.91449416,2.88888889 7.94444444,3.8589386 7.94444444,5.05555556 C7.94444444,6.25217251 8.91449416,7.22222223 10.1111111,7.22222223 Z" id="path-customer"></path>
		    </defs>
		    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
		        <g id="Rectangle-Clipped" transform="translate(4.8, 0.500000)">
		            <mask id="mask-customer" fill="white">
		                <use xlinkHref="#path-customer"></use>
		            </mask>
		            <g id="path-customer"></g>
		            <g id="Rectangle" mask="url(#mask-customer)">
		                <g transform="translate(-5.000000, -2.000000)" id="Group">
		                    <g transform="translate(0.833333, 0.500000)">
		                        <rect id="Rectangle" fill="#FFFFFF" x="0" y="0" width="28" height="28"></rect>
		                    </g>
		                </g>
		            </g>
		        </g>
		    </g>
		</svg>
	):(
		<svg width="28px" height="28px" viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
		    <defs>
		        <path d="M17.3333333,23.1111111 L17.3333333,18.7777777 C17.3333333,16.3845439 15.3932339,14.4444444 13,14.4444444 L7.22222223,14.4444444 C4.8289883,14.4444444 2.88888889,16.3845439 2.88888889,18.7777777 L2.88888889,23.1111111 L17.3333333,23.1111111 Z M2.77999845e-13,24.5555556 L2.77999845e-13,18.7777777 C2.77999845e-13,14.7890546 3.23349903,11.5555556 7.22222223,11.5555556 L13,11.5555556 C16.9887232,11.5555556 20.2222223,14.7890546 20.2222223,18.7777777 L20.2222223,24.5555556 C20.2222223,25.3533003 19.5755224,26 18.7777777,26 L1.44444444,26 C0.646699806,26 2.7928277e-13,25.3533003 2.77999845e-13,24.5555556 Z M10.1111111,10.1111111 C7.31900487,10.1111111 5.05555556,7.8476618 5.05555556,5.05555556 C5.05555556,2.26344933 7.31900487,0 10.1111111,0 C12.9032173,0 15.1666667,2.26344933 15.1666667,5.05555556 C15.1666667,7.8476618 12.9032173,10.1111111 10.1111111,10.1111111 Z M10.1111111,7.22222223 C11.3077281,7.22222223 12.2777778,6.25217251 12.2777778,5.05555556 C12.2777778,3.8589386 11.3077281,2.88888889 10.1111111,2.88888889 C8.91449416,2.88888889 7.94444444,3.8589386 7.94444444,5.05555556 C7.94444444,6.25217251 8.91449416,7.22222223 10.1111111,7.22222223 Z" id="path-customer"></path>
		    </defs>
		    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
		        <g id="Rectangle-Clipped" transform="translate(4.8, 0.500000)">
		            <mask id="mask-customer" fill="white">
		                <use xlinkHref="#path-customer"></use>
		            </mask>
		            <g id="path-customer"></g>
		            <g id="Rectangle" mask="url(#mask-customer)">
		                <g transform="translate(-5.000000, -2.000000)" id="Group">
		                    <g transform="translate(0.833333, 0.500000)">
		                        <rect id="Rectangle" fill="#9B9B9B" x="0" y="0" width="28" height="28"></rect>
		                    </g>
		                </g>
		            </g>
		        </g>
		    </g>
		</svg>
	)
  )
}

export default Portacitos;