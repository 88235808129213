import React from 'react';
import Eye from './icons/eye'
import EyeClosed from './icons/eyeclosed'

export default class InputText extends React.Component {
    constructor(props) {
        super(props);

        const type = props.type || 'text'

        this.state = {
            passwordType : type
        };
    }

    _onChange(e) {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;
        this.props.onChange(fieldName, fieldValue);
    }

    _onKeyPress(e) {
        const fieldValue = e.target.value
        if(this.props.onKeyPress){
            this.props.onKeyPress(e, fieldValue)
        }
    }

    _changePasswordVisibility() {
        const { passwordType } = this.state;

        if(passwordType === 'password'){
            this.setState({ passwordType : 'text' })
        }else{
            this.setState({ passwordType : 'password' })
        }
    }

    render() {
        const {id, type, name, value, placeholder, classes, label, error, disabled} = this.props;

        return (
            <React.Fragment>
                <div className={'position-relative input-text-wrapper'}>
                    <input 
                        type={ this.state.passwordType } 
                        id={id}
                        name={name} 
                        value={value}
                        placeholder={placeholder} 
                        className={classes}
                        disabled={disabled}
                        onChange={this._onChange.bind(this)}
                        onKeyPress={this._onKeyPress.bind(this)} />
                    
                    {(typeof error !== 'undefined' && error !== '') ?
                            <p className={'red fs-09 mt-0'}>{error}</p>
                    : null }

                    <label>{label}</label>

                    { type === 'password' ?
                        <span className={ this.state.passwordType === 'password' ? 'show-password' : 'hide-password' } onClick={this._changePasswordVisibility.bind(this)}>
                            { this.state.passwordType === 'password' ? 
                                <Eye />
                            :
                                <EyeClosed />
                            }
                        </span>
                    :
                        null
                    }
                </div>
            </React.Fragment>
        );
    }
}
