import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import Menu from '../../components/menu';

import { Modal } from 'antd';
import { ExclamationCircleOutlined,
            LoadingOutlined,
            ItalicOutlined,
            BoldOutlined } from '@ant-design/icons';
import deleteIcon from '../../assets/img/delete-icon.svg';            

import Loader from '../../components/loader';
import InputText from '../../components/input';
import TextAreaInput from '../../components/textarea';
import ContentEditable from 'react-contenteditable'
import sanitizeHtml from "sanitize-html"

import { Select } from 'antd';

import { inputFieldChanged,
            getReply,
            updateReply } from '../../actions/settings.js';
import { getTarifas } from '../../actions/tarifa.js';
import { isLogged } from '../../actions/user.js';

import { withTranslation } from 'react-i18next';

const { confirm } = Modal;

const { Option } = Select;

class AdminReplyEdit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded : true,
            formError : {},
            comment_replies: [
                {
                    'title' : '',
                    'reply' : ''
                }
            ],
            description_replies: [
                {
                    'title' : '',
                    'reply' : ''
                }
            ],
        };
    }

    componentDidMount() {
        const { i18n } = this.props;

        let lang = typeof this.props.match.params.lang !== 'undefined' ? this.props.match.params.lang : null;

        if(lang === 'es' || lang === 'en'){ 
            i18n.changeLanguage(lang) 
        }

        this._getApiData()
    }

    _getApiData(){
        this.setState({ isLoaded : false})

        Promise.resolve(this.props.isLogged())
        .then((response)=> {
            if(this.props.isLoggedUser){
                if(this.props.user.role.code != 0){
                    this.props.history.push('/404')
                }

                let id = typeof this.props.match.params.id !== 'undefined' ? this.props.match.params.id : null;

                Promise.resolve(this.props.getReply(id))
                .then((response)=> {

                    this.props.inputFieldChanged('tarifa', this.props.reply.tarifa)
                    this.props.inputFieldChanged('title', this.props.reply.title)
                    this.props.inputFieldChanged('comment_replies', JSON.parse(this.props.reply.comment_replies))
                    this.props.inputFieldChanged('description_replies', JSON.parse(this.props.reply.description_replies))

                    Promise.resolve(this.props.getTarifas())
                    .then((response)=> {
                        if(this.props.tarifas){
                            let dataSource = [];

                            this.props.tarifas.map((tarifa, key) =>{
                                let item = {
                                    id : tarifa.id,
                                    service : tarifa.service,
                                }

                                dataSource.push(item);
                            })

                            this.props.inputFieldChanged('tarifas', dataSource)
                            this.setState({ isLoaded : true })
                        }
                    });
                });
            }else{
                this.props.history.push('/login')
            }
        });
    }

    _saveData() {
        const { t } = this.props;
        let error = false;
        const formError = {};
        let requestedTests = {};
        this.setState({ formError : formError })
        this.setState({ isLoaded : false })

        if (typeof this.props.title === 'undefined' || this.props.title === ''){
            formError.title = t('Debes seleccionar un titulo')
            this.setState({ formError : formError })
            error = true
        }

        if(error === false){
            let comment_replies = typeof this.props.comment_replies !== 'undefined' ? this.props.comment_replies : this.state.comment_replies;
            let description_replies = typeof this.props.description_replies !== 'undefined' ? this.props.description_replies : this.state.description_replies;

            Promise.resolve(this.props.updateReply(
                this.props.reply.id,
                this.props.title,
                this.props.tarifa,
                JSON.stringify(comment_replies),
                JSON.stringify(description_replies),
            ))
            .then((response)=> {
                if(this.props.dataError === false){
                    // Limpiamos la Store
                    this.props.inputFieldChanged('title', undefined)
                    this.props.inputFieldChanged('tarifa', undefined)
                    this.props.inputFieldChanged('comment_replies', [
                        {
                            'title' : '',
                            'reply' : ''
                        }
                    ])
                    this.props.inputFieldChanged('description_replies', [
                        {
                            'title' : '',
                            'reply' : ''
                        }
                    ])

                    this.props.history.push('/admin/ajustes')
                }
            });
        }

        this.setState({ isLoaded : true })
    }

    _cancelData() {
        // Limpiamos la Store
        this.props.inputFieldChanged('title', undefined)
        this.props.inputFieldChanged('tarifa', undefined)
        this.props.inputFieldChanged('comment_replies', [
            {
                'title' : '',
                'reply' : ''
            }
        ])
        this.props.inputFieldChanged('description_replies', [
            {
                'title' : '',
                'reply' : ''
            }
        ])

        this.props.history.push('/admin/ajustes')
    }

    _handleInputCommentReplyChange(key, field, value) {
        const { comment_replies } = (this.props.comment_replies == 'undefined' || this.props.comment_replies == null) ? this.state : this.props;
        if(field === 'title'){
            comment_replies[key].title = value;
        }

        if(field === 'reply'){
            comment_replies[key].reply = value;
        }

        this.setState({
            comment_replies: comment_replies
        });

        this.props.inputFieldChanged('comment_replies', comment_replies);
    }

    _addCommentReply() {
        const { t } = this.props;

        const {comment_replies} = (this.props.comment_replies == 'undefined' || this.props.comment_replies == null) ? this.state : this.props;

        comment_replies.push({
            'title' : '',
            'reply' : '',
        });

        this.setState({
            comment_replies: comment_replies
        });
    }

    _removeCommentReply(key) {
        const {comment_replies} = (this.props.comment_replies == 'undefined' || this.props.comment_replies == null) ? this.state : this.props;

        comment_replies.splice(key, 1);

        this.setState({
            comment_replies: comment_replies
        });

        this.props.inputFieldChanged('comment_replies', comment_replies);
    }

    _handleInputDescriptionReplyChange(key, field, value) {
        const { description_replies } = (this.props.description_replies == 'undefined' || this.props.description_replies == null) ? this.state : this.props;

        if(field === 'title'){
            description_replies[key].title = value;
        }

        if(field === 'reply'){
            description_replies[key].reply = value;
        }

        this.setState({
            description_replies: description_replies
        });

        this.props.inputFieldChanged('description_replies', description_replies);
    }

    _addDescriptionReply() {
        const { t } = this.props;

        const {description_replies} = (this.props.description_replies == 'undefined' || this.props.description_replies == null) ? this.state : this.props;

        description_replies.push({
            'title' : '',
            'reply' : '',
        });

        this.setState({
            description_replies: description_replies
        });
    }

    _removeDescriptionReply(key) {
        const {description_replies} = (this.props.description_replies == 'undefined' || this.props.description_replies == null) ? this.state : this.props;

        description_replies.splice(key, 1);

        this.setState({
            description_replies: description_replies
        });

        this.props.inputFieldChanged('description_replies', description_replies);
    }


    _renderForm() {
        const { t } = this.props;
        const {title} = (typeof this.props.title === 'undefined') ? '' : this.props;
        const {tarifa} = (typeof this.props.tarifa === 'undefined') ? '' : this.props;
        const {comment_replies} = (typeof this.props.comment_replies === 'undefined') ? this.state : this.props;
        const {description_replies} = (typeof this.props.description_replies === 'undefined') ? this.state : this.props;

        return(
            <React.Fragment>
                <div className={'box-with-shadow pt-4 pl-5 pr-5 pb-2 border'}>
                    <div className={'row mt-3'}>
                        
                        <div className={'col-3 border-bottom-gray-min pl-0'}>
                            <h2 className={'fs-14 font-weight-normal'}>
                                {t('Informe')}
                            </h2>
                        </div>
                        <div className={'col-9 border-bottom-gray-min pr-0'}>
                            <div className={'row'}>
                                <div className={'col-12 mb-2'}>
                                    <InputText
                                        id={'title'}
                                        name={'title'}
                                        placeholder={t('Ponle un nombre a este conjunto de respuestas')}
                                        classes={'w-100 mb-3'}
                                        label={t('Título del informe')}
                                        value={title}
                                        onChange={this.props.inputFieldChanged.bind(this)}
                                        error={this.state.formError.title }  
                                        />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className={'row mt-4'}>
                        
                        <div className={'col-3 border-bottom-gray-min pl-0'}>
                            <h2 className={'fs-14 font-weight-normal'}>
                                {t('Respuestas de descripción')}
                            </h2>
                        </div>
                        <div className={'col-9 border-bottom-gray-min pr-0'}>
                            <div className={'row h-100 mb-2'}>
                                { description_replies.map((reply, key) => {
                                    return(
                                        <React.Fragment key={key}>
                                            <div className={'col-11 pb-3 mb-2'}>
                                                <InputText
                                                    id={'title'}
                                                    name={'title'}
                                                    placeholder={t('Ponle título a la respuesta')}
                                                    classes={'w-100'}
                                                    label={t('Titulo')}
                                                    onChange={this._handleInputDescriptionReplyChange.bind(this, key)}
                                                    value={reply.title}
                                                    error={''}
                                                    />
                                            </div>

                                            <div className={'col-1 pb-4 my-auto'}>
                                                { key > 0 ?
                                                    <img src={deleteIcon} role={"button"} onClick={this._removeDescriptionReply.bind(this, key)} alt={t('Borrar esta respuesta')} title={t('Borrar')} />
                                                :
                                                    null
                                                }
                                            </div>

                                            <div className={'col-11 pb-3'}>
                                                <TextAreaInput
                                                    id={'reply'}
                                                    name={'reply'}
                                                    placeholder={t('Escribe aquí la respuesta')}
                                                    classes={'w-100 m-0'}
                                                    label={t('Respuesta')}
                                                    onChange={this._handleInputDescriptionReplyChange.bind(this, key)}
                                                    value={reply.reply}
                                                    error={''}
                                                    />
                                            </div>
                                        </React.Fragment>
                                    )
                                })}

                                <button className={'btn-white fs-08 ml-3 mb-4'} onClick={() => { this._addDescriptionReply() }}>
                                    { t('Añadir') }
                                </button>
                            </div>
                        </div>

                    </div>

                    <div className={'row mt-4'}>
                        
                        <div className={'col-3 border-bottom-gray-min pl-0'}>
                            <h2 className={'fs-14 font-weight-normal'}>
                                {t('Respuestas de comentarios')}
                            </h2>
                        </div>
                        <div className={'col-9 border-bottom-gray-min pr-0'}>
                            <div className={'row h-100 mb-2'}>
                                { comment_replies.map((reply, key) => {
                                    return(
                                        <React.Fragment key={key}>
                                            <div className={'col-11 pb-3 mb-2'}>
                                                <InputText
                                                    id={'title'}
                                                    name={'title'}
                                                    placeholder={t('Ponle título a la respuesta')}
                                                    classes={'w-100'}
                                                    label={t('Titulo')}
                                                    onChange={this._handleInputCommentReplyChange.bind(this, key)}
                                                    value={reply.title}
                                                    error={''}
                                                    />
                                            </div>

                                            <div className={'col-1 pb-4 my-auto'}>
                                                { key > 0 ?
                                                    <img src={deleteIcon} role={"button"} onClick={this._removeCommentReply.bind(this, key)} alt={t('Borrar esta respuesta')} title={t('Borrar')} />
                                                :
                                                    null
                                                }
                                            </div>

                                            <div className={'col-11 pb-4'}>
                                                <TextAreaInput
                                                    id={'reply'}
                                                    name={'reply'}
                                                    placeholder={t('Escribe aquí la respuesta')}
                                                    classes={'w-100 m-0'}
                                                    label={t('Respuesta')}
                                                    onChange={this._handleInputCommentReplyChange.bind(this, key)}
                                                    value={reply.reply}
                                                    error={''}
                                                    />
                                            </div>
                                        </React.Fragment>
                                    )
                                })}

                                <button className={'btn-white fs-08 ml-3 mb-4'} onClick={() => { this._addCommentReply() }}>
                                    { t('Añadir') }
                                </button>
                            </div>
                        </div>

                    </div>

                    <div className={'row mt-5 mb-4'}>
                        
                        <div className={'col-6 text-left'}>
                            <button className={'btn-white'} onClick={() => {this._cancelData()}}>
                                {t('Cancelar')}
                            </button>
                        </div>

                        <div className={'col-6 text-right'}>
                            <button className={'btn-purple'} onClick={() => {this._saveData()}}>
                                {t('Guardar')}
                            </button>
                        </div>

                    </div>
                </div>
            </React.Fragment>
        )
    }

    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <div className={"split-container"}>
                    <Menu history={this.props.history} />
                    <div className={"container coachee"}>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <header className={"managementPanel__content-header mb-3"}>
                                    <div className={"col-5"}>
                                        <h1 className={"fs-14 poppins"}>{t('Modificar respuestas')}</h1>
                                    </div>

                                    <div className={"col-7 text-right"}>
                                    </div>
                                </header>
                                
                                <section className={"coachee__content-body"}>
                                    { this.state.isLoaded ?
                                        this._renderForm() 
                                    :
                                        <React.Fragment>
                                            <Loader t={t} />
                                        </React.Fragment>
                                    }
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

function mapStateToProps(state) {
    const storeUsers = state.users;
    const storeSettings = state.settings;
    const storeTarifas = state.tarifas;

    return {
        isLoggedUser : storeUsers.isLogged,
        user: storeUsers.user,
        tarifas : storeTarifas.tarifas,
        reply : storeSettings.reply,
        title : storeSettings.title,
        tarifa : storeSettings.tarifa,
        comment_replies : storeSettings.comment_replies,
        description_replies : storeSettings.description_replies,
        dataError : storeSettings.dataError,
        dataErrorMessage : storeSettings.dataErrorMessage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        isLogged: redirectTo => dispatch(isLogged()),
        inputFieldChanged: (field, value) => dispatch(inputFieldChanged(field, value)),
        getTarifas: () => dispatch(getTarifas()),
        getReply: (id) => dispatch(getReply(id)),
        updateReply: (id, title, tarifa, comment_replies, description_replies) => dispatch(updateReply(id, title, tarifa, comment_replies, description_replies))
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(AdminReplyEdit));



