import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import Menu from '../components/menu';
import CustomTable from '../components/table';
import MuestrasIcon from "../components/icons/muestras";
import RecogidasIcon from "../components/icons/recogidas";
import deleteIcon from '../assets/img/delete-icon.svg';

import TarifaCard from '../components/tarifaCard';

import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { getTarifas } from '../actions/tarifa.js';
import { isLogged } from '../actions/user.js';

import { withTranslation } from 'react-i18next';

const { confirm } = Modal;

class Tarifas extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded : true,
            dataSource : [],
            tarifas : [
                {
                    tiempo : '0-1 días',
                    nombre : 'Citología',
                    descripcion : '​Hasta dos localizaciones.<br /><br />Cuando dos localizaciones se describen de forma conjunta se consideran una.',
                    precio : '32'
                },
                {
                    tiempo : '0-1 días',
                    nombre : 'Localización adicional',
                    descripcion : '​​Cada localización a partir de 2.',
                    precio : '11'
                },
                {
                    tiempo : '1-2 días',
                    nombre : 'Citología control',
                    descripcion : '​​Cada localización a partir de 2.',
                    precio : '21'
                }
            ]
        };
    }

    componentDidMount() {
        const { i18n } = this.props;

        let lang = typeof this.props.match.params.lang !== 'undefined' ? this.props.match.params.lang : null;

        if(lang === 'es' || lang === 'en'){ 
            i18n.changeLanguage(lang) 
        }

        this._getApiData()
    }

    _getApiData(){
        this.setState({ isLoaded : false})

        Promise.resolve(this.props.isLogged())
        .then((response)=> {
            if(this.props.isLoggedUser){
                
                Promise.resolve(this.props.getTarifas())
                .then((response)=> {
                    if(this.props.tarifas){
                        let dataSource = [];

                        this.props.tarifas.map((tarifa, key) =>{
                            let item = {
                                id : tarifa.id,
                                service : tarifa.service,
                                description : tarifa.description,
                                time : tarifa.time,
                                price : tarifa.price
                            }

                            dataSource.push(item);
                        })

                        this.setState({ dataSource : dataSource })
                        this.setState({ isLoaded : true })
                    }
                });

            }else{
                this.props.history.push('/login')
            }
        });
    }

    _deleteMuestra(id) {
        const { t } = this.props;

        confirm({
            title: t('¿Seguro que quieres eliminar esta muestra?'),
            icon: <ExclamationCircleOutlined style={{ color: '#FF866B' }} />,
            content: t('Si la eliminas se borra definitivamente de tu listado'),
            okText: t('Si, borrar'),
            cancelText: t('No'),
            onOk : () => {

                Promise.resolve(this.props.deleteMuestra(id))
                .then((response)=> {
                    if(this.props.dataError === true){
                        alert( this.props.dataErrorMessage )
                    }

                    this._getApiData()
                });
                
            },
            onCancel() {
                
            },
        });
    }

    _renderTable() {
        const { t } = this.props;
        const { dataSource } = this.state;

        return (
            dataSource.map((tarifa, key) =>{
                return(
                    <div className={'col-6 col-md-4'}>
                        <TarifaCard
                        tiempo={tarifa.time}
                        nombre={tarifa.service}
                        descripcion={tarifa.description}
                        precio={tarifa.price}
                        key={key} />
                    </div>
                )
            })
        )
    }

    _renderLoader(){
        return ('')
    }

    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <div className={"split-container"}>
                    <Menu history={this.props.history} />
                    <div className={"container coachee"}>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <section className={"coachee__content-body"}>
                                    <p className={'fs-11 w-75 my-5'}>
                                    { t('El precio de estos estudios para') } 
                                    <span className={'purple font-weight-bold'}> { t('animales exóticos es un 10% menor, excepto para') }</span> 
                                    { t('los conceptos: estudio de frotis, estudio de médula ósea, leucograma de aves y reptiles y el suplemento de urgencia.')}</p>
                                    <div className={"row"}>
                                        { this._renderTable() }
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

function mapStateToProps(state) {
    const storeUsers = state.users;
    const storeTarifas = state.tarifas;

    return {
        isLoggedUser : storeUsers.isLogged,
        user: storeUsers.user,
        tarifas : storeTarifas.tarifas,
        dataError : storeTarifas.dataError,
        dataErrorMessage : storeTarifas.dataErrorMessage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        isLogged: redirectTo => dispatch(isLogged()),
        getTarifas: () => dispatch(getTarifas())
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(Tarifas));



