import { types } from '../constants/constants';
import jwt from 'jsonwebtoken';
import i18next from 'i18next';
import { push } from 'react-router-redux';

/** Método que almacena en el store de Redux los inputs que
    el usuario va rellenando, para garantizar la persistencia
    de los datos.
    @param string $field el nombre del input
    @param string $value el valor del input
**/
export function inputFieldChanged(field, value) {
    return function(dispatch) {
        dispatch({ type: "USER_INPUT_FIELD_CHANGED", payload: {field : field, value : value} });
    }
}

export function isLogged() {
    return async function(dispatch) {
        let errorMessage = {};

        try {
            const token = localStorage.getItem('token');
            
            if (typeof token == 'undefined' || token === null) {
                errorMessage.islogged = '';
                dispatch({ type: "IS_LOGGED_ERROR", payload: { result : 0, message : errorMessage } });
            } else {
                let token_decoded = jwt.decode(token);

                if (typeof token_decoded === 'undefined' || Date.now() > token_decoded.exp * 1000) {
                    errorMessage.islogged = 'El token de sesion no es válido.';
                    dispatch({ type: "IS_LOGGED_ERROR", payload: { result : 0, message : errorMessage } });
                } else {
                    dispatch({ 
                        type: "IS_LOGGED_SUCCESS", 
                        payload: { 
                            user : token_decoded.user
                        }
                    });
                }
            }
        } catch (err) {
            errorMessage.islogged = err;
            dispatch({ type: "IS_LOGGED_ERROR", payload: { result : 0, message : errorMessage} });
        }
    }
}

export function doLogin(email, password) {
    return async function(dispatch) {
        try
        {
            let error = false;
            let errorMessage = {};

            if(typeof email === 'undefined' || email === ''){
                error = true;
                errorMessage.email = i18next.t('El email es obligatorio');
            }

            if(typeof password === 'undefined' || password === ''){
                error = true;
                errorMessage.password = i18next.t('La contraseña es obligatoria');
            }

            if(error === false){ // No error in data

                var formBody = [];
            
                var details={ 
                    'email' : email,
                    'password' : password
                };

                for (var property in details) {
                      var encodedKey = encodeURIComponent(property);
                      var encodedValue = encodeURIComponent(details[property]);
                      formBody.push(encodedKey + "=" + encodedValue);
                }

                var status = null;

                return fetch(types.API_URL + 'user/login', {
                    method: 'post',
                    headers: { "Content-Type": "application/x-www-form-urlencoded" },
                    body: formBody.join("&"),
                })
                .then(function(response) {
                    status = response.status; // Get HTTP status code
                    return response.json();
                })
                .then((responseJson, response) => {
                    if(status === 200){ // If success
                        
                        let token_decoded = jwt.decode(responseJson.token);

                        if (typeof token_decoded.user === 'undefined' || token_decoded.user === null) {
                            errorMessage.login = 'El token obtenido no es válido.';
                            dispatch({ type: "DO_LOGIN_ERROR", payload: { message : errorMessage } });
                        } else {
                            try {
                                localStorage.setItem('token', responseJson.token);
                                let redirectTo = typeof sessionStorage.getItem('islinked') !== 'undefined' && sessionStorage.getItem('islinked') !== '' ? 
                                                    sessionStorage.getItem('islinked') : '/';
                                dispatch({ 
                                    type: "DO_LOGIN", 
                                    payload: { 
                                        user : token_decoded.user,
                                        redirectTo : redirectTo
                                    }
                                });
                            } catch (err) {
                                errorMessage.login = 'El token obtenido no es válido: ' + err;
                                dispatch({ type: "DO_LOGIN_ERROR", payload: { message : errorMessage } });
                            }
                        }

                    }else{
                        errorMessage.login = responseJson;
                        dispatch({ type: "DO_LOGIN_ERROR", payload: { message : errorMessage } });
                    }
                })
                .catch((error) => {
                    errorMessage.login = 'Hubo un problema: ' + error;
                    dispatch({ type: "DO_LOGIN_ERROR", payload: { message : errorMessage } });
                });

            }else{
                dispatch({ type: "DO_LOGIN_ERROR", payload: { message : errorMessage } });
            }
        }
        catch(error)
        {
            alert(error);
        }
    }
}

export function doLogout() {
    return async function(dispatch) {
        try
        {
            localStorage.removeItem('token');
            dispatch({ 
                type: "DO_LOGOUT", 
                payload: {}
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

export function doRecover(email) {
    return async function(dispatch) {
        try
        {
            let error = false;
            let errorMessage = {};

            if(typeof email === 'undefined' || email === ''){
                error = true;
                errorMessage.email = i18next.t('El email es obligatorio');
            }

            if(error === false){ // No error in data

                var formBody = [];
            
                var details={ 
                    'email' : email
                };

                for (var property in details) {
                      var encodedKey = encodeURIComponent(property);
                      var encodedValue = encodeURIComponent(details[property]);
                      formBody.push(encodedKey + "=" + encodedValue);
                }

                var status = null;

                return fetch(types.API_URL + 'user/recover-password', {
                    method: 'post',
                    headers: { 
                        "Content-Type": "application/x-www-form-urlencoded",
                        "Content-Language": i18next.language
                    },
                    body: formBody.join("&"),
                })
                .then(function(response) {
                    status = response.status; // Get HTTP status code
                    return response.json();
                })
                .then((responseJson, response) => {
                    if(status === 200){ // If success
                        
                        dispatch({ 
                            type: "DO_RECOVER", 
                            payload: { 
                                successMessage : i18next.t(responseJson)
                            }
                        });

                    }else{
                        errorMessage.recover = responseJson;
                        dispatch({ type: "DO_RECOVER_ERROR", payload: { result : 0, message : errorMessage } });
                    }
                })
                .catch((error) => {
                    errorMessage.recover = 'catch: ' + error;
                    dispatch({ type: "DO_RECOVER_ERROR", payload: { result : 0, message : errorMessage } });
                });

            }else{
                dispatch({ type: "DO_RECOVER_ERROR", payload: { message : errorMessage } });
            }
        }
        catch(error)
        {
            alert(error);
        }
    }
}

export function updatePassword(email, password) {
    return async function(dispatch) {
        try
        {
            let error = false;
            let errorMessage = {};

            if(typeof password === 'undefined' || password === ''){
                error = true;
                errorMessage.password = i18next.t('La contraseña es obligatoria');
            }

            if(error === false){ // No error in data

                var formBody = [];
            
                var details={ 
                    'email' : email,
                    'password' : password
                };

                for (var property in details) {
                      var encodedKey = encodeURIComponent(property);
                      var encodedValue = encodeURIComponent(details[property]);
                      formBody.push(encodedKey + "=" + encodedValue);
                }

                var status = null;

                return fetch(types.API_URL + 'user/update-password', {
                    method: 'post',
                    headers: { "Content-Type": "application/x-www-form-urlencoded" },
                    body: formBody.join("&"),
                })
                .then(function(response) {
                    status = response.status; // Get HTTP status code
                    return response.json();
                })
                .then((responseJson, response) => {
                    if(status === 200){ // If success
                        
                        dispatch({ 
                            type: "DO_RECOVER", 
                            payload: { 
                                successMessage : responseJson
                            }
                        });

                    }else{
                        errorMessage.recover = responseJson;
                        dispatch({ type: "DO_RECOVER_ERROR", payload: { result : 0, message : errorMessage } });
                    }
                })
                .catch((error) => {
                    errorMessage.recover = 'catch: ' + error;
                    dispatch({ type: "DO_RECOVER_ERROR", payload: { result : 0, message : errorMessage } });
                });

            }else{
                dispatch({ type: "DO_RECOVER_ERROR", payload: { message : errorMessage } });
            }
        }
        catch(error)
        {
            alert(error);
        }
    }
}

export function getUser(id) {
    return async function(dispatch) {        
        try
        {
            return fetch(types.API_URL + 'user/' + id, {
                method: 'get',
            })
            .then(function(response) {
                if (response.status === 404 || response.status === 200) {
                    return response.json()
                } else {
                    dispatch({ type: "GET_USER_ERROR", payload: { result : 0, message : 'Error en la solicitud' } });
                }
            })
            .then((responseJson) => {
                if(responseJson !== null){ // If creation success
                    dispatch({ type: "GET_USER", payload: { user : responseJson.user } });
                }else{
                    dispatch({ type: "GET_USER_ERROR", payload: { result : 0, message : 'No hay resultados.' } });
                }
            })
            .catch((error) => {
                dispatch({ type: "GET_USER_ERROR", payload: { result : 0, message : 'Hubo un error procesando tu solicitud. Error: ' + error } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

export function getUserByEmail(email) {
    return async function(dispatch) {
        try
        {
            let error = false;
            let errorMessage = {};

            if(typeof email === 'undefined' || email === ''){
                error = true;
                errorMessage.email = "El email es obligatorio";
            }

            if(error === false){ // No error in data

                var formBody = [];
            
                var details={ 
                    'email' : email
                };

                for (var property in details) {
                      var encodedKey = encodeURIComponent(property);
                      var encodedValue = encodeURIComponent(details[property]);
                      formBody.push(encodedKey + "=" + encodedValue);
                }

                var status = null;

                return fetch(types.API_URL + 'user/get-by-email', {
                    method: 'post',
                    headers: { "Content-Type": "application/x-www-form-urlencoded" },
                    body: formBody.join("&"),
                })
                .then(function(response) {
                    status = response.status; // Get HTTP status code
                    return response.json();
                })
                .then((responseJson, response) => {
                    if(status === 200){ // If success
                        
                        dispatch({ 
                            type: "GET_COACH", 
                            payload: { 
                                user : responseJson.user
                            }
                        });

                    }else{
                        errorMessage.recover = responseJson;
                        dispatch({ type: "GET_COACH_ERROR", payload: { result : 0, message : errorMessage } });
                    }
                })
                .catch((error) => {
                    errorMessage.recover = 'catch: ' + error;
                    dispatch({ type: "GET_COACH_ERROR", payload: { result : 0, message : errorMessage } });
                });

            }else{
                dispatch({ type: "GET_COACH_ERROR", payload: { message : errorMessage } });
            }
        }
        catch(error)
        {
            alert(error);
        }
    }
}

export function updateUser(_id, first_name, last_name, email, department, position, phone, mobile, city, country) {
    return async function(dispatch) {
        try
        {
            let error = false;
            let errorMessage = {};

            if(typeof first_name === 'undefined' || first_name === ''){
                error = true;
                errorMessage.first_name = "El nombre es obligatorio";
            }

            if(typeof last_name === 'undefined' || last_name === ''){
                error = true;
                errorMessage.last_name = "Los apellidos son obligatorios";
            }

            if(typeof email === 'undefined' || email === ''){
                error = true;
                errorMessage.email = "El email es obligatorio";
            }

            if(error === false){ // No error in data

                var formBody = [];
            
                var details={ 
                    '_id' : _id, 
                    'first_name' : first_name,
                    'last_name' : last_name,
                    'email' : email,
                    'department' : department,
                    'position' : position,
                    'phone' : phone,
                    'mobile' : mobile,
                    'city' : city,
                    'country' : country
                };

                for (var property in details) {
                      var encodedKey = encodeURIComponent(property);
                      var encodedValue = encodeURIComponent(details[property]);
                      formBody.push(encodedKey + "=" + encodedValue);
                }

                var status = null;

                return fetch(types.API_URL + 'user/update', {
                    method: 'post',
                    headers: { "Content-Type": "application/x-www-form-urlencoded" },
                    body: formBody.join("&"),
                })
                .then(function(response) {
                    status = response.status; // Get HTTP status code
                    return response.json();
                })
                .then((responseJson, response) => {
                    if(status === 200){ // If success
                        
                        dispatch({ 
                            type: "DO_USER_UPDATE", 
                            payload: { 
                                user : responseJson.user,
                                successMessage : 'Datos guardados correctamente'
                            }
                        });

                    }else{
                        errorMessage = responseJson;
                        dispatch({ type: "DO_USER_UPDATE_ERROR", payload: { message : errorMessage } });
                    }
                })
                .catch((error) => {
                    errorMessage.message = 'catch: ' + error;
                    dispatch({ type: "DO_USER_UPDATE_ERROR", payload: { message : errorMessage } });
                });

            }else{
                dispatch({ type: "DO_USER_UPDATE_ERROR", payload: { message : errorMessage } });
            }
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que manda a la API los datos para actualizar
    el perfil de una empresa
**/
export function saveProfile(id, company, email, picture) {
    return async function(dispatch) {
        try
        {
            var formBody = [];
            
            var details={ 
                id : id,
                company : company,
                email: email,
                picture: picture
            };

            for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(details[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
            }

            var status = null;
            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'user/save-profile', {
                method: 'post',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    "token": token
                },
                body: formBody.join("&"),
            })
            .then(function(response) {
                status = response.status; // Get HTTP status code
                return response.json();
            })
            .then((responseJson, response) => {
                if(status === 200){ // If success
                    dispatch({ 
                        type: "SAVE_PROFILE", 
                        payload: { 
                            user : responseJson.user
                        }
                    });
                }else{
                    if(status === 401){ 
                        push('/') 
                    }else{
                        dispatch({ type: "SAVE_PROFILE_ERROR", payload: { message : responseJson } });
                    }
                }
            })
            .catch((error) => {
                let errorMessage = 'Hubo un problema conectando con la API: ' + error;
                dispatch({ type: "SAVE_PROFILE_ERROR", payload: { message : errorMessage } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que manda a la API los datos para registrar
    un nuevo usuario
**/
export function saveUserData(
    address,
    address_number,
    address_floor,
    address_other,
    city,
    zipcode,
    province,
    cif,
    phone,
    business,
    business_address,
    business_address_number,
    business_address_floor,
    business_address_other,
    business_city,
    business_zipcode,
    business_province,
    invoice_contacts,
    report_contacts,
    comunication_contacts
    ) {
    return async function(dispatch) {
        try
        {
            var formBody = [];
            
            var details={ 
                address : address,
                address_number : address_number,
                address_floor : address_floor,
                address_other : address_other,
                city : city,
                zipcode : zipcode,
                province : province,
                cif : cif,
                phone : phone,
                business : business,
                business_address : business_address,
                business_address_number : business_address_number,
                business_address_floor : business_address_floor,
                business_address_other : business_address_other,
                business_city : business_city,
                business_zipcode : business_zipcode,
                business_province : business_province,
                invoice_contacts : JSON.stringify(invoice_contacts),
                report_contacts : JSON.stringify(report_contacts),
                comunication_contacts : JSON.stringify(comunication_contacts)
            };

            for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(details[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
            }

            var status = null;
            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'user/save-data', {
                method: 'post',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    "token" : token
                },
                body: formBody.join("&"),
            })
            .then(function(response) {
                status = response.status; // Get HTTP status code
                return response.json();
            })
            .then((responseJson, response) => {
                if(status === 200){ // If success
                    dispatch({ 
                        type: "SAVE_DATA", 
                        payload: { 
                            user : responseJson.user
                        }
                    });
                }else{
                    dispatch({ type: "SAVE_DATA_ERROR", payload: { message : responseJson } });
                }
            })
            .catch((error) => {
                let errorMessage = 'Hubo un problema conectando con la API: ' + error;
                dispatch({ type: "SAVE_DATA_ERROR", payload: { message : errorMessage } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que manda a la API los datos del fichero SEPA para
    el perfil de una empresa
**/
export function saveSepa(id, sepa) {
    return async function(dispatch) {
        try
        {
            var formBody = [];
            
            var details={ 
                id : id,
                sepa: sepa
            };

            for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(details[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
            }

            var status = null;
            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'user/save-sepa', {
                method: 'post',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    "token": token
                },
                body: formBody.join("&"),
            })
            .then(function(response) {
                status = response.status; // Get HTTP status code
                return response.json();
            })
            .then((responseJson, response) => {
                if(status === 200){ // If success
                    dispatch({ 
                        type: "SAVE_SEPA", 
                        payload: responseJson
                    });
                }else{
                    if(status === 401){ 
                        push('/') 
                    }else{
                        dispatch({ type: "SAVE_SEPA_ERROR", payload: { message : responseJson } });
                    }
                }
            })
            .catch((error) => {
                let errorMessage = 'Hubo un problema conectando con la API: ' + error;
                dispatch({ type: "SAVE_SEPA_ERROR", payload: { message : errorMessage } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que manda a la API la contraseña actual y la nueva
    para poder cambiarla
**/
export function changePassword(current_password, new_password) {
    return async function(dispatch) {
        try
        {
            var formBody = [];
            
            var details={ 
                current_password : current_password,
                new_password: new_password
            };

            for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(details[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
            }

            var status = null;
            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'user/change-password', {
                method: 'post',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    "token": token
                },
                body: formBody.join("&"),
            })
            .then(function(response) {
                status = response.status; // Get HTTP status code
                return response.json();
            })
            .then((responseJson, response) => {
                if(status === 200){ // If success
                    dispatch({ 
                        type: "UPDATE_PASSWORD", 
                        payload: responseJson
                    });
                }else{
                    if(status === 401){ 
                        push('/') 
                    }else{
                        dispatch({ type: "UPDATE_PASSWORD_ERROR", payload: { message : responseJson } });
                    }
                }
            })
            .catch((error) => {
                let errorMessage = 'Hubo un problema conectando con la API: ' + error;
                dispatch({ type: "UPDATE_PASSWORD_ERROR", payload: { message : errorMessage } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que solicita la lista de solicitudes de portacitos
    para un usuario dado por id
**/
export function getPortacitos(id=null) {
    return async function(dispatch) {
        try
        {
            let url = ''

            if(id === null){
                url = types.API_URL + 'portacito/list'
            }else{
                url = types.API_URL + 'portacito/' + id + '/list'
            }

            var status = null;
            const token = localStorage.getItem('token');

            return fetch(url, {
                method: 'get',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    "token": token
                }
            })
            .then(function(response) {
                status = response.status; // Get HTTP status code
                return response.json();
            })
            .then((responseJson, response) => {
                if(status === 401){ push('/') }
                if(status === 200){ // If success
                    dispatch({ 
                        type: "GET_PORTACITOS", 
                        payload: responseJson
                    });
                }else{
                    dispatch({ type: "GET_PORTACITOS_ERROR", payload: { message : responseJson } });
                }
            })
            .catch((error) => {
                let errorMessage = 'Hubo un problema conectando con la API: ' + error;
                dispatch({ type: "GET_PORTACITOS_ERROR", payload: { message : errorMessage } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que solicita la lista de usuarios, se puede pasar un
    parametro con el tipo de cuenta de usuario
**/
export function getUsers(type=null) {
    return async function(dispatch) {        
        try
        {
            return fetch(types.API_URL + 'user/list/?type=' + type, {
                method: 'get',
            })
            .then(function(response) {
                if (response.status === 404 || response.status === 200) {
                    return response.json()
                } else {
                    dispatch({ type: "GET_USERS_ERROR", payload: { result : 0, message : 'Error en la solicitud' } });
                }
            })
            .then((responseJson) => {
                if(responseJson !== null){ // If creation success
                    dispatch({ type: "GET_USERS", payload: { users : responseJson } });
                }else{
                    dispatch({ type: "GET_USERS_ERROR", payload: { result : 0, message : 'No hay resultados.' } });
                }
            })
            .catch((error) => {

                dispatch({ type: "GET_USERS_ERROR", payload: { result : 0, message : 'Hubo un error procesando tu solicitud. Error: ' + error } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

export function getCustomer(id) {
    return async function(dispatch) {        
        try
        {
            return fetch(types.API_URL + 'user/' + id, {
                method: 'get',
            })
            .then(function(response) {
                if (response.status === 404 || response.status === 200) {
                    return response.json()
                } else {
                    dispatch({ type: "GET_CUSTOMER_ERROR", payload: { result : 0, message : 'Error en la solicitud' } });
                }
            })
            .then((responseJson) => {
                if(responseJson !== null){ // If creation success
                    dispatch({ type: "GET_CUSTOMER", payload: { customer : responseJson.user } });
                }else{
                    dispatch({ type: "GET_CUSTOMER_ERROR", payload: { result : 0, message : 'No hay resultados.' } });
                }
            })
            .catch((error) => {
                dispatch({ type: "GET_CUSTOMER_ERROR", payload: { result : 0, message : 'Hubo un error procesando tu solicitud. Error: ' + error } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que manda a la API una solicitud para eliminar un usuario
    dada su ID
**/
export function deleteUser(id) {
    return async function(dispatch) {
        try
        {
            var formBody = [];
            
            var details={ 
                id : id
            };

            for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(details[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
            }

            var status = null;
            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'user/delete', {
                method: 'post',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    "token": token
                },
                body: formBody.join("&"),
            })
            .then(function(response) {
                status = response.status; // Get HTTP status code
                return response.json();
            })
            .then((responseJson, response) => {
                if(status === 200){ // If success
                    dispatch({ 
                        type: "DELETE_USER", 
                        payload: responseJson
                    });
                }else{
                    if(status === 401){ 
                        push('/') 
                    }else{
                        dispatch({ type: "DELETE_USER_ERROR", payload: { message : responseJson } });
                    }
                }
            })
            .catch((error) => {
                let errorMessage = 'Hubo un problema conectando con la API: ' + error;
                dispatch({ type: "DELETE_USER_ERROR", payload: { message : errorMessage } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}



