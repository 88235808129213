import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import Menu from '../../components/menu';
import CustomTable from '../../components/table';
import MuestrasIcon from "../../components/icons/muestras";
import RecogidasIcon from "../../components/icons/recogidas";
import deleteIcon from '../../assets/img/delete-icon.svg';

import { Modal } from 'antd';
import { ExclamationCircleOutlined,
            LoadingOutlined } from '@ant-design/icons';

import { isLogged,
            getPortacitos } from '../../actions/user.js';
import { changePortaStatus } from '../../actions/app.js';

import { withTranslation } from 'react-i18next';

const { confirm } = Modal;

class AdminPortacitos extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded : true,
            dataSource : []
        };
    }

    componentDidMount() {
        const { i18n } = this.props;

        let lang = typeof this.props.match.params.lang !== 'undefined' ? this.props.match.params.lang : null;

        if(lang === 'es' || lang === 'en'){ 
            i18n.changeLanguage(lang) 
        }

        this._getApiData()
    }

    _getApiData(){
        this.setState({ isLoaded : false})

        Promise.resolve(this.props.isLogged())
        .then((response)=> {
            if(this.props.isLoggedUser){
                if(this.props.user.role.code != 0){
                    this.props.history.push('/404')
                }
                
                Promise.resolve(this.props.getPortacitos())
                .then((response)=> {
                    if(this.props.portacitos){
                        let dataSource = [];

                        this.props.portacitos.map((portacito, key) =>{
                            let date = new Date(portacito.date);
                            let fecha = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
                            let item = {
                                id : portacito.id,
                                key : key,
                                fecha : fecha,
                                timestamp : date.getTime(),
                                cantidad : portacito.amount,
                                importe : portacito.price,
                                estado : portacito.status,
                                customer : portacito.customer
                            }

                            dataSource.push(item);
                        })

                        this.setState({ dataSource : dataSource })
                        this.setState({ isLoaded : true })
                    }
                });

            }else{
                this.props.history.push('/login')
            }
        });
    }

    async _changePortaStatus (id) {
        this.setState({ isLoaded : false })
        const result = await this.props.changePortaStatus(id)
        this._getApiData()
    }

    _renderTable() {
        const { t } = this.props;
        const { dataSource } = this.state;

        const columns = [
            {
                title: t('Fecha'),
                dataIndex: "fecha",
                key: "fecha",
                align: "left",
                width: 170,
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.timestamp - b.timestamp,
            },
            {
                title: t('Cliente'),
                dataIndex: "customer",
                key: "customer",
                ellipsis: true,
                width: 280,
                sorter: (a, b) => a.customer.localeCompare(b.customer),
            },
            {
                title: t('Cantidad'),
                dataIndex: "cantidad",
                key: "cantidad",
                ellipsis: true,
                width: 200,
                sorter: (a, b) => a.cantidad - b.cantidad,
            },
            {
                title: t('Importe'),
                dataIndex: "importe",
                key: "importe",
                ellipsis: true,
                width: 250,
                sorter: (a, b) => a.importe.localeCompare(b.importe),
            },
            {
                title: t('Estado'),
                dataIndex: "estado",
                width: 170,
                key: "estado",
                align: "center",
                render: (text, record) => (
                    <React.Fragment>
                        { record.estado === 0 ? (
                            <span className={"white-badge poppins w-100 d-inline-block"} onClick={() => this._changePortaStatus(record.id) } role={'button'}>
                                {t('Solicitado')}
                            </span>
                        ) : null }

                        { record.estado === 1 ? (
                            <span className={"purple-light-badge poppins opacity-3 w-100 d-inline-block"} onClick={() => this._changePortaStatus(record.id) } role={'button'}>
                                {t('Enviado')}
                            </span>
                        ) : null }
                    </React.Fragment>
                )
            }

        ]

        const pagination = {  }

        return (
            <React.Fragment>

                <CustomTable
                    columns={columns}
                    dataSource={dataSource}
                    pagination={pagination}
                    loaded={this.state.isLoaded}
                    t={t}
                />
                                
            </React.Fragment>
        )
    }

    _renderLoader(){
        return ('')
    }

    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <div className={"split-container"}>
                    <Menu history={this.props.history} />
                    <div className={"container coachee"}>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <header className={"managementPanel__content-header mb-3"}>
                                    <div className={"col-5"}>
                                        <h1 className={"fs-14 poppins"}>{t('Solicitudes de porta-citos')}</h1>
                                    </div>

                                    <div className={"col-7 text-right"}>
                                        
                                    </div>
                                </header>
                                
                                <section className={"coachee__content-body"}>
                                    { this._renderTable() }
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

function mapStateToProps(state) {
    const storeUsers = state.users;

    return {
        isLoggedUser : storeUsers.isLogged,
        user: storeUsers.user,
        portacitos : storeUsers.portacitos,
        dataError : storeUsers.dataError,
        dataErrorMessage : storeUsers.dataErrorMessage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        isLogged: redirectTo => dispatch(isLogged()),
        getPortacitos: (id) => dispatch(getPortacitos(id)),
        changePortaStatus: (id) => dispatch(changePortaStatus(id))
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(AdminPortacitos));



